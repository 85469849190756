import React from "react";
import styles from "./styles.module.scss";

function MessageIcon() {
  return (
    <div>
      <img
        className={styles.icon_background}
        src="https://advertflair-public.s3.us-west-2.amazonaws.com/assets/icons/message.svg"
        alt=""
      />
    </div>
  );
}

export default MessageIcon;
