import React, { useState } from "react";
import styles from "./ImageCarousel.module.scss";

function ImageCarousel() {
  const [image, setImage] = useState(
    "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/i+phone15_pro.webp"
  );

  const imageFunction = (image) => {
    console.log("working");
    setImage(image);
  };

  return (
    <div className={styles.container}>
      <div className={styles.imageCarousel}>
        <img src={image} alt="" />
      </div>
      <div className={styles.thumbnail}>
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/i+phone15_pro.webp"
          alt=""
          onClick={() =>
            imageFunction(
              "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/i+phone15_pro.webp"
            )
          }
        />
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/macbook_pro.webp"
          alt=""
          onClick={() => {
            imageFunction(
              "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/macbook_pro.webp"
            );
          }}
        />
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/sony-camera.webp"
          alt=""
          onClick={() => {
            imageFunction(
              "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/sony-camera.webp"
            );
          }}
        />
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/havana-leather-chair-yellow.png"
          alt=""
          onClick={() => {
            imageFunction(
              "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/havana-leather-chair-yellow.png"
            );
          }}
        />
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/perri-leather-chair.jpg"
          alt=""
          onClick={() => {
            imageFunction(
              "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/perri-leather-chair.jpg"
            );
          }}
        />
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/02_Orange-Hand-bag.png"
          alt=""
          onClick={() => {
            imageFunction(
              "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/02_Orange-Hand-bag.png"
            );
          }}
        />

        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/03_Checked-Hand-bag.png"
          alt=""
          onClick={() => {
            imageFunction(
              "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/03_Checked-Hand-bag.png"
            );
          }}
        />

        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/Luggage-Bag-Nature.png"
          alt=""
          onClick={() => {
            imageFunction(
              "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/Luggage-Bag-Nature.png"
            );
          }}
        />

        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/Hand-Bag-Blue.png"
          alt=""
          onClick={() => {
            imageFunction(
              "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/Hand-Bag-Blue.png"
            );
          }}
        />

        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/dowel-counter-stool/render_1.webp"
          alt=""
          onClick={() => {
            imageFunction(
              "https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/dowel-counter-stool/render_1.webp"
            );
          }}
        />

        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/render_6.webp"
          alt=""
          onClick={() => {
            imageFunction(
              "https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/render_6.webp"
            );
          }}
        />
      </div>
    </div>
  );
}

export default ImageCarousel;
