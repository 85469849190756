import React, { useRef } from "react";
import axios from "axios";
import styles from "./loginpage.module.scss";
import toast, { Toaster } from "react-hot-toast";

function LoginPage() {
  const usernameRef = useRef(null);
  const passwordRef = useRef(null);

  const handleSignIn = async () => {
    // Extract values from the refs
    const username = usernameRef.current.value;
    const password = passwordRef.current.value;
    if (!username || !password) {
      toast.error("Username or password cannot be blank");
      return;
    }

    try {
      // Make a POST request to the API
      const response = await axios.post(
        "http://localhost:8000/client/login.php",
        {
          username,
          password,
        }
      );
      console.log("Login successful:", response.data);

      if (response.data.success) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Login failed:", error);
      // Handle errors like displaying a message to the user
    }
  };
  return (
    <div className={styles.container}>
      <div className={styles.mainContent}>
        <div className={styles.logoSection}>
          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/assets/icons/advertflair_bgremoved.png"
            alt=""
          />
          <p>Advertflair</p>
        </div>
        <div className={styles.loginSection}>
          <div className={styles.title}>Sign in to your account</div>
          <div className={styles.inputSection}>
            <label htmlFor="">Username</label>
            <input
              type="text"
              placeholder="Enter your name"
              id="username"
              ref={usernameRef}
              autoComplete="new"
            />
          </div>
          <div className={styles.inputSection}>
            <label htmlFor="">Password</label>
            <input
              type="password"
              ref={passwordRef}
              placeholder="Enter your password"
              id="password"
              autoComplete="new-password"
            />
          </div>

          <div className={styles.additionalSection}>
            <div style={{ display: "flex", gap: "8px" }}>
              <input type="checkbox" id="rememberMe" name="rememberMe" />
              Remember Me
            </div>
            <div>Forget Password</div>
          </div>

          <button onClick={handleSignIn}>Signin</button>
        </div>
      </div>
      <Toaster />
    </div>
  );
}

export default LoginPage;
