import React from "react";
import styles from "./styles.module.scss";
import SearchIcon from "../icons/SearchIcon";

function TableSearch() {
  return (
    <div className={`${styles.container} ${styles.bordered}`}>
      <SearchIcon />
      <input type="text" placeholder="Search" />
    </div>
  );
}

export default TableSearch;
