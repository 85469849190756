import React from "react";

function Walkthrough() {
  return (
    <div>
      <div style={{ width: "100%", height: "100vh" }}>
        <iframe
          src="https://experience.crigroups.com"
          style={{ width: "100%", height: "100%", border: "none" }}
          title="Embedded Page"
        />
      </div>
      <div className="absolute z-10 bottom-0 bg-black h-12 w-full"></div>
    </div>
  );
}

export default Walkthrough;
