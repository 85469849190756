import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { useParams } from "react-router-dom";

function OverallStool() {
  const [desktopHeader, setDesktopHeader] = useState("");
  const [desktopFooter, setDesktopFooter] = useState("");
  const [mobileHeader, setMobileHeader] = useState("");
  const [mobileFooter, setMobileFooter] = useState("");
  const { client } = useParams();

  const handleClientChange = () => {
    if (client == "ralph-lauren") {
      setDesktopFooter(
        "https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/dowel-counter-stool/footer.webp"
      );
      setDesktopHeader(
        "https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/dowel-counter-stool/header.webp"
      );
      setMobileHeader(
        "https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/dowel-counter-stool/mobile_header.webp"
      );
      setMobileFooter(
        "https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/dowel-counter-stool/mobile_footer.webp"
      );
    } else if (client == "urbn") {
      setDesktopFooter(
        "https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/overall/footer.webp"
      );
      setDesktopHeader(
        "https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/overall/header.webp"
      );
      setMobileHeader(
        "https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/overall/mobileheader.webp"
      );
      setMobileFooter(
        "https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/overall/mobilefooter.webp"
      );
    }
  };

  const [selectedImage, setSelectedImage] = useState(
    "https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/dowel-counter-stool/render_1.webp"
  );
  const [glbView, setGlbView] = useState(false);
  const [mobilevideo, setMobilevideo] = useState(false);
  const [desktopvideo, setDesktopvideo] = useState(false);
  const [renderImage, setRenderImage] = useState(true);
  const toggleGlbView = () => {
    setGlbView(!glbView);
    setMobilevideo(false);
    setDesktopvideo(false);
    setRenderImage(false);
  };

  const togglemobileVideo = () => {
    setMobilevideo(!mobilevideo);
    setGlbView(false);
    setRenderImage(false);
  };

  const toggledesktopVideo = () => {
    setDesktopvideo(!desktopvideo);
    setGlbView(false);
    setRenderImage(false);
  };

  const handleImageClick = (imageSrc) => {
    setRenderImage(true);
    setGlbView(false);
    setMobilevideo(false);
    setDesktopvideo(false);
    setSelectedImage(imageSrc);
  };

  useEffect(() => {
    handleClientChange();
  }, [client]);
  return (
    <div>
      <div className={styles.mobilecontainer}>
        <img src={mobileHeader} alt="Mobile Header" />
        <div className={styles.renderSection}>
          {mobilevideo ? (
            <video
              autoPlay
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/dowel-counter-stool/video_preview.mp4"
            ></video>
          ) : (
            ""
          )}

          {glbView ? (
            <div>
              <iframe
                title="desktopglb"
                src="https://www.pod.advflr.com/turnaround/anthropologie/dowel-counter-stool"
                width={318}
                height={320}
              ></iframe>{" "}
            </div>
          ) : (
            ""
          )}

          {renderImage ? <img src={selectedImage} alt="" /> : ""}
        </div>
        <div className={styles.thumbnailSection}>
          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/dowel-counter-stool/render_1.webp"
            alt=""
            onClick={() =>
              handleImageClick(
                "https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/dowel-counter-stool/render_1.webp"
              )
            }
          />
          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/dowel-counter-stool/render_2.webp"
            alt=""
            onClick={() =>
              handleImageClick(
                "https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/dowel-counter-stool/render_2.webp"
              )
            }
          />
          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/dowel-counter-stool/glb_thumbnail.webp"
            alt=""
            onClick={toggleGlbView}
          />

          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/dowel-counter-stool/render_3.webp"
            alt="Thumb 2"
            onClick={() =>
              handleImageClick(
                "https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/dowel-counter-stool/render_3.webp"
              )
            }
          />

          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/dowel-counter-stool/render_4.webp"
            alt=""
            onClick={() =>
              handleImageClick(
                "https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/dowel-counter-stool/render_4.webp"
              )
            }
          />
        </div>
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/dowel-counter-stool/mobile_details.webp"
          alt=""
        />
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/dowel-counter-stool/mobile_related_1.webp"
          alt=""
        />
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/dowel-counter-stool/mobile_related_2.webp"
          alt=""
        />
        <img src={mobileFooter} alt="" />
      </div>

      <div className={styles.desktopcontainer}>
        <img src={desktopHeader} alt="Header" />

        <div className={styles.mainContainer}>
          <div className={styles.renderSection}>
            <div className={styles.render}>
              {desktopvideo ? (
                <video
                  autoPlay
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/dowel-counter-stool/video_preview.mp4"
                ></video>
              ) : (
                ""
              )}

              {glbView ? (
                <div>
                  <iframe
                    title="desktopglb"
                    src="https://www.pod.advflr.com/turnaround/anthropologie/dowel-counter-stool"
                    width={472}
                    height={512}
                  ></iframe>{" "}
                </div>
              ) : (
                ""
              )}

              {renderImage ? <img src={selectedImage} alt="" /> : ""}
            </div>

            <div className={styles.thumbnailSection}>
              <img
                src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/dowel-counter-stool/render_1.webp"
                alt="Thumb1"
                onClick={() =>
                  handleImageClick(
                    "https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/dowel-counter-stool/render_1.webp"
                  )
                }
              />
              <img
                src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/dowel-counter-stool/render_2.webp"
                alt="Thumb 2"
                onClick={() =>
                  handleImageClick(
                    "https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/dowel-counter-stool/render_2.webp"
                  )
                }
              />
              <img
                src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/dowel-counter-stool/glb_thumbnail.webp"
                alt="Glb Thumbnail"
                onClick={toggleGlbView}
              />

              <img
                src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/dowel-counter-stool/render_3.webp"
                alt="Thumb 2"
                onClick={() =>
                  handleImageClick(
                    "https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/dowel-counter-stool/render_3.webp"
                  )
                }
              />

              <img
                src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/dowel-counter-stool/render_4.webp"
                alt=""
                onClick={() =>
                  handleImageClick(
                    "https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/dowel-counter-stool/render_4.webp"
                  )
                }
              />
            </div>
          </div>
          <div className={styles.detailSection}>
            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/dowel-counter-stool/details.webp"
              alt=""
            />
          </div>
        </div>

        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/dowel-counter-stool/related_1.webp"
          alt=""
        />
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/dowel-counter-stool/related_2.webp"
          alt=""
        />
        <img src={desktopFooter} alt="" />
      </div>
    </div>
  );
}

export default OverallStool;
