import React, { useState } from "react";
import styles from "./KaneKidsMini.module.scss";

function KaneKidsMini() {
  const [renderImage, setRenderImage] = useState(
    "https://advertflair-public.s3.us-west-2.amazonaws.com/client/Statebags/kanekids_minibag/thumbnail_1.webp"
  );
  const [imageContainer, setImageContainer] = useState(true);
  const [glbContainer, setGlbContainer] = useState(false);
  const [videoContainer, setVideoContainer] = useState(false); // Corrected variable name
  const [interactiveContainer, setInteractiveContainer] = useState(false); // Corrected variable name
  const [defaultContainer, setDefaultContaienr] = useState(true);
  const [airplaneContainer, setairplaneContaienr] = useState(false);
  const [astronautContainer, setastronautContaienr] = useState(false);
  const [greennavyContainer, setgreennavyContaienr] = useState(false);
  const [fuzzycontainer, setfuzzyContaienr] = useState(false);
  const [furryheartscontainer, setfurryheartsContaienr] = useState(false);
  const [strawberriescontainer, setstrawberriesContaienr] = useState(false);
  const [pinksilvercontainer, setpinksilverContaienr] = useState(false);
  

  const thumbnail1Image = () => {
    setRenderImage(
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/Statebags/kanekids_minibag/thumbnail_1.webp"
    );
    setImageContainer(true);
    setVideoContainer(false);
    setGlbContainer(false);
    setInteractiveContainer(false);
  };

  const toggleairplaneContainer = () => {
    setDefaultContaienr(false);
    setairplaneContaienr(true);
    setRenderImage(
      "https://statebags.com/cdn/shop/files/STB1860_KaneKidsMiniTravel_Airplanes_TT_FRONT_1.png?v=1711384788&width=900"
    );
  };
  const toggleastronautContainer = () => {
    setDefaultContaienr(false);
    setairplaneContaienr(false);
    setastronautContaienr(true);
    setRenderImage(
      "https://statebags.com/cdn/shop/files/STB1624_KaneKidsMini_Astronaut_TT_FRONT.png?v=1683061324&width=1296"
    );
  };
  const togglegreennavycontainer = () => { 
    setDefaultContaienr(false);
    setairplaneContaienr(false);
    setastronautContaienr(false);
    setgreennavyContaienr(true);
    setRenderImage(
      "https://statebags.com/cdn/shop/files/STB1960_KaneKidsMiniTravel_Green_Navy_TT_FRONT_1.png?v=1718046764&width=1296"
    );
  };
  const togglefuzzycontainer = () => { 
    setDefaultContaienr(false);
    setairplaneContaienr(false);
    setastronautContaienr(false);
    setgreennavyContaienr(false);
    setfuzzyContaienr(true);
    setRenderImage(
      "https://statebags.com/cdn/shop/files/STB2015_KaneKidsMini_FuzzyBolt_TT_FRONT_1.png?v=1718140155&width=1296"
    );
  };
  const togglefurryheartscontainer = () => { 
    setDefaultContaienr(false);
    setairplaneContaienr(false);
    setastronautContaienr(false);
    setgreennavyContaienr(false);
    setfuzzyContaienr(false);
    setfurryheartsContaienr(true)
    setRenderImage(
      "https://statebags.com/cdn/shop/files/STB1766_KANEKIDSMINI_FUZZYHEARTS_TT_FRONT_1.png?v=1713986575&width=1296"
    );
  };
  const togglestrawberriescontainer = () => { 
    setDefaultContaienr(false);
    setairplaneContaienr(false);
    setastronautContaienr(false);
    setgreennavyContaienr(false);
    setfuzzyContaienr(false);
    setfurryheartsContaienr(false)
    setstrawberriesContaienr(true)
    setRenderImage(
      "https://statebags.com/cdn/shop/files/STB1980_KaneKidsMiniTravel_Strawberries_TT_FRONT_1.png?v=1718046303&width=1296"
    );
  };
  const togglepinksilvercontainer = () => { 
    setDefaultContaienr(false);
    setairplaneContaienr(false);
    setastronautContaienr(false);
    setgreennavyContaienr(false);
    setfuzzyContaienr(false);
    setfurryheartsContaienr(false)
    setstrawberriesContaienr(false)
    setpinksilverContaienr(true)
    setRenderImage(
      "https://statebags.com/cdn/shop/files/STB1974_KaneKidsMiniTravel_Pink_Silver_TT_FRONT_1.png?v=1718034274&width=1296"
    );
  };
  const toggledefaultContainer = () => {
    setDefaultContaienr(true);
    setairplaneContaienr(false);
    setastronautContaienr(false);
    setgreennavyContaienr(false);
    setRenderImage(
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/Statebags/kanekids_minibag/thumbnail_1.webp"
    );
  };

  const thumbnail2Image = () => {
    setRenderImage(
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/Statebags/kanekids_minibag/thumbnail_2.webp"
    );
    setImageContainer(true);
    setVideoContainer(false);
    setGlbContainer(false);
    setInteractiveContainer(false);
  };

  const thumbnail3Image = () => {
    setRenderImage(
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/Statebags/kanekids_minibag/thumbnail_3.webp"
    );
    setImageContainer(true);
    setVideoContainer(false);
    setGlbContainer(false);
    setInteractiveContainer(false);
  };

  const glbFunction = () => {
    setImageContainer(false);
    setVideoContainer(false);
    setGlbContainer(true);
    setInteractiveContainer(false);
  };

  const interactiveFunction = () => {
    setImageContainer(false);
    setVideoContainer(false);
    setGlbContainer(false);
    setInteractiveContainer(true);
  };

  const videoFunction = () => {
    setImageContainer(false);
    setGlbContainer(false);
    setVideoContainer(true);
    setInteractiveContainer(false);
  };

  return (
    <div>
      <div className={styles.container}>
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/Statebags/kanekids_minibag/desktop_header.webp"
          alt="Desktop Header"
        />

        <div className={styles.mainsection}>
          <div className={styles.leftSection}>
            <div className={styles.renderSection}>
              {imageContainer && <img src={renderImage} alt="Render Image" />}

              {glbContainer && (
                <iframe
                  src="https://www.pod.advflr.com/turnaround/Statebags/kans-kids-mini"
                  title="glb"
                  frameborder="0"
                ></iframe>
              )}

              {interactiveContainer && (
                <iframe
                  src="https://app.vectary.com/p/6Gu4LTxb1aFxociI12iPQa"
                  title="Interactive"
                  frameborder="0"
                ></iframe>
              )}

              {videoContainer && (
                <video
                  id="my-video"
                  class="video-js"
                  controls
                  preload="auto"
                  autoPlay
                  poster=""
                  data-setup="{}"
                  muted
                >
                  <source
                    src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/Statebags/kanekids_minibag/kane-kids-mini-video.mp4"
                    type="video/mp4"
                  />
                </video>
              )}
            </div>

            <div className={styles.thumbnailSection}>
              {defaultContainer && (
                <>
                  <img
                    src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/Statebags/kanekids_minibag/thumbnail_1.webp"
                    alt="Thumbnail 1"
                    onClick={() =>
                      setRenderImage(
                        "https://advertflair-public.s3.us-west-2.amazonaws.com/client/Statebags/kanekids_minibag/thumbnail_1.webp"
                      )
                    }
                  />
                  <img
                    src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/Statebags/kanekids_minibag/thumbnail_2.webp"
                    alt="Thumbnail 2"
                    onClick={() =>
                      setRenderImage(
                        "https://advertflair-public.s3.us-west-2.amazonaws.com/client/Statebags/kanekids_minibag/thumbnail_2.webp"
                      )
                    }
                  />
                  <img
                    src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/Statebags/kanekids_minibag/thumbnail_3.webp"
                    alt="Thumbnail 3"
                    onClick={() =>
                      setRenderImage(
                        "https://advertflair-public.s3.us-west-2.amazonaws.com/client/Statebags/kanekids_minibag/thumbnail_3.webp"
                      )
                    }
                  />
                  <img
                    src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/366-1.webp"
                    alt="360 View"
                    onClick={glbFunction}
                  />
                  <img
                    src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/belkin/earbuds/touch_view.webp"
                    alt="360 View"
                    onClick={interactiveFunction}
                  />
                  <img
                    src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/video_thumbnail.webp"
                    alt="Video"
                    onClick={videoFunction}
                  />
                </>
              )}

              {airplaneContainer && (
                <>
                  <img
                    src="https://statebags.com/cdn/shop/files/STB1860_KaneKidsMiniTravel_Airplanes_TT_FRONT_1.png?v=1711384788&width=900"
                    alt="Thumbnail 1"
                    onClick={() =>
                      setRenderImage(
                        "https://statebags.com/cdn/shop/files/STB1860_KaneKidsMiniTravel_Airplanes_TT_FRONT_1.png?v=1711384788&width=900"
                      )
                    }
                  />
                  <img
                    src="https://statebags.com/cdn/shop/files/STB1860_KaneKidsMiniTravel_Airplanes_BACK_QTR_LUGGAGE_1.png?v=1711384788&width=900"
                    alt="Thumbnail 2"
                    onClick={() =>
                      setRenderImage(
                        "https://statebags.com/cdn/shop/files/STB1860_KaneKidsMiniTravel_Airplanes_BACK_QTR_LUGGAGE_1.png?v=1711384788&width=900"
                      )
                    }
                  />
                  <img
                    src="https://statebags.com/cdn/shop/files/STB1860_KaneKidsMiniTravel_Airplanes_TT_BACK_QTR_1.png?v=1711384788&width=900"
                    alt="Thumbnail 3"
                    onClick={() =>
                      setRenderImage(
                        "https://statebags.com/cdn/shop/files/STB1860_KaneKidsMiniTravel_Airplanes_TT_BACK_QTR_1.png?v=1711384788&width=900"
                      )
                    }
                  />
                  <img
                    src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/366-1.webp"
                    alt="360 View"
                    onClick={glbFunction}
                  />
                  <img
                    src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/belkin/earbuds/touch_view.webp"
                    alt="360 View"
                    onClick={interactiveFunction}
                  />
                  <img
                    src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/video_thumbnail.webp"
                    alt="Video"
                    onClick={videoFunction}
                  />
                </>
              )}
              {astronautContainer && (
                <>
                  <img
                    src="https://statebags.com/cdn/shop/files/STB1624_KaneKidsMini_Astronaut_TT_FRONT.png?v=1683061324&width=180"
                    alt="Thumbnail 1"
                    onClick={() =>
                      setRenderImage(
                        "https://statebags.com/cdn/shop/files/STB1624_KaneKidsMini_Astronaut_TT_FRONT.png?v=1683061324&width=1296"
                      )
                    }
                  />
                  <img
                    src="https://statebags.com/cdn/shop/files/STB1735_KaneKidsMiniTravel_Astronaut_BACK_QTR_LUGGAGE_1.png?v=1695738757&width=1296"
                    alt="Thumbnail 2"
                    onClick={() =>
                      setRenderImage(
                        "https://statebags.com/cdn/shop/files/STB1735_KaneKidsMiniTravel_Astronaut_BACK_QTR_LUGGAGE_1.png?v=1695738757&width=1296"
                      )
                    }
                  />
                  <img
                    src="https://statebags.com/cdn/shop/files/STB1624_KaneKidsMini_Astronaut_TT_SIDE.png?v=1695738756&width=1296"
                    alt="Thumbnail 3"
                    onClick={() =>
                      setRenderImage(
                        "https://statebags.com/cdn/shop/files/STB1624_KaneKidsMini_Astronaut_TT_SIDE.png?v=1695738756&width=1296"
                      )
                    }
                  />
                  <img
                    src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/366-1.webp"
                    alt="360 View"
                    onClick={glbFunction}
                  />
                  <img
                    src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/belkin/earbuds/touch_view.webp"
                    alt="360 View"
                    onClick={interactiveFunction}
                  />
                  <img
                    src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/video_thumbnail.webp"
                    alt="Video"
                    onClick={videoFunction}
                  />
                </>
              )}
              {greennavyContainer && (
                <>
                  <img
                    src="https://statebags.com/cdn/shop/files/STB1960_KaneKidsMiniTravel_Green_Navy_TT_FRONT_1.png?v=1718046764&width=1296"
                    alt="Thumbnail 1"
                    onClick={() =>
                      setRenderImage(
                        "https://statebags.com/cdn/shop/files/STB1960_KaneKidsMiniTravel_Green_Navy_TT_FRONT_1.png?v=1718046764&width=1296"
                      )
                    }
                  />
                  <img
                    src="https://statebags.com/cdn/shop/files/STB1960_KaneKidsMiniTravel_Green_Navy_TT_FRONT_INT_1.png?v=1718046764&width=1296"
                    alt="Thumbnail 2"
                    onClick={() =>
                      setRenderImage(
                        "https://statebags.com/cdn/shop/files/STB1960_KaneKidsMiniTravel_Green_Navy_TT_FRONT_INT_1.png?v=1718046764&width=1296"
                      )
                    }
                  />
                  <img
                    src="https://statebags.com/cdn/shop/files/STB1960_KaneKidsMiniTravel_Green_Navy_TT_FRONT_QTR_1.png?v=1718046764&width=1296"
                    alt="Thumbnail 3"
                    onClick={() =>
                      setRenderImage(
                        "https://statebags.com/cdn/shop/files/STB1960_KaneKidsMiniTravel_Green_Navy_TT_FRONT_QTR_1.png?v=1718046764&width=1296"
                      )
                    }
                  />
                  <img
                    src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/366-1.webp"
                    alt="360 View"
                    onClick={glbFunction}
                  />
                  <img
                    src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/belkin/earbuds/touch_view.webp"
                    alt="360 View"
                    onClick={interactiveFunction}
                  />
                  <img
                    src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/video_thumbnail.webp"
                    alt="Video"
                    onClick={videoFunction}
                  />
                </>
              )}
              {fuzzycontainer && (
                <>
                  <img
                    src="https://statebags.com/cdn/shop/files/STB2015_KaneKidsMini_FuzzyBolt_TT_FRONT_1.png?v=1718140155&width=1296"
                    alt="Thumbnail 1"
                    onClick={() =>
                      setRenderImage(
                        "https://statebags.com/cdn/shop/files/STB2015_KaneKidsMini_FuzzyBolt_TT_FRONT_1.png?v=1718140155&width=1296"
                      )
                    }
                  />
                  <img
                    src="https://statebags.com/cdn/shop/files/STB2015_KaneKidsMini_FuzzyBolt_TT_FRONT_QTR_1.png?v=1718140155&width=1296"
                    alt="Thumbnail 2"
                    onClick={() =>
                      setRenderImage(
                        "https://statebags.com/cdn/shop/files/STB2015_KaneKidsMini_FuzzyBolt_TT_FRONT_QTR_1.png?v=1718140155&width=1296"
                      )
                    }
                  />
                  <img
                    src="https://statebags.com/cdn/shop/files/STB2015_KaneKidsMini_FuzzyBolt_TT_BACK_QTR_1.png?v=1718140155&width=1296"
                    alt="Thumbnail 3"
                    onClick={() =>
                      setRenderImage(
                        "https://statebags.com/cdn/shop/files/STB2015_KaneKidsMini_FuzzyBolt_TT_BACK_QTR_1.png?v=1718140155&width=1296"
                      )
                    }
                  />
                  <img
                    src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/366-1.webp"
                    alt="360 View"
                    onClick={glbFunction}
                  />
                  <img
                    src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/belkin/earbuds/touch_view.webp"
                    alt="360 View"
                    onClick={interactiveFunction}
                  />
                  <img
                    src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/video_thumbnail.webp"
                    alt="Video"
                    onClick={videoFunction}
                  />
                </>
              )}
              {furryheartscontainer && (
                <>
                  <img
                    src="https://statebags.com/cdn/shop/files/STB1766_KANEKIDSMINI_FUZZYHEARTS_TT_FRONT_1.png?v=1713986575&width=1296"
                    alt="Thumbnail 1"
                    onClick={() =>
                      setRenderImage(
                        "https://statebags.com/cdn/shop/files/STB1766_KANEKIDSMINI_FUZZYHEARTS_TT_FRONT_1.png?v=1713986575&width=1296"
                      )
                    }
                  />
                  <img
                    src="https://statebags.com/cdn/shop/files/STB1766_KANEKIDSMINI_FUZZYHEARTS_TT_SIDE_1.png?v=1714135409&width=1296"
                    alt="Thumbnail 2"
                    onClick={() =>
                      setRenderImage(
                        "https://statebags.com/cdn/shop/files/STB1766_KANEKIDSMINI_FUZZYHEARTS_TT_SIDE_1.png?v=1714135409&width=1296"
                      )
                    }
                  />
                  <img
                    src="https://statebags.com/cdn/shop/files/MTvCXlhqcopy.jpg?v=1717083711&width=1296"
                    alt="Thumbnail 3"
                    onClick={() =>
                      setRenderImage(
                        "https://statebags.com/cdn/shop/files/MTvCXlhqcopy.jpg?v=1717083711&width=1296"
                      )
                    }
                  />
                  <img
                    src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/366-1.webp"
                    alt="360 View"
                    onClick={glbFunction}
                  />
                  <img
                    src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/belkin/earbuds/touch_view.webp"
                    alt="360 View"
                    onClick={interactiveFunction}
                  />
                  <img
                    src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/video_thumbnail.webp"
                    alt="Video"
                    onClick={videoFunction}
                  />
                </>
              )}
              {strawberriescontainer && (
                <>
                  <img
                    src="https://statebags.com/cdn/shop/files/STB1980_KaneKidsMiniTravel_Strawberries_TT_FRONT_1.png?v=1718046303&width=1296"
                    alt="Thumbnail 1"
                    onClick={() =>
                      setRenderImage(
                        "https://statebags.com/cdn/shop/files/STB1980_KaneKidsMiniTravel_Strawberries_TT_FRONT_1.png?v=1718046303&width=1296"
                      )
                    }
                  />
                  <img
                    src="https://statebags.com/cdn/shop/files/STB1980_KaneKidsMiniTravel_Strawberries_TT_FRONT_QTR_1.png?v=1718046303&width=1296"
                    alt="Thumbnail 2"
                    onClick={() =>
                      setRenderImage(
                        "https://statebags.com/cdn/shop/files/STB1980_KaneKidsMiniTravel_Strawberries_TT_FRONT_QTR_1.png?v=1718046303&width=1296"
                      )
                    }
                  />
                  <img
                    src="https://statebags.com/cdn/shop/files/STB1980_KaneKidsMiniTravel_Strawberries_TT_BACK_QTR_1.png?v=1718046303&width=1296"
                    alt="Thumbnail 3"
                    onClick={() =>
                      setRenderImage(
                        "https://statebags.com/cdn/shop/files/STB1980_KaneKidsMiniTravel_Strawberries_TT_BACK_QTR_1.png?v=1718046303&width=1296"
                      )
                    }
                  />
                  <img
                    src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/366-1.webp"
                    alt="360 View"
                    onClick={glbFunction}
                  />
                  <img
                    src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/belkin/earbuds/touch_view.webp"
                    alt="360 View"
                    onClick={interactiveFunction}
                  />
                  <img
                    src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/video_thumbnail.webp"
                    alt="Video"
                    onClick={videoFunction}
                  />
                </>
              )}
              {pinksilvercontainer && (
                <>
                  <img
                    src="https://statebags.com/cdn/shop/files/STB1974_KaneKidsMiniTravel_Pink_Silver_TT_FRONT_1.png?v=1718034274&width=1296"
                    alt="Thumbnail 1"
                    onClick={() =>
                      setRenderImage(
                        "https://statebags.com/cdn/shop/files/STB1974_KaneKidsMiniTravel_Pink_Silver_TT_FRONT_1.png?v=1718034274&width=1296"
                      )
                    }
                  />
                  <img
                    src="https://statebags.com/cdn/shop/files/STB1974_KaneKidsMiniTravel_Pink_Silver_TT_FRONT_INT_1.png?v=1718034274&width=1296"
                    alt="Thumbnail 2"
                    onClick={() =>
                      setRenderImage(
                        "https://statebags.com/cdn/shop/files/STB1974_KaneKidsMiniTravel_Pink_Silver_TT_FRONT_INT_1.png?v=1718034274&width=1296"
                      )
                    }
                  />
                  <img
                    src="https://statebags.com/cdn/shop/files/STATE_BTS_24_Size_Comparisons_Kane_Mini.heic?v=1718669538&width=1296"
                    alt="Thumbnail 3"
                    onClick={() =>
                      setRenderImage(
                        "https://statebags.com/cdn/shop/files/STATE_BTS_24_Size_Comparisons_Kane_Mini.heic?v=1718669538&width=1296"
                      )
                    }
                  />
                  <img
                    src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/366-1.webp"
                    alt="360 View"
                    onClick={glbFunction}
                  />
                  <img
                    src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/belkin/earbuds/touch_view.webp"
                    alt="360 View"
                    onClick={interactiveFunction}
                  />
                  <img
                    src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/video_thumbnail.webp"
                    alt="Video"
                    onClick={videoFunction}
                  />
                </>
              )}
            </div>
          </div>
          <div className={styles.rightSection}>
            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/Statebags/kanekids_minibag/right_1.webp"
              alt="Cart Section"
            />
            <h2>
              Color <span>Polyster Canvas | PINK / MINT</span>
            </h2>

            <div className={styles.thumbnailSection}>
              <img
                src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/Statebags/kanekids_minibag/thumbnail_1.webp"
                alt="Image-1"
                onClick={toggledefaultContainer}
              />
              <img
                src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/kanekidsminitravel-airplanes.webp"
                alt="Image-2"
                onClick={toggleairplaneContainer}
              />
              <img
                src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/kanekidsmini-astronaut.webp"
                alt="Image-3"
                onClick={toggleastronautContainer}
              />
              <img
                src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/kanekidsminitravel-green-navy.webp"
                alt="Image-4"
                onClick={togglegreennavycontainer}
              />
              <img
                src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/kanekidsmini-fuzzybolt.webp"
                alt="Image-5"
                onClick={togglefuzzycontainer}
              />
              <img
                src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/kannekidsmini-furryhearts.webp"
                alt="Image-6"
                onClick={togglefurryheartscontainer}
              />
              <img
                src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/kanekidsminitravel_strawberries.webp"
                alt="Image-7"
                onClick={togglestrawberriescontainer}
              />
              <img
                src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/kanekidsminitravel-pink-silver.webp"
                alt="Image-8"
                onClick={togglepinksilvercontainer}
              />
            </div>

            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/Statebags/kanekids_minibag/right_2.webp"
              alt="Cart Section"
            />
          </div>
        </div>

        <div className={styles.footerSection}>
          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/Statebags/kanekids_minibag/desktop_footer.webp"
            alt="footer"
          />
        </div>
      </div>

      <div className={styles.mobileContainer}>
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/Statebags/kanekids_minibag/mobile_header.webp"
          alt="Mobile Header"
        />

        <div className={styles.mainSection}>
          <div className={styles.renderSection}>
            {imageContainer && <img src={renderImage} alt="Render Image" />}

            {glbContainer && (
              <iframe
                src="https://www.pod.advflr.com/turnaround/Statebags/kans-kids-mini"
                title="glb"
                frameborder="0"
              ></iframe>
            )}

            {interactiveContainer && (
              <iframe
                src="https://app.vectary.com/p/6Gu4LTxb1aFxociI12iPQa"
                title="Interactive"
                frameborder="0"
              ></iframe>
            )}

            {videoContainer && (
              <video
                id="my-video"
                class="video-js"
                controls
                preload="auto"
                autoPlay
                poster=""
                data-setup="{}"
                muted
              >
                <source
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/Statebags/kanekids_minibag/kane-kids-mini-video.mp4"
                  type="video/mp4"
                />
              </video>
            )}
          </div>

          <div className={styles.thumbnailSection}>
            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/Statebags/kanekids_minibag/thumbnail_1.webp"
              alt="Thumbnail 1"
              onClick={thumbnail1Image}
            />
            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/Statebags/kanekids_minibag/thumbnail_2.webp"
              alt="Thumbnail 2"
              onClick={thumbnail2Image}
            />
            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/Statebags/kanekids_minibag/thumbnail_3.webp"
              alt="Thumbnail 3"
              onClick={thumbnail3Image}
            />
            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/366-1.webp"
              alt="360 View"
              onClick={glbFunction}
            />
            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/belkin/earbuds/touch_view.webp"
              alt="Interactive View"
              onClick={interactiveFunction}
            />
            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/video_thumbnail.webp"
              alt="Video"
              onClick={videoFunction}
            />
          </div>
        </div>
        <div className={styles.footerSection}>
          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/Statebags/kanekids_minibag/mobile_footer.webp"
            alt="Mobile Footer"
          />
        </div>
      </div>
    </div>
  );
}

export default KaneKidsMini;
