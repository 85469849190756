import React from "react";
import styles from "./styles.module.scss";

function SortFiles() {
  return (
    <select className={styles.container} name="" id="">
      <option value="" selected>
        Sort by
      </option>
      <option value="">POID</option>
      <option value="">Priority</option>
      <option value="">Revision</option>
      <option value="">LOT</option>
      <option value="">Uploaded On</option>
    </select>
  );
}

export default SortFiles;
