import React from "react";
import { pagelayout } from "../../../../assets/bestbuy";
import styles from "../../../../index.css";

function BestbuyFooter() {
  const desktopFooter = pagelayout.find(
    (item) => item.name === "desktopFooter"
  ).link;
  const mobileFooter = pagelayout.find(
    (item) => item.name === "mobileFooter"
  ).link;
  return (
    <div>
      <img className="hidden md:flex" src={desktopFooter} alt="" />
      <img className="md:hidden" src={mobileFooter} alt="" />
    </div>
  );
}

export default BestbuyFooter;
