export const pagelayout = [
  {
    id: "0",
    name: "mobileHeader",
    link: "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/overall/advertflair-page/mobileHeader.webp",
  },
  {
    id: "1",
    name: "desktopHeader",
    link: "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/overall/advertflair-page/desktopHeader.webp",
  },
  {
    id: "2",
    name: "mobileFooter",
    link: "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/overall/advertflair-page/mobileFooter.webp",
  },
  {
    id: "3",
    name: "desktopFooter",
    link: "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/overall/advertflair-page/desktopFooter.webp",
  },
];

export const renderImages = [
  {
    id: 0,
    name: "Earbuds",
    category: "electronics",
    thumbnail:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/airpods+1.webp",
    outputlink:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/airpods+1.webp",
  },
  {
    id: 1,
    name: "Marshall Speaker",
    thumbnail:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/M.webp",
    outputlink:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/M.webp",
  },
  {
    id: 2,
    name: "PSP",
    category: "electronics",
    thumbnail:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/G.webp",
    outputlink:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/G.webp",
  },
  {
    id: 3,
    name: "Samsung Phone",
    category: "electronics",
    thumbnail:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/samsung_s24ultra.webp",
    outputlink:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/samsung_s24ultra.webp",
  },

  {
    id: 4,
    name: "Macbook",
    category: "electronics",
    thumbnail:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/macbook_pro.webp",
    outputlink:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/macbook_pro.webp",
  },

  {
    id: 5,
    name: "Sony Camera",
    category: "electronics",
    thumbnail:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/sony-camera.webp",
    outputlink:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/sony-camera.webp",
  },

  {
    id: 6,
    name: "Drone",
    category: "electronics",
    thumbnail:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/camera-drone.webp",
    outputlink:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/camera-drone.webp",
  },
  {
    id: 7,
    name: "Sofa",
    category: "furniture",
    thumbnail:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/sofa.webp",
    outputlink:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/sofa.webp",
  },
  {
    id: 8,
    name: "Rolex",
    category: "watches",
    thumbnail:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/watch.webp",
    outputlink:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/watch.webp",
  },

  {
    id: 9,
    name: "Stool",
    category: "furniture",
    thumbnail:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/round-velvet-footstool.webp",
    outputlink:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/round-velvet-footstool.webp",
  },

  {
    id: 10,
    name: "Pink Cuboard",
    category: "furniture",
    thumbnail:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/teamson-home-stratford.png",
    outputlink:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/teamson-home-stratford.png",
  },

  {
    id: 11,
    name: "Dress",
    thumbnail:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/claridge-blazer-dress.png",
    outputlink:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/claridge-blazer-dress.png",
  },

  {
    id: 12,
    name: "Bracelet",
    category: "jewellery",
    thumbnail:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/lab-grown-diamond-tennis-bracelet.png",
    outputlink:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/lab-grown-diamond-tennis-bracelet.png",
  },

  {
    id: 13,
    name: "Earing Daisy",
    category: "jewellery",
    thumbnail:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/daisy-drop-earrings.png",
    outputlink:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/daisy-drop-earrings.png",
  },

  {
    id: 14,
    name: "Elongated Earring",
    category: "jewellery",
    thumbnail:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/diamond-elongated-hoop-earrings.png",
    outputlink:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/diamond-elongated-hoop-earrings.png",
  },
  {
    id: 15,
    name: "Crest Bock Bucketd",
    category: "jewellery",
    thumbnail:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/crest-bock-bucket-bag.png",
    outputlink:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/crest-bock-bucket-bag.png",
  },
  {
    id: 16,
    name: "Havana Leather Chair - Blue",
    category: "furniture",
    thumbnail:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/havana-leather-chair-blue.png",
    outputlink:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/havana-leather-chair-blue.png",
  },
  {
    id: 17,
    name: "Havana Leather Chair - Yellow",
    category: "furniture",
    thumbnail:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/havana-leather-chair-yellow.png",
    outputlink:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/havana-leather-chair-yellow.png",
  },
  {
    id: 18,
    name: "Denver Outdoor Sectional",
    category: "furniture",
    thumbnail:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/denver-outdoor-sectional.png",
    outputlink:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/denver-outdoor-sectional.png",
  },
  {
    id: 19,
    name: "Gold Tone Cutout Daisy",
    category: "jewellery",
    thumbnail:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/Gold-tone+Cutout+Daisy.png",
    outputlink:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/Gold-tone+Cutout+Daisy.png",
  },
  {
    id: 20,
    name: "Havana Leather Chair",
    category: "furniture",
    thumbnail:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/havana-leather-chair.jpg",
    outputlink:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/havana-leather-chair.jpg",
  },
  {
    id: 21,
    name: "Economy Safety Glasses",

    thumbnail:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/economy-safety-glasses.jpg",
    outputlink:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/economy-safety-glasses.jpg",
  },
  {
    id: 22,
    name: "Sonoma Tow Seat Sofa",
    category: "furniture",
    thumbnail:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/sonoma-two-seat-sofa.jpg",
    outputlink:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/sonoma-two-seat-sofa.jpg",
  },
  {
    id: 23,
    name: "Perri Leather Chair",
    category: "furniture",
    thumbnail:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/perri-leather-chair.jpg",
    outputlink:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/perri-leather-chair.jpg",
  },

  {
    id: 24,
    name: "Keros outdor Dining",
    category: "furniture",
    thumbnail:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/keros-outdoor-dining-table.jpg",
    outputlink:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/keros-outdoor-dining-table.jpg",
  },
  {
    id: 25,
    name: "Kalis Indoor-outdoor Chair",
    category: "furniture",
    thumbnail:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/kalis-indooroutdoor-occasional-chair.jpg",
    outputlink:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/kalis-indooroutdoor-occasional-chair.jpg",
  },
  {
    id: 26,
    name: "Water Bottle",
    thumbnail:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/01_Water-bottle.png",
    outputlink:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/01_Water-bottle.png",
  },
  {
    id: 27,
    name: "Orange Hand Bag",
    category: "bags",
    thumbnail:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/02_Orange-Hand-bag.png",
    outputlink:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/02_Orange-Hand-bag.png",
  },
  {
    id: 28,
    name: "Checked Hand Bag",
    category: "bags",
    thumbnail:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/03_Checked-Hand-bag.png",
    outputlink:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/03_Checked-Hand-bag.png",
  },
  {
    id: 29,
    name: "Wellington Bag",
    category: "bags",
    thumbnail:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/04_state-Hand-bag.png",
    outputlink:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/04_state-Hand-bag.png",
  },
  {
    id: 30,
    name: "Cap",
    thumbnail:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/Cap.png",
    outputlink:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/Cap.png",
  },
  {
    id: 31,
    name: "Furniture Blue",
    category: "furniture",
    thumbnail:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/Furniture-Blue.png",
    outputlink:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/Furniture-Blue.png",
  },
  {
    id: 32,
    name: "Hand Bag Black",
    category: "bags",
    thumbnail:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/Hand-Bag-Black.png",
    outputlink:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/Hand-Bag-Black.png",
  },
  {
    id: 33,
    name: "Hand Bag - Blue",
    category: "bags",
    thumbnail:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/Hand-Bag-Blue.png",
    outputlink:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/Hand-Bag-Blue.png",
  },
  {
    id: 34,
    name: "Luggage Bag",
    category: "bags",
    thumbnail:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/Luggage-Bag-Nature.png",
    outputlink:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/Luggage-Bag-Nature.png",
  },
  {
    id: 35,
    name: "Louis Vuiton Bag",
    category: "bags",
    thumbnail:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/LV-bag-light.png",
    outputlink:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/LV-bag-light.png",
  },
  {
    id: 36,
    name: "Dowel Counter Stool",
    category: "furniture",
    thumbnail:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/dowel-counter-stool/render_1.webp",
    outputlink:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/dowel-counter-stool/render_1.webp",
  },
  {
    id: 37,
    name: "Owala Freesip Black",
    thumbnail:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/owala_freesip_black/render_4.webp",
    outputlink:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/owala_freesip_black/render_4.webp",
  },
  {
    id: 38,
    name: "Stojo Cup",
    thumbnail:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/render_6.webp",
    outputlink:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/render_6.webp",
  },
  {
    id: 39,
    name: "Torani Sauce",
    thumbnail:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/torani_syrup/thumbnail_4.webp",
    outputlink:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/torani_syrup/thumbnail_4.webp",
  },
  {
    id: 40,
    name: "Torani Syrup",
    thumbnail:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/torani_syrup/renderImage.webp",
    outputlink:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/torani_syrup/renderImage.webp",
  },
];

export const configurator = [
  {
    id: 0,
    name: "Wellington Bag",
    thumbnail:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/Statebags/willington/render_6.webp",
    outputlink: "https://www.pod.advflr.com/client/statebags/wellington",
  },
  {
    id: 1,
    name: "Trolly Stojo",
    thumbnail:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/trolly/render_2.webp",
    outputlink: "https://www.pod.advflr.com/client/statebags/trolly",
  },
];

export const renderVideos = [
  {
    id: 0,
    name: "Marshal Speaker",
    thumbnail:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/M.webp",
    outputlink:
      "https://www.pod.advflr.com/client/videos?videoUrl=https://player.vimeo.com/video/984285632?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
  },
  {
    id: 1,
    name: "Psp Player",
    thumbnail:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/G.webp",
    outputlink:
      "https://player.vimeo.com/video/983568652?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
  },
  {
    id: 2,
    name: "Iphone 15 Pro max",
    thumbnail:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/i+phone15_pro.webp",
    outputlink:
      "https://player.vimeo.com/video/983432609?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
  },
];

export const interactive = [
  {
    id: 0,
    name: "Iphone 15 Pro",
    thumbnail:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/i+phone15_pro.webp",
    outputlink:
      "https://www.pod.advflr.com/client/videos?videoUrl=https://app.vectary.com/p/4evaXuKW9ZH61aIXJm6ftI",
  },
  {
    id: 1,
    name: "Samsung S24 Ultra",
    thumbnail:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/samsung_s24ultra.webp",
    outputlink:
      "https://pod.advflr.com/client/videos?videoUrl=https://app.vectary.com/p/1dZQVoPvuFTxEs8Vr0W8Iw",
  },
  {
    id: 2,
    name: "Macbook Air",
    thumbnail:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/macbook_pro.webp",
    outputlink:
      "https://pod.advflr.com/client/videos?videoUrl=https://app.vectary.com/p/2WqjG9BFsuard1OH8StwC0",
  },
];

export const turnarounds = [
  {
    id: 0,
    name: "Psp Player",
    thumbnail:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/G.webp",
    outputlink: "https://www.pod.advflr.com/turnaround/advertflair/joy_stick",
    qrlink:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/joy_stick_qr.webp",
  },
  {
    id: 1,
    name: "Marshal Speaker",
    thumbnail:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/M.webp",
    outputlink: "https://www.pod.advflr.com/turnaround/advertflair/marshall",
    qrlink:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/marshall_qr.webp",
  },
  {
    id: 2,
    name: "Iphone 15 Pro max",
    thumbnail:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/i+phone15_pro.webp",
    outputlink: "https://www.pod.advflr.com/turnaround/advertflair/speaker",
    qrlink:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/speaker_qr.webp",
  },
];

export const tryons = [
  {
    id: 0,
    name: "Petit Moyen Bag",
    thumbnail:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/petit-moyen-checked-bag.webp",
    outputlink:
      "https://www.bagsdk.advflr.com/?glb=https://advertflair-public.s3.us-west-2.amazonaws.com/client/clarev/output/petitmoyen_ar.glb",
    qrlink:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/petit-moyen-checked-bag-vto-qr.webp",
  },
  {
    id: 1,
    name: "Camflogue Hoodie",
    thumbnail:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/tshirt/camflogue.jpeg",
    outputlink:
      "https://www.shirtsdk.advflr.com/?glb=https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/tshirt/jacket_ar.glb",
    qrlink:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/qr/overCoatQr.png",
  },
  {
    id: 2,
    name: "claridge Blazer Dress",
    thumbnail:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/claridge-blazer-dress.webp",
    outputlink:
      "https://www.shirtsdk.advflr.com/?glb=https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/vto/claridge-blazer-dress-vto.glb",
    qrlink:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/claridge-blazer-dress-qr.png",
  },

  {
    id: 2,
    name: "Diamond Bracelet",
    thumbnail:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/lab-grown-diamond-tennis-bracelet.jpg",
    outputlink: "https://www.pod.advflr.com/turnaround/advertflair/speaker",
    qrlink:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/speaker_qr.webp",
  },
];
