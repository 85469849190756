import React, { useState } from "react";
import styles from "./styles.module.scss";
function Charger() {
  const [selectedImage, setSelectedImage] = useState(
    "https://advertflair-public.s3.us-west-2.amazonaws.com/client/belkin/charger/render_1.webp"
  );
  const [glbContainer, setGlbContainer] = useState(false);
  const [mobileimageContainer, setMobileimageContainer] = useState(true);
  const [imageContainer, setImageContainer] = useState(true);
  const [videoContainer, setVideoContainer] = useState(false);

  const handleImageClick = (imageSrc) => {
    setGlbContainer(false);
    setSelectedImage(imageSrc);
    setImageContainer(true);
    setMobileimageContainer(true);
    setVideoContainer(false);
  };

  const toggleVideo = () => {
    setVideoContainer(true);
    setImageContainer(false);
    setMobileimageContainer(false);
    setGlbContainer(false);
  };

  const toggleGlb = () => {
    setGlbContainer(true);
    setImageContainer(false);
    setMobileimageContainer(false);
    setVideoContainer(false);
  };

  return (
    <div>
      <div className={styles.container}>
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/belkin/earbuds/navbar.webp"
          alt=""
        />
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/belkin/charger/navbar.webp"
          alt=""
        />

        <div className={styles.mainSection}>
          <div className={styles.renderSection}>
            {imageContainer && <img src={selectedImage} alt="" />}
            {glbContainer && (
              <iframe
                title="desktopGlb"
                seamless
                src="https://www.pod.advflr.com/turnaround/belkin/charger_black"
              ></iframe>
            )}

            {videoContainer && (
              <video
                id="my-video"
                class="video-js"
                controls
                preload="auto"
                autoPlay
                poster=""
                data-setup="{}"
                muted
              >
                <source
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/belkin/charger/render_video.mp4"
                  type="video/mp4"
                />
              </video>
            )}
          </div>
          <div className={styles.cartSection}>
            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/belkin/charger/cart.webp"
              alt=""
            />
          </div>
        </div>
        <div className={styles.thumbnailSection}>
          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/belkin/charger/render_1.webp"
            alt=""
            onClick={() =>
              handleImageClick(
                "https://advertflair-public.s3.us-west-2.amazonaws.com/client/belkin/charger/render_1.webp"
              )
            }
          />
          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/belkin/charger/render_2.webp"
            alt=""
            onClick={() =>
              handleImageClick(
                "https://advertflair-public.s3.us-west-2.amazonaws.com/client/belkin/charger/render_2.webp"
              )
            }
          />
          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/belkin/charger/360_icon.webp"
            alt=""
            onClick={toggleGlb}
          />

          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/belkin/charger/video_thumbnail.webp"
            alt=""
            onClick={toggleVideo}
          />

          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/belkin/charger/render_3.webp"
            alt=""
            onClick={() =>
              handleImageClick(
                "https://advertflair-public.s3.us-west-2.amazonaws.com/client/belkin/charger/render_3.webp"
              )
            }
          />
          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/belkin/charger/render_4.webp"
            alt=""
            onClick={() =>
              handleImageClick(
                "https://advertflair-public.s3.us-west-2.amazonaws.com/client/belkin/charger/render_4.webp"
              )
            }
          />
        </div>
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/belkin/charger/footer.webp"
          alt=""
        />
      </div>

      <div className={styles.mobileContainer}>
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/belkin/charger/mobile_header.webp"
          alt=""
        />
        <div className={styles.renderSection}>
          {mobileimageContainer && <img src={selectedImage} alt="" />}
          {glbContainer && (
            <iframe
              title="desktopGlb"
              seamless
              src="https://www.pod.advflr.com/turnaround/belkin/charger_black"
            ></iframe>
          )}

          {videoContainer && (
            <div>
              <video
                id="my-video"
                class="video-js"
                controls
                preload="auto"
                autoPlay
                poster=""
                data-setup="{}"
                muted
              >
                <source
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/belkin/charger/render_video.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
          )}
        </div>

        <div className={styles.thumbnailSection}>
          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/belkin/charger/render_1.webp"
            alt=""
            onClick={() =>
              handleImageClick(
                "https://advertflair-public.s3.us-west-2.amazonaws.com/client/belkin/charger/render_1.webp"
              )
            }
          />
          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/belkin/charger/render_2.webp"
            alt=""
            onClick={() =>
              handleImageClick(
                "https://advertflair-public.s3.us-west-2.amazonaws.com/client/belkin/charger/render_2.webp"
              )
            }
          />
          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/belkin/charger/360_icon.webp"
            alt=""
            onClick={toggleGlb}
          />

          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/belkin/charger/video_thumbnail.webp"
            alt=""
            onClick={toggleVideo}
          />

          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/belkin/charger/render_3.webp"
            alt=""
            onClick={() =>
              handleImageClick(
                "https://advertflair-public.s3.us-west-2.amazonaws.com/client/belkin/charger/render_3.webp"
              )
            }
          />
        </div>
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/belkin/charger/mobile_body.webp"
          alt=""
        />
      </div>
    </div>
  );
}

export default Charger;
