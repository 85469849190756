import React, { useState, useRef } from "react";
import styles from "./styles.module.scss";
import IframeLink from "../modals/iframelink/IframeLink";

function ApprovedTable() {
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalPosition, setModalPosition] = useState({});
  const refsMap = useRef({});

  const handleShareClick = (id) => {
    if (refsMap.current[id]) {
      const rect = refsMap.current[id].getBoundingClientRect();
      setModalPosition({
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX,
      });
      setModalOpen(true);
    }
  };

  const data = [
    {
      id: "Earbuds",
      priority: "High",
      inputRef: "Reference",
      Revision: "Rev - 02",
      iqaFeedbacks: "Feedbacks",
      Lot: "WK-23",
      uploadInfo: "2024-03-12 13:23:23",
    },
    {
      id: "Charger",
      priority: "High",
      inputRef: "Reference",
      Revision: "Rev - 02",
      iqaFeedbacks: "Feedbacks",
      Lot: "WK-23",
      uploadInfo: "2024-03-12 13:23:23",
    },
    // Add other rows as needed
  ];
  return (
    <div className={`${styles.container} ${styles.no_margin}`}>
      <table>
        <thead>
          <tr>
            <td>Job ID / POID</td>
            <td>Priority</td>
            <td>Input Ref</td>
            <td>Revision</td>
            <td>IQA Feedbacks</td>
            <td>LOT</td>
            <td>Upload Info</td>
            <td>Action</td>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item.id}>
              <td>{item.id}</td>
              <td>{item.priority}</td>
              <td>{item.inputRef}</td>
              <td>{item.Revision}</td>
              <td>{item.iqaFeedbacks}</td>
              <td>{item.Lot}</td>
              <td>{item.uploadInfo}</td>

              <td>
                <button
                  ref={(el) => (refsMap.current[item.id] = el)}
                  onClick={() => handleShareClick(item.id)}
                  className={styles.primary}
                >
                  Share
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {isModalOpen && (
        <IframeLink
          position={modalPosition}
          onClose={() => setModalOpen(false)}
        />
      )}{" "}
    </div>
  );
}

export default ApprovedTable;
