import React, { useState } from "react";
import styles from "./Wellington.module.scss";

function Wellington() {
  const [selectedImage, setSelectedImage] = useState(
    "https://advertflair-public.s3.us-west-2.amazonaws.com/client/Statebags/willington/render_1.webp"
  );
  const [glbContainer, setGlbContainer] = useState(false);
  const [mobileimageContainer, setMobileimageContainer] = useState(true);
  const [blackGlbContainer, setBlackGlbContainer] = useState(false);
  const [imageContainer, setImageContainer] = useState(true);
  const [tryonContainer, setTryonContainer] = useState(false);
  const [blacktryonText, setBlacktryonText] = useState(false);
  const [blacktryonContainer, setBlacktryonContainer] = useState(false);
  const [tryonText, setTryonText] = useState(true);
  const [videoContainer, setVideoContainer] = useState(false);

  const handleImageClick = (imageSrc) => {
    setGlbContainer(false);
    setSelectedImage(imageSrc);
    setTryonContainer(false);
    setImageContainer(true);
    setMobileimageContainer(true);
    setBlackGlbContainer(false);
    setVideoContainer(false);
    setBlacktryonText(false);
    setBlacktryonContainer(false);
    setTryonText(true);
  };

  const toggleGlb = () => {
    setGlbContainer(true);
    setImageContainer(false);
    setMobileimageContainer(false);
    setTryonContainer(false);
    setBlackGlbContainer(false);
    setVideoContainer(false);
    setBlacktryonText(false);
    setBlacktryonContainer(false);
    setTryonText(true);
  };

  const toggleremovePopup = () => {
    setBlacktryonContainer(false);
    setTryonContainer(false);
    setMobileimageContainer(true);
  };

  const toggleTryon = () => {
    setGlbContainer(false);
    setImageContainer(false);
    setMobileimageContainer(false);
    setTryonContainer(true);
    setBlackGlbContainer(false);
    setVideoContainer(false);
    setBlacktryonText(false);
    setBlacktryonContainer(false);
    setTryonText(true);
  };

  const toggleVideo = () => {
    setVideoContainer(true);
    setImageContainer(false);
    setMobileimageContainer(false);
    setGlbContainer(false);
    setTryonContainer(false);
    setBlackGlbContainer(false);
    setBlacktryonText(false);
    setBlacktryonContainer(false);
    setTryonText(true);
  };

  const toggleBlackglb = () => {
    setGlbContainer(false);
    setImageContainer(false);
    setMobileimageContainer(false);
    setTryonContainer(false);
    setBlackGlbContainer(true);
    setVideoContainer(false);
    setTryonText(false);
    setBlacktryonText(true);
    setBlacktryonContainer(false);
  };

  const toggleBlacktryon = () => {
    setGlbContainer(false);
    setImageContainer(false);
    setMobileimageContainer(false);
    setTryonContainer(false);
    setBlackGlbContainer(false);
    setVideoContainer(false);
    setTryonText(false);
    setBlacktryonText(true);
    setBlacktryonContainer(true);
  };

  return (
    <div>
      <div className={styles.container}>
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/trolly/header.png"
          alt=""
        />

        <div className={styles.mainSection}>
          <div className={styles.renderSection}>
            {tryonText && <p onClick={toggleTryon}>Try Before Buy</p>}

            {blacktryonText && <p onClick={toggleBlacktryon}>Try Before Buy</p>}
            {imageContainer && <img src={selectedImage} alt="" />}

            {glbContainer && (
              <iframe
                title="desktopGlb"
                seamless
                src="https://www.pod.advflr.com/turnaround/Statebags/wellington"
              ></iframe>
            )}

            {blackGlbContainer && (
              <iframe
                title="desktopGlb"
                seamless
                src="https://www.pod.advflr.com/turnaround/Statebags/wellington_black"
              ></iframe>
            )}

            {videoContainer && (
              <video
                id="my-video"
                class="video-js"
                controls
                preload="auto"
                autoPlay
                poster=""
                data-setup="{}"
                muted
              >
                <source
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/Statebags/willington/wellingtonVideo.mp4"
                  type="video/mp4"
                />
              </video>
            )}

            {tryonContainer && (
              <iframe
                title="desktopGlb"
                seamless
                allow="camera"
                src="https://www.bagsdk.advflr.com/?glb=https://advertflair-public.s3.us-west-2.amazonaws.com/client/Statebags/output/wellington_orange_ar.glb"
              ></iframe>
            )}

            {blacktryonContainer && (
              <iframe
                title="desktopGlb"
                seamless
                allow="camera"
                src="https://www.bagsdk.advflr.com/?glb=https://advertflair-public.s3.us-west-2.amazonaws.com/client/Statebags/output/wellington_black_ar.glb"
              ></iframe>
            )}
          </div>

          <div className={styles.cartSection}>
            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/Statebags/willington/cart_1.webp"
              alt=""
            />

            <p id="colorVariant">Color</p>

            <div className={styles.colorthumbnailSection}>
              <img
                src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/Statebags/willington/render_1.webp"
                alt=""
                onClick={toggleGlb}
              />
              <img
                src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/Statebags/willington/variant_2.webp"
                alt=""
                onClick={toggleBlackglb}
              />
            </div>

            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/Statebags/willington/cart_2.webp"
              alt=""
            />
          </div>
        </div>

        <div className={styles.thumbnailSection}>
          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/Statebags/willington/render_1.webp"
            alt=""
            onClick={() =>
              handleImageClick(
                "https://advertflair-public.s3.us-west-2.amazonaws.com/client/Statebags/willington/render_1.webp"
              )
            }
          />

          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/Statebags/willington/render_2.webp"
            alt=""
            onClick={() =>
              handleImageClick(
                "https://advertflair-public.s3.us-west-2.amazonaws.com/client/Statebags/willington/render_2.webp"
              )
            }
          />

          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/Statebags/willington/render_6.webp"
            alt=""
            onClick={() =>
              handleImageClick(
                "https://advertflair-public.s3.us-west-2.amazonaws.com/client/Statebags/willington/render_6.webp"
              )
            }
          />

          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/366-1.webp"
            alt="360Glb"
            onClick={toggleGlb}
          />

          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/video_thumbnail.webp"
            alt=""
            onClick={toggleVideo}
          />
        </div>
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/Statebags/willington/desktop_footer.webp"
          alt=""
        />
      </div>

      <div className={styles.mobilecontainer}>
        {tryonContainer && (
          <div>
            <div className={styles.popup}>
              <iframe
                title="desktopGlb"
                seamless
                allow="camera"
                src="https://www.bagsdk.advflr.com/?glb=https://advertflair-public.s3.us-west-2.amazonaws.com/client/Statebags/output/wellington_orange_ar.glb"
              ></iframe>
            </div>

            <div onClick={toggleremovePopup} className={styles.closeIcon}>
              X
            </div>
          </div>
        )}

        {blacktryonContainer && (
          <div>
            <div className={styles.popup}>
              <iframe
                title="desktopGlb"
                seamless
                allow="camera"
                src="https://www.bagsdk.advflr.com/?glb=https://advertflair-public.s3.us-west-2.amazonaws.com/client/Statebags/output/wellington_black_ar.glb"
              ></iframe>
            </div>

            <div onClick={toggleremovePopup} className={styles.closeIcon}>
              X
            </div>
          </div>
        )}

        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/Statebags/willington/mobile_header.webp"
          alt=""
        />

        <div className={styles.mainSection}>
          <div className={styles.renderSection}>
            {tryonText && <p onClick={toggleTryon}>Try Before Buy</p>}

            {blacktryonText && <p onClick={toggleBlacktryon}>Try Before Buy</p>}
            {mobileimageContainer && <img src={selectedImage} alt="" />}

            {glbContainer && (
              <iframe
                title="desktopGlb"
                seamless
                src="https://www.pod.advflr.com/turnaround/Statebags/wellington"
              ></iframe>
            )}

            {blackGlbContainer && (
              <iframe
                title="desktopGlb"
                seamless
                src="https://www.pod.advflr.com/turnaround/Statebags/wellington_black"
              ></iframe>
            )}

            {videoContainer && (
              <video
                id="my-video"
                class="video-js"
                controls
                preload="auto"
                autoPlay
                poster=""
                data-setup="{}"
                muted
              >
                <source
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/Statebags/willington/wellingtonVideo.mp4"
                  type="video/mp4"
                />
              </video>
            )}
          </div>
        </div>

        <div className={styles.thumbnailSection}>
          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/Statebags/willington/render_1.webp"
            alt=""
            onClick={() =>
              handleImageClick(
                "https://advertflair-public.s3.us-west-2.amazonaws.com/client/Statebags/willington/render_1.webp"
              )
            }
          />

          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/Statebags/willington/render_2.webp"
            alt=""
            onClick={() =>
              handleImageClick(
                "https://advertflair-public.s3.us-west-2.amazonaws.com/client/Statebags/willington/render_2.webp"
              )
            }
          />

          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/Statebags/willington/render_6.webp"
            alt=""
            onClick={() =>
              handleImageClick(
                "https://advertflair-public.s3.us-west-2.amazonaws.com/client/Statebags/willington/render_6.webp"
              )
            }
          />

          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/366-1.webp"
            alt="360Glb"
            onClick={toggleGlb}
          />
          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/video_thumbnail.webp"
            alt=""
            onClick={toggleVideo}
          />
        </div>
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/Statebags/willington/mobilecart_1.webp"
          alt=""
        />
        <p className={styles.colorlabel}>Color</p>

        <div className={styles.colorthumbnailSection}>
          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/Statebags/willington/render_1.webp"
            alt="Variatn 1"
            onClick={toggleGlb}
          />
          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/Statebags/willington/variant_2.webp"
            alt="Variant 2"
            onClick={toggleBlackglb}
          />
        </div>
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/Statebags/willington/mobilecart_2.webp"
          alt=""
        />

        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/Statebags/willington/mobile_footer.webp"
          alt=""
        />
      </div>
    </div>
  );
}

export default Wellington;
