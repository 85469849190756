import React from "react";

function FaqIcon() {
  return (
    <img
      src="https://advertflair-public.s3.us-west-2.amazonaws.com/assets/icons/faq.svg"
      alt=""
    />
  );
}

export default FaqIcon;
