import React from "react";
import BackIcon from "../../../atoms/icons/BackIcon";
import Title from "../../title/Title";
import styles from "./styles.module.scss";
import common from "../../../../styles/styles.module.scss";
import { Link } from "react-router-dom";
import { useGoBack } from "../../../utils/NavigationUtils";

function Raise() {
  return (
    <div className={styles.container}>
      <div className={styles.leftSection}>
        <BackIcon onclick={useGoBack} />
        <Title
          title="Raised Issues"
          subtitle="Transparency Matters: See All Raised Issues."
        ></Title>
      </div>

      <div className={common.primaryButton}>
        <Link to="/support/new-ticket">Raise New Issue</Link>
      </div>
    </div>
  );
}

export default Raise;
