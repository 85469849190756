import React from "react";
import styles from "./styles.module.scss";
import { useParams } from "react-router-dom";
import QRCode from "qrcode.react";

function ApproveGlb() {
  const { clientId, modelId, lensId, lensGroup } = useParams();

  const vtoUrl = `https://www.pod.advflr.com/client/vto/${lensId}/${lensGroup}`;
  const vtoiframeCode = `<iframe src="${vtoUrl}" width={512} height={512}></iframe>`;

  const glbUrl = `https://www.pod.advflr.com/turnaround/${clientId}/${modelId}`;

  const iframeCode = `<iframe src="${glbUrl}" width={512} height={512}></iframe>`;

  return (
    <div className={styles.container}>
      <div className={styles.exportSection}>
        <div className={styles.titleSection}>
          <h1>3D Model & AR : Generated Code</h1>
          <p>Here's the generated version of AR & 3D Model</p>
        </div>

        <div className={styles.line}></div>

        <div className={styles.outputSection}>
          <div className={styles.qrContainer}>
            <div className={styles.qrSection}>
              <QRCode className={styles.QRCode} value={glbUrl} size={212} />
              <p>360 Turnaround QR</p>
            </div>

            <div className={styles.qrSection}>
              <QRCode className={styles.QRCode} value={vtoUrl} size={212} />
              <p>Virtual Tryon QR</p>
            </div>
          </div>

          <div className={styles.codeContainer}>
            <div className={styles.codeSection}>
              <pre>{iframeCode}</pre>
              <div>
                <button
                  onClick={() => navigator.clipboard.writeText(iframeCode)}
                >
                  Copy ( 360 - GLB )
                </button>
              </div>
            </div>

            <div className={styles.codeSection}>
              <pre>{vtoiframeCode}</pre>
              <div>
                <button
                  onClick={() => navigator.clipboard.writeText(vtoiframeCode)}
                >
                  Copy ( VTO Tryon )
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ApproveGlb;
