import React, { useState } from "react";
import styles from "./Shadestore.module.scss";

function RollerShade() {
  const [selectedImage, setSelectedImage] = useState(
    "https://advertflair-public.s3.us-west-2.amazonaws.com/client/shadestore/roller-shades/render_2.jpg"
  );
  const [glbContainer, setGlbContainer] = useState(false);
  const [mobileimageContainer, setMobileimageContainer] = useState(true);
  const [imageContainer, setImageContainer] = useState(true);
  const [sand, setSand] = useState(false);
  const [beechwood, setBeechwood] = useState(false);
  const [interactive, setInteractive] = useState(false);

  const handleImageClick = (imageSrc) => {
    setGlbContainer(false);
    setSelectedImage(imageSrc);
    setImageContainer(true);
    setMobileimageContainer(true);
    setSand(false);
    setBeechwood(false);
    setInteractive(false);
  };

  const toggleGlb = () => {
    setGlbContainer(true);
    setImageContainer(false);
    setMobileimageContainer(false);
    setSand(false);
    setBeechwood(false);
    setInteractive(false);
  };

  const togglesandGlb = () => {
    setGlbContainer(false);
    setImageContainer(false);
    setMobileimageContainer(false);
    setSand(true);
    setBeechwood(false);
    setInteractive(false);
  };
  const togglebeechwoodGlb = () => {
    setGlbContainer(false);
    setImageContainer(false);
    setMobileimageContainer(false);
    setSand(false);
    setBeechwood(true);
    setInteractive(false);
  };

  const toggleInteractive = () => {
    setGlbContainer(false);
    setImageContainer(false);
    setMobileimageContainer(false);
    setSand(false);
    setBeechwood(false);
    setInteractive(true);
  };

  return (
    <div>
      <div className={styles.container}>
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/shadestore/roller-shades/header_22.png"
          alt=""
        />

        <div className={styles.mainSection}>
          <div className={styles.renderSection}>
            {imageContainer && <img src={selectedImage} alt="" />}

            {glbContainer && (
              <iframe
                title="desktopGlb"
                seamless
                src="https://www.pod.advflr.com/turnaround/shadestore/roller-shades/wall"
              ></iframe>
            )}

            {sand && (
              <iframe
                title="desktopGlb"
                seamless
                src="https://www.pod.advflr.com/turnaround/shadestore/roller-shades-sand/wall"
              ></iframe>
            )}

            {beechwood && (
              <iframe
                title="desktopGlb"
                seamless
                src="https://www.pod.advflr.com/turnaround/shadestore/roller-shades-beachwood/wall"
              ></iframe>
            )}

            {interactive && (
              <iframe
                title="animatedglb"
                src="https://app.vectary.com/p/20DW2E4mxFc88MrutFWCCK"
                seamless
              ></iframe>
            )}
          </div>

          <div className={styles.cartSection}>
            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/shadestore/roller-shades/cart_view.png"
              alt=""
            />

            <div className={styles.colorthumbnailSection}>
              <img
                src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/shadestore/roller-shades/white.png"
                alt=""
                onClick={toggleGlb}
              />
              <img
                src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/shadestore/roller-shades/sand.png"
                alt=""
                onClick={togglesandGlb}
              />
              <img
                src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/shadestore/roller-shades/beechwood.png"
                alt=""
                onClick={togglebeechwoodGlb}
              />
            </div>
          </div>
        </div>

        <div className={styles.thumbnailSection}>
          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/shadestore/roller-shades/render_2.jpg"
            alt=""
            onClick={() =>
              handleImageClick(
                "https://advertflair-public.s3.us-west-2.amazonaws.com/client/shadestore/roller-shades/render_2.jpg"
              )
            }
          />

          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/shadestore/roller-shades/rollershade_1.webp"
            alt=""
            onClick={() =>
              handleImageClick(
                "https://advertflair-public.s3.us-west-2.amazonaws.com/client/shadestore/roller-shades/rollershade_1.webp"
              )
            }
          />

          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/366-1.webp"
            alt="360Glb"
            onClick={toggleGlb}
          />

          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/belkin/earbuds/touch_view.webp"
            alt=""
            onClick={toggleInteractive}
          />
        </div>
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/shadestore/roller-shades/footer_22.png"
          alt=""
        />
      </div>

      <div className={styles.mobilecontainer}>
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/shadestore/roller-shades/header_22.png"
          alt=""
        />

        <div className={styles.mainSection}>
          <div className={styles.renderSection}>
            {mobileimageContainer && <img src={selectedImage} alt="" />}

            {glbContainer && (
              <iframe
                title="desktopGlb"
                seamless
                src="https://www.pod.advflr.com/turnaround/shadestore/roller-shades/wall"
              ></iframe>
            )}

            {sand && (
              <iframe
                title="desktopGlb"
                seamless
                src="https://www.pod.advflr.com/turnaround/shadestore/roller-shades-sand/wall"
              ></iframe>
            )}

            {beechwood && (
              <iframe
                title="desktopGlb"
                seamless
                src="https://www.pod.advflr.com/turnaround/shadestore/roller-shades-beachwood/wall"
              ></iframe>
            )}

            {interactive && (
              <iframe
                title="animatedglb"
                src="https://app.vectary.com/p/20DW2E4mxFc88MrutFWCCK"
                seamless
              ></iframe>
            )}
          </div>
        </div>

        <div className={styles.thumbnailSection}>
          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/shadestore/roller-shades/render_2.jpg"
            alt=""
            onClick={() =>
              handleImageClick(
                "https://advertflair-public.s3.us-west-2.amazonaws.com/client/shadestore/roller-shades/render_2.jpg"
              )
            }
          />

          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/shadestore/roller-shades/rollershade_1.webp"
            alt=""
            onClick={() =>
              handleImageClick(
                "https://advertflair-public.s3.us-west-2.amazonaws.com/client/shadestore/roller-shades/rollershade_1.webp"
              )
            }
          />

          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/366-1.webp"
            alt="360Glb"
            onClick={toggleGlb}
          />

          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/belkin/earbuds/touch_view.webp"
            alt=""
            onClick={toggleInteractive}
          />
        </div>

        <div className={styles.cartSection}>
          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/shadestore/roller-shades/cart_view.png"
            alt=""
          />

          <div className={styles.colorthumbnailSection}>
            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/shadestore/roller-shades/white.png"
              alt=""
              onClick={toggleGlb}
            />
            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/shadestore/roller-shades/sand.png"
              alt=""
              onClick={togglesandGlb}
            />
            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/shadestore/roller-shades/beechwood.png"
              alt=""
              onClick={togglebeechwoodGlb}
            />
          </div>
        </div>

        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/shadestore/cellular-shades/mobile_footer.png"
          alt=""
        />
      </div>
    </div>
  );
}

export default RollerShade;
