import React from "react";
import styles from "./styles.module.scss";

function FileTable() {
  return (
    <div className={`${styles.container} ${styles.no_margin}`}>
      <table>
        <thead>
          <tr>
            <td>Job ID / POID</td>
            <td>Priority</td>
            <td>Input Ref</td>
            <td>Revision</td>
            <td>IQA Feedbacks</td>
            <td>LOT</td>
            <td>Upload Info</td>
            <td>Action</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>PTRCIJSKDGJKSDG</td>
            <td className={styles.danger}>High</td>
            <td>Reference </td>
            <td>Rev - 01</td>
            <td>Feedback</td>
            <td>WK46</td>
            <td>2022-12-05 19:12:12</td>
            <td>
              <button className={styles.primary}>Review</button>
            </td>
          </tr>
          <tr>
            <td>PTRCIJSKDGJKSDG</td>
            <td className={styles.danger}>High</td>
            <td>Reference </td>
            <td>Rev - 01</td>
            <td>Feedback</td>
            <td>WK46</td>
            <td>2022-12-05 19:12:12</td>
            <td>
              <button>Review</button>
            </td>
          </tr>
          <tr>
            <td>PTRCIJSKDGJKSDG</td>
            <td className={styles.danger}>High</td>
            <td>Reference </td>
            <td>Rev - 01</td>
            <td>Feedback</td>
            <td>WK46</td>
            <td>2022-12-05 19:12:12</td>
            <td>
              <button>Review</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default FileTable;
