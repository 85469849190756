import React from "react";
import styles from "./styles.module.scss";
import SearchIcon from "../icons/SearchIcon";

function OverallSearch() {
  return (
    <div className={styles.container}>
      <SearchIcon />
      <input type="text" placeholder="Search Here" />
    </div>
  );
}

export default OverallSearch;
