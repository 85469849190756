export const pagelayout = [
  {
    id: "0",
    name: "mobileHeader",
    link: "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/overall/Best_Buy/mobile_header.webp",
  },
  {
    id: "1",
    name: "desktopHeader",
    link: "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/overall/Best_Buy/archieved/desktop_header.webp",
  },
  {
    id: "2",
    name: "mobileFooter",
    link: "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/overall/Best_Buy/mobile_footer.webp",
  },
  {
    id: "3",
    name: "desktopFooter",
    link: "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/overall/Best_Buy/desktop_footer.webp",
  },
];

export const renderImages = [
  {
    id: 0,
    name: "Earbuds",
    thumbnail:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/airpods+1.webp",
    outputlink:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/airpods+1.webp",
  },
  {
    id: 1,
    name: "Marshall Speaker",
    thumbnail:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/M.webp",
    outputlink:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/M.webp",
  },
  {
    id: 2,
    name: "PSP",
    thumbnail:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/G.webp",
    outputlink:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/G.webp",
  },
  {
    id: 3,
    name: "Samsung Phone",
    thumbnail:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/samsung_s24ultra.webp",
    outputlink:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/samsung_s24ultra.webp",
  },

  {
    id: 4,
    name: "Macbook",
    thumbnail:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/macbook_pro.webp",
    outputlink:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/macbook_pro.webp",
  },

  {
    id: 5,
    name: "Sony Camera",
    thumbnail:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/sony-camera.webp",
    outputlink:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/sony-camera.webp",
  },
];

export const configurator = [
  {
    id: 0,
    name: "Wellington Bag",
    thumbnail:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/Statebags/willington/render_6.webp",
    outputlink: "https://www.pod.advflr.com/client/statebags/wellington",
  },
  {
    id: 1,
    name: "Trolly Stojo",
    thumbnail:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/trolly/render_2.webp",
    outputlink: "https://www.pod.advflr.com/client/statebags/trolly",
  },
];

export const renderVideos = [
  {
    id: 0,
    name: "Marshal Speaker",
    thumbnail:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/M.webp",
    outputlink:
      "https://www.pod.advflr.com/client/videos?videoUrl=https://player.vimeo.com/video/984285632?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
  },
  {
    id: 1,
    name: "Psp Player",
    thumbnail:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/G.webp",
    outputlink:
      "https://player.vimeo.com/video/983568652?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
  },
  {
    id: 2,
    name: "Iphone 15 Pro max",
    thumbnail:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/i+phone15_pro.webp",
    outputlink:
      "https://player.vimeo.com/video/983432609?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
  },
];

export const interactive = [
  {
    id: 0,
    name: "Iphone 15 Pro",
    thumbnail:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/i+phone15_pro.webp",
    outputlink:
      "https://www.pod.advflr.com/client/videos?videoUrl=https://app.vectary.com/p/4evaXuKW9ZH61aIXJm6ftI",
  },
  {
    id: 1,
    name: "Samsung S24 Ultra",
    thumbnail:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/samsung_s24ultra.webp",
    outputlink:
      "https://pod.advflr.com/client/videos?videoUrl=https://app.vectary.com/p/1dZQVoPvuFTxEs8Vr0W8Iw",
  },
  {
    id: 2,
    name: "Macbook Air",
    thumbnail:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/macbook_pro.webp",
    outputlink:
      "https://pod.advflr.com/client/videos?videoUrl=https://app.vectary.com/p/2WqjG9BFsuard1OH8StwC0",
  },
];

export const turnarounds = [
  {
    id: 0,
    name: "Psp Player",
    thumbnail:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/G.webp",
    outputlink: "https://www.pod.advflr.com/turnaround/advertflair/joy_stick",
    qrlink:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/joy_stick_qr.webp",
  },
  {
    id: 1,
    name: "Marshal Speaker",
    thumbnail:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/M.webp",
    outputlink: "https://www.pod.advflr.com/turnaround/advertflair/marshall",
    qrlink:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/marshall_qr.webp",
  },
  {
    id: 2,
    name: "Iphone 15 Pro max",
    thumbnail:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/i+phone15_pro.webp",
    outputlink: "https://www.pod.advflr.com/turnaround/advertflair/speaker",
    qrlink:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/speaker_qr.webp",
  },
];

export const tryons = [
  {
    id: 0,
    name: "Petit Moyen Bag",
    thumbnail:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/petit-moyen-checked-bag.webp",
    outputlink:
      "https://www.bagsdk.advflr.com/?glb=https://advertflair-public.s3.us-west-2.amazonaws.com/client/clarev/output/petitmoyen_ar.glb",
    qrlink:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/petit-moyen-checked-bag-vto-qr.webp",
  },
  {
    id: 1,
    name: "Camflogue Hoodie",
    thumbnail:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/tshirt/camflogue.jpeg",
    outputlink:
      "https://www.shirtsdk.advflr.com/?glb=https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/tshirt/jacket_ar.glb",
    qrlink:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/qr/overCoatQr.png",
  },
  {
    id: 2,
    name: "claridge Blazer Dress",
    thumbnail:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/claridge-blazer-dress.webp",
    outputlink:
      "https://www.shirtsdk.advflr.com/?glb=https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/vto/claridge-blazer-dress-vto.glb",
    qrlink:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/claridge-blazer-dress-qr.png",
  },

  {
    id: 2,
    name: "Diamond Bracelet",
    thumbnail:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/lab-grown-diamond-tennis-bracelet.jpg",
    outputlink: "https://www.pod.advflr.com/turnaround/advertflair/speaker",
    qrlink:
      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/speaker_qr.webp",
  },
];
