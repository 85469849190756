import React from 'react'
import styles from './styles.module.scss'
import common from '../../../../styles/styles.module.scss'

function ActionCard({ title, subtitle, buttonText }) {
  return (
    <div className={styles.container}>
        <div className={common.column_gap_10}>
          <p>{title}</p>
          <h2>{subtitle}</h2>
        </div>
        <button className={styles.primaryButton}>{buttonText}</button>
    </div>
  )
}

export default ActionCard