import React, { useRef } from "react";
import { useParams } from "react-router-dom";
import styles from "./styles.module.scss";

function TurnaroundAnimated() {
  const { client, product } = useParams();
  const modelViewerRef = useRef(null);

  const handleShrinkClick = () => {
    const modelViewer = modelViewerRef.current;
    if (modelViewer) {
      modelViewer.animationName = "shrinkanimation";
      modelViewer.play();
    }

    modelViewer.addEventListener("animationfinish", () => {
      modelViewer.animationName = "";
    });
  };

  return (
    <div className={styles.container}>
      <model-viewer
        src={`https://advertflair-public.s3.us-west-2.amazonaws.com/client/${client}/output/${product}.glb`}
        ar
        ar-modes="webxr scene-viewer quick-look"
        camera-controls
        tone-mapping="neutral"
        poster="poster.webp"
        shadow-intensity="0.37"
        camera-orbit="0deg 75deg 2.174m"
        field-of-view="30deg"
        shadow-softness="1"
        ref={modelViewerRef}
      >
        <button
          class="Hotspot"
          slot="hotspot-1"
          data-surface="0 0 65610 58576 58575 0.109 0.441 0.450"
          data-visibility-attribute="visible"
          onClick={handleShrinkClick}
        >
          <div class="HotspotAnnotation">Shrink</div>
        </button>
        <button
          class="Hotspot"
          slot="hotspot-2"
          data-surface="0 0 59911 66631 66632 0.383 0.264 0.353"
          data-visibility-attribute="visible"
        ></button>

        <button
          class="Hotspot"
          slot="hotspot-5"
          data-surface="0 0 22113 5914 22120 0.001 0.469 0.530"
          data-visibility-attribute="visible"
        >
          <div class="HotspotAnnotation">Stop Animation</div>
        </button>
        <div class="progress-bar hide" slot="progress-bar">
          <div class="update-bar"></div>
        </div>
        <button slot="ar-button" id="ar-button">
          View in your space
        </button>
        <div id="ar-prompt">
          <img
            alt=""
            src="https://modelviewer.dev/shared-assets/icons/hand.png"
          />
        </div>
      </model-viewer>
    </div>
  );
}

export default TurnaroundAnimated;
