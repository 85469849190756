import React from 'react'
import styles from './styles.module.scss'
import OverallSearch from '../../atoms/inputs/OverallSearch'
import NotificationIcon from '../../atoms/icons/NotificationIcon'
import MessageIcon from '../../atoms/icons/MessageIcon'
import ProfileIcon from '../../atoms/icons/ProfileIcon'

function HomeHeader() {
  return (
        <div className={styles.container}>
            <div>
              <OverallSearch />
            </div>
          
            <div className={styles.notification}>
                <MessageIcon />
                <NotificationIcon/>
                <ProfileIcon />
            </div>
        </div>
  )
}

export default HomeHeader