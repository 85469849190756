import React from "react";

function Livesupport() {
  return (
    <img
      src="https://advertflair-public.s3.us-west-2.amazonaws.com/assets/icons/Livesupport.svg"
      style={{ width: "32px" }}
      alt=""
    />
  );
}

export default Livesupport;
