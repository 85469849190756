import React, { useState } from "react";
import styles from "./styles.module.scss";

function BaseballHat() {
  const [glbView, setGlbView] = useState(false);
  const [mobileGlb, setMobileGlb] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [desktopAr, setDesktopAr] = useState(false);
  const [mobileAr, setMobileAr] = useState(false);

  const [imageView, setImageView] = useState(true);

  const toggleDesktopAR = () => {
    setDesktopAr(!desktopAr);
    setGlbView(false);
    setMobileGlb(false);
    setImageView(!imageView);
  };

  const toggleMobileAR = () => {
    setMobileAr(!mobileAr);
    setGlbView(false);
    setMobileGlb(false);
    setImageView(!imageView);
  };

  const [selectedImage, setSelectedImage] = useState(
    "https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/baseball-hat/render_1.webp"
  );

  const toggleMobileglb = () => {
    setMobileGlb(!mobileGlb);
    setImageView(!imageView);
    setShowVideo(false);
  };

  const handleImageClick = (imageSrc) => {
    setGlbView(false);
    setShowVideo(false);
    setMobileGlb(false);
    setMobileAr(false);
    setDesktopAr(false);
    setSelectedImage(imageSrc);
    setImageView(true);
  };

  return (
    <div>
      <div className={styles.container}>
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/baseball-hat/mobile_header.webp"
          alt=""
        />

        <div className={styles.renderSection}>
          <p onClick={toggleMobileAR}>Try Before Buy</p>
          {mobileGlb && (
            <iframe
              title="mobileGlb"
              src="https://www.pod.advflr.com/turnaround/anthropologie/baseball-hat"
            ></iframe>
          )}

          {mobileAr && (
            <iframe
              title="mobileAr"
              src="https://www.pod.advflr.com/client/vto/frontcamera/d204a9a7-3df4-4718-b035-99c185f1b182/1ee15629-39c0-4eb9-849b-8dabd1a6729f"
            ></iframe>
          )}

          {imageView && <img src={selectedImage} alt="" />}
        </div>

        <div className={styles.thumbnailSection}>
          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/baseball-hat/render_1.webp"
            alt=""
            onClick={() =>
              handleImageClick(
                "https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/baseball-hat/render_1.webp"
              )
            }
          />
          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/baseball-hat/render_2.webp"
            alt=""
            onClick={() =>
              handleImageClick(
                "https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/baseball-hat/render_2.webp"
              )
            }
          />

          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/baseball-hat/360_thumbnail.webp"
            onClick={toggleMobileglb}
            alt=""
          />
        </div>

        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/baseball-hat/mobile_details_1.webp"
          alt=""
        />
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/baseball-hat/mobile_details_2.webp"
          alt=""
        />
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/baseball-hat/mobile_footer.webp"
          alt=""
        />
      </div>

      <div className={styles.desktopContainer}>
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/baseball-hat/header.webp"
          alt=""
        />

        <div className={styles.mainContainer}>
          <div className={styles.renderSection}>
            <p onClick={toggleDesktopAR}>Try Before Buy</p>

            {mobileGlb && (
              <iframe
                title="mobileGlb"
                src="https://www.pod.advflr.com/turnaround/anthropologie/baseball-hat"
              ></iframe>
            )}

            {desktopAr && (
              <iframe
                title="desktopAR"
                src="https://www.pod.advflr.com/client/vto/frontcamera/d204a9a7-3df4-4718-b035-99c185f1b182/1ee15629-39c0-4eb9-849b-8dabd1a6729f"
              ></iframe>
            )}

            {showVideo && (
              <video
                className={styles.videoSection}
                autoPlay
                src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/dowel-counter-stool/video_preview.mp4"
              ></video>
            )}

            {imageView && <img src={selectedImage} alt="" />}
          </div>

          <div className={styles.cartSection}>
            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/baseball-hat/details_1.webp"
              alt=""
            />
          </div>
        </div>

        <div className={styles.thumbnailSection}>
          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/baseball-hat/render_1.webp"
            alt=""
            onClick={() =>
              handleImageClick(
                "https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/baseball-hat/render_1.webp"
              )
            }
          />
          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/baseball-hat/render_2.webp"
            alt=""
            onClick={() =>
              handleImageClick(
                "https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/baseball-hat/render_2.webp"
              )
            }
          />
          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/baseball-hat/360_thumbnail.webp"
            onClick={toggleMobileglb}
            alt=""
          />
        </div>

        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/baseball-hat/people_viewed.webp"
          alt=""
        />

        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/baseball-hat/footer.webp"
          alt=""
        />
      </div>
    </div>
  );
}

export default BaseballHat;
