import React, { useEffect, useState } from 'react'
import styles from './styles.module.scss';
import FetchS3 from './FetchS3';
import { useParams } from 'react-router-dom';

function ViewGlb() {

    const { clientId, modelId } = useParams();
    const glbUrl = `client/${clientId}/output/${modelId}.glb`;
    const posterUrl = `/assets/client/${clientId}/output/${modelId}.webp`;
    const { fileUrl, objectUrl } = FetchS3(glbUrl);
    const [glbData, setGlbData] = useState('');
    const [glbLoaded, setGlbLoaded] = useState(false);

    useEffect(() => {
        if (fileUrl && objectUrl) {
            setGlbData(objectUrl);
            setGlbLoaded(true);
        }

        return () => {
        };
    }, [ fileUrl, objectUrl]);

  return (
    <div className={styles.container}>

        <div className={styles.glbSection}>
            {!glbLoaded && <p>Loading GLB model...</p>}
                <model-viewer
                    alt="Rolex Model"
                    src={glbData}
                    ar
                    poster={posterUrl}
                    shadow-intensity="1"
                    camera-controls
                    touch-action="pan-y"
                    style={{ width: '100%', height: '100vh' }}
                ></model-viewer>
            </div>       
    </div>
  )
}

export default ViewGlb