import React from "react";
import styles from "./styles.module.scss";
import { useParams } from "react-router-dom";

function TurnaroundWall() {
  const { client, product } = useParams();

  return (
    <div className={styles.container}>
      <model-viewer
        src={`https://advertflair-public.s3.us-west-2.amazonaws.com/client/${client}/output/${product}.glb`}
        ar
        ar-placement="wall"
        poster={`https://advertflair-public.s3.us-west-2.amazonaws.com/client/${client}/output/${product}.webp`}
        shadow-intensity="1"
        camera-controls
        style={{ width: "100%", height: "100vh" }}
        touch-action="pan-y"
        autoplay
      ></model-viewer>
    </div>
  );
}

export default TurnaroundWall;
