import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";

function ExternalFeedback() {
  const [data, setData] = useState({ files: [] });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://3dstudio.advertflair.com/external_feedback_images.php?poid=PTRC06VVHTMEYTBL"
        );
        const jsonData = await response.json();
        setData(jsonData);
      } catch (error) {
        console.error("Failed to fetch data", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <h1>External Feedback Images:</h1>
      <ul className={styles.externalrejections}>
        {data.files.map((file, index) => (
          <li key={index}>
            <h2>{file.file_name}</h2>
            <img src={file.image_url} alt={file.file_name} />
            <p>Created at: {file.file_created_time}</p>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default ExternalFeedback;
