import React from "react";
const VrEnvironment = () => {
  // The path to your 360-degree skybox image
  const skyboxImage = "path_to_your_skybox_image.jpg";

  return (
    <a-scene
      vr-mode-ui="enabled: true" // Enable VR button
      arjs="sourceType: webcam;" // AR.js for simpler AR, if using
      embedded
      renderer="colorManagement: true; antialias: true;"
      webxr="requiredFeatures: hit-test, local-floor, bounded-floor; optionalFeatures: hand-tracking"
    >
      <a-entity
        gltf-model="url(https://advertflair-public.s3.us-west-2.amazonaws.com/client/rebags/bags/louis-vuitton/louis-vuitton-bag.glb)"
        position="0 1 -5"
        scale="0.5 0.5 0.5" // Scale down for AR if needed
        id="model"
      ></a-entity>

      <button id="startAR">Start AR</button>
    </a-scene>
  );
};

export default VrEnvironment;
