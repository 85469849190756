import React from "react";
import styles from "./Claires.module.scss";

function ClairesDaisy() {
  return (
    <div>
      <div className={styles.container}>
        <img src="https://placehold.co/1440x246?text=desktop-header" alt="" />

        <div className={styles.mainSection}>
          <div className={styles.leftSection}>
            <div className={styles.renderSection}>
              <img
                src="https://placehold.co/512x512?text=render-image"
                alt="Render Image"
              />
            </div>

            <div className={styles.thumbnailSection}>
              <img src="https://placehold.co/512x512?text=thumbnail-1" />
              <img src="https://placehold.co/512x512?text=thumbnail-2" />
              <img src="https://placehold.co/512x512?text=thumbnail-3" />
              <img src="https://placehold.co/512x512?text=360" />
              <img src="https://placehold.co/512x512?text=video" />
            </div>
          </div>
          <div className={styles.rightSection}>
            <img src="https://placehold.co/666x1200?text=Cart-Section" alt="" />
          </div>
        </div>

        <div className={styles.footerSection}>
          <img
            src="https://placehold.co/1440x2400?text=Desktop-Footer"
            alt=""
          />
        </div>
      </div>
      <div className={styles.mobileContainer}>
        <img src="https://placehold.co/512x120?text=mobile-header" alt="" />

        <div className={styles.mainSection}>
          <div className={styles.renderSection}>
            <img src="https://placehold.co/512x512?text=mobile-render" alt="" />
          </div>

          <div className={styles.thumbnailSection}>
            <img src="https://placehold.co/512x512?text=thumbnail-1" />
            <img src="https://placehold.co/512x512?text=thumbnail-2" />
            <img src="https://placehold.co/512x512?text=thumbnail-3" />
            <img src="https://placehold.co/512x512?text=360" />
            <img src="https://placehold.co/512x512?text=video" />
          </div>
        </div>

        <div className={styles.footerSection}>
          <img src="https://placehold.co/512x1200?text=mobile-footer" alt="" />
        </div>
      </div>
    </div>
  );
}

export default ClairesDaisy;
