import React, { useState } from "react";
import styles from "./styles.module.scss";
function Earbuds() {
  const [selectedImage, setSelectedImage] = useState(
    "https://advertflair-public.s3.us-west-2.amazonaws.com/client/belkin/earbuds/render_1.webp"
  );
  const [glbContainer, setGlbContainer] = useState(false);
  const [touchableGlbContainer, setTouchableGlbContainer] = useState(false);
  const [greenglb, setGreenglb] = useState(false);
  const [blackglb, setBlackglb] = useState(false);
  const [violetglb, setVioletglb] = useState(false);
  const [whiteglb, setWhiteglb] = useState(false);
  const [mobileimageContainer, setMobileimageContainer] = useState(true);
  const [imageContainer, setImageContainer] = useState(true);

  const handleImageClick = (imageSrc) => {
    setGlbContainer(false);
    setGreenglb(false);
    setBlackglb(false);
    setVioletglb(false);
    setWhiteglb(false);
    setSelectedImage(imageSrc);
    setImageContainer(true);
    setMobileimageContainer(true);
    setTouchableGlbContainer(false);
  };

  const toggleGlb = () => {
    setGlbContainer(true);
    setImageContainer(false);
    setMobileimageContainer(false);
    setGreenglb(false);
    setTouchableGlbContainer(false);
  };

  const touchableGlb = () => {
    setGlbContainer(false);
    setImageContainer(false);
    setMobileimageContainer(false);
    setGreenglb(false);
    setVioletglb(false);
    setWhiteglb(false);
    setBlackglb(false);
    setTouchableGlbContainer(true);
  };

  const toggleBlackglb = () => {
    setGlbContainer(false);
    setImageContainer(false);
    setMobileimageContainer(false);
    setGreenglb(false);
    setVioletglb(false);
    setWhiteglb(false);
    setBlackglb(true);
    setTouchableGlbContainer(false);
  };

  const toggleGreenglb = () => {
    setGlbContainer(false);
    setImageContainer(false);
    setMobileimageContainer(false);
    setVioletglb(false);
    setWhiteglb(false);
    setBlackglb(false);
    setTouchableGlbContainer(false);
    setGreenglb(true);
  };

  const toggleWhiteglb = () => {
    setGlbContainer(false);
    setImageContainer(false);
    setMobileimageContainer(false);
    setGreenglb(false);
    setVioletglb(false);
    setWhiteglb(true);
    setBlackglb(false);
    setTouchableGlbContainer(false);
  };

  return (
    <div>
      <div className={styles.container}>
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/belkin/earbuds/navbar.webp"
          alt=""
        />
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/belkin/earbuds/navbar_2.webp"
          alt=""
        />

        <div className={styles.mainSection}>
          <div className={styles.renderSection}>
            {imageContainer && <img src={selectedImage} alt="" />}
            {glbContainer && (
              <iframe
                title="desktopGlb"
                seamless
                src="https://www.pod.advflr.com/turnaround/belkin/earbuds_white"
              ></iframe>
            )}

            {blackglb && (
              <iframe
                title="desktopGlb"
                seamless
                src="https://www.pod.advflr.com/turnaround/belkin/earbuds_black"
              ></iframe>
            )}

            {touchableGlbContainer && (
              <iframe
                title="animatedglb"
                src="https://app.vectary.com/p/7OuGgUWzbo3hHOt4V0HoVw"
                seamless
              ></iframe>
            )}

            {violetglb && (
              <iframe
                title="desktopGlb"
                seamless
                src="https://www.pod.advflr.com/turnaround/belkin/earbuds_violet"
              ></iframe>
            )}

            {greenglb && (
              <iframe
                title="desktopGlb"
                seamless
                src="https://www.pod.advflr.com/turnaround/belkin/earbuds_green"
              ></iframe>
            )}

            {whiteglb && (
              <iframe
                title="desktopGlb"
                height={"692"}
                width={"495"}
                seamless
                src="https://www.pod.advflr.com/turnaround/belkin/earbuds_white"
              ></iframe>
            )}
          </div>
          <div className={styles.cartSection}>
            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/belkin/earbuds/cart_1.png"
              alt=""
            />

            <div className={styles.colorSection}>
              <p>Color</p>
              <div className={styles.colors}>
                <div onClick={toggleBlackglb} className={styles.black}></div>
                <div onClick={toggleGreenglb} className={styles.green}></div>
                <div onClick={toggleWhiteglb} className={styles.white}></div>
              </div>
            </div>
            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/belkin/earbuds/cart_2.png"
              alt=""
              style={{ marginLeft: "2rem" }}
            />
          </div>
        </div>
        <div className={styles.thumbnailSection}>
          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/belkin/earbuds/render_1.webp"
            alt=""
            onClick={() =>
              handleImageClick(
                "https://advertflair-public.s3.us-west-2.amazonaws.com/client/belkin/earbuds/render_1.webp"
              )
            }
          />
          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/belkin/earbuds/render_2.webp"
            alt=""
            onClick={() =>
              handleImageClick(
                "https://advertflair-public.s3.us-west-2.amazonaws.com/client/belkin/earbuds/render_2.webp"
              )
            }
          />
          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/belkin/earbuds/360_icon-1.webp"
            alt=""
            onClick={toggleGlb}
          />
          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/belkin/earbuds/touch_view.webp"
            alt=""
            onClick={touchableGlb}
          />
          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/belkin/earbuds/render_3.webp"
            alt=""
            onClick={() =>
              handleImageClick(
                "https://advertflair-public.s3.us-west-2.amazonaws.com/client/belkin/earbuds/render_3.webp"
              )
            }
          />
          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/belkin/earbuds/render_4.webp"
            alt=""
            onClick={() =>
              handleImageClick(
                "https://advertflair-public.s3.us-west-2.amazonaws.com/client/belkin/earbuds/render_4.webp"
              )
            }
          />
        </div>
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/belkin/earbuds/footer.webp"
          alt=""
        />
      </div>

      <div className={styles.mobileContainer}>
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/belkin/earbuds/mobile_header.webp"
          alt=""
        />
        <div className={styles.renderSection}>
          {mobileimageContainer && <img src={selectedImage} alt="" />}

          {glbContainer && (
            <iframe
              title="desktopGlb"
              seamless
              src="https://www.pod.advflr.com/turnaround/belkin/earbuds_white"
            ></iframe>
          )}

          {touchableGlbContainer && (
            <iframe
              title="animatedglb"
              src="https://app.vectary.com/p/7OuGgUWzbo3hHOt4V0HoVw"
              seamless
            ></iframe>
          )}

          {blackglb && (
            <iframe
              title="desktopGlb"
              seamless
              src="https://www.pod.advflr.com/turnaround/belkin/earbuds_black"
            ></iframe>
          )}

          {greenglb && (
            <iframe
              title="desktopGlb"
              seamless
              src="https://www.pod.advflr.com/turnaround/belkin/earbuds_green"
            ></iframe>
          )}

          {violetglb && (
            <iframe
              title="desktopGlb"
              seamless
              src="https://www.pod.advflr.com/turnaround/belkin/earbuds_violet"
            ></iframe>
          )}

          {whiteglb && (
            <iframe
              title="desktopGlb"
              height={"692"}
              width={"495"}
              seamless
              src="https://www.pod.advflr.com/turnaround/belkin/earbuds_white"
            ></iframe>
          )}
        </div>

        <div className={styles.thumbnailSection}>
          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/belkin/earbuds/render_1.webp"
            alt=""
            onClick={() =>
              handleImageClick(
                "https://advertflair-public.s3.us-west-2.amazonaws.com/client/belkin/earbuds/render_1.webp"
              )
            }
          />
          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/belkin/earbuds/render_2.webp"
            alt=""
            onClick={() =>
              handleImageClick(
                "https://advertflair-public.s3.us-west-2.amazonaws.com/client/belkin/earbuds/render_2.webp"
              )
            }
          />
          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/belkin/earbuds/360_icon-1.webp"
            alt=""
            onClick={toggleGlb}
          />
          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/belkin/earbuds/touch_view.webp"
            alt=""
            onClick={touchableGlb}
          />
          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/belkin/earbuds/render_3.webp"
            alt=""
            onClick={() =>
              handleImageClick(
                "https://advertflair-public.s3.us-west-2.amazonaws.com/client/belkin/earbuds/render_3.webp"
              )
            }
          />
        </div>
        <div className={styles.colorSection}>
          <p>Color</p>
          <div className={styles.colors}>
            <div onClick={toggleBlackglb} className={styles.black}></div>
            <div onClick={toggleGreenglb} className={styles.green}></div>
            <div onClick={toggleWhiteglb} className={styles.white}></div>
          </div>
        </div>
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/belkin/earbuds/mobile_body.jpg"
          alt=""
        />
      </div>
    </div>
  );
}

export default Earbuds;
