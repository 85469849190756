import React, { useState, useEffect } from "react";
import layout from "./layout.module.scss";
import {
  bootstrapCameraKit,
  createMediaStreamSource,
  Transform2D,
} from "@snap/camera-kit";

function LouisBag() {
  const [selectedImage, setSelectedImage] = useState(
    "https://advertflair-public.s3.us-west-2.amazonaws.com/client/rebags/bags/louis-vuitton/render_1.webp"
  );
  const [arViewActive, setArViewActive] = useState(false);
  const [mobileArViewActive, setMobileArViewActive] = useState(false);
  const [glbView, setGlbView] = useState(false);
  const [cameraView, setCameraView] = useState(true);
  const [showVideo, setShowVideo] = useState(false);

  const toggleVideo = () => {
    setShowVideo(!showVideo);
    setGlbView(false);
  };

  const toggleGlbView = () => {
    setGlbView(!glbView);
    setShowVideo(false);
  };

  const handleImageClick = (imageSrc) => {
    setGlbView(false);
    setShowVideo(false);
    setSelectedImage(imageSrc);
  };

  useEffect(() => {
    const main = async () => {
      try {
        const apiToken =
          "eyJhbGciOiJIUzI1NiIsImtpZCI6IkNhbnZhc1MyU0hNQUNQcm9kIiwidHlwIjoiSldUIn0.eyJhdWQiOiJjYW52YXMtY2FudmFzYXBpIiwiaXNzIjoiY2FudmFzLXMyc3Rva2VuIiwibmJmIjoxNzEwNTMxNjIxLCJzdWIiOiI0ZmM0NTg4Yi01OWMwLTRmNWUtYjYyMS05ZjZiNDM4MTFkZjZ-U1RBR0lOR345ZGI4NjliZS05MThjLTQ4ZWQtYmY2ZC02YjUzMjczMTk2NzQifQ.tYZpUHNq29VUhg0Jg910He63IsnKPGSyRrfeTTRi3Ow"; // Replace with your actual API token
        const cameraKit = await bootstrapCameraKit({ apiToken });

        const canvas = document.getElementById("arBox");
        const session = await cameraKit.createSession({
          liveRenderTarget: canvas,
        });
        session.events.addEventListener("error", (event) => {
          if (event.detail.error.name === "LensExecutionError") {
            console.log(
              "The current Lens encountered an error and was removed.",
              event.detail.error
            );
          }
        });

        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        const cameraType = cameraView ? "rear" : "front";
        const source = createMediaStreamSource(stream, {
          transform: Transform2D.MirrorX,
          cameraType: cameraType,
        });
        await session.setSource(source);

        const lens = await cameraKit.lensRepository.loadLens(
          "0b364b49-5822-486c-ad54-888e1543b953",
          "1ee15629-39c0-4eb9-849b-8dabd1a6729f"
        );
        await session.applyLens(lens);

        await session.play();
        console.log("Lens rendering has started!");
      } catch (error) {
        // Log the error if it's not the "Uncaught runtime error" you want to skip
        if (error.name !== "UncaughtRuntimeError") {
          console.error("Error accessing camera:", error);
        }
      }
    };

    const mainMobile = async () => {
      try {
        const apiToken =
          "eyJhbGciOiJIUzI1NiIsImtpZCI6IkNhbnZhc1MyU0hNQUNQcm9kIiwidHlwIjoiSldUIn0.eyJhdWQiOiJjYW52YXMtY2FudmFzYXBpIiwiaXNzIjoiY2FudmFzLXMyc3Rva2VuIiwibmJmIjoxNzEwNTMxNjIxLCJzdWIiOiI0ZmM0NTg4Yi01OWMwLTRmNWUtYjYyMS05ZjZiNDM4MTFkZjZ-U1RBR0lOR345ZGI4NjliZS05MThjLTQ4ZWQtYmY2ZC02YjUzMjczMTk2NzQifQ.tYZpUHNq29VUhg0Jg910He63IsnKPGSyRrfeTTRi3Ow"; // Replace with your actual API token
        const cameraKit = await bootstrapCameraKit({ apiToken });

        const canvas = document.getElementById("arBox2");
        const session = await cameraKit.createSession({
          liveRenderTarget: canvas,
        });
        session.events.addEventListener("error", (event) => {
          if (event.detail.error.name === "LensExecutionError") {
            console.log(
              "The current Lens encountered an error and was removed.",
              event.detail.error
            );
          }
        });

        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        const cameraType = cameraView ? "rear" : "front"; // Use cameraView state here
        const source = createMediaStreamSource(stream, {
          transform: Transform2D.MirrorX,
          cameraType: cameraType,
        });
        await session.setSource(source);

        const lens = await cameraKit.lensRepository.loadLens(
          "7daf88c7-687a-4b7b-81d2-8f379fa55fc2",
          "1ee15629-39c0-4eb9-849b-8dabd1a6729f"
        );
        await session.applyLens(lens);

        await session.play();
        console.log("Lens rendering has started!");
      } catch (error) {
        // Log the error if it's not the "Uncaught runtime error" you want to skip
        if (error.name !== "UncaughtRuntimeError") {
          console.error("Error accessing camera:", error);
        }
      }
    };

    if (arViewActive) {
      main();
    }

    if (mobileArViewActive) {
      mainMobile();
    }

    return () => {
      // Cleanup code if needed
    };
  }, [arViewActive, mobileArViewActive, cameraView]);

  const toggleARView = () => {
    setArViewActive(!arViewActive);
  };

  const toggleMobileARView = () => {
    setMobileArViewActive(!mobileArViewActive);
  };

  const toggleCameraView = () => {
    setCameraView((prevCameraView) => !prevCameraView);
  };

  const switchCamera = () => {
    toggleCameraView(); // Toggle camera view between front and rear
  };

  return (
    <div>
      <div className={layout.Desktopcontainer}>
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/rebags/glasses/+oakley-sutro-sunglasses+/desktopheader.webp"
          alt=""
        />

        <div className={layout.contentSection}>
          <div className={layout.thumbnailSection}>
            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/rebags/bags/louis-vuitton/render_1.webp"
              onClick={() =>
                handleImageClick(
                  "https://advertflair-public.s3.us-west-2.amazonaws.com/client/rebags/bags/louis-vuitton/render_1.webp"
                )
              }
              alt="Render"
            />
            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/rebags/bags/louis-vuitton/render_2.webp"
              onClick={() =>
                handleImageClick(
                  "https://advertflair-public.s3.us-west-2.amazonaws.com/client/rebags/bags/louis-vuitton/render_2.webp"
                )
              }
              alt="Render"
            />
            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/rebags/bags/louis-vuitton/360thumbnail.webp"
              onClick={toggleGlbView}
              alt="Render"
            />

            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/rebags/bags/louis-vuitton/videoThumbnail.webp"
              onClick={toggleVideo}
              alt="Render"
            />
            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/rebags/bags/louis-vuitton/render_3.webp"
              onClick={() =>
                handleImageClick(
                  "https://advertflair-public.s3.us-west-2.amazonaws.com/client/rebags/bags/louis-vuitton/render_3.webp"
                )
              }
              alt="Render"
            />
          </div>

          <div className={layout.renderSection}>
            {arViewActive ? <canvas id="arBox" className={layout.arBox} /> : ""}
            {arViewActive ? <p>Switch Camera</p> : null}
            <div>
              {showVideo && !arViewActive && !glbView && (
                <video
                  autoPlay
                  allowFullscreen="false"
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/rebags/bags/louis-vuitton/renderVideo.mp4"
                ></video>
              )}

              {!showVideo && glbView && (
                <div className={layout.glbSection}>
                  <model-viewer
                    alt="Rolex Model"
                    src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/rebags/bags/louis-vuitton/louis-vuitton-bag.glb"
                    ar
                    poster="https://advertflair-public.s3.us-west-2.amazonaws.com/client/rebags/bags/louis-vuitton/loading_progress.webp"
                    shadow-intensity="1"
                    camera-controls
                    touch-action="pan-y"
                    style={{ width: "502px", height: "502px" }}
                  ></model-viewer>
                </div>
              )}

              {!showVideo && !glbView && (
                <div className={layout.imageSection}>
                  <img src={selectedImage} alt="" />
                </div>
              )}
            </div>
          </div>

          <img
            className={layout.detailsSection}
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/rebags/bags/louis-vuitton/details.jpg"
            alt=""
          />
        </div>
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/rebags/bags/louis-vuitton/similar.jpg"
          alt=""
        />
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/rebags/bags/louis-vuitton/likable.jpg"
          alt=""
        />
        <img src="/assets/client/rebag/watch/sell.png" alt="" />
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/rebags/shoes/nike-air-max-90/assets/footer.webp"
          alt=""
        />
      </div>

      <div className={layout.mobilecontainer}>
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/rebags/bags/louis-vuitton/mobileHeader.jpg"
          alt=""
        />

        <div className={layout.contentSection}>
          <div className={layout.thumbnailSection}>
            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/rebags/bags/louis-vuitton/render_1.webp"
              onClick={() =>
                handleImageClick(
                  "https://advertflair-public.s3.us-west-2.amazonaws.com/client/rebags/bags/louis-vuitton/render_1.webp"
                )
              }
              alt="Render"
            />
            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/rebags/bags/louis-vuitton/render_2.webp"
              onClick={() =>
                handleImageClick(
                  "https://advertflair-public.s3.us-west-2.amazonaws.com/client/rebags/bags/louis-vuitton/render_2.webp"
                )
              }
              alt="Render"
            />
            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/rebags/bags/louis-vuitton/360thumbnail.webp"
              onClick={toggleGlbView}
              alt="Render"
            />
            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/rebags/bags/louis-vuitton/videoThumbnail.webp"
              onClick={toggleVideo}
            />
            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/rebags/bags/louis-vuitton/render_3.webp"
              onClick={() =>
                handleImageClick(
                  "https://advertflair-public.s3.us-west-2.amazonaws.com/client/rebags/bags/louis-vuitton/render_3.webp"
                )
              }
              alt="Render"
            />
          </div>
          <div className={layout.renderSection}>
            {mobileArViewActive ? (
              <canvas id="arBox2" className={layout.arBox} />
            ) : null}
            {mobileArViewActive ? (
              <p onClick={switchCamera}>Switch Camera</p>
            ) : null}
            <div>
              {showVideo && !glbView && (
                <video
                  autoPlay
                  allowFullscreen="false"
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/rebags/bags/louis-vuitton/renderVideo.mp4"
                ></video>
              )}

              {!showVideo && glbView && (
                <div className={layout.glbSection}>
                  <model-viewer
                    alt="Rolex Model"
                    src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/rebags/bags/louis-vuitton/louis-vuitton-bag.glb"
                    ar
                    poster="https://advertflair-public.s3.us-west-2.amazonaws.com/client/rebags/bags/louis-vuitton/loading_progress.webp"
                    shadow-intensity="1"
                    camera-controls
                    touch-action="pan-y"
                    style={{ width: "353px", height: "375px" }}
                  ></model-viewer>
                </div>
              )}

              {!showVideo && !glbView && (
                <div className={layout.imageSection}>
                  <img src={selectedImage} alt="" />
                </div>
              )}
            </div>
          </div>

          <img
            className={layout.detailsSection}
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/rebags/bags/louis-vuitton/mobiledetails.jpg"
            alt=""
          />
        </div>
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/rebags/shoes/nike-air-max-90/assets/mobilesimilar.webp"
          alt=""
        />

        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/rebags/shoes/nike-air-max-90/assets/mobilelikable.webp"
          alt=""
        />
        <img src="/assets/client/rebag/watch/mobile_sell.png" alt="" />
        <img src="/assets/client/rebag/watch/mobile_footer1.png" alt="" />
        <img src="/assets/client/rebag/watch/mobile_footer2.png" alt="" />
      </div>
    </div>
  );
}

export default LouisBag;
