import React from "react";
import styles from "./styles.module.scss";
import DashboardLayout from "../../templates/dashboard_layout/DashboardLayout";
import Title from "../../molecules/title/Title";
import BackIcon from "../../atoms/icons/BackIcon";
import ListJobsHeader from "../../molecules/header/listjobsheader/ListJobsHeader";
import ApprovedTable from "../../molecules/tables/ApprovedTable";

function ApprovedListPage() {
  return (
    <DashboardLayout>
      <div className={styles.header}>
        <BackIcon />
        <Title title="Approved Jobs" subtitle="245 Jobs approved this month" />
      </div>
      <ListJobsHeader />
      <ApprovedTable />
    </DashboardLayout>
  );
}

export default ApprovedListPage;
