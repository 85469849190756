import create from "zustand";

const UsePortfolioStore = create((set) => ({
  selectedMenu: "360 / AR / VR",
  selectedCategory: 'all',
  productsCount: '',
  rawData: [],
  filteredData: [],
  modalWindowOpen: false,
  embedProduct: [],
  categoryCount: [],
  subCategorySelected: false,
  selectedSubCategory: '',

  setSubCategorySelected: (value) => set({subCategorySelected: value}),
  setSelectedSubCategory: (value) => set({selectedSubCategory: value}),
  setEmbedProduct: (value) => set({embedProduct: value}),
  setCategoryCount: (value) => set({categoryCount: value}),
  setProductsCount: (value) => set({productsCount: value}),
  setModalWindowOpen: (value) => set({modalWindowOpen: value}),
  setSelectedMenu: (value) => set({selectedMenu: value}),
  setSelectedCategory: (value) => set({selectedCategory: value}),
  setRawData: (value) => set({rawData: value}),
  setFilteredData: (value) => set({filteredData: value})
}));

export default UsePortfolioStore;
