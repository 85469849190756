import React from 'react'
import styles from './styles.module.scss'
import DashboardLayout from '../../templates/dashboard_layout/DashboardLayout'
import SupportCard from '../../molecules/cards/support/SupportCard'
import Slack from '../../molecules/cards/slackCard/Slack'
import Title from '../../molecules/title/Title'

function SupportHome() {
  return (

    <DashboardLayout>

        <Title
        title="Support"
        subtitle="We're Here. 24/7."
        />

        <div className={styles.cardSection}>
            
            <SupportCard 
                title="Report an issue" 
                subtitle="Spot a Problem? Report It Quickly."
                icon="Info" 
            />

            <SupportCard 
                title="Live support" 
                subtitle="Never Wait. Live Support is Here."
                icon="Support" 
            />

            <SupportCard 
                title="FAQ’s" 
                subtitle="Save Time. Find Answers Here. " 
                icon="Faq"
            />
        </div>

        <Slack />

    </DashboardLayout>
  )
}

export default SupportHome