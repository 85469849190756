import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { useParams } from "react-router-dom";

import {
  bootstrapCameraKit,
  CameraKitSession,
  createMediaStreamSource,
  Transform2D,
} from "@snap/camera-kit";

const VirtualTryon = () => {
  const { lensId, lensGroup } = useParams();

  const [isBackFacing, setIsBackFacing] = useState(true);
  const [mediaStream, setMediaStream] = useState(null);
  const [requestMade, setRequestMade] = useState(false); // Add a state variable to track request status

  useEffect(() => {
    if (!requestMade) {
      setRequestMade(true); // Set the flag to indicate the request has been made

      const liveRenderTarget = document.getElementById("canvas");
      const flipCamera = document.getElementById("flip");

      async function init() {
        const cameraKit = await bootstrapCameraKit({
          apiToken:
            "eyJhbGciOiJIUzI1NiIsImtpZCI6IkNhbnZhc1MyU0hNQUNQcm9kIiwidHlwIjoiSldUIn0.eyJhdWQiOiJjYW52YXMtY2FudmFzYXBpIiwiaXNzIjoiY2FudmFzLXMyc3Rva2VuIiwibmJmIjoxNzEwNTMxNjIxLCJzdWIiOiI0ZmM0NTg4Yi01OWMwLTRmNWUtYjYyMS05ZjZiNDM4MTFkZjZ-U1RBR0lOR345ZGI4NjliZS05MThjLTQ4ZWQtYmY2ZC02YjUzMjczMTk2NzQifQ.tYZpUHNq29VUhg0Jg910He63IsnKPGSyRrfeTTRi3Ow",
        });

        const session = await cameraKit.createSession({ liveRenderTarget });
        const { lenses } = await cameraKit.lensRepository.loadLensGroups([
          lensGroup,
        ]);

        const selectedLens = lenses.find((lens) => lens.id === lensId);

        if (selectedLens) {
          session.applyLens(selectedLens);
        }

        bindFlipCamera(session, flipCamera);
      }

      function bindFlipCamera(session: CameraKitSession, flipCamera) {
        flipCamera.style.cursor = "pointer";

        flipCamera.addEventListener("click", () => {
          updateCamera(session, flipCamera); // Pass flipCamera to updateCamera
        });

        updateCamera(session, flipCamera); // Pass flipCamera to updateCamera
      }

      async function updateCamera(session: CameraKitSession, flipCamera) {
        console.log("this is working");
        setIsBackFacing(!isBackFacing);
        flipCamera.innerText = isBackFacing ? "" : " ";
        if (mediaStream) {
          session.pause();
          mediaStream.getVideoTracks()[0].stop();
        }

        const stream = await navigator.mediaDevices.getUserMedia({
          video: {
            facingMode: isBackFacing ? "environment" : "user", // Reverse facingMode values
          },
        });

        setMediaStream(stream);

        const source = createMediaStreamSource(stream, {
          cameraType: isBackFacing ? "back" : "front",
        });

        await session.setSource(source);

        if (!isBackFacing) {
          source.setTransform(Transform2D.MirrorX);
        }

        session.play();
      }

      init();

      return () => {
        if (mediaStream) {
          mediaStream.getVideoTracks()[0].stop();
        }
      };
    }
  }, [isBackFacing, mediaStream, lensId, lensGroup, requestMade]);

  return (
    <div className={styles.container}>
      <canvas id="canvas" width={1024} />
      <span id="flip"></span>
    </div>
  );
};

export default VirtualTryon;
