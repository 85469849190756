import create from "zustand";

const useDisplayStore = create((set) => ({
  displaySection: "images",
  subCategory: "",
  count: 0,
  renderContainer: false,
  thumbnailsLoaded: false,
  embedUrl: "",
  setDisplaySection: (section) => set({ displaySection: section }),
  setSubCategory: (category) => set({ subCategory: category }),
  setCount: (count) => set({ count }),
  setRenderContainer: (render) => set({ renderContainer: render }),
  setThumbnailsLoaded: (value) => set({ thumbnailsLoaded: value }),
  setEmbedUrl: (url) => set({ embedurl: url }),
}));

export default useDisplayStore;
