import React, { useState } from "react";
import styles from "./styles.module.scss";

function SkinnyJeanVto() {
  const [selectedImage, setSelectedImage] = useState(
    "https://advertflair-public.s3.us-west-2.amazonaws.com/client/perfect-jeans/skinny-2/render_1.webp"
  );
  const [glbContainer, setGlbContainer] = useState(false);
  const [mobileGlbcontainer, setMobileGlbcontainer] = useState(false);
  const [mobileimageContainer, setMobileimageContainer] = useState(true);
  const [imageContainer, setImageContainer] = useState(true);
  const [desktopAr, setDesktopAr] = useState(false);
  const [mobileAr, setMobileAr] = useState(false);

  const toggleDesktopAR = () => {
    setGlbContainer(false);
    setImageContainer(false);
    setDesktopAr(true);
  };

  const toggleMobileAR = () => {
    setMobileGlbcontainer(false);
    setMobileimageContainer(false);
    setMobileAr(true);
  };

  const toggleGlb = () => {
    setGlbContainer(true);
    setImageContainer(false);
  };

  const togglemobileGlb = () => {
    setMobileGlbcontainer(true);
    setMobileimageContainer(false);
  };

  const handleImageClick = (imageSrc) => {
    setGlbContainer(false);
    setMobileGlbcontainer(false);
    setSelectedImage(imageSrc);
    setImageContainer(true);
    setMobileimageContainer(true);
  };

  return (
    <div>
      <div className={styles.container}>
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/perfect-jeans/skinny-2/header.webp"
          alt=""
        />

        <div className={styles.mainSection}>
          <div className={styles.thumbnailSection}>
            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/perfect-jeans/skinny-2/render_1.webp"
              alt=""
              onClick={() =>
                handleImageClick(
                  "https://advertflair-public.s3.us-west-2.amazonaws.com/client/perfect-jeans/skinny-2/render_1.webp"
                )
              }
            />
            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/perfect-jeans/skinny-2/render_2.webp"
              alt=""
              onClick={() =>
                handleImageClick(
                  "https://advertflair-public.s3.us-west-2.amazonaws.com/client/perfect-jeans/skinny-2/render_2.webp"
                )
              }
            />
            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/perfect-jeans/skinny-2/render_3.webp"
              alt=""
              onClick={() =>
                handleImageClick(
                  "https://advertflair-public.s3.us-west-2.amazonaws.com/client/perfect-jeans/skinny-2/render_3.webp"
                )
              }
            />

            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/perfect-jeans/skinny-2/360_thumbnail.webp
              "
              alt=""
              onClick={toggleGlb}
            />
            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/perfect-jeans/skinny-2/render_4.webp"
              alt=""
              onClick={() =>
                handleImageClick(
                  "https://advertflair-public.s3.us-west-2.amazonaws.com/client/perfect-jeans/skinny-2/render_4.webp"
                )
              }
            />
          </div>
          <div className={styles.renderSection}>
            <p onClick={toggleDesktopAR}>Try Before Buy</p>
            {imageContainer && <img src={selectedImage} alt="" />}
            {glbContainer && (
              <iframe
                title="desktopGlb"
                height={"692"}
                width={"495"}
                seamless
                src="https://www.pod.advflr.com/turnaround/perfect-jeans/slim"
              ></iframe>
            )}

            {desktopAr && (
              <iframe
                title="desktopAR"
                src="https://www.pod.advflr.com/client/vto/frontcamera/47fbf7d8-2688-4465-80bc-a6f54d416544/1ee15629-39c0-4eb9-849b-8dabd1a6729f"
              ></iframe>
            )}
          </div>

          <div>
            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/perfect-jeans/skinny-2/cart.webp"
              alt=""
            />
          </div>
        </div>
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/perfect-jeans/skinny/details.webp"
          alt=""
        />

        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/perfect-jeans/skinny-2/footer.webp"
          alt=""
        />
      </div>

      <div className={styles.mobileContainer}>
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/perfect-jeans/skinny-2/mobile_header.webp"
          alt=""
        />

        <div className={styles.mainSection}>
          <div className={styles.renderSection}>
            <p onClick={toggleMobileAR}>Try Before Buy</p>
            {mobileimageContainer && <img src={selectedImage} alt="" />}
            {mobileGlbcontainer && (
              <iframe
                title="desktopGlb"
                width={"240"}
                height={"322"}
                seamless
                src="https://www.pod.advflr.com/turnaround/perfect-jeans/slim"
              ></iframe>
            )}

            {mobileAr && (
              <iframe
                title="mobileAr"
                src="https://www.pod.advflr.com/client/vto/frontcamera/47fbf7d8-2688-4465-80bc-a6f54d416544/1ee15629-39c0-4eb9-849b-8dabd1a6729f"
              ></iframe>
            )}
          </div>
          <div className={styles.thumbnailSection}>
            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/perfect-jeans/skinny-2/render_1.webp"
              alt=""
              onClick={() =>
                handleImageClick(
                  "https://advertflair-public.s3.us-west-2.amazonaws.com/client/perfect-jeans/skinny-2/render_1.webp"
                )
              }
            />
            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/perfect-jeans/skinny-2/render_2.webp"
              alt=""
              onClick={() =>
                handleImageClick(
                  "https://advertflair-public.s3.us-west-2.amazonaws.com/client/perfect-jeans/skinny-2/render_2.webp"
                )
              }
            />
            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/perfect-jeans/skinny-2/360_thumbnail.webp
              "
              alt=""
              onClick={togglemobileGlb}
            />
            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/perfect-jeans/skinny-2/render_3.webp"
              alt=""
              onClick={() =>
                handleImageClick(
                  "https://advertflair-public.s3.us-west-2.amazonaws.com/client/perfect-jeans/skinny-2/render_3.webp"
                )
              }
            />
          </div>
        </div>

        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/perfect-jeans/skinny-2/mobile_cart.webp"
          alt=""
        />
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/perfect-jeans/skinny-2/mobile_details.webp"
          alt=""
        />
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/perfect-jeans/skinny-2/mobile_footer.webp"
          alt=""
        />
      </div>
    </div>
  );
}

export default SkinnyJeanVto;
