import React, { useState } from "react";
import styles from "./styles.module.scss";

function HandheldVacuum() {
  const [selectedImage, setSelectedImage] = useState(
    "https://advertflair-public.s3.us-west-2.amazonaws.com/client/sharperimage/handheld-vacum/render_1.webp"
  );
  const [glbContainer, setGlbContainer] = useState(false);
  const [mobileGlbcontainer, setMobileGlbcontainer] = useState(false);
  const [mobileimageContainer, setMobileimageContainer] = useState(true);

  const [imageContainer, setImageContainer] = useState(true);
  const [videoContainer, setVideoContainer] = useState(false);
  const [mobileVideoContainer, setMobileVideoContainer] = useState(false);

  const toggleGlb = () => {
    setGlbContainer(true);
    setImageContainer(false);
    setVideoContainer(false);
  };

  const togglemobileGlb = () => {
    setMobileGlbcontainer(true);
    setMobileimageContainer(false);
    setMobileVideoContainer(false);
  };

  const toggleVideo = () => {
    setVideoContainer(true);
    setImageContainer(false);
    setGlbContainer(false);
  };

  const togglemobileVideo = () => {
    setMobileVideoContainer(true);
    setMobileGlbcontainer(false);
    setMobileimageContainer(false);
  };

  const handleImageClick = (imageSrc) => {
    setGlbContainer(false);
    setMobileGlbcontainer(false);
    setSelectedImage(imageSrc);
    setImageContainer(true);
    setMobileimageContainer(true);
    setMobileVideoContainer(false);
    setVideoContainer(false);
  };

  return (
    <div>
      <div className={styles.desktopContainer}>
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/sharperimage/handheld-vacum/header.webp"
          alt="Header"
        />

        <div className={styles.maincontainer}>
          <div className={styles.renderSection}>
            {imageContainer && <img src={selectedImage} alt="" />}

            {glbContainer && (
              <iframe
                title="desktopGlb"
                height={"354px"}
                seamless
                src="https://www.pod.advflr.com/turnaround/sharperimage/handheld-vacuum"
              ></iframe>
            )}

            {videoContainer && (
              <div>
                <video
                  id="my-video"
                  class="video-js"
                  controls
                  preload="auto"
                  width="518"
                  height="354"
                  autoPlay
                  poster="https://advertflair-public.s3.us-west-2.amazonaws.com/client/sharperimage/handheld-vacum/video_preview_2.webp"
                  data-setup="{}"
                >
                  <source
                    src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/sharperimage/handheld-vacum/render_video.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            )}

            <div className={styles.thumbnailSection}>
              <img
                src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/sharperimage/handheld-vacum/render_1.webp"
                alt=""
                onClick={() =>
                  handleImageClick(
                    "https://advertflair-public.s3.us-west-2.amazonaws.com/client/sharperimage/handheld-vacum/render_1.webp"
                  )
                }
              />
              <img
                src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/sharperimage/handheld-vacum/360_thumb.webp"
                alt=""
                onClick={toggleGlb}
              />
              <img
                src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/sharperimage/handheld-vacum/video_thumb.webp"
                alt=""
                onClick={toggleVideo}
              />

              <img
                src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/sharperimage/handheld-vacum/main_render.webp"
                alt=""
                onClick={() =>
                  handleImageClick(
                    "https://advertflair-public.s3.us-west-2.amazonaws.com/client/sharperimage/handheld-vacum/main_render.webp"
                  )
                }
              />

              <img
                src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/sharperimage/handheld-vacum/render_3.webp"
                alt=""
                onClick={() =>
                  handleImageClick(
                    "https://advertflair-public.s3.us-west-2.amazonaws.com/client/sharperimage/handheld-vacum/render_3.webp"
                  )
                }
              />

              <img
                src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/sharperimage/handheld-vacum/render_4.webp"
                alt=""
                onClick={() =>
                  handleImageClick(
                    "https://advertflair-public.s3.us-west-2.amazonaws.com/client/sharperimage/handheld-vacum/render_4.webp"
                  )
                }
              />

              <img
                src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/sharperimage/handheld-vacum/render_5.webp"
                alt=""
                onClick={() =>
                  handleImageClick(
                    "https://advertflair-public.s3.us-west-2.amazonaws.com/client/sharperimage/handheld-vacum/render_5.webp"
                  )
                }
              />

              <img
                src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/sharperimage/handheld-vacum/render_6.webp"
                alt=""
                onClick={() =>
                  handleImageClick(
                    "https://advertflair-public.s3.us-west-2.amazonaws.com/client/sharperimage/handheld-vacum/render_6.webp"
                  )
                }
              />

              <img
                src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/sharperimage/handheld-vacum/render_8.webp"
                alt=""
                onClick={() =>
                  handleImageClick(
                    "https://advertflair-public.s3.us-west-2.amazonaws.com/client/sharperimage/handheld-vacum/render_8.webp"
                  )
                }
              />
            </div>
          </div>
          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/sharperimage/handheld-vacum/details.webp"
            alt=""
            style={{ width: "460px" }}
          />
          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/sharperimage/handheld-vacum/likable.webp"
            alt=""
            style={{ width: "200px" }}
          />
        </div>

        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/sharperimage/handheld-vacum/features.webp"
          alt=""
          style={{ width: "1020px", marginLeft: "2rem" }}
        />
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/sharperimage/handheld-vacum/likable_2.webp"
          alt=""
          style={{
            width: "1020px",
            marginLeft: "2rem",
          }}
        />

        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/sharperimage/handheld-vacum/footer.webp"
          alt=""
        />
      </div>

      <div className={styles.container}>
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/sharperimage/handheld-vacum/mobile_header.webp"
          alt="Mobile Header"
        />

        <div className={styles.maincontainer}>
          <div className={styles.renderSection}>
            {mobileimageContainer && <img src={selectedImage} alt="" />}

            {mobileGlbcontainer && (
              <iframe
                title="mobileGlb"
                src="https://www.pod.advflr.com/turnaround/sharperimage/handheld-vacuum"
              ></iframe>
            )}

            {mobileVideoContainer && (
              <div>
                <video
                  id="my-video"
                  class="video-js"
                  controls
                  preload="auto"
                  width="300"
                  height="206"
                  autoPlay
                  poster="https://advertflair-public.s3.us-west-2.amazonaws.com/client/sharperimage/handheld-vacum/video_preview_2.webp"
                  data-setup="{}"
                >
                  <source
                    src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/sharperimage/handheld-vacum/render_video.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            )}
          </div>
          <div className={styles.thumbnailSection}>
            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/sharperimage/handheld-vacum/render_1.webp"
              alt=""
              onClick={() =>
                handleImageClick(
                  "https://advertflair-public.s3.us-west-2.amazonaws.com/client/sharperimage/handheld-vacum/render_1.webp"
                )
              }
            />
            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/sharperimage/handheld-vacum/360_thumb.webp"
              alt=""
              onClick={togglemobileGlb}
            />
            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/sharperimage/handheld-vacum/video_thumb.webp"
              alt=""
              onClick={togglemobileVideo}
            />

            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/sharperimage/handheld-vacum/main_render.webp"
              alt=""
              onClick={() =>
                handleImageClick(
                  "https://advertflair-public.s3.us-west-2.amazonaws.com/client/sharperimage/handheld-vacum/main_render.webp"
                )
              }
            />

            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/sharperimage/handheld-vacum/render_3.webp"
              alt=""
              onClick={() =>
                handleImageClick(
                  "https://advertflair-public.s3.us-west-2.amazonaws.com/client/sharperimage/handheld-vacum/render_3.webp"
                )
              }
            />

            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/sharperimage/handheld-vacum/render_4.webp"
              alt=""
              onClick={() =>
                handleImageClick(
                  "https://advertflair-public.s3.us-west-2.amazonaws.com/client/sharperimage/handheld-vacum/render_4.webp"
                )
              }
            />

            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/sharperimage/handheld-vacum/render_5.webp"
              alt=""
              onClick={() =>
                handleImageClick(
                  "https://advertflair-public.s3.us-west-2.amazonaws.com/client/sharperimage/handheld-vacum/render_5.webp"
                )
              }
            />

            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/sharperimage/handheld-vacum/render_6.webp"
              alt=""
              onClick={() =>
                handleImageClick(
                  "https://advertflair-public.s3.us-west-2.amazonaws.com/client/sharperimage/handheld-vacum/render_6.webp"
                )
              }
            />

            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/sharperimage/handheld-vacum/render_8.webp"
              alt=""
              onClick={() =>
                handleImageClick(
                  "https://advertflair-public.s3.us-west-2.amazonaws.com/client/sharperimage/handheld-vacum/render_8.webp"
                )
              }
            />
          </div>

          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/sharperimage/handheld-vacum/mobile_details.webp"
            alt="Details"
          />
          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/sharperimage/handheld-vacum/mobile_details_2.webp"
            alt=""
          />

          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/sharperimage/handheld-vacum/mobile_likable.webp"
            alt=""
          />

          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/sharperimage/handheld-vacum/mobile_likable_2.webp"
            alt=""
          />

          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/sharperimage/handheld-vacum/mobile_footer.webp"
            alt=""
          />
        </div>
      </div>
    </div>
  );
}

export default HandheldVacuum;
