import React, { useState } from "react";
import styles from "./cup.module.scss";

function Cup() {
  const [selectedImage, setSelectedImage] = useState(
    "https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/render_1.webp"
  );
  const [glbContainer, setGlbContainer] = useState(false);
  const [mobileimageContainer, setMobileimageContainer] = useState(true);
  const [imageContainer, setImageContainer] = useState(true);
  const [videoContainer, setVideoContainer] = useState(false);
  const [purpleContainer, setPurpleContainer] = useState(false);
  const [steelContainer, setSteelContainer] = useState(false);
  const [denimContainer, setDenimContainer] = useState(false);
  const [carbonContainer, setCarbonContainer] = useState(false);
  const [carnationContainer, setCarnationContainer] = useState(false);
  const [touchableGlbContainer, setTouchableGlbContainer] = useState(false);

  const handleImageClick = (imageSrc) => {
    setGlbContainer(false);
    setSelectedImage(imageSrc);
    setImageContainer(true);
    setMobileimageContainer(true);
    setVideoContainer(false);
    setSteelContainer(false);
    setCarbonContainer(false);
    setCarnationContainer(false);
    setDenimContainer(false);
    setTouchableGlbContainer(false);
    setPurpleContainer(false);
  };

  const toggleVideo = () => {
    setVideoContainer(true);
    setImageContainer(false);
    setMobileimageContainer(false);
    setGlbContainer(false);
    setSteelContainer(false);
    setCarbonContainer(false);
    setCarnationContainer(false);
    setDenimContainer(false);
    setTouchableGlbContainer(false);
    setPurpleContainer(false);
  };

  const toggleGlb = () => {
    setGlbContainer(true);
    setImageContainer(false);
    setMobileimageContainer(false);
    setVideoContainer(false);
    setSteelContainer(false);
    setCarbonContainer(false);
    setCarnationContainer(false);
    setDenimContainer(false);
    setPurpleContainer(false);
    setTouchableGlbContainer(false);
  };

  const toggletouchableGlb = () => {
    setGlbContainer(false);
    setImageContainer(false);
    setMobileimageContainer(false);
    setVideoContainer(false);
    setSteelContainer(false);
    setCarbonContainer(false);
    setCarnationContainer(false);
    setDenimContainer(false);
    setPurpleContainer(false);
    setTouchableGlbContainer(true);
  };

  const togglepurpleGlb = () => {
    setGlbContainer(false);
    setImageContainer(false);
    setMobileimageContainer(false);
    setVideoContainer(false);
    setSteelContainer(false);
    setCarbonContainer(false);
    setCarnationContainer(false);
    setDenimContainer(false);
    setPurpleContainer(true);
    setTouchableGlbContainer(false);
  };

  const togglesteelGlb = () => {
    setGlbContainer(false);
    setImageContainer(false);
    setMobileimageContainer(false);
    setVideoContainer(false);
    setSteelContainer(true);
    setCarbonContainer(false);
    setCarnationContainer(false);
    setDenimContainer(false);
    setPurpleContainer(false);
    setTouchableGlbContainer(false);
  };

  const toggledenimGlb = () => {
    setGlbContainer(false);
    setImageContainer(false);
    setMobileimageContainer(false);
    setVideoContainer(false);
    setSteelContainer(false);
    setCarbonContainer(false);
    setCarnationContainer(false);
    setDenimContainer(true);
    setPurpleContainer(false);
    setTouchableGlbContainer(false);
  };

  const togglecarbonGlb = () => {
    setGlbContainer(false);
    setImageContainer(false);
    setMobileimageContainer(false);
    setVideoContainer(false);
    setSteelContainer(false);
    setCarbonContainer(true);
    setCarnationContainer(false);
    setDenimContainer(false);
    setPurpleContainer(false);
    setTouchableGlbContainer(false);
  };

  const togglecarnationGlb = () => {
    setGlbContainer(false);
    setImageContainer(false);
    setMobileimageContainer(false);
    setVideoContainer(false);
    setSteelContainer(false);
    setCarbonContainer(false);
    setCarnationContainer(true);
    setDenimContainer(false);
    setPurpleContainer(false);
    setTouchableGlbContainer(false);
  };

  return (
    <div>
      <div className={styles.mobileContainer}>
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/mobile_header.webp"
          alt=""
        />
        <div className={styles.renderSection}>
          <div className={styles.render}>
            {mobileimageContainer && <img src={selectedImage} alt="" />}

            {glbContainer && (
              <iframe
                title="desktopGlb"
                seamless
                src="https://www.pod.advflr.com/turnaround/stojo/cup"
              ></iframe>
            )}

            {touchableGlbContainer && (
              <iframe
                title="animatedglb"
                src="https://app.vectary.com/p/18X8BhgwE60Qr9bNpXOi2a"
                seamless
              ></iframe>
            )}

            {purpleContainer && (
              <iframe
                title="desktopGlb"
                seamless
                src="https://www.pod.advflr.com/turnaround/stojo/cup_purple"
              ></iframe>
            )}

            {steelContainer && (
              <iframe
                title="desktopGlb"
                seamless
                src="https://www.pod.advflr.com/turnaround/stojo/cup_steel"
              ></iframe>
            )}

            {denimContainer && (
              <iframe
                title="desktopGlb"
                seamless
                src="https://www.pod.advflr.com/turnaround/stojo/cup_denim"
              ></iframe>
            )}

            {carbonContainer && (
              <iframe
                title="desktopGlb"
                seamless
                src="https://www.pod.advflr.com/turnaround/stojo/cup_carbon"
              ></iframe>
            )}
            {carnationContainer && (
              <iframe
                title="desktopGlb"
                seamless
                src="https://www.pod.advflr.com/turnaround/stojo/cup_carnation"
              ></iframe>
            )}

            {videoContainer && (
              <div>
                <video
                  id="my-video"
                  class="video-js"
                  controls
                  preload="auto"
                  autoPlay
                  poster=""
                  data-setup="{}"
                  muted
                >
                  <source
                    src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/stojo_render.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            )}
          </div>

          <div className={styles.thumbnailSection}>
            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/render_1.webp"
              alt=""
              onClick={() =>
                handleImageClick(
                  "https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/render_1.webp"
                )
              }
            />

            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/render_6.webp"
              alt=""
              onClick={() =>
                handleImageClick(
                  "https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/render_6.webp"
                )
              }
            />

            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/366-1.webp"
              alt="360Glb"
              onClick={toggleGlb}
            />

            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/video_thumbnail.webp"
              alt=""
              onClick={toggleVideo}
            />
          </div>
        </div>
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/mobile_cart_1.webp"
          alt=""
        />

        <p>Color</p>

        <div className={styles.colorThumbnail}>
          <div
            className={`${styles.circle} ${styles.purple}`}
            onClick={togglepurpleGlb}
          ></div>
          <div
            className={`${styles.circle} ${styles.steel}`}
            onClick={togglesteelGlb}
          ></div>
          <div
            className={`${styles.circle} ${styles.denim}`}
            onClick={toggledenimGlb}
          ></div>
          <div
            className={`${styles.circle} ${styles.carbon}`}
            onClick={togglecarbonGlb}
          ></div>
          <div
            className={`${styles.circle} ${styles.carnation}`}
            onClick={togglecarnationGlb}
          ></div>
        </div>
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/mobile_cart_2.webp"
          alt=""
        />
      </div>

      <div className={styles.container}>
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/desktop_header.webp"
          alt=""
        />

        <div className={styles.mainSection}>
          <div className={styles.renderSection}>
            <div className={styles.render}>
              {imageContainer && <img src={selectedImage} alt="" />}
              {glbContainer && (
                <iframe
                  title="desktopGlb"
                  seamless
                  src="https://www.pod.advflr.com/turnaround/stojo/cup"
                ></iframe>
              )}

              {touchableGlbContainer && (
                <iframe
                  title="animatedglb"
                  src="https://app.vectary.com/p/18X8BhgwE60Qr9bNpXOi2a"
                  seamless
                ></iframe>
              )}

              {purpleContainer && (
                <iframe
                  title="desktopGlb"
                  seamless
                  src="https://www.pod.advflr.com/turnaround/stojo/cup_purple"
                ></iframe>
              )}

              {steelContainer && (
                <iframe
                  title="desktopGlb"
                  seamless
                  src="https://www.pod.advflr.com/turnaround/stojo/cup_steel"
                ></iframe>
              )}

              {denimContainer && (
                <iframe
                  title="desktopGlb"
                  seamless
                  src="https://www.pod.advflr.com/turnaround/stojo/cup_denim"
                ></iframe>
              )}

              {carbonContainer && (
                <iframe
                  title="desktopGlb"
                  seamless
                  src="https://www.pod.advflr.com/turnaround/stojo/cup_carbon"
                ></iframe>
              )}
              {carnationContainer && (
                <iframe
                  title="desktopGlb"
                  seamless
                  src="https://www.pod.advflr.com/turnaround/stojo/cup_carnation"
                ></iframe>
              )}

              {videoContainer && (
                <div>
                  <video
                    id="my-video"
                    class="video-js"
                    controls
                    preload="auto"
                    autoPlay
                    poster=""
                    data-setup="{}"
                    muted
                  >
                    <source
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/stojo_render.mp4"
                      type="video/mp4"
                    />
                  </video>
                </div>
              )}
            </div>
            <div className={styles.thumbnailSection}>
              <img
                src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/render_1.webp"
                alt=""
                onClick={() =>
                  handleImageClick(
                    "https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/render_1.webp"
                  )
                }
              />

              <img
                src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/render_6.webp"
                alt=""
                onClick={() =>
                  handleImageClick(
                    "https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/render_6.webp"
                  )
                }
              />

              <img
                src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/366-1.webp"
                alt="360Glb"
                onClick={toggleGlb}
              />

              <img
                src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/belkin/earbuds/touch_view.webp"
                alt=""
                onClick={toggletouchableGlb}
              />
              <img
                src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/video_thumbnail.webp"
                alt=""
                onClick={toggleVideo}
              />
            </div>
          </div>
          <div className={styles.cartSection}>
            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/cart_1-1.webp"
              alt=""
            />
            <p>Color</p>

            <div className={styles.colorThumbnail}>
              <div
                className={`${styles.circle} ${styles.purple}`}
                onClick={togglepurpleGlb}
              ></div>
              <div
                className={`${styles.circle} ${styles.steel}`}
                onClick={togglesteelGlb}
              ></div>
              <div
                className={`${styles.circle} ${styles.denim}`}
                onClick={toggledenimGlb}
              ></div>
              <div
                className={`${styles.circle} ${styles.carbon}`}
                onClick={togglecarbonGlb}
              ></div>
              <div
                className={`${styles.circle} ${styles.carnation}`}
                onClick={togglecarnationGlb}
              ></div>
            </div>

            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/cart_2-2.webp"
              alt=""
            />
          </div>
        </div>
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/desktop_body.webp"
          alt=""
        />
      </div>
    </div>
  );
}

export default Cup;
