import create from "zustand";

const UsePortfolioStore = create((set) => ({
  selectedMenu: "360 / AR / VR",
  selectedCategory: "all",
  productsCount: "",
  rawData: [],
  filteredData: [],
  modalWindowOpen: false,
  embedProduct: [],
  categoryCount: [],
  subCategorySelected: false,
  selectedSubCategory: "",
  length_360: "",
  length_videos: "",
  length_configurator: "",
  length_interactive: "",
  length_tryon: "",
  length_images: "",

  setSubCategorySelected: (value) => set({ subCategorySelected: value }),
  setSelectedSubCategory: (value) => set({ selectedSubCategory: value }),
  setEmbedProduct: (value) => set({ embedProduct: value }),
  setCategoryCount: (value) => set({ categoryCount: value }),
  setProductsCount: (value) => set({ productsCount: value }),
  setModalWindowOpen: (value) => set({ modalWindowOpen: value }),
  setSelectedMenu: (value) => set({ selectedMenu: value }),
  setSelectedCategory: (value) => set({ selectedCategory: value }),
  setRawData: (value) => set({ rawData: value }),
  setFilteredData: (value) => set({ filteredData: value }),
  setLength_360: (value) => set({ length_360: value }),
  setLength_videos: (value) => set({ length_videos: value }),
  setLength_images: (value) => set({ length_images: value }),
  setLength_configurator: (value) => set({ length_configurator: value }),
  setLength_interactive: (value) => set({ length_interactive: value }),
  setLength_tryon: (value) => set({ length_tryon: value }),
}));

export default UsePortfolioStore;
