import React, { useState } from "react";
import styles from "./Shadestore.module.scss";

function CellularShade() {
  const [selectedImage, setSelectedImage] = useState(
    "https://advertflair-public.s3.us-west-2.amazonaws.com/client/shadestore/cellular-shades/render_1.jpg"
  );
  const [glbContainer, setGlbContainer] = useState(false);
  const [mobileimageContainer, setMobileimageContainer] = useState(true);
  const [imageContainer, setImageContainer] = useState(true);
  const [wheat, setWheat] = useState(false);
  const [buff, setBuff] = useState(false);
  const [interactive, setInteractive] = useState(false);

  const handleImageClick = (imageSrc) => {
    setGlbContainer(false);
    setSelectedImage(imageSrc);
    setImageContainer(true);
    setMobileimageContainer(true);
    setBuff(false);
    setWheat(false);
    setInteractive(false);
  };

  const toggleGlb = () => {
    setGlbContainer(true);
    setImageContainer(false);
    setMobileimageContainer(false);
    setBuff(false);
    setWheat(false);
    setInteractive(false);
  };

  const toggleInteractive = () => {
    setGlbContainer(false);
    setImageContainer(false);
    setMobileimageContainer(false);
    setBuff(false);
    setWheat(false);
    setInteractive(true);
  };

  const togglewheatGlb = () => {
    setGlbContainer(false);
    setImageContainer(false);
    setMobileimageContainer(false);
    setBuff(false);
    setWheat(true);
    setInteractive(false);
  };
  const togglebuffGlb = () => {
    setGlbContainer(false);
    setImageContainer(false);
    setMobileimageContainer(false);
    setBuff(true);
    setWheat(false);
    setInteractive(false);
  };

  return (
    <div>
      <div className={styles.container}>
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/shadestore/cellular-shades/header_11.png"
          alt=""
        />

        <div className={styles.mainSection}>
          <div className={styles.renderSection}>
            {imageContainer && <img src={selectedImage} alt="" />}

            {glbContainer && (
              <iframe
                title="desktopGlb"
                seamless
                src="https://www.pod.advflr.com/turnaround/shadestore/cellular-shades/wall"
              ></iframe>
            )}

            {wheat && (
              <iframe
                title="desktopGlb"
                seamless
                src="https://www.pod.advflr.com/turnaround/shadestore/cellular-shades-wheat/wall"
              ></iframe>
            )}

            {buff && (
              <iframe
                title="desktopGlb"
                seamless
                src="https://www.pod.advflr.com/turnaround/shadestore/cellular-shades-buff/wall"
              ></iframe>
            )}

            {interactive && (
              <iframe
                title="animatedglb"
                src="https://app.vectary.com/p/5Zbz9k4Wdmr5wUmJplQFC9"
                seamless
              ></iframe>
            )}
          </div>

          <div className={styles.cartSection}>
            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/shadestore/roller-shades/cart_view.png"
              alt=""
            />

            <div className={styles.colorthumbnailSection}>
              <img
                src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/shadestore/cellular-shades/winter.png"
                alt=""
                onClick={toggleGlb}
              />
              <img
                src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/shadestore/cellular-shades/wheat.png"
                alt=""
                onClick={togglewheatGlb}
              />

              <img
                src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/shadestore/cellular-shades/buff.png"
                alt=""
                onClick={togglebuffGlb}
              />
            </div>
          </div>
        </div>

        <div className={styles.thumbnailSection}>
          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/shadestore/cellular-shades/render_1.jpg"
            alt=""
            onClick={() =>
              handleImageClick(
                "https://advertflair-public.s3.us-west-2.amazonaws.com/client/shadestore/cellular-shades/render_1.jpg"
              )
            }
          />

          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/shadestore/cellular-shades/render_cellular.webp"
            alt=""
            onClick={() =>
              handleImageClick(
                "https://advertflair-public.s3.us-west-2.amazonaws.com/client/shadestore/cellular-shades/render_cellular.webp"
              )
            }
          />

          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/366-1.webp"
            alt="360Glb"
            onClick={toggleGlb}
          />

          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/belkin/earbuds/touch_view.webp"
            alt=""
            onClick={toggleInteractive}
          />
        </div>
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/shadestore/cellular-shades/footer.webp"
          alt=""
        />
      </div>

      <div className={styles.mobilecontainer}>
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/shadestore/cellular-shades/header_11.png"
          alt=""
        />

        <div className={styles.mainSection}>
          <div className={styles.renderSection}>
            {mobileimageContainer && <img src={selectedImage} alt="" />}

            {glbContainer && (
              <iframe
                title="desktopGlb"
                seamless
                src="https://www.pod.advflr.com/turnaround/shadestore/cellular-shades/wall"
              ></iframe>
            )}

            {wheat && (
              <iframe
                title="desktopGlb"
                seamless
                src="https://www.pod.advflr.com/turnaround/shadestore/cellular-shades-wheat/wall"
              ></iframe>
            )}

            {buff && (
              <iframe
                title="desktopGlb"
                seamless
                src="https://www.pod.advflr.com/turnaround/shadestore/cellular-shades-buff/wall"
              ></iframe>
            )}

            {interactive && (
              <iframe
                title="animatedglb"
                src="https://app.vectary.com/p/5Zbz9k4Wdmr5wUmJplQFC9"
                seamless
              ></iframe>
            )}
          </div>
        </div>

        <div className={styles.thumbnailSection}>
          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/shadestore/cellular-shades/render_1.jpg"
            alt=""
            onClick={() =>
              handleImageClick(
                "https://advertflair-public.s3.us-west-2.amazonaws.com/client/shadestore/cellular-shades/render_1.jpg"
              )
            }
          />

          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/shadestore/cellular-shades/render_cellular.webp"
            alt=""
            onClick={() =>
              handleImageClick(
                "https://advertflair-public.s3.us-west-2.amazonaws.com/client/shadestore/cellular-shades/render_cellular.webp"
              )
            }
          />

          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/366-1.webp"
            alt="360Glb"
            onClick={toggleGlb}
          />

          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/belkin/earbuds/touch_view.webp"
            alt=""
            onClick={toggleInteractive}
          />
        </div>

        <div className={styles.cartSection}>
          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/shadestore/roller-shades/cart_view.png"
            alt=""
          />

          <div className={styles.colorthumbnailSection}>
            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/shadestore/roller-shades/white.png"
              alt=""
              onClick={toggleGlb}
            />
            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/shadestore/cellular-shades/wheat.png"
              alt=""
              onClick={togglewheatGlb}
            />
            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/shadestore/cellular-shades/buff.png"
              alt=""
              onClick={togglebuffGlb}
            />
          </div>
        </div>

        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/shadestore/cellular-shades/footer.webp"
          alt=""
        />
      </div>
    </div>
  );
}

export default CellularShade;
