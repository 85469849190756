import React, { useState } from "react";
import styles from "./styles.module.scss";

function Template() {
  const [renderimage, setRenderimage] = useState(
    "https://placehold.co/512x512?text=thumbnail-1"
  );

  const [imageContainer, setImageContainer] = useState(true);
  const [glbContainer, setGlbContainer] = useState(false);
  const [videocontainer, setVideocontainer] = useState(false);

  const clickFunction = () => {
    setRenderimage("www.google.com/newimage");
  };

  const thumbnail1Image = () => {
    setRenderimage("https://placehold.co/512x512?text=thumbnail-1");
    setImageContainer(true);
    setVideocontainer(false);
    setGlbContainer(false);
  };

  const thumbnail2Image = () => {
    setRenderimage("https://placehold.co/512x512?text=thumbnail-2");
    setImageContainer(true);
    setVideocontainer(false);
    setGlbContainer(false);
  };

  const thumbnail3Image = () => {
    setRenderimage("https://placehold.co/512x512?text=thumbnail-3");
    setImageContainer(true);
    setVideocontainer(false);
    setGlbContainer(false);
  };

  const glbFunction = () => {
    setImageContainer(false);
    setVideocontainer(false);
    setGlbContainer(true);
  };

  const videofunction = () => {
    setImageContainer(false);
    setGlbContainer(false);
    setVideocontainer(true);
  };

  return (
    <div>
      <div className={styles.container}>
        <img src="https://placehold.co/1440x246?text=desktop-header" alt="" />

        <div className={styles.mainSection}>
          <div className={styles.leftSection}>
            <div className={styles.renderSection}>
              <p>{renderimage}</p>
              <button onClick={clickFunction}>Click me</button>

              {imageContainer && <img src={renderimage} alt="Render Image" />}

              {glbContainer && (
                <iframe
                  src="https://www.pod.advflr.com/turnaround/clarev/grandetote"
                  title="Glb"
                  frameborder="0"
                ></iframe>
              )}

              {videocontainer && (
                <video
                  id="my-video"
                  class="video-js"
                  controls
                  preload="auto"
                  autoPlay
                  poster=""
                  data-setup="{}"
                  muted
                >
                  <source
                    src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/rebags/bags/louis-vuitton/renderVideo.mp4"
                    type="video/mp4"
                  />
                </video>
              )}
            </div>

            <div className={styles.thumbnailSection}>
              <img
                onClick={thumbnail1Image}
                src="https://placehold.co/512x512?text=thumbnail-1"
              />
              <img
                onClick={thumbnail2Image}
                src="https://placehold.co/512x512?text=thumbnail-2"
              />
              <img
                onClick={thumbnail3Image}
                src="https://placehold.co/512x512?text=thumbnail-3"
              />
              <img
                onClick={glbFunction}
                src="https://placehold.co/512x512?text=360"
              />
              <img
                onClick={videofunction}
                src="https://placehold.co/512x512?text=video"
              />
            </div>
          </div>
          <div className={styles.rightSection}>
            <img src="https://placehold.co/666x1200?text=Cart-Section" alt="" />
          </div>
        </div>

        <div className={styles.footerSection}>
          <img
            src="https://placehold.co/1440x2400?text=Desktop-Footer"
            alt=""
          />
        </div>
      </div>
      <div className={styles.mobileContainer}>
        <img src="https://placehold.co/512x120?text=mobile-header" alt="" />

        <div className={styles.mainSection}>
          <div className={styles.renderSection}>
            <img src="https://placehold.co/512x512?text=mobile-render" alt="" />
          </div>

          <div className={styles.thumbnailSection}>
            <img src="https://placehold.co/512x512?text=thumbnail-1" />
            <img src="https://placehold.co/512x512?text=thumbnail-2" />
            <img src="https://placehold.co/512x512?text=thumbnail-3" />
            <img src="https://placehold.co/512x512?text=360" />
            <img src="https://placehold.co/512x512?text=video" />
          </div>
        </div>

        <div className={styles.footerSection}>
          <img src="https://placehold.co/512x1200?text=mobile-footer" alt="" />
        </div>
      </div>
    </div>
  );
}

export default Template;
