import React, { useState } from "react";
import styles from "./ClarevProducts.module.scss";

function ClarevProducts() {
  const [imagecontainer, setImagecontainer] = useState(true);
  const [videocontainer, setVideocontainer] = useState(false);
  const [vtocontainer, setVtocontainer] = useState(false);
  const [Turnaroundcontainer, setTurnaroundcontainer] = useState(false);
  const [interactivecontainer, setInteractivecontainer] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");

  const [product, setProduct] = useState("All VTO's");
  const [popupcontainer, setPopupcontainer] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [qrcodeurl, setQrcodeurl] = useState("");
  const [qrcontainer, setQrcontainer] = useState(true);
  const [configuratorcontainer, setConfiguratorcontainer] = useState(false);

  const toggleRenderImages = () => {
    setProduct("3D Rendering");
    setImagecontainer(true);
    setVideocontainer(false);
    setVtocontainer(false);
    setTurnaroundcontainer(false);
    setInteractivecontainer(false);
    setConfiguratorcontainer(false);
  };

  const toggleVideos = () => {
    setProduct("3D Product Videos");
    setVtocontainer(false);
    setVideocontainer(true);
    setImagecontainer(false);
    setTurnaroundcontainer(false);
    setInteractivecontainer(false);
    setConfiguratorcontainer(false);
  };

  const toggleVtos = () => {
    setProduct("Virtual Try-on");
    setVtocontainer(true);
    setVideocontainer(false);
    setImagecontainer(false);
    setTurnaroundcontainer(false);
    setInteractivecontainer(false);
    setConfiguratorcontainer(false);
  };

  const toggleTurnaround = () => {
    setProduct("360/AR/VR");
    setVtocontainer(false);
    setVideocontainer(false);
    setImagecontainer(false);
    setTurnaroundcontainer(true);
    setInteractivecontainer(false);
    setConfiguratorcontainer(false);
  };

  const toggleConfigurator = () => {
    setProduct("Configurator");
    setVtocontainer(false);
    setVideocontainer(false);
    setImagecontainer(false);
    setTurnaroundcontainer(false);
    setConfiguratorcontainer(true);
    setInteractivecontainer(false);
  };

  const toggleInteractive = () => {
    setProduct("Interactive");
    setVtocontainer(false);
    setVideocontainer(false);
    setImagecontainer(false);
    setTurnaroundcontainer(false);
    setInteractivecontainer(true);
    setConfiguratorcontainer(false);
  };

  const closePopup = () => {
    setPopupcontainer(false);
    setImageUrl(false);
  };

  const openPopup = (url, url2) => {
    console.log("working");
    setImageUrl(url);
    setQrcontainer(true);
    setQrcodeurl(url2);
    setPopupcontainer(true);
  };

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
    if (e.target.value === "3D Product Videos") {
      toggleVideos();
    } else if (e.target.value === "3D Rendering") {
      toggleRenderImages();
    } else if (e.target.value === "360/AR/VR") {
      toggleTurnaround();
    } else if (e.target.value === "Interactive") {
      toggleInteractive();
    } else if (e.target.value === "Configurator") {
      toggleConfigurator();
    } else if (e.target.value === "Virtual Try-on") {
      toggleVtos();
    } else if (e.target.value === "Performance Marketing Ads") {
      window.open("http://pod.advflr.com/client/ads", "_blank");
    } else {
      toggleRenderImages();
    }
  };

  return (
    <div>
      <div className={styles.container}>
        {popupcontainer && (
          <div className={styles.modalPopup}>
            <div className={styles.header}>
              <button onClick={closePopup} className={styles.primary}>
                Close
              </button>
            </div>

            <div className={styles.content}>
              {qrcontainer && <img src={qrcodeurl} alt="" />}
              <iframe
                title="ar"
                src={imageUrl}
                seamless
                allow="camera"
              ></iframe>
            </div>
          </div>
        )}

        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/overall/clare.v/desktop-header.webp"
          alt=""
        />
        <div className={styles.header}>
          <p>Advertflair / {product}</p>
        </div>

        <div className={styles.mainSection}>
          <div className={styles.leftSection}>
            <div className={styles.title}>Services offered :</div>
            <div className={styles.horizontal_line}></div>
            <p onClick={toggleRenderImages}>3D Rendering</p>
            <p onClick={toggleVideos}>3D Product Videos</p>
            <p onClick={toggleTurnaround}>360/AR/VR</p>
            <p onClick={toggleConfigurator}>Configurator</p>
            <p onClick={toggleInteractive}>Interactive</p>
            <p onClick={toggleVtos}>Virtual Try-on</p>

            <p
              onClick={() =>
                window.open("http://pod.advflr.com/client/ads", "_blank")
              }
            >
              Performance Marketing Ads
            </p>
          </div>
          <div className={styles.rightSection}>
            <div className={styles.titleSection}>
              <div className={styles.title}>
                {imagecontainer && (
                  <>
                    3D Rendering <span>26 Products</span>
                  </>
                )}

                {videocontainer && (
                  <>
                    3D Product Videos <span>18 Products</span>
                  </>
                )}

                {vtocontainer && (
                  <>
                    Virtual Try-on <span>15 Products</span>
                  </>
                )}

                {Turnaroundcontainer && (
                  <>
                    360/AR/VR <span>38 Products</span>
                  </>
                )}

                {configuratorcontainer && (
                  <>
                    Configurator <span>2 Products</span>
                  </>
                )}

                {interactivecontainer && (
                  <>
                    Interactive <span>12 Products</span>
                  </>
                )}
              </div>
            </div>

            <div className={styles.gridSection}>
              {imagecontainer && (
                <>
                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/pettimoyen-black.webp"
                      alt=""
                      onClick={() =>
                        window.open(
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/pettimoyen-black.webp",
                          "_blank"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/03_Checked-Hand-bag.png"
                      alt=""
                      onClick={() =>
                        window.open(
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/03_Checked-Hand-bag.png",
                          "_blank"
                        )
                      }
                    />
                  </div>
                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/02_Orange-Hand-bag.png"
                      alt=""
                      onClick={() =>
                        window.open(
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/02_Orange-Hand-bag.png",
                          "_blank"
                        )
                      }
                    />
                  </div>
                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/crest-bock-bucket-bag.png"
                      alt=""
                      onClick={() =>
                        window.open(
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/crest-bock-bucket-bag.png",
                          "_blank"
                        )
                      }
                    />
                  </div>
                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/pettimoyen-purple.webp"
                      alt=""
                      onClick={() =>
                        window.open(
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/pettimoyen-purple.webp",
                          "_blank"
                        )
                      }
                    />
                  </div>
                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/04_state-Hand-bag.png"
                      alt=""
                      onClick={() =>
                        window.open(
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/04_state-Hand-bag.png",
                          "_blank"
                        )
                      }
                    />
                  </div>
                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/Hand-Bag-Black.png"
                      alt=""
                      onClick={() =>
                        window.open(
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/Hand-Bag-Black.png",
                          "_blank"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/Hand-Bag-Blue.png"
                      alt=""
                      onClick={() =>
                        window.open(
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/Hand-Bag-Blue.png",
                          "_blank"
                        )
                      }
                    />
                  </div>
                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/Luggage-Bag-Nature.png"
                      alt=""
                      onClick={() =>
                        window.open(
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/Luggage-Bag-Nature.png",
                          "_blank"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/LV-bag-light.png"
                      alt=""
                      onClick={() =>
                        window.open(
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/LV-bag-light.png",
                          "_blank"
                        )
                      }
                    />
                  </div>
                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/teamson-home-stratford.webp"
                      alt=""
                      onClick={() =>
                        window.open(
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/teamson-home-stratford.webp",
                          "_blank"
                        )
                      }
                    />
                  </div>
                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/round-velvet-footstool.webp"
                      alt=""
                      onClick={() =>
                        window.open(
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/round-velvet-footstool.webp",
                          "_blank"
                        )
                      }
                    />
                  </div>
                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/teamson-home-stratford.png"
                      alt=""
                      onClick={() =>
                        window.open(
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/teamson-home-stratford.png",
                          "_blank"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/lab-grown-diamond-tennis-bracelet.png"
                      alt=""
                      onClick={() =>
                        window.open(
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/lab-grown-diamond-tennis-bracelet.png",
                          "_blank"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/daisy-drop-earrings.png"
                      alt=""
                      onClick={() =>
                        window.open(
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/daisy-drop-earrings.png",
                          "_blank"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/diamond-elongated-hoop-earrings.png"
                      alt=""
                      onClick={() =>
                        window.open(
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/diamond-elongated-hoop-earrings.png",
                          "_blank"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/Gold-tone+Cutout+Daisy.png"
                      alt=""
                      onClick={() =>
                        window.open(
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/Gold-tone+Cutout+Daisy.png",
                          "_blank"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/havana-leather-chair-blue.png"
                      alt=""
                      onClick={() =>
                        window.open(
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/havana-leather-chair-blue.png",
                          "_blank"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/havana-leather-chair-yellow.png"
                      alt=""
                      onClick={() =>
                        window.open(
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/havana-leather-chair-yellow.png",
                          "_blank"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/denver-outdoor-sectional.png"
                      alt=""
                      onClick={() =>
                        window.open(
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/denver-outdoor-sectional.png",
                          "_blank"
                        )
                      }
                    />
                  </div>
                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/Furniture-Blue.png"
                      alt=""
                      onClick={() =>
                        window.open(
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/Furniture-Blue.png",
                          "_blank"
                        )
                      }
                    />
                  </div>
                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/dowel-counter-stool/render_1.webp"
                      alt=""
                      onClick={() =>
                        window.open(
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/dowel-counter-stool/render_1.webp",
                          "_blank"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/Cap.png"
                      alt=""
                      onClick={() =>
                        window.open(
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/Cap.png",
                          "_blank"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/01_Water-bottle.png"
                      alt=""
                      onClick={() =>
                        window.open(
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/01_Water-bottle.png",
                          "_blank"
                        )
                      }
                    />
                  </div>
                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/render_6.webp"
                      alt=""
                      onClick={() =>
                        window.open(
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/render_6.webp",
                          "_blank"
                        )
                      }
                    />
                  </div>
                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/owala_freesip_black/render_4.webp"
                      alt=""
                      onClick={() =>
                        window.open(
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/owala_freesip_black/render_4.webp",
                          "_blank"
                        )
                      }
                    />
                  </div>
                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/torani_syrup/thumbnail_4.webp"
                      alt=""
                      onClick={() =>
                        window.open(
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/torani_syrup/thumbnail_4.webp",
                          "_blank"
                        )
                      }
                    />
                  </div>
                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/torani_syrup/renderImage.webp"
                      alt=""
                      onClick={() =>
                        window.open(
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/torani_syrup/renderImage.webp",
                          "_blank"
                        )
                      }
                    />
                  </div>
                </>
              )}

              {configuratorcontainer && (
                <>
                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/Statebags/willington/render_6.webp"
                      alt=""
                      onClick={() =>
                        window.open(
                          "https://www.pod.advflr.com/client/statebags/wellington",
                          "_blank"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/trolly/render_2.webp"
                      alt=""
                      onClick={() =>
                        window.open(
                          "https://www.pod.advflr.com/client/statebags/trolly",
                          "_blank"
                        )
                      }
                    />
                  </div>
                </>
              )}

              {videocontainer && (
                <>
                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/Statebags/willington/render_6.webp"
                      alt=""
                      onClick={() =>
                        window.open(
                          "https://pod.advflr.com/client/videos?videoUrl=https://player.vimeo.com/video/952270191?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
                          "_blank"
                        )
                      }
                    />
                  </div>
                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/trolly/render_2.webp"
                      alt=""
                      onClick={() =>
                        window.open(
                          "https://pod.advflr.com/client/videos?videoUrl=https://player.vimeo.com/video/952278530?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
                          "_blank"
                        )
                      }
                    />
                  </div>
                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/crest-bock-bucket-bag.webp"
                      alt=""
                      onClick={() =>
                        window.open(
                          "https://player.vimeo.com/video/954275255?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
                          "_blank"
                        )
                      }
                    />
                  </div>
                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/rebags/bags/louis-vuitton/render_1.webp"
                      alt=""
                      onClick={() =>
                        window.open(
                          "https://pod.advflr.com/client/videos?videoUrl=https://player.vimeo.com/video/952272984?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
                          "_blank"
                        )
                      }
                    />
                  </div>
                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/Statebags/kanekids_minibag/thumbnail_1.webp"
                      alt=""
                      onClick={() =>
                        window.open(
                          "https://pod.advflr.com/client/videos?videoUrl=https://player.vimeo.com/video/952272869?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
                          "_blank"
                        )
                      }
                    />
                  </div>
                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/kanekidsminitravel-airplanes.webp"
                      alt=""
                      onClick={() =>
                        window.open(
                          "https://pod.advflr.com/client/videos?videoUrl=https://player.vimeo.com/video/960924062?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
                          "_blank"
                        )
                      }
                    />
                  </div>
                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/kanekidsmini-astronaut.webp"
                      alt=""
                      onClick={() =>
                        window.open(
                          "https://pod.advflr.com/client/videos?videoUrl=https://player.vimeo.com/video/960969830?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
                          "_blank"
                        )
                      }
                    />
                  </div>
                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/kanekidsminitravel-green-navy.webp"
                      alt=""
                      onClick={() =>
                        window.open(
                          "https://pod.advflr.com/client/videos?videoUrl=https://player.vimeo.com/video/960991738?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
                          "_blank"
                        )
                      }
                    />
                  </div>
                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/kanekidsmini-fuzzybolt.webp"
                      alt=""
                      onClick={() =>
                        window.open(
                          "https://pod.advflr.com/client/videos?videoUrl=https://player.vimeo.com/video/960995132?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
                          "_blank"
                        )
                      }
                    />
                  </div>
                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/kannekidsmini-furryhearts.webp"
                      alt=""
                      onClick={() =>
                        window.open(
                          "https://pod.advflr.com/client/videos?videoUrl=https://player.vimeo.com/video/961003334?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
                          "_blank"
                        )
                      }
                    />
                  </div>
                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/kanekidsminitravel_strawberries.webp"
                      alt=""
                      onClick={() =>
                        window.open(
                          "https://pod.advflr.com/client/videos?videoUrl=https://player.vimeo.com/video/961152726?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
                          "_blank"
                        )
                      }
                    />
                  </div>
                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/kanekidsminitravel-pink-silver.webp"
                      alt=""
                      onClick={() =>
                        window.open(
                          "https://pod.advflr.com/client/videos?videoUrl=https://player.vimeo.com/video/964636183?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
                          "_blank"
                        )
                      }
                    />
                  </div>
                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/belkin/charger/render_1.webp"
                      alt=""
                      onClick={() =>
                        window.open(
                          "https://www.pod.advflr.com/client/videos?videoUrl=https://player.vimeo.com/video/952266760?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
                          "_blank"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/owala_freesip_black/render_1.webp"
                      alt=""
                      onClick={() =>
                        window.open(
                          "https://pod.advflr.com/client/videos?videoUrl=https://player.vimeo.com/video/952278476?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
                          "_blank"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/sharperimage/handheld-vacum/render_1.webp"
                      alt=""
                      onClick={() =>
                        window.open(
                          "https://pod.advflr.com/client/videos?videoUrl=https://player.vimeo.com/video/952271461?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
                          "_blank"
                        )
                      }
                    />
                  </div>
                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/render_1.webp"
                      alt=""
                      onClick={() =>
                        window.open(
                          "https://pod.advflr.com/client/videos?videoUrl=https://player.vimeo.com/video/952285873?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
                          "_blank"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/torani_syrup/thumbnail_2.webp"
                      alt=""
                      onClick={() =>
                        window.open(
                          "https://pod.advflr.com/client/videos?videoUrl=https://player.vimeo.com/video/952278508?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
                          "_blank"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/torani_sauce/thumbnail_1.webp"
                      alt=""
                      onClick={() =>
                        window.open(
                          "https://pod.advflr.com/client/videos?videoUrl=https://player.vimeo.com/video/952278455?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
                          "_blank"
                        )
                      }
                    />
                  </div>
                </>
              )}

              {interactivecontainer && (
                <>
                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/petit-moyen-checked-bag.webp"
                      alt=""
                      onClick={() =>
                        window.open(
                          "https://pod.advflr.com/client/videos?videoUrl=https://app.vectary.com/p/7Is1hRdp6XwvUeqHYgQNGd",
                          "_blank"
                        )
                      }
                    />
                  </div>
                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/camel-new-look-bag.webp"
                      alt=""
                      onClick={() =>
                        window.open(
                          "https://pod.advflr.com/client/videos?videoUrl=https://app.vectary.com/p/4KSqjFOKOYnTyTMV5LUGvQ",
                          "_blank"
                        )
                      }
                    />
                  </div>
                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/crest-bock-bucket-bag.webp"
                      alt=""
                      onClick={() =>
                        window.open(
                          "https://pod.advflr.com/client/videos?videoUrl=https://app.vectary.com/p/1xZqU3oC3pedHsETQHHTKs",
                          "_blank"
                        )
                      }
                    />
                  </div>
                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/trolly/render_2.webp"
                      alt=""
                      onClick={() =>
                        window.open(
                          "https://pod.advflr.com/client/videos?videoUrl=https://app.vectary.com/p/1fs1urRKKKXPO1s4naAQYh",
                          "_blank"
                        )
                      }
                    />
                  </div>
                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/Statebags/kanekids_minibag/thumbnail_1.webp"
                      alt=""
                      onClick={() =>
                        window.open(
                          "https://pod.advflr.com/client/videos?videoUrl=https://app.vectary.com/p/6Gu4LTxb1aFxociI12iPQa",
                          "_blank"
                        )
                      }
                    />
                  </div>
                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/galvanized-metal-shed.webp"
                      alt=""
                      onClick={() =>
                        window.open(
                          "https://pod.advflr.com/client/videos?videoUrl=https://app.vectary.com/p/2G2VYz3CFjuYtvlolwnckL",
                          "_blank"
                        )
                      }
                    />
                  </div>
                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/teamson-home-stratford.jpeg"
                      alt=""
                      onClick={() =>
                        window.open(
                          "https://pod.advflr.com/client/videos?videoUrl=https://app.vectary.com/p/6QWKFUDHDTpmrsnxI8glnm",
                          "_blank"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/bookshelf.png"
                      alt=""
                      onClick={() =>
                        window.open(
                          "https://pod.advflr.com/client/videos?videoUrl=https://app.vectary.com/p/4hGVuSHu5ykuwhSmEAVnku",
                          "_blank"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/render_1.webp"
                      alt=""
                      onClick={() =>
                        window.open(
                          "https://pod.advflr.com/client/videos?videoUrl=https://app.vectary.com/p/18X8BhgwE60Qr9bNpXOi2a",
                          "_blank"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/belkin/earbuds/render_1.webp"
                      alt=""
                      onClick={() =>
                        window.open(
                          "https://pod.advflr.com/client/videos?videoUrl=https://app.vectary.com/p/7OuGgUWzbo3hHOt4V0HoVw",
                          "_blank"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/owala_freesip_black/render_1.webp"
                      alt=""
                      onClick={() =>
                        window.open(
                          "https://pod.advflr.com/client/videos?videoUrl=https://app.vectary.com/p/7QWY3rWb7dip8vFGJiGk4S",
                          "_blank"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/shadestore/roller-shades/render_2.jpg"
                      alt=""
                      onClick={() =>
                        window.open(
                          "https://pod.advflr.com/client/videos?videoUrl=https://app.vectary.com/p/20DW2E4mxFc88MrutFWCCK",
                          "_blank"
                        )
                      }
                    />
                  </div>
                </>
              )}

              {Turnaroundcontainer && (
                <>
                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/handbag-black-grande-bateau.webp"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://www.pod.advflr.com/turnaround/advertflair/handbag-black-grande-bateau",
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/handbag-black-grande-bateau-qr.webp"
                        )
                      }
                    />
                  </div>
                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/camel-new-look-bag.webp"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://www.pod.advflr.com/turnaround/advertflair/camel-new-look-bag",
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/camel-new-look-bag-qr.webp"
                        )
                      }
                    />
                  </div>
                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/petit-moyen-checked-bag.webp"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://www.pod.advflr.com/turnaround/advertflair/petit-moyen-checked-bag",
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/petit-moyen-checked-bag-qr.webp"
                        )
                      }
                    />
                  </div>
                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/petit-moyen-violet.webp"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://www.pod.advflr.com/turnaround/advertflair/petit-moyen-violet",
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/petit-moyen-violet-qr.webp"
                        )
                      }
                    />
                  </div>
                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/petit-moyen-black.webp"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://www.pod.advflr.com/turnaround/advertflair/petit-moyen-black",
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/petit-moyen-black-qr.webp"
                        )
                      }
                    />
                  </div>
                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/cream-woven-checker.webp"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://www.pod.advflr.com/turnaround/advertflair/cream-woven-checker",
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/cream-woven-checker-qr.webp"
                        )
                      }
                    />
                  </div>
                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/grass-woven-checker.webp"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://www.pod.advflr.com/turnaround/advertflair/grass-woven-checker",
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/grass-woven-checker-qr.webp"
                        )
                      }
                    />
                  </div>
                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/crest-bock-bucket-bag.webp"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://www.pod.advflr.com/turnaround/advertflair/crest-bock-bucket-bag",
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/crest-bock-bucket-bag.png"
                        )
                      }
                    />
                  </div>
                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/galvanized-metal-shed.webp"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://www.pod.advflr.com/turnaround/advertflair/galvanized-metal-shed-glb",
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/galvanized-metal-shed-glb-qr.png"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/cordless-vacuum-cleaner.webp"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://www.pod.advflr.com/turnaround/advertflair/cordless-vacuum-cleaner",
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/cordless-vacuum-cleaner-qr.png"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/steelpro-max-pool.webp"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://www.pod.advflr.com/turnaround/advertflair/steelpro-max-pool",
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/steelpro-max-pool-qr.png"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/teamson-home-stratford.jpeg"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://www.pod.advflr.com/turnaround/advertflair/teamson-home-stratford",
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/teamson-home-stratford-qr.png"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/round-velvet-footstool.jpeg"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://www.pod.advflr.com/turnaround/advertflair/round-velvet-footstool",
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/round-velvet-footstool-qr.png"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/bookshelf.png"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://www.pod.advflr.com/turnaround/advertflair/bookshelf",
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/bookshelf-qr.png"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/daisy-drop-earrings.webp"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://www.pod.advflr.com/turnaround-jewellery/advertflair/daisy-drop-earrings",
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/daisy-drop-earrings-qr.png"
                        )
                      }
                    />
                  </div>
                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/lab-grown-diamond-tennis-bracelet.jpg"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://www.pod.advflr.com/turnaround-jewellery/advertflair/lab-grown-diamond-tennis-bracelet",
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/lab-grown-diamond-tennis-bracelet-qr.png"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/diamond-feather-drop-earrings.jpg"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://www.pod.advflr.com/turnaround/advertflair/diamond-feather-drop-earrings",
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/diamond-feather-drop-earrings-qr.png"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/diamond-elongated-hoop-earrings.jpg"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://www.pod.advflr.com/turnaround/advertflair/diamond-elongated-hoop-earrings",
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/diamond-elongated-hoop-earrings-qr.png"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/claridge-blazer-dress.webp"
                      alt="hello"
                      onClick={() =>
                        openPopup(
                          "https://www.pod.advflr.com/turnaround/advertflair/claridge-blazer-dress",
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/claridge-blazer-dress.png"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/keros-outdoor-dining-table.webp"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://www.pod.advflr.com/turnaround/advertflair/keros-outdoor-dining-table",
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/keros-outdoor-dining-table.png"
                        )
                      }
                    />
                  </div>
                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/perri-leather-chair.webp"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://www.pod.advflr.com/turnaround/advertflair/perri-leather-chair",
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/perri-leather-chair.png"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/havana-leather-chair.webp"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://www.pod.advflr.com/turnaround/advertflair/havana-leather-chair",
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/havana-leather-chair.png"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <img
                      src="https://m.media-amazon.com/images/I/61XpoVIokcL._AC_SL1500_.jpg"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://www.pod.advflr.com/turnaround/advertflair/sunset_trading",
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/sunset_trading.png"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/kalis-indooroutdoor-occasional-chair.webp"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://www.pod.advflr.com/turnaround/advertflair/kalis-indooroutdoor-occasional-chair",
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/kalis-indooroutdoor-occasional-chair.png"
                        )
                      }
                    />
                  </div>
                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/sonoma-two-seat-sofa.webp"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://www.pod.advflr.com/turnaround/advertflair/sonoma-two-seat-sofa",
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/sonoma-two-seat-sofa.png"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/dowel-counter-stool/render_1.webp"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://www.pod.advflr.com/turnaround/anthropologie/dowel-counter-stool/",
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/dowel-counter-stool.png"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/71Wawn3zILL._AC_SL1500_.jpg"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://www.pod.advflr.com/turnaround/advertflair/chesterfield_style_sofa",
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/chesterfield_style_sofa.png"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <img
                      src="https://m.media-amazon.com/images/I/91C+7LkErCL._AC_SL1500_.jpg"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://www.pod.advflr.com/turnaround/advertflair/christopher_knight_home",
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/christopher_knight_home.png"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <img
                      src="https://m.media-amazon.com/images/I/810WRBT-+oL._AC_SL1500_.jpg"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://www.pod.advflr.com/turnaround/advertflair/lazada_sofas",
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/lazada_sofas.png"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <img
                      src="https://m.media-amazon.com/images/I/81zUxanzu3L._AC_SL1500_.jpg"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://www.pod.advflr.com/turnaround/advertflair/manual_reclining_sectional_motion_sofa",
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/manual_reclining_sectional_motion_sofa.png"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <img
                      src="https://m.media-amazon.com/images/I/81zckd4pOHL._AC_SL1500_.jpg"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://www.pod.advflr.com/turnaround/advertflair/modern_convertible",
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/modern_convertible.png"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <img
                      src="https://m.media-amazon.com/images/I/71XsnaoJl6L._AC_SL1500_.jpg"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://www.pod.advflr.com/turnaround/advertflair/muuegm_sofas",
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/muuegm_sofas.png"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/81XL4egbPdL._AC_SL1500_.jpg"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://www.pod.advflr.com/turnaround/advertflair/patio_dining_set",
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/patio_dining_set+(2).png"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/render_1.webp"
                      alt=""
                      onClick={() =>
                        window.open(
                          "https://www.pod.advflr.com/turnaround/stojo/cup/",
                          "_blank"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/owala_freesip_black/render_1.webp"
                      alt=""
                      onClick={() =>
                        window.open(
                          "https://www.pod.advflr.com/turnaround/urbanoutfitters/owala_freesip_black",
                          "_blank"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/economy-safety-glasses.webp"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://www.pod.advflr.com/turnaround/advertflair/economy-safety-glasses",
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/economy-safety-glasses.png"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/ellen-wedge-sandal-caramel.webp"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://www.pod.advflr.com/turnaround/advertflair/ellen-wedge-sandal-caramel",
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/ellen-wedge-sandal-caramel.png"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/sharperimage/handheld-vacum/main_render.webp"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://www.pod.advflr.com/turnaround/sharperimage/handheld-vacuum",
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/qr/overCoatQr.png"
                        )
                      }
                    />
                  </div>
                </>
              )}

              {vtocontainer && (
                <>
                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/petit-moyen-checked-bag.webp"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://www.bagsdk.advflr.com/?glb=https://advertflair-public.s3.us-west-2.amazonaws.com/client/clarev/output/petitmoyen_ar.glb",
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/petit-moyen-checked-bag-vto-qr.webp"
                        )
                      }
                    />
                  </div>
                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/clarev/grandetote-2/render1.webp"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://www.bagsdk.advflr.com/?glb=https://advertflair-public.s3.us-west-2.amazonaws.com/client/clarev/output/grandebateau_ar.glb",
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/qr/grandetoteQr.png"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/rebags/louis/render1-1.webp"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://www.bagsdk.advflr.com/?glb=https://advertflair-public.s3.us-west-2.amazonaws.com/client/rebags/output/louisbag_vto.glb",
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/qr/louisQr.png"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/marc-jacobs/marc-jacobs-1.jpg"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://www.bagsdk.advflr.com/?glb=https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/output/default_bag.glb",
                          " https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/qr/macrosQr.png"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/temp/posh-mini-crossbag.webp"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://www.bagsdk.advflr.com/?glb=https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/vto/posh-mini-crossbag.glb",
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/qr/mini-cross-bagQr.png"
                        )
                      }
                    />
                  </div>
                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/lab-grown-diamond-tennis-bracelet.jpg"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://www.watchsdk.advflr.com/?glb=https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/vto/lab-grown-diamond-tennis-bracelet-vto.glb",
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/lab-grown-diamond-tennis-bracelet-qr.png"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <div className={styles.label}>
                      <p>Tryon</p>
                    </div>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/diamond-elongated-hoop-earrings.jpg"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://www.facesdk.advflr.com/?glb=https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/vto/diamond-elongated-hoop-earrings-vto.glb",
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/diamond-elongated-hoop-earrings-qr.png"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <div className={styles.label}>
                      <p>Tryon</p>
                    </div>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/daisy-drop-earrings.webp"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://www.facesdk.advflr.com/?glb=https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/vto/daisy-drop-earrings.glb",
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/daisy-drop-earrings-qr.png"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/reading-glass/render_1.webp"
                      alt=""
                      onClick={() =>
                        window.open(
                          "https://www.pod.advflr.com/client/vto/frontcamera/01ce031c-bbcc-40b2-a0a5-0478793ec0c7/1ee15629-39c0-4eb9-849b-8dabd1a6729f",
                          "_blank"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/tshirt/camflogue.jpeg"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://www.shirtsdk.advflr.com/?glb=https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/tshirt/jacket_ar.glb",
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/qr/overCoatQr.png"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/claridge-blazer-dress.webp"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://www.shirtsdk.advflr.com/?glb=https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/vto/claridge-blazer-dress-vto.glb",
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/claridge-blazer-dress-qr.png"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/tshirt/thumbnail_1.webp"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://www.shirtsdk.advflr.com/?glb=https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/vto/butwiser_tshirt.glb",
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/qr/butt-wiserQr.png"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/rebags/rolex/render_1.png"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://www.watchsdk.advflr.com/?glb=https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/vto/rolex.glb",
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/qr/rolexQr.png"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/patek-watch/patk_world.jpg"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://www.watchsdk.advflr.com/?glb=https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/vto/watch.glb",
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/qr/patekQr.png"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/rado/rado%2Bwomens%2Bwatch.webp"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://www.watchsdk.advflr.com/?glb=https://advertflair-public.s3.us-west-2.amazonaws.com/client/rado/output/rado-womens-watch-ar.glb",
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/qr/rado-womenQr.png"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/rado/rado%2Bmens%2Bwatch.jpg"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://www.watchsdk.advflr.com/?glb=https://advertflair-public.s3.us-west-2.amazonaws.com/client/rado/output/rado-mens-watch-ar.glb",
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/qr/rado-menQr.png"
                        )
                      }
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/overall/clare.v/desktop-footer.webp"
          alt=""
        />
      </div>

      <div className={styles.mobileContainer}>
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/overall/clare.v/mobile-header.webp"
          alt="Hello"
        />

        {popupcontainer && (
          <div className={styles.modalPopup}>
            <div className={styles.header}>
              <button onClick={closePopup} className={styles.primary}>
                Close
              </button>
            </div>

            <div className={styles.content}>
              {qrcontainer && <img src={qrcodeurl} alt="" />}
              <iframe
                title="ar"
                src={imageUrl}
                seamless
                allow="camera"
              ></iframe>
            </div>
          </div>
        )}

        <div className={styles.title}>
          {imagecontainer && (
            <>
              3D Rendering <span>26 Products</span>
            </>
          )}

          {videocontainer && (
            <>
              3D Product Videos <span>18 Products</span>
            </>
          )}

          {vtocontainer && (
            <>
              Virtual Try-on <span>16 Products</span>
            </>
          )}

          {Turnaroundcontainer && (
            <>
              360/AR/VR <span>38 Products</span>
            </>
          )}

          {configuratorcontainer && (
            <>
              Configurator <span>2 Products</span>
            </>
          )}

          {interactivecontainer && (
            <>
              Interactive <span>12 Products</span>
            </>
          )}
        </div>

        <select
          name=""
          id=""
          value={selectedOption}
          onChange={handleOptionChange}
        >
          <option value="" selected>
            Services offered
          </option>
          <option>3D Rendering</option>
          <option>3D Product Videos</option>
          <option>360/AR/VR</option>
          <option>Configurator</option>
          <option>Interactive</option>
          <option>Virtual Try-on</option>
          <option>Performance Marketing Ads</option>
        </select>
        <div className={styles.subtitle}>{vtocontainer && <>All VTO's</>}</div>
        <div className={styles.gridSection}>
          {imagecontainer && (
            <>
              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/pettimoyen-black.webp"
                  alt=""
                  onClick={() =>
                    window.open(
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/pettimoyen-black.webp",
                      "_blank"
                    )
                  }
                />
              </div>
              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/03_Checked-Hand-bag.png"
                  alt=""
                  onClick={() =>
                    window.open(
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/03_Checked-Hand-bag.png",
                      "_blank"
                    )
                  }
                />
              </div>
              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/02_Orange-Hand-bag.png"
                  alt=""
                  onClick={() =>
                    window.open(
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/02_Orange-Hand-bag.png",
                      "_blank"
                    )
                  }
                />
              </div>
              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/crest-bock-bucket-bag.png"
                  alt=""
                  onClick={() =>
                    window.open(
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/crest-bock-bucket-bag.png",
                      "_blank"
                    )
                  }
                />
              </div>

              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/pettimoyen-purple.webp"
                  alt=""
                  onClick={() =>
                    window.open(
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/pettimoyen-purple.webp",
                      "_blank"
                    )
                  }
                />
              </div>
              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/04_state-Hand-bag.png"
                  alt=""
                  onClick={() =>
                    window.open(
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/04_state-Hand-bag.png",
                      "_blank"
                    )
                  }
                />
              </div>
              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/Hand-Bag-Black.png"
                  alt=""
                  onClick={() =>
                    window.open(
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/Hand-Bag-Black.png",
                      "_blank"
                    )
                  }
                />
              </div>

              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/Hand-Bag-Blue.png"
                  alt=""
                  onClick={() =>
                    window.open(
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/Hand-Bag-Blue.png",
                      "_blank"
                    )
                  }
                />
              </div>
              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/Luggage-Bag-Nature.png"
                  alt=""
                  onClick={() =>
                    window.open(
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/Luggage-Bag-Nature.png",
                      "_blank"
                    )
                  }
                />
              </div>

              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/LV-bag-light.png"
                  alt=""
                  onClick={() =>
                    window.open(
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/LV-bag-light.png",
                      "_blank"
                    )
                  }
                />
              </div>
              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/teamson-home-stratford.webp"
                  alt=""
                  onClick={() =>
                    window.open(
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/teamson-home-stratford.webp",
                      "_blank"
                    )
                  }
                />
              </div>
              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/round-velvet-footstool.webp"
                  alt=""
                  onClick={() =>
                    window.open(
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/round-velvet-footstool.webp",
                      "_blank"
                    )
                  }
                />
              </div>
              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/teamson-home-stratford.png"
                  alt=""
                  onClick={() =>
                    window.open(
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/teamson-home-stratford.png",
                      "_blank"
                    )
                  }
                />
              </div>
              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/lab-grown-diamond-tennis-bracelet.png"
                  alt=""
                  onClick={() =>
                    window.open(
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/lab-grown-diamond-tennis-bracelet.png",
                      "_blank"
                    )
                  }
                />
              </div>

              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/daisy-drop-earrings.png"
                  alt=""
                  onClick={() =>
                    window.open(
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/daisy-drop-earrings.png",
                      "_blank"
                    )
                  }
                />
              </div>

              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/diamond-elongated-hoop-earrings.png"
                  alt=""
                  onClick={() =>
                    window.open(
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/diamond-elongated-hoop-earrings.png",
                      "_blank"
                    )
                  }
                />
              </div>

              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/Gold-tone+Cutout+Daisy.png"
                  alt=""
                  onClick={() =>
                    window.open(
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/Gold-tone+Cutout+Daisy.png",
                      "_blank"
                    )
                  }
                />
              </div>

              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/havana-leather-chair-blue.png"
                  alt=""
                  onClick={() =>
                    window.open(
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/havana-leather-chair-blue.png",
                      "_blank"
                    )
                  }
                />
              </div>

              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/havana-leather-chair-yellow.png"
                  alt=""
                  onClick={() =>
                    window.open(
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/havana-leather-chair-yellow.png",
                      "_blank"
                    )
                  }
                />
              </div>

              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/denver-outdoor-sectional.png"
                  alt=""
                  onClick={() =>
                    window.open(
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/denver-outdoor-sectional.png",
                      "_blank"
                    )
                  }
                />
              </div>
              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/Furniture-Blue.png"
                  alt=""
                  onClick={() =>
                    window.open(
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/Furniture-Blue.png",
                      "_blank"
                    )
                  }
                />
              </div>
              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/dowel-counter-stool/render_1.webp"
                  alt=""
                  onClick={() =>
                    window.open(
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/dowel-counter-stool/render_1.webp",
                      "_blank"
                    )
                  }
                />
              </div>

              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/Cap.png"
                  alt=""
                  onClick={() =>
                    window.open(
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/Cap.png",
                      "_blank"
                    )
                  }
                />
              </div>

              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/01_Water-bottle.png"
                  alt=""
                  onClick={() =>
                    window.open(
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/01_Water-bottle.png",
                      "_blank"
                    )
                  }
                />
              </div>
              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/render_6.webp"
                  alt=""
                  onClick={() =>
                    window.open(
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/render_6.webp",
                      "_blank"
                    )
                  }
                />
              </div>
              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/owala_freesip_black/render_4.webp"
                  alt=""
                  onClick={() =>
                    window.open(
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/owala_freesip_black/render_4.webp",
                      "_blank"
                    )
                  }
                />
              </div>
              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/torani_syrup/thumbnail_4.webp"
                  alt=""
                  onClick={() =>
                    window.open(
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/torani_syrup/thumbnail_4.webp",
                      "_blank"
                    )
                  }
                />
              </div>
              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/torani_syrup/renderImage.webp"
                  alt=""
                  onClick={() =>
                    window.open(
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/torani_syrup/renderImage.webp",
                      "_blank"
                    )
                  }
                />
              </div>
            </>
          )}

          {configuratorcontainer && (
            <>
              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/Statebags/willington/render_6.webp"
                  alt=""
                  onClick={() =>
                    window.open(
                      "https://www.pod.advflr.com/client/statebags/wellington",
                      "_blank"
                    )
                  }
                />
              </div>

              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/trolly/render_2.webp"
                  alt=""
                  onClick={() =>
                    window.open(
                      "https://www.pod.advflr.com/client/statebags/trolly",
                      "_blank"
                    )
                  }
                />
              </div>
            </>
          )}

          {videocontainer && (
            <>
              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/Statebags/willington/render_6.webp"
                  alt=""
                  onClick={() =>
                    window.open(
                      "https://pod.advflr.com/client/videos?videoUrl=https://player.vimeo.com/video/952272984?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
                      "_blank"
                    )
                  }
                />
              </div>
              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/trolly/render_2.webp"
                  alt=""
                  onClick={() =>
                    window.open(
                      "https://pod.advflr.com/client/videos?videoUrl=https://player.vimeo.com/video/952278530?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
                      "_blank"
                    )
                  }
                />
              </div>
              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/crest-bock-bucket-bag.webp"
                  alt=""
                  onClick={() =>
                    window.open(
                      "https://player.vimeo.com/video/954275255?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
                      "_blank"
                    )
                  }
                />
              </div>
              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/rebags/bags/louis-vuitton/render_1.webp"
                  alt=""
                  onClick={() =>
                    window.open(
                      "https://pod.advflr.com/client/videos?videoUrl=https://player.vimeo.com/video/952270191?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
                      "_blank"
                    )
                  }
                />
              </div>
              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/Statebags/kanekids_minibag/thumbnail_1.webp"
                  alt=""
                  onClick={() =>
                    window.open(
                      "https://pod.advflr.com/client/videos?videoUrl=https://player.vimeo.com/video/952272869?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
                      "_blank"
                    )
                  }
                />
              </div>
              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/kanekidsminitravel-airplanes.webp"
                  alt=""
                  onClick={() =>
                    window.open(
                      "https://pod.advflr.com/client/videos?videoUrl=https://player.vimeo.com/video/960924062?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
                      "_blank"
                    )
                  }
                />
              </div>
              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/kanekidsmini-astronaut.webp"
                  alt=""
                  onClick={() =>
                    window.open(
                      "https://pod.advflr.com/client/videos?videoUrl=https://player.vimeo.com/video/960969830?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
                      "_blank"
                    )
                  }
                />
              </div>
              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/kanekidsminitravel-green-navy.webp"
                  alt=""
                  onClick={() =>
                    window.open(
                      "https://pod.advflr.com/client/videos?videoUrl=https://player.vimeo.com/video/960991738?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
                      "_blank"
                    )
                  }
                />
              </div>
              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/kanekidsmini-fuzzybolt.webp"
                  alt=""
                  onClick={() =>
                    window.open(
                      "https://pod.advflr.com/client/videos?videoUrl=https://player.vimeo.com/video/960995132?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
                      "_blank"
                    )
                  }
                />
              </div>
              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/kannekidsmini-furryhearts.webp"
                  alt=""
                  onClick={() =>
                    window.open(
                      "https://pod.advflr.com/client/videos?videoUrl=https://player.vimeo.com/video/961003334?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
                      "_blank"
                    )
                  }
                />
              </div>
              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/kanekidsminitravel_strawberries.webp"
                  alt=""
                  onClick={() =>
                    window.open(
                      "https://pod.advflr.com/client/videos?videoUrl=https://player.vimeo.com/video/961152726?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
                      "_blank"
                    )
                  }
                />
              </div>
              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/kanekidsminitravel-pink-silver.webp"
                  alt=""
                  onClick={() =>
                    window.open(
                      "https://pod.advflr.com/client/videos?videoUrl=https://player.vimeo.com/video/964636183?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
                      "_blank"
                    )
                  }
                />
              </div>
              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/belkin/charger/render_1.webp"
                  alt=""
                  onClick={() =>
                    window.open(
                      "https://www.pod.advflr.com/client/videos?videoUrl=https://player.vimeo.com/video/952266760?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
                      "_blank"
                    )
                  }
                />
              </div>

              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/owala_freesip_black/render_1.webp"
                  alt=""
                  onClick={() =>
                    window.open(
                      "https://pod.advflr.com/client/videos?videoUrl=https://player.vimeo.com/video/952278476?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
                      "_blank"
                    )
                  }
                />
              </div>

              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/sharperimage/handheld-vacum/render_1.webp"
                  alt=""
                  onClick={() =>
                    window.open(
                      "https://pod.advflr.com/client/videos?videoUrl=https://player.vimeo.com/video/952271461?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
                      "_blank"
                    )
                  }
                />
              </div>
              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/render_1.webp"
                  alt=""
                  onClick={() =>
                    window.open(
                      "https://pod.advflr.com/client/videos?videoUrl=https://player.vimeo.com/video/952285873?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
                      "_blank"
                    )
                  }
                />
              </div>

              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/torani_syrup/thumbnail_2.webp"
                  alt=""
                  onClick={() =>
                    window.open(
                      "https://pod.advflr.com/client/videos?videoUrl=https://player.vimeo.com/video/952278508?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
                      "_blank"
                    )
                  }
                />
              </div>

              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/torani_sauce/thumbnail_1.webp"
                  alt=""
                  onClick={() =>
                    window.open(
                      "https://pod.advflr.com/client/videos?videoUrl=https://player.vimeo.com/video/952278455?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
                      "_blank"
                    )
                  }
                />
              </div>
            </>
          )}

          {interactivecontainer && (
            <>
              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/petit-moyen-checked-bag.webp"
                  alt=""
                  onClick={() =>
                    window.open(
                      "https://pod.advflr.com/client/videos?videoUrl=https://app.vectary.com/p/7Is1hRdp6XwvUeqHYgQNGd",
                      "_blank"
                    )
                  }
                />
              </div>
              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/camel-new-look-bag.webp"
                  alt=""
                  onClick={() =>
                    window.open(
                      "https://pod.advflr.com/client/videos?videoUrl=https://app.vectary.com/p/4KSqjFOKOYnTyTMV5LUGvQ",
                      "_blank"
                    )
                  }
                />
              </div>
              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/crest-bock-bucket-bag.webp"
                  alt=""
                  onClick={() =>
                    window.open(
                      "https://pod.advflr.com/client/videos?videoUrl=https://app.vectary.com/p/1xZqU3oC3pedHsETQHHTKs",
                      "_blank"
                    )
                  }
                />
              </div>
              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/trolly/render_2.webp"
                  alt=""
                  onClick={() =>
                    window.open(
                      "https://pod.advflr.com/client/videos?videoUrl=https://app.vectary.com/p/1fs1urRKKKXPO1s4naAQYh",
                      "_blank"
                    )
                  }
                />
              </div>
              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/Statebags/kanekids_minibag/thumbnail_1.webp"
                  alt=""
                  onClick={() =>
                    window.open(
                      "https://pod.advflr.com/client/videos?videoUrl=https://app.vectary.com/p/6Gu4LTxb1aFxociI12iPQa",
                      "_blank"
                    )
                  }
                />
              </div>
              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/galvanized-metal-shed.webp"
                  alt=""
                  onClick={() =>
                    window.open(
                      "https://pod.advflr.com/client/videos?videoUrl=https://app.vectary.com/p/2G2VYz3CFjuYtvlolwnckL",
                      "_blank"
                    )
                  }
                />
              </div>

              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/teamson-home-stratford.jpeg"
                  alt=""
                  onClick={() =>
                    window.open(
                      "https://pod.advflr.com/client/videos?videoUrl=https://app.vectary.com/p/6QWKFUDHDTpmrsnxI8glnm",
                      "_blank"
                    )
                  }
                />
              </div>
              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/bookshelf.png"
                  alt=""
                  onClick={() =>
                    window.open(
                      "https://pod.advflr.com/client/videos?videoUrl=https://app.vectary.com/p/4hGVuSHu5ykuwhSmEAVnku",
                      "_blank"
                    )
                  }
                />
              </div>
              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/render_1.webp"
                  alt=""
                  onClick={() =>
                    window.open(
                      "https://pod.advflr.com/client/videos?videoUrl=https://app.vectary.com/p/18X8BhgwE60Qr9bNpXOi2a",
                      "_blank"
                    )
                  }
                />
              </div>
              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/belkin/earbuds/render_1.webp"
                  alt=""
                  onClick={() =>
                    window.open(
                      "https://pod.advflr.com/client/videos?videoUrl=https://app.vectary.com/p/7OuGgUWzbo3hHOt4V0HoVw",
                      "_blank"
                    )
                  }
                />
              </div>

              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/owala_freesip_black/render_1.webp"
                  alt=""
                  onClick={() =>
                    window.open(
                      "https://pod.advflr.com/client/videos?videoUrl=https://app.vectary.com/p/7QWY3rWb7dip8vFGJiGk4S",
                      "_blank"
                    )
                  }
                />
              </div>

              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/shadestore/roller-shades/render_2.jpg"
                  alt=""
                  onClick={() =>
                    window.open(
                      "https://pod.advflr.com/client/videos?videoUrl=https://app.vectary.com/p/20DW2E4mxFc88MrutFWCCK",
                      "_blank"
                    )
                  }
                />
              </div>
            </>
          )}

          {Turnaroundcontainer && (
            <>
              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/handbag-black-grande-bateau.webp"
                  alt=""
                  onClick={() =>
                    openPopup(
                      "https://www.pod.advflr.com/turnaround/advertflair/handbag-black-grande-bateau",
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/handbag-black-grande-bateau-qr.webp"
                    )
                  }
                />
              </div>
              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/camel-new-look-bag.webp"
                  alt=""
                  onClick={() =>
                    openPopup(
                      "https://www.pod.advflr.com/turnaround/advertflair/camel-new-look-bag",
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/camel-new-look-bag-qr.webp"
                    )
                  }
                />
              </div>
              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/petit-moyen-checked-bag.webp"
                  alt=""
                  onClick={() =>
                    openPopup(
                      "https://www.pod.advflr.com/turnaround/advertflair/petit-moyen-checked-bag",
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/petit-moyen-checked-bag-qr.webp"
                    )
                  }
                />
              </div>
              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/petit-moyen-violet.webp"
                  alt=""
                  onClick={() =>
                    openPopup(
                      "https://www.pod.advflr.com/turnaround/advertflair/petit-moyen-violet",
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/petit-moyen-violet-qr.webp"
                    )
                  }
                />
              </div>
              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/petit-moyen-black.webp"
                  alt=""
                  onClick={() =>
                    openPopup(
                      "https://www.pod.advflr.com/turnaround/advertflair/petit-moyen-black",
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/petit-moyen-black-qr.webp"
                    )
                  }
                />
              </div>
              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/cream-woven-checker.webp"
                  alt=""
                  onClick={() =>
                    openPopup(
                      "https://www.pod.advflr.com/turnaround/advertflair/cream-woven-checker",
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/cream-woven-checker-qr.webp"
                    )
                  }
                />
              </div>
              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/grass-woven-checker.webp"
                  alt=""
                  onClick={() =>
                    openPopup(
                      "https://www.pod.advflr.com/turnaround/advertflair/grass-woven-checker",
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/grass-woven-checker-qr.webp"
                    )
                  }
                />
              </div>
              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/crest-bock-bucket-bag.webp"
                  alt=""
                  onClick={() =>
                    openPopup(
                      "https://www.pod.advflr.com/turnaround/advertflair/crest-bock-bucket-bag",
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/crest-bock-bucket-bag.png"
                    )
                  }
                />
              </div>
              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/galvanized-metal-shed.webp"
                  alt=""
                  onClick={() =>
                    openPopup(
                      "https://www.pod.advflr.com/turnaround/advertflair/galvanized-metal-shed-glb",
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/galvanized-metal-shed-glb-qr.png"
                    )
                  }
                />
              </div>
              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/cordless-vacuum-cleaner.webp"
                  alt=""
                  onClick={() =>
                    openPopup(
                      "https://www.pod.advflr.com/turnaround/advertflair/cordless-vacuum-cleaner",
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/cordless-vacuum-cleaner-qr.png"
                    )
                  }
                />
              </div>
              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/steelpro-max-pool.webp"
                  alt=""
                  onClick={() =>
                    openPopup(
                      "https://www.pod.advflr.com/turnaround/advertflair/steelpro-max-pool",
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/steelpro-max-pool-qr.png"
                    )
                  }
                />
              </div>

              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/teamson-home-stratford.jpeg"
                  alt=""
                  onClick={() =>
                    openPopup(
                      "https://www.pod.advflr.com/turnaround/advertflair/teamson-home-stratford",
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/teamson-home-stratford-qr.png"
                    )
                  }
                />
              </div>

              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/round-velvet-footstool.jpeg"
                  alt=""
                  onClick={() =>
                    openPopup(
                      "https://www.pod.advflr.com/turnaround/advertflair/round-velvet-footstool",
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/round-velvet-footstool-qr.png"
                    )
                  }
                />
              </div>

              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/bookshelf.png"
                  alt=""
                  onClick={() =>
                    openPopup(
                      "https://www.pod.advflr.com/turnaround/advertflair/bookshelf",
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/bookshelf-qr.png"
                    )
                  }
                />
              </div>

              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/daisy-drop-earrings.webp"
                  alt=""
                  onClick={() =>
                    openPopup(
                      "https://www.pod.advflr.com/turnaround-jewellery/advertflair/daisy-drop-earrings",
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/daisy-drop-earrings-qr.png"
                    )
                  }
                />
              </div>
              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/lab-grown-diamond-tennis-bracelet.jpg"
                  alt=""
                  onClick={() =>
                    openPopup(
                      "https://www.pod.advflr.com/turnaround-jewellery/advertflair/lab-grown-diamond-tennis-bracelet",
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/lab-grown-diamond-tennis-bracelet-qr.png"
                    )
                  }
                />
              </div>

              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/diamond-feather-drop-earrings.jpg"
                  alt=""
                  onClick={() =>
                    openPopup(
                      "https://www.pod.advflr.com/turnaround/advertflair/diamond-feather-drop-earrings",
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/diamond-feather-drop-earrings-qr.png"
                    )
                  }
                />
              </div>

              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/diamond-elongated-hoop-earrings.jpg"
                  alt=""
                  onClick={() =>
                    openPopup(
                      "https://www.pod.advflr.com/turnaround/advertflair/diamond-elongated-hoop-earrings",
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/diamond-elongated-hoop-earrings-qr.png"
                    )
                  }
                />
              </div>

              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/claridge-blazer-dress.webp"
                  alt="hello"
                  onClick={() =>
                    openPopup(
                      "https://www.pod.advflr.com/turnaround/advertflair/claridge-blazer-dress",
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/claridge-blazer-dress.png"
                    )
                  }
                />
              </div>

              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/keros-outdoor-dining-table.webp"
                  alt=""
                  onClick={() =>
                    openPopup(
                      "https://www.pod.advflr.com/turnaround/advertflair/keros-outdoor-dining-table",
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/keros-outdoor-dining-table.png"
                    )
                  }
                />
              </div>
              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/perri-leather-chair.webp"
                  alt=""
                  onClick={() =>
                    openPopup(
                      "https://www.pod.advflr.com/turnaround/advertflair/perri-leather-chair",
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/perri-leather-chair.png"
                    )
                  }
                />
              </div>

              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/havana-leather-chair.webp"
                  alt=""
                  onClick={() =>
                    openPopup(
                      "https://www.pod.advflr.com/turnaround/advertflair/havana-leather-chair",
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/havana-leather-chair.png"
                    )
                  }
                />
              </div>

              <div className={styles.imageSection}>
                <img
                  src="https://m.media-amazon.com/images/I/61XpoVIokcL._AC_SL1500_.jpg"
                  alt=""
                  onClick={() =>
                    openPopup(
                      "https://www.pod.advflr.com/turnaround/advertflair/sunset_trading",
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/sunset_trading.png"
                    )
                  }
                />
              </div>

              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/kalis-indooroutdoor-occasional-chair.webp"
                  alt=""
                  onClick={() =>
                    openPopup(
                      "https://www.pod.advflr.com/turnaround/advertflair/kalis-indooroutdoor-occasional-chair",
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/kalis-indooroutdoor-occasional-chair.png"
                    )
                  }
                />
              </div>
              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/sonoma-two-seat-sofa.webp"
                  alt=""
                  onClick={() =>
                    openPopup(
                      "https://www.pod.advflr.com/turnaround/advertflair/sonoma-two-seat-sofa",
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/sonoma-two-seat-sofa.png"
                    )
                  }
                />
              </div>

              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/dowel-counter-stool/render_1.webp"
                  alt=""
                  onClick={() =>
                    openPopup(
                      "https://www.pod.advflr.com/turnaround/anthropologie/dowel-counter-stool/",
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/dowel-counter-stool.png"
                    )
                  }
                />
              </div>

              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/71Wawn3zILL._AC_SL1500_.jpg"
                  alt=""
                  onClick={() =>
                    openPopup(
                      "https://www.pod.advflr.com/turnaround/advertflair/chesterfield_style_sofa",
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/chesterfield_style_sofa.png"
                    )
                  }
                />
              </div>

              <div className={styles.imageSection}>
                <img
                  src="https://m.media-amazon.com/images/I/91C+7LkErCL._AC_SL1500_.jpg"
                  alt=""
                  onClick={() =>
                    openPopup(
                      "https://www.pod.advflr.com/turnaround/advertflair/christopher_knight_home",
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/christopher_knight_home.png"
                    )
                  }
                />
              </div>

              <div className={styles.imageSection}>
                <img
                  src="https://m.media-amazon.com/images/I/810WRBT-+oL._AC_SL1500_.jpg"
                  alt=""
                  onClick={() =>
                    openPopup(
                      "https://www.pod.advflr.com/turnaround/advertflair/lazada_sofas",
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/lazada_sofas.png"
                    )
                  }
                />
              </div>

              <div className={styles.imageSection}>
                <img
                  src="https://m.media-amazon.com/images/I/81zUxanzu3L._AC_SL1500_.jpg"
                  alt=""
                  onClick={() =>
                    openPopup(
                      "https://www.pod.advflr.com/turnaround/advertflair/manual_reclining_sectional_motion_sofa",
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/manual_reclining_sectional_motion_sofa.png"
                    )
                  }
                />
              </div>

              <div className={styles.imageSection}>
                <img
                  src="https://m.media-amazon.com/images/I/81zckd4pOHL._AC_SL1500_.jpg"
                  alt=""
                  onClick={() =>
                    openPopup(
                      "https://www.pod.advflr.com/turnaround/advertflair/modern_convertible",
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/modern_convertible.png"
                    )
                  }
                />
              </div>

              <div className={styles.imageSection}>
                <img
                  src="https://m.media-amazon.com/images/I/71XsnaoJl6L._AC_SL1500_.jpg"
                  alt=""
                  onClick={() =>
                    openPopup(
                      "https://www.pod.advflr.com/turnaround/advertflair/muuegm_sofas",
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/muuegm_sofas.png"
                    )
                  }
                />
              </div>

              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/81XL4egbPdL._AC_SL1500_.jpg"
                  alt=""
                  onClick={() =>
                    openPopup(
                      "https://www.pod.advflr.com/turnaround/advertflair/patio_dining_set",
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/patio_dining_set+(2).png"
                    )
                  }
                />
              </div>

              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/render_1.webp"
                  alt=""
                  onClick={() =>
                    window.open(
                      "https://www.pod.advflr.com/turnaround/stojo/cup/",
                      "_blank"
                    )
                  }
                />
              </div>

              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/owala_freesip_black/render_1.webp"
                  alt=""
                  onClick={() =>
                    window.open(
                      "https://www.pod.advflr.com/turnaround/urbanoutfitters/owala_freesip_black",
                      "_blank"
                    )
                  }
                />
              </div>

              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/economy-safety-glasses.webp"
                  alt=""
                  onClick={() =>
                    openPopup(
                      "https://www.pod.advflr.com/turnaround/advertflair/economy-safety-glasses",
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/economy-safety-glasses.png"
                    )
                  }
                />
              </div>

              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/ellen-wedge-sandal-caramel.webp"
                  alt=""
                  onClick={() =>
                    openPopup(
                      "https://www.pod.advflr.com/turnaround/advertflair/ellen-wedge-sandal-caramel",
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/ellen-wedge-sandal-caramel.png"
                    )
                  }
                />
              </div>

              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/sharperimage/handheld-vacum/main_render.webp"
                  alt=""
                  onClick={() =>
                    openPopup(
                      "https://www.pod.advflr.com/turnaround/sharperimage/handheld-vacuum",
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/qr/overCoatQr.png"
                    )
                  }
                />
              </div>
            </>
          )}

          {vtocontainer && (
            <>
              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/petit-moyen-checked-bag.webp"
                  alt=""
                  onClick={() =>
                    openPopup(
                      "https://www.bagsdk.advflr.com/?glb=https://advertflair-public.s3.us-west-2.amazonaws.com/client/clarev/output/petitmoyen_ar.glb",
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/petit-moyen-checked-bag-vto-qr.webp"
                    )
                  }
                />
              </div>
              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/clarev/grandetote-2/render1.webp"
                  alt=""
                  onClick={() =>
                    openPopup(
                      "https://www.bagsdk.advflr.com/?glb=https://advertflair-public.s3.us-west-2.amazonaws.com/client/clarev/output/grandebateau_ar.glb",
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/qr/grandetoteQr.png"
                    )
                  }
                />
              </div>
              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/rebags/louis/render1-1.webp"
                  alt=""
                  onClick={() =>
                    openPopup(
                      "https://www.bagsdk.advflr.com/?glb=https://advertflair-public.s3.us-west-2.amazonaws.com/client/rebags/output/louisbag_vto.glb",
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/qr/louisQr.png"
                    )
                  }
                />
              </div>

              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/marc-jacobs/marc-jacobs-1.jpg"
                  alt=""
                  onClick={() =>
                    openPopup(
                      "https://www.bagsdk.advflr.com/?glb=https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/output/default_bag.glb",
                      " https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/qr/macrosQr.png"
                    )
                  }
                />
              </div>

              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/temp/posh-mini-crossbag.webp"
                  alt=""
                  onClick={() =>
                    openPopup(
                      "https://www.bagsdk.advflr.com/?glb=https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/vto/posh-mini-crossbag.glb",
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/qr/mini-cross-bagQr.png"
                    )
                  }
                />
              </div>
              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/lab-grown-diamond-tennis-bracelet.jpg"
                  alt=""
                  onClick={() =>
                    openPopup(
                      "https://www.watchsdk.advflr.com/?glb=https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/vto/lab-grown-diamond-tennis-bracelet-vto.glb",
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/lab-grown-diamond-tennis-bracelet-qr.png"
                    )
                  }
                />
              </div>

              <div className={styles.imageSection}>
                <div className={styles.label}>
                  <p>Tryon</p>
                </div>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/diamond-elongated-hoop-earrings.jpg"
                  alt=""
                  onClick={() =>
                    openPopup(
                      "https://www.facesdk.advflr.com/?glb=https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/vto/diamond-elongated-hoop-earrings-vto.glb",
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/diamond-elongated-hoop-earrings-qr.png"
                    )
                  }
                />
              </div>

              <div className={styles.imageSection}>
                <div className={styles.label}>
                  <p>Tryon</p>
                </div>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/daisy-drop-earrings.webp"
                  alt=""
                  onClick={() =>
                    openPopup(
                      "https://www.facesdk.advflr.com/?glb=https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/vto/daisy-drop-earrings.glb",
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/daisy-drop-earrings-qr.png"
                    )
                  }
                />
              </div>

              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/reading-glass/render_1.webp"
                  alt=""
                  onClick={() =>
                    window.open(
                      "https://www.pod.advflr.com/client/vto/frontcamera/01ce031c-bbcc-40b2-a0a5-0478793ec0c7/1ee15629-39c0-4eb9-849b-8dabd1a6729f",
                      "_blank"
                    )
                  }
                />
              </div>

              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/tshirt/camflogue.jpeg"
                  alt=""
                  onClick={() =>
                    openPopup(
                      "https://www.shirtsdk.advflr.com/?glb=https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/tshirt/jacket_ar.glb",
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/qr/overCoatQr.png"
                    )
                  }
                />
              </div>

              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/claridge-blazer-dress.webp"
                  alt=""
                  onClick={() =>
                    openPopup(
                      "https://www.shirtsdk.advflr.com/?glb=https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/vto/claridge-blazer-dress-vto.glb",
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/qr/claridge-blazer-dress-qr.png"
                    )
                  }
                />
              </div>

              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/tshirt/thumbnail_1.webp"
                  alt=""
                  onClick={() =>
                    openPopup(
                      "https://www.shirtsdk.advflr.com/?glb=https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/vto/butwiser_tshirt.glb",
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/qr/butt-wiserQr.png"
                    )
                  }
                />
              </div>

              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/rebags/rolex/render_1.png"
                  alt=""
                  onClick={() =>
                    openPopup(
                      "https://www.watchsdk.advflr.com/?glb=https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/vto/rolex.glb",
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/qr/rolexQr.png"
                    )
                  }
                />
              </div>

              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/patek-watch/patk_world.jpg"
                  alt=""
                  onClick={() =>
                    openPopup(
                      "https://www.watchsdk.advflr.com/?glb=https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/vto/watch.glb",
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/qr/patekQr.png"
                    )
                  }
                />
              </div>

              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/rado/rado%2Bwomens%2Bwatch.webp"
                  alt=""
                  onClick={() =>
                    openPopup(
                      "https://www.watchsdk.advflr.com/?glb=https://advertflair-public.s3.us-west-2.amazonaws.com/client/rado/output/rado-womens-watch-ar.glb",
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/qr/rado-womenQr.png"
                    )
                  }
                />
              </div>

              <div className={styles.imageSection}>
                <img
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/rado/rado%2Bmens%2Bwatch.jpg"
                  alt=""
                  onClick={() =>
                    openPopup(
                      "https://www.watchsdk.advflr.com/?glb=https://advertflair-public.s3.us-west-2.amazonaws.com/client/rado/output/rado-mens-watch-ar.glb",
                      "https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/qr/rado-menQr.png"
                    )
                  }
                />
              </div>
            </>
          )}
        </div>
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/overall/clare.v/mobile-footer.webp"
          alt="Hello"
        />
      </div>
    </div>
  );
}

export default ClarevProducts;
