import React, { useState } from "react";
import styles from "./Drone.module.scss";
//import VideoContainer from "../../../hooks/VideoContainer";

function Drone() {
  const [selectedImage, setSelectedImage] = useState(
    "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/camera-drone.webp"
  );
  const [imageContainer, setImageContainer] = useState(true);
  const [glbContainer, setGlbContainer] = useState(false);
  const [videocontainer, setVideoContainer] = useState(false);
  const [touchableGlbContainer, setTouchableGlbContainer] = useState(false);

  const handleImageClick = (imageSrc) => {
    setGlbContainer(false);
    setSelectedImage(imageSrc);
    setImageContainer(true);
    setVideoContainer(false);
    setTouchableGlbContainer(false);
  };

  const toggleGlb = () => {
    setGlbContainer(true);
    setImageContainer(false);
    setVideoContainer(false);
    setTouchableGlbContainer(false);
  };

  const videoFunction = () => {
    setImageContainer(false);
    setGlbContainer(false);
    setVideoContainer(true);
    setTouchableGlbContainer(false);
  };

  const toggletouchableGlb = () => {
    setGlbContainer(false);
    setImageContainer(false);
    setVideoContainer(false);
    setTouchableGlbContainer(true);
  };

  return (
    <div>
      <div className={styles.container}>
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/overall/Best_Buy/desktop_header.webp"
          alt=""
        />

        <div className={styles.mainSection}>
          <div className={styles.leftSection}>
            <div className={styles.renderSection}>
              <div className={styles.textSection}></div>
              {imageContainer && <img src={selectedImage} alt="" />}
              {glbContainer && (
                <iframe
                  title="desktopGlb"
                  seamless
                  src="https://www.pod.advflr.com/turnaround/advertflair/camera-drone"
                ></iframe>
              )}

              {touchableGlbContainer && (
                <iframe
                  title="desktopGlb"
                  seamless
                  allow="camera"
                  src="https://app.vectary.com/p/45oTh8G9HqBDYriBaVUzm9"
                ></iframe>
              )}

              {videocontainer && (
                <iframe
                  src="https://player.vimeo.com/video/978080920?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;autoplay=1"
                  width="640"
                  height="360"
                  frameborder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowfullscreen
                  title="Video Player"
                ></iframe>
              )}
            </div>

            <div className={styles.thumbnailSection}>
              <img
                src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/camera-drone.webp"
                alt=""
                onClick={() =>
                  handleImageClick(
                    "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/camera-drone.webp"
                  )
                }
              />
              <img
                src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/camera_drone_2.webp"
                alt=""
                onClick={() =>
                  handleImageClick(
                    "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/camera_drone_2.webp"
                  )
                }
              />
              <img
                src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/camera_drone_1.webp"
                alt=""
                onClick={() =>
                  handleImageClick(
                    "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/camera_drone_1.webp"
                  )
                }
              />
              <img
                src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/366-1.webp"
                alt=""
                onClick={toggleGlb}
              />
              <img
                src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/video_thumbnail.webp"
                alt="Video"
                onClick={videoFunction}
              />
              <img
                src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/belkin/earbuds/touch_view.webp"
                alt=""
                onClick={toggletouchableGlb}
              />
            </div>

            <img
              style={{ width: "700px" }}
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/overall/Best_Buy/desktop_left_side_cart.webp"
              alt=""
            />
          </div>

          <div className={styles.rightSection}>
            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/overall/Best_Buy/desktop_right_side_cart.webp"
              alt=""
            />
          </div>
        </div>

        <div className={styles.footerSection}>
          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/overall/Best_Buy/desktop_footer1.webp"
            alt=""
          />
        </div>
        <div className={styles.footerSection}>
          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/overall/Best_Buy/desktop_footer.webp"
            alt=""
          />
        </div>
      </div>
      <div className={styles.mobileContainer}>
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/overall/Best_Buy/mobile_header.webp"
          alt=""
        />

        <div className={styles.mainSection}>
          <div className={styles.renderSection}>
            <div className={styles.textSection}></div>
            {imageContainer && <img src={selectedImage} alt="" />}
            {glbContainer && (
              <iframe
                title="desktopGlb"
                seamless
                src="https://www.pod.advflr.com/turnaround/advertflair/camera-drone"
              ></iframe>
            )}

            {touchableGlbContainer && (
              <iframe
                title="desktopGlb"
                seamless
                allow="camera"
                src="https://app.vectary.com/p/45oTh8G9HqBDYriBaVUzm9"
              ></iframe>
            )}

            {videocontainer && (
              <>
                <iframe
                  src="https://player.vimeo.com/video/978080920?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;autoplay=1"
                  width="640"
                  height="360"
                  frameborder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowfullscreen
                  title="Video Player"
                ></iframe>
              </>
            )}
          </div>

          <div className={styles.thumbnailSection}>
            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/camera-drone.webp"
              alt=""
              onClick={() =>
                handleImageClick(
                  "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/render-images/camera-drone.webp"
                )
              }
            />
            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/camera_drone_2.webp"
              alt=""
              onClick={() =>
                handleImageClick(
                  "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/camera_drone_2.webp"
                )
              }
            />
            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/camera_drone_1.webp"
              alt=""
              onClick={() =>
                handleImageClick(
                  "https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/thumbnails/camera_drone_1.webp"
                )
              }
            />
            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/366-1.webp"
              alt=""
              onClick={toggleGlb}
            />
            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/video_thumbnail.webp"
              alt="Video"
              onClick={videoFunction}
            />
            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/belkin/earbuds/touch_view.webp"
              alt=""
              onClick={toggletouchableGlb}
            />
          </div>
        </div>

        <div className={styles.contentSection}>
          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/overall/Best_Buy/desktop_left_side_cart.webp"
            alt=""
          />
        </div>

        <div className={styles.footerSection}>
          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/overall/Best_Buy/mobile_footer.webp"
            alt=""
          />
        </div>
      </div>
    </div>
  );
}

export default Drone;
