import { useEffect } from 'react';
import { bootstrapCameraKit, createMediaStreamSource, Transform2D } from '@snap/camera-kit';


const UseDesktopLens = ({ lensGroupId, lensId, arViewActive }) => {
    useEffect(() => {
        const initLensSession = async () => {
            try {
                // Fetch API token here
                console.log('started');
                if (!arViewActive) return;

                const apiToken = "eyJhbGciOiJIUzI1NiIsImtpZCI6IkNhbnZhc1MyU0hNQUNQcm9kIiwidHlwIjoiSldUIn0.eyJhdWQiOiJjYW52YXMtY2FudmFzYXBpIiwiaXNzIjoiY2FudmFzLXMyc3Rva2VuIiwibmJmIjoxNzEwNTMxNjIxLCJzdWIiOiI0ZmM0NTg4Yi01OWMwLTRmNWUtYjYyMS05ZjZiNDM4MTFkZjZ-U1RBR0lOR345ZGI4NjliZS05MThjLTQ4ZWQtYmY2ZC02YjUzMjczMTk2NzQifQ.tYZpUHNq29VUhg0Jg910He63IsnKPGSyRrfeTTRi3Ow"; // Fetch your API token here

                const cameraKit = await bootstrapCameraKit({ apiToken });
                const canvas = document.getElementById("arBox");
                const session = await cameraKit.createSession({ liveRenderTarget: canvas });
                
                session.events.addEventListener('error', (event) => {
                    if (event.detail.error.name === 'LensExecutionError') {
                        console.log('The current Lens encountered an error and was removed.', event.detail.error);
                    }
                });

                const stream = await navigator.mediaDevices.getUserMedia({ video: true });
                const source = createMediaStreamSource(stream, { transform: Transform2D.MirrorX, cameraType: 'rear' });
                await session.setSource(source);

                const lens = await cameraKit.lensRepository.loadLens( lensGroupId, lensId );
                await session.applyLens(lens);

                await session.play();
                console.log("Lens rendering has started!");
            } catch (error) {
                console.error("Error initializing Lens session:", error);
            }
        };

        initLensSession();

        return () => {
            // Clean up resources if needed
        };
    }, [lensGroupId, lensId, arViewActive]);
};

export default UseDesktopLens;
