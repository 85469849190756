import React from 'react'
import styles from './styles.module.scss'
import FaqIcon from '../../../atoms/icons/FaqIcon'
import Livesupport from '../../../atoms/icons/Livesupport'
import Info from '../../../atoms/icons/Info'
function SupportCard({ title, subtitle, icon }) {
  return (
    <div className={styles.container}>
        <div className={styles.icon}>
            {icon === 'Faq' && <FaqIcon />}
            {icon === 'Support' && <Livesupport />}
            {icon === 'Info' && <Info />}
        </div>

        <div className={styles.contentSection}>
            <div className={styles.titleSection}>
                <div className={styles.title}>
                {title}
                </div>
                <div className={styles.subtitle}>
                {subtitle}
                </div>
            </div>

            <div className={styles.iconSection}>
                <img src="/assets/icons/ArrowRight.svg" alt="" />
            </div>
        </div>
    </div>
  )
}

export default SupportCard