import React from "react";
import styles from "./styles.module.scss";

function AppdrawerIcon() {
  return (
    <div>
      <img
        className={styles.icon_squared}
        src="https://advertflair-public.s3.us-west-2.amazonaws.com/assets/icons/appDrawer.svg"
        alt=""
      />
    </div>
  );
}

export default AppdrawerIcon;
