import React from 'react'
import styles from '../styles.module.scss'
import CheckedIcon from '../../icons/CheckedIcon'

function Month() {
  return (
    <div className={styles.container}>
        <CheckedIcon />
        <select>
            <option value="">Select Month</option>
            <option value="January">January</option>
            <option value="Feburary">Feburary</option>
            <option value="March">March</option>
        </select>
    </div>
  )
}

export default Month