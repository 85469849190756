import React from 'react'
import styles from '../styles.module.scss'
import NotcheckedIcon from '../../icons/NotcheckedIcon'

function Week() {
  return (
    <div className={styles.container}>
        <NotcheckedIcon />
        <select name="" id="">
            <option value="">Select Week</option>
            <option value="Week 04">Week 04</option>
            <option value="Week 05">Week 05</option>
            <option value="Week 06">Week 06</option>
        </select>
    </div>
  )
}

export default Week