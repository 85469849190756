import React from "react";
import "../../../../index.css";
import BestbuyFooter from "./BestbuyFooter";
import BestbuyHeader from "./BestbuyHeader";
import BestbuyContent from "./BestbuyContent";

function BestbuyPage() {
  return (
    <div className="flex flex-col gap-2 w-full h-screen">
      <BestbuyHeader />
      <BestbuyContent />
      <BestbuyFooter />
    </div>
  );
}

export default BestbuyPage;
