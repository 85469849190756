import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import {
  bootstrapCameraKit,
  createMediaStreamSource,
  Transform2D,
} from "@snap/camera-kit";

function FreePeople() {
  const [arViewActive, setArViewActive] = useState(false);

  const toggleARView = () => {
    setArViewActive(!arViewActive);
  };

  useEffect(() => {
    const main = async () => {
      try {
        const apiToken =
          "eyJhbGciOiJIUzI1NiIsImtpZCI6IkNhbnZhc1MyU0hNQUNQcm9kIiwidHlwIjoiSldUIn0.eyJhdWQiOiJjYW52YXMtY2FudmFzYXBpIiwiaXNzIjoiY2FudmFzLXMyc3Rva2VuIiwibmJmIjoxNzEwNTMxNjIxLCJzdWIiOiI0ZmM0NTg4Yi01OWMwLTRmNWUtYjYyMS05ZjZiNDM4MTFkZjZ-U1RBR0lOR345ZGI4NjliZS05MThjLTQ4ZWQtYmY2ZC02YjUzMjczMTk2NzQifQ.tYZpUHNq29VUhg0Jg910He63IsnKPGSyRrfeTTRi3Ow"; // Replace with your actual API token
        const cameraKit = await bootstrapCameraKit({ apiToken });

        const canvas = document.getElementById("arBox");
        const session = await cameraKit.createSession({
          liveRenderTarget: canvas,
        });
        session.events.addEventListener("error", (event) => {
          if (event.detail.error.name === "LensExecutionError") {
            console.log(
              "The current Lens encountered an error and was removed.",
              event.detail.error
            );
          }
        });

        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        const source = createMediaStreamSource(stream, {
          transform: Transform2D.MirrorX,
          cameraType: "front",
        });
        await session.setSource(source);

        const lens = await cameraKit.lensRepository.loadLens(
          "0b364b49-5822-486c-ad54-888e1543b953",
          "1ee15629-39c0-4eb9-849b-8dabd1a6729f"
        );
        await session.applyLens(lens);

        await session.play();
        console.log("Lens rendering has started!");
      } catch (error) {
        // Log the error if it's not the "Uncaught runtime error" you want to skip
        if (error.name !== "UncaughtRuntimeError") {
          console.error("Error accessing camera:", error);
        }
      }
    };

    if (arViewActive) {
      main();
    }

    return () => {
      // Cleanup code if needed
    };
  }, [arViewActive]);

  return (
    <div>
      <div className={styles.container}>
        <img src="/assets/client/freepeople/mobileheader.png" alt="" />
        <p onClick={toggleARView}>Try Before Buy</p>
        <div className={styles.mainSection}>
          {arViewActive ? (
            <div className={styles.arContainer}>
              <canvas id="arBox" />
            </div>
          ) : (
            <div className={styles.thumbnailSection}>
              <img src="/assets/client/freepeople/thumbnail1.png" alt="" />
              <img src="/assets/client/freepeople/thumbnail2.png" alt="" />
              <img src="/assets/client/freepeople/thumbnail3.png" alt="" />
            </div>
          )}
          <div className={styles.cartSection}>
            <img src="/assets/client/freepeople/cartDetails.png" alt="" />
          </div>
        </div>
      </div>

      <div className={styles.desktopContainer}>
        <img src="/assets/client/freepeople/desktopHeader.png" alt="" />
        <p onClick={toggleARView}>Try Before Buy</p>
        <div className={styles.mainSection}>
          {arViewActive ? (
            <div className={styles.arContainer}>
              <canvas id="arBox" />
            </div>
          ) : (
            <div className={styles.thumbnailSection}>
              <img src="/assets/client/freepeople/thumbnail1.png" alt="" />
              <img src="/assets/client/freepeople/thumbnail2.png" alt="" />
              <img src="/assets/client/freepeople/thumbnail3.png" alt="" />
            </div>
          )}
          <div className={styles.cartSection}>
            <img src="/assets/client/freepeople/cartDetails.png" alt="" />
            <div className={styles.buttonSection}>
              <button onClick={toggleARView}>
                {arViewActive ? "Back to Gallery" : "Try on Before buy"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FreePeople;
