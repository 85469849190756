import React from "react";
import styles from "./styles.module.scss";
import { useParams } from "react-router-dom";

function IqaTurnaround() {
  const { poid } = useParams();

  return (
    <div className={styles.container}>
      <model-viewer
        src={`https://advertflair-public.s3.us-west-2.amazonaws.com/feedbacks/glb/${poid}.glb`}
        poster="https://advertflair-public.s3.us-west-2.amazonaws.com/feedbacks/glb/glb_loadingimage.webp"
        shadow-intensity="1"
        camera-controls
        style={{ width: "100%", height: "100vh" }}
        touch-action="pan-y"
      ></model-viewer>
    </div>
  );
}

export default IqaTurnaround;
