import React from 'react'
import styles from './styles.module.scss'
import common from '../../../../styles/styles.module.scss'

function Slack() {
  return (
    <div className={styles.container}>
        <img src="/assets/backgrounds/slack.png" alt="" />
        <div className={styles.titleSection}>
            <div className={styles.title}>Collaborate more with team</div>
            <div className={styles.subtitle}>Bridge the Gap: Connect, Collaborate, Conquer.</div>
        </div>
        <button className={common.primaryButton}>Start Discussion</button>
    </div>
  )
}

export default Slack