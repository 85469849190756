import React from "react";
import styles from "./styles.module.scss";

function FolderIcon({ poid }) {
  return (
    <div className={styles.megaContainer}>
      <div className={styles.container}>
        <div className={styles.optionMenu}>
          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/assets/icons/options.svg"
            alt=""
          />
        </div>

        <div className={styles.icon}>
          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/assets/icons/folder.svg"
            alt=""
          />
        </div>
      </div>
      <p>{poid}</p>
    </div>
  );
}

export default FolderIcon;
