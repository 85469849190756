import React from "react";
import styles from "./styles.module.scss";
import DashboardLayout from "../../templates/dashboard_layout/DashboardLayout";
import Title from "../../molecules/title/Title";
import BackIcon from "../../atoms/icons/BackIcon";
import ListJobsHeader from "../../molecules/header/listjobsheader/ListJobsHeader";
import FileTable from "../../molecules/tables/FileTable";

function ListPage() {
  return (
    <DashboardLayout>
      <div className={styles.header}>
        <BackIcon />
        <Title title="Review Jobs" subtitle="03 Jobs in review" />
      </div>
      <ListJobsHeader />
      <FileTable />
    </DashboardLayout>
  );
}

export default ListPage;
