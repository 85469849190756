import React, { useState } from "react";
import styles from "./cup.module.scss";

function Trolly() {
  const [selectedImage, setSelectedImage] = useState(
    "https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/trolly/render_2.webp"
  );
  const [glbContainer, setGlbContainer] = useState(false);
  const [mobileimageContainer, setMobileimageContainer] = useState(true);
  const [imageContainer, setImageContainer] = useState(true);
  const [videoContainer, setVideoContainer] = useState(false);
  const [secondvideoContainer, setSecondvideoContainer] = useState(false);
  const [touchableGlbContainer, setTouchableGlbContainer] = useState(false);
  const [variant1, setVariant1] = useState(false);
  const [variant2, setVariant2] = useState(false);
  const [variant3, setVariant3] = useState(false);

  const handleImageClick = (imageSrc) => {
    setGlbContainer(false);
    setSelectedImage(imageSrc);
    setImageContainer(true);
    setMobileimageContainer(true);
    setVideoContainer(false);
    setVariant2(false);
    setVariant3(false);
    setSecondvideoContainer(false);
    setTouchableGlbContainer(false);
  };

  const toggleVideo = () => {
    setSecondvideoContainer(false);
    setVideoContainer(true);
    setImageContainer(false);
    setMobileimageContainer(false);
    setGlbContainer(false);
    setVariant2(false);
    setTouchableGlbContainer(false);
    setVariant3(false);
  };

  const toggletouchableGlb = () => {
    setSecondvideoContainer(false);
    setVideoContainer(false);
    setTouchableGlbContainer(true);
    setImageContainer(false);
    setMobileimageContainer(false);
    setGlbContainer(false);
    setVariant2(false);
    setVariant3(false);
  };

  const toggleSecondvideo = () => {
    setSecondvideoContainer(true);
    setVideoContainer(false);
    setImageContainer(false);
    setMobileimageContainer(false);
    setGlbContainer(false);
    setTouchableGlbContainer(false);
    setVariant2(false);
    setVariant3(false);
  };

  const toggleGlb = () => {
    setGlbContainer(true);
    setImageContainer(false);
    setMobileimageContainer(false);
    setVideoContainer(false);
    setTouchableGlbContainer(false);
    setVariant2(false);
    setVariant3(false);
    setSecondvideoContainer(false);
  };

  const toggleVariant1 = () => {
    setGlbContainer(false);
    setImageContainer(false);
    setMobileimageContainer(false);
    setVideoContainer(false);
    setTouchableGlbContainer(false);
    setVariant2(false);
    setVariant3(false);
    setVariant1(true);
    setSecondvideoContainer(false);
  };

  const toggleVariant2 = () => {
    setGlbContainer(false);
    setImageContainer(false);
    setMobileimageContainer(false);
    setVideoContainer(false);
    setTouchableGlbContainer(false);
    setVariant1(false);
    setVariant3(false);
    setVariant2(true);
    setSecondvideoContainer(false);
  };

  const toggleVariant3 = () => {
    setGlbContainer(false);
    setImageContainer(false);
    setMobileimageContainer(false);
    setTouchableGlbContainer(false);
    setVideoContainer(false);
    setVariant1(false);
    setVariant2(false);
    setVariant3(true);
    setSecondvideoContainer(false);
  };

  return (
    <div>
      <div className={styles.mobileContainer}>
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/trolly/mobile_header.webp"
          alt=""
        />
        <div className={styles.renderSection}>
          <div className={styles.render}>
            {mobileimageContainer && <img src={selectedImage} alt="" />}

            {glbContainer && (
              <iframe
                title="desktopGlb"
                seamless
                src="https://www.pod.advflr.com/turnaround/stojo/floral_trolly"
              ></iframe>
            )}

            {variant1 && (
              <iframe
                title="desktopGlb"
                seamless
                src="https://www.pod.advflr.com/turnaround/stojo/floral_trolly"
              ></iframe>
            )}

            {variant2 && (
              <iframe
                title="desktopGlb"
                seamless
                src="https://www.pod.advflr.com/turnaround/stojo/trolly_variant_2"
              ></iframe>
            )}

            {variant3 && (
              <iframe
                title="desktopGlb"
                seamless
                src="https://www.pod.advflr.com/turnaround/stojo/trolly_variant_3"
              ></iframe>
            )}

            {touchableGlbContainer && (
              <iframe
                title="animatedglb"
                src="https://app.vectary.com/p/1fs1urRKKKXPO1s4naAQYh"
                seamless
              ></iframe>
            )}

            {videoContainer && (
              <div>
                <video
                  id="my-video"
                  class="video-js"
                  controls
                  preload="auto"
                  autoPlay
                  poster=""
                  data-setup="{}"
                  muted
                >
                  <source
                    src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/trolly/trollyRender.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            )}

            {secondvideoContainer && (
              <div>
                <video
                  id="my-video"
                  class="video-js"
                  controls
                  preload="auto"
                  autoPlay
                  poster=""
                  data-setup="{}"
                  muted
                >
                  <source
                    src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/trolly/videorender_2.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            )}
          </div>

          <div className={styles.thumbnailSection}>
            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/trolly/render_1.webp"
              alt=""
              onClick={() =>
                handleImageClick(
                  "https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/trolly/render_1.webp"
                )
              }
            />

            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/trolly/render_2.webp"
              alt=""
              onClick={() =>
                handleImageClick(
                  "https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/trolly/render_2.webp"
                )
              }
            />

            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/366-1.webp"
              alt="360Glb"
              onClick={toggleGlb}
            />

            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/video_thumbnail.webp"
              alt=""
              onClick={toggleVideo}
            />
            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/video_thumbnail.webp"
              alt=""
              onClick={toggleSecondvideo}
            />
          </div>
        </div>
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/trolly/mobile_body_1.webp"
          alt=""
        />
        <p>Color</p>
        <div className={styles.colorThumbnail}>
          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/trolly/variant_1.webp"
            alt=""
            onClick={toggleVariant1}
          />
          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/trolly/variant_2.webp"
            alt=""
            onClick={toggleVariant2}
          />
          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/trolly/variant_3.webp"
            alt=""
            onClick={toggleVariant3}
          />
        </div>
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/trolly/mobile_body_2.webp"
          alt=""
        />
      </div>

      <div className={styles.container}>
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/trolly/header.png"
          alt=""
        />

        <div className={styles.mainSection}>
          <div className={styles.renderSection}>
            <div className={styles.render}>
              {imageContainer && <img src={selectedImage} alt="" />}
              {glbContainer && (
                <iframe
                  title="desktopGlb"
                  seamless
                  src="https://www.pod.advflr.com/turnaround/stojo/floral_trolly"
                ></iframe>
              )}

              {variant1 && (
                <iframe
                  title="desktopGlb"
                  seamless
                  src="https://www.pod.advflr.com/turnaround/stojo/floral_trolly"
                ></iframe>
              )}

              {variant2 && (
                <iframe
                  title="desktopGlb"
                  seamless
                  src="https://www.pod.advflr.com/turnaround/stojo/trolly_variant_2"
                ></iframe>
              )}

              {variant3 && (
                <iframe
                  title="desktopGlb"
                  seamless
                  src="https://www.pod.advflr.com/turnaround/stojo/trolly_variant_3"
                ></iframe>
              )}

              {touchableGlbContainer && (
                <iframe
                  title="animatedglb"
                  src="https://app.vectary.com/p/1fs1urRKKKXPO1s4naAQYh"
                  seamless
                ></iframe>
              )}

              {videoContainer && (
                <div>
                  <video
                    id="my-video"
                    class="video-js"
                    controls
                    preload="auto"
                    autoPlay
                    poster=""
                    data-setup="{}"
                    muted
                  >
                    <source
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/trolly/trollyRender.mp4"
                      type="video/mp4"
                    />
                  </video>
                </div>
              )}

              {secondvideoContainer && (
                <div>
                  <video
                    id="my-video"
                    class="video-js"
                    controls
                    preload="auto"
                    autoPlay
                    poster=""
                    data-setup="{}"
                    muted
                  >
                    <source
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/trolly/videorender_2.mp4"
                      type="video/mp4"
                    />
                  </video>
                </div>
              )}
            </div>
            <div className={styles.thumbnailSection}>
              <img
                src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/trolly/render_2.webp"
                alt=""
                onClick={() =>
                  handleImageClick(
                    "https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/trolly/render_2.webp"
                  )
                }
              />
              <img
                src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/trolly/render_1.webp"
                alt=""
                onClick={() =>
                  handleImageClick(
                    "https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/trolly/render_1.webp"
                  )
                }
              />

              <img
                src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/366-1.webp"
                alt="360Glb"
                onClick={toggleGlb}
              />

              <img
                src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/belkin/earbuds/touch_view.webp"
                alt=""
                onClick={toggletouchableGlb}
              />

              <img
                src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/video_thumbnail.webp"
                alt=""
                onClick={toggleVideo}
              />

              <img
                src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/video_thumbnail.webp"
                alt=""
                onClick={toggleSecondvideo}
              />
            </div>
          </div>
          <div className={styles.cartSection}>
            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/trolly/cart_1.webp"
              alt=""
            />
            <p className={styles.color}>Color</p>

            <div className={styles.thumbnailSection}>
              <img
                src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/trolly/variant_1.webp"
                alt=""
                onClick={toggleVariant1}
              />
              <img
                src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/trolly/variant_2.webp"
                alt=""
                onClick={toggleVariant2}
              />
              <img
                src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/trolly/variant_3.webp"
                alt=""
                onClick={toggleVariant3}
              />
            </div>
            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/trolly/cart_2.webp"
              alt=""
            />
          </div>
        </div>
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/trolly/body.webp"
          alt=""
        />
      </div>
    </div>
  );
}

export default Trolly;
