import { useEffect, useState } from 'react';
import AWS from 'aws-sdk';

const FetchS3 = (fileKey) => {
    const [fileUrl, setFileUrl] = useState(null);
    const [objectUrl, setObjectUrl] = useState(null);

    useEffect(() => {
        const fetchDataFromS3 = async () => {
            try {
                // Configure AWS SDK
                AWS.config.update({
                    accessKeyId: 'AKIAUMJ7HTB5PVVZDXX5',
                    secretAccessKey: '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'
                });
        
                // Initialize S3 instance
                const s3 = new AWS.S3({
                    region: 'us-west-2'
                });
        
                // Set S3 parameters
                const params = {
                    Bucket: 'advertflair-studio',
                    Key: fileKey,
                    Expires: 3600
                };
        
                // Get signed URL from S3
                const url = await new Promise((resolve, reject) => {
                    s3.getSignedUrl('getObject', params, (err, url) => {
                        if (err) {
                            reject(err);
                        } else {
                            resolve(url);
                        }
                    });
                });
        
                // Fetch data from the signed URL
                const response = await fetch(url);
                const data = await response.blob();
                const objectUrl = URL.createObjectURL(data);
        
                setFileUrl(objectUrl);
                setObjectUrl(objectUrl); // Set object URL
            } catch (error) {
                console.error('Error fetching URL:', error);
            }
        };

        if (!fileUrl) {
            fetchDataFromS3();
        }

        return () => {
            // Clean up any resources if needed
        };
    }, [fileKey, fileUrl]);

    return { fileUrl, objectUrl };
};

export default FetchS3;
