import React from "react";
import "../../../../index.css";
import AdvertflairHeader from "./AdvertflairHeader";
import AdvertflairFooter from "./AdvertflairFooter";
import AdvertflairContent from "./AdvertflairContent";

function AdvertflairPage() {
  return (
    <div className="flex flex-col gap-2 w-full h-screen">
      <AdvertflairHeader />
      <AdvertflairContent />
      <AdvertflairFooter />
    </div>
  );
}

export default AdvertflairPage;
