import React from 'react'
import styles from './styles.module.scss';
import ArrowRight from '../../../atoms/icons/ArrowRight';

function JobsCount() {
  return (
    <div className={styles.container}>
      <div className={styles.titleSection}>
        <div className={styles.heading}>
          <div className={styles.key}>In progress</div>
          <div className={styles.value}> <strong>12 Jobs</strong> in progress </div>
        </div>

        <div className={styles.actionSection}>
                <div className={styles.actionBtn}>View
                <ArrowRight />
                </div>
        </div>
      </div>

      <div className={styles.contentSection}>
          <div className={styles.card}>
            <div className={`${styles.indicator} ${styles.purple}`}></div>

            <div className={styles.content}>
              <div className={styles.key}>Assigned</div>
              <h1>27</h1>
            </div>
          </div>

          <div className={styles.card}>
            <div className={`${styles.indicator} ${styles.yellow}`}></div>

            <div className={styles.content}>
              <div className={styles.key}>Yet to start</div>
              <h1>12</h1>
            </div>
          </div>

          <div className={styles.card}>
            <div className={`${styles.indicator} ${styles.orange}`}></div>

            <div className={styles.content}>
              <div className={styles.key}>On hold</div>
              <h1>03</h1>
            </div>
          </div>


      </div>
    </div>
  )
}

export default JobsCount