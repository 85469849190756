import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { useParams } from "react-router-dom";

function TurnAroundConfigurator() {
  const { product } = useParams();

  const [modelViewer, setModelViewer] = useState(null);
  const [selectValue, setSelectValue] = useState("default");
  const [variantOptions, setVariantOptions] = useState([]);

  useEffect(() => {
    const modelViewerEl = document.querySelector("model-viewer#shoe");
    setModelViewer(modelViewerEl);
  }, []);

  useEffect(() => {
    if (modelViewer) {
      const names = modelViewer.availableVariants;
      const options = names.map((name) => (
        <option key={name} value={name}>
          {name}
        </option>
      ));
      setVariantOptions([
        <option key="default" value="default">
          Default
        </option>,
        ...options,
        <option key="beach" value="beach">
          Beach
        </option>,
        <option key="midnight" value="midnight">
          Midnight
        </option>,
        <option key="street" value="street">
          Street
        </option>,
      ]);
    }
  }, [modelViewer]);

  const handleSelectChange = (event) => {
    setSelectValue(event.target.value);
    if (event.target.value === "default") {
      modelViewer.variantName = null;
    } else {
      modelViewer.variantName = event.target.value;
    }
  };

  return (
    <div className={styles.container}>
      <model-viewer
        id="shoe"
        src={`https://advertflair-public.s3.us-west-2.amazonaws.com/client/configurator/${product}/${product}.glb`}
        ar
        poster={`https://advertflair-public.s3.us-west-2.amazonaws.com/client/configurator/${product}/${product}.webp`}
        shadow-intensity="1"
        environment-image="https://advertflair-public.s3.us-west-2.amazonaws.com/client/advertflair/overall/jewel-lightmap.hdr"
        camera-controls
        style={{ width: "100%", height: "100vh" }}
        touch-action="pan-y"
        autoplay
      >
        <div className={styles.controls}>
          <div className={styles.thumbnailSection}>
            <img
              alt=""
              src={`https://advertflair-public.s3.us-west-2.amazonaws.com/client/configurator/${product}/variant_1.webp`}
              onClick={() =>
                handleSelectChange({ target: { value: "midnight" } })
              }
            />
            <img
              alt=""
              src={`https://advertflair-public.s3.us-west-2.amazonaws.com/client/configurator/${product}/variant_2.webp`}
              onClick={() => handleSelectChange({ target: { value: "beach" } })}
            />

            <img
              alt=""
              src={`https://advertflair-public.s3.us-west-2.amazonaws.com/client/configurator/${product}/variant_3.webp`}
              onClick={() =>
                handleSelectChange({ target: { value: "street" } })
              }
            />
          </div>
        </div>
      </model-viewer>
    </div>
  );
}

export default TurnAroundConfigurator;
