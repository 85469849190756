import React from "react";
import styles from "../../../../index.css";
import useDisplayStore from "../../../../store/useDisplayStore";

function BestbuySidebar() {
  const setDisplaySection = useDisplayStore((state) => state.setDisplaySection);
  const setRenderContainer = useDisplayStore(
    (state) => state.setRenderContainer
  );

  const handleMenuclick = (data) => {
    setRenderContainer(false);
    setDisplaySection(data);
    if (data === "ads") {
      window.open("http://pod.advflr.com/client/ads", "_blank");
      setDisplaySection("images");
    }
  };

  return (
    <>
      <div className="hidden flex-col gap-2 lg:flex p-4 mt-10">
        <p className="text-black font-bold">Services Offered</p>
        <div className="h-[0.5px] w-full bg-black"></div>
        <p
          className="text-slate-600 font-small cursor-pointer hover:underline hover:font-semibold "
          onClick={() => handleMenuclick("images")}
        >
          3D Rendering
        </p>
        <p
          className="text-slate-600 font-small cursor-pointer hover:underline hover:font-semibold"
          onClick={() => handleMenuclick("videos")}
        >
          3D Product Videos
        </p>
        <p
          className="text-slate-600 font-small cursor-pointer hover:underline hover:font-semibold"
          onClick={() => handleMenuclick("turnaround")}
        >
          360/AR/VR
        </p>

        <p
          className="text-slate-600 font-small cursor-pointer hover:underline hover:font-semibold"
          onClick={() => handleMenuclick("interactive")}
        >
          Interactive
        </p>
        <p
          className="text-slate-600 font-small cursor-pointer hover:underline hover:font-semibold"
          onClick={() => handleMenuclick("configurator")}
        >
          Configurator
        </p>
        <p
          className="text-slate-600 font-small cursor-pointer hover:underline hover:font-semibold"
          onClick={() => handleMenuclick("tryons")}
        >
          Virtual Try-on
        </p>

        <p
          className="text-slate-600 font-small cursor-pointer hover:underline hover:font-semibold"
          onClick={() =>
            window.open("http://pod.advflr.com/client/ads", "_blank")
          }
        >
          Performance Marketing Ads
        </p>
      </div>

      <div className="lg:hidden">
        <select
          className="mt-2 p-2 !border-[1px] !border-gray-400 !border-solid rounded-md"
          onChange={(e) => handleMenuclick(e.target.value)}
        >
          <option value="" disabled selected>
            Services Offered
          </option>
          <option value="images">3D Rendering</option>
          <option value="videos">3D Product Videos</option>
          <option value="turnaround">360/AR/VR</option>
          <option value="interactive">Interactive</option>
          <option value="configurator">Configurator</option>
          <option value="tryons">Virtual Try-on</option>
          <option value="ads">Performance Marketing Ads</option>
        </select>
      </div>
    </>
  );
}

export default BestbuySidebar;
