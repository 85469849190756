import React, { useState, useEffect } from 'react';
import layout from './layout.module.scss';
import FetchS3 from '../../../hooks/FetchS3';
import UseDesktopLens from '../../../hooks/UseDesktopLens';
import UseMobileLens from '../../../hooks/UseMobileLens';


function WatchNew() {
    const [selectedImage, setSelectedImage] = useState('/assets/client/rebag/watch/render_1.png');
    const [arViewActive, setArViewActive] = useState(false);
    const [mobileArViewActive, setMobileArViewActive] = useState(false);
    const [glbView, setGlbView] = useState(false);
    const [glbData, setGlbData] = useState('');
    const [cameraView, setCameraView] = useState(true);
    
    const fileKey = 'client/rebag/output/RolexOyster.glb';
    const { fileUrl, objectUrl } = FetchS3(fileKey);
    const lensGroupId = "7daf88c7-687a-4b7b-81d2-8f379fa55fc2";
    const lensId = "1ee15629-39c0-4eb9-849b-8dabd1a6729f";


  useEffect(() => {

    if (fileUrl && objectUrl) {
        setGlbData(objectUrl);
    }

    

    return () => {
        // Cleanup code if needed
    };
}, [ cameraView, fileUrl, objectUrl]);




const handleImageClick = (imageSrc) => {
    setGlbView(false);
    setSelectedImage(imageSrc);
};    

const toggleARView = () => {
    setArViewActive(!arViewActive);
};

const toggleMobileARView = () => {
    setMobileArViewActive(!mobileArViewActive);
};


const toggleCameraView = () => {
    setCameraView(prevCameraView => !prevCameraView); 
};

const toggleGlbView = () => {
    setGlbView(!glbView);
}

const switchCamera = () => {
    toggleCameraView(); // Toggle camera view between front and rear
};

    
    

  return (
    <div>
    <div className={layout.Desktopcontainer}>
        <img src="/assets/client/rebag/watch/desktopheader.png" alt="" />
        <div className={layout.arLabel} onClick={toggleARView}>
            {arViewActive ? 'Back to Gallery' : 'Try Before buy'}
        </div>
        
        <div className={layout.contentSection}>
            <div className={layout.thumbnailSection}>
                <img src="/assets/client/rebag/watch/render_1.png" onClick={() => handleImageClick("/assets/client/rebag/watch/render_1.png")} alt='Render' />
                <img src="/assets/client/rebag/watch/render_2.png" onClick={() => handleImageClick("/assets/client/rebag/watch/render_2.png")}  alt='Render' />
                <img src="/assets/client/rebag/watch/render_3.png" onClick={() => handleImageClick("/assets/client/rebag/watch/render_3.png")}  alt='Render' />
                <img src="/assets/client/rebag/watch/render_4.png" onClick={() => handleImageClick("/assets/client/rebag/watch/render_4.png")}  alt='Render' />
                <img src="/assets/client/rebag/watch/glbImage.png" onClick={toggleGlbView}  alt='Render'/>
            </div>
            
            <div className={layout.renderSection}>  


            <UseDesktopLens
                lensGroupId={lensGroupId}
                lensId={lensId}
                arViewActive={arViewActive}  // Pass additional value here
            />


         
                {arViewActive ? 
                    <canvas id="arBox" className={layout.arBox}>
                        {arViewActive && <UseDesktopLens lensGroupId={lensGroupId} lensId={lensId} />}
                        <p>Switch Camera</p>
                    </canvas>
                : ''}

            <div>
            
                {!arViewActive ? <div className={layout.imageSection}>
                    {glbView ? '' : <img src={selectedImage}  alt="" /> }
                </div> : ''}
                


                {glbView ? 
                <div className={layout.glbSection}>
                    <model-viewer  alt="Rolex Model" src={glbData} ar  poster="/assets/client/rebag/watch/RolexOyster.webp" shadow-intensity="1" camera-controls touch-action="pan-y" style={{ width: '508px', height: '508px' }}></model-viewer> </div> : ''}
            </div>
            </div>
            


            
            <img className={layout.detailsSection} src="/assets/client/rebag/watch/details_1.png" alt="" />
        </div>
        <img src="/assets/client/rebag/watch/similar.png" alt="" />
        <img src="/assets/client/rebag/watch/likable.png" alt="" />
        <img src="/assets/client/rebag/watch/sell.png" alt="" />
        <img src="/assets/client/rebag/watch/footer1.png" alt="" />
        <img src="/assets/client/rebag/watch/footer2.png" alt="" />
    </div>


    <div className={layout.mobilecontainer}>
        <img src="/assets/client/rebag/watch/mobileHeader.png" alt="" />
        <div className={layout.arLabel} onClick={toggleMobileARView}>
            {mobileArViewActive ? 'Back to Gallery' : 'Try Before buy'}
        </div>
        <div className={layout.contentSection}>
            <div className={layout.thumbnailSection}>
                <img src="/assets/client/rebag/watch/render_1.png" onClick={() => handleImageClick("/assets/client/rebag/watch/render_1.png")} alt='Render' />
                <img src="/assets/client/rebag/watch/render_2.png" onClick={() => handleImageClick("/assets/client/rebag/watch/render_2.png")} alt='Render' />
                <img src="/assets/client/rebag/watch/render_3.png" onClick={() => handleImageClick("/assets/client/rebag/watch/render_3.png")} alt='Render' />
                <img src="/assets/client/rebag/watch/render_4.png" onClick={() => handleImageClick("/assets/client/rebag/watch/render_4.png")} alt='Render' />
                <img src="/assets/client/rebag/watch/glbImage.png" onClick={toggleGlbView}  alt='Render'/>
            </div>
            <div className={layout.renderSection}>   

                <UseMobileLens
                    lensGroupId={lensGroupId}
                    lensId={lensId}
                    mobileArViewActive={mobileArViewActive} 
                />

            {mobileArViewActive ? <canvas id="arBox2" className={layout.arBox}/>  : null}


            <div>

                {!mobileArViewActive ? 
                <div className={layout.imageSection}>
                    {glbView ? '' : <img src={selectedImage}  alt="" /> } 
                </div> : null }


                {!mobileArViewActive && glbView ? <div className={layout.glbSection}> 
                <model-viewer  alt="Rolex Model" src={glbData} ar  poster="/assets/client/rebag/watch/RolexOyster.webp" shadow-intensity="1" camera-controls touch-action="pan-y" style={{ width: '353px', height: '375px' }}></model-viewer>
                 </div> : null }
            
            </div>
             
            
            </div>

            <img className={layout.detailsSection} src="/assets/client/rebag/watch/details_1.png" alt="" />
        </div>
        <img src="/assets/client/rebag/watch/mobile_similar.png" alt="" />
        <img src="/assets/client/rebag/watch/mobile_likable.png" alt="" />
        <img src="/assets/client/rebag/watch/mobile_sell.png" alt="" />
        <img src="/assets/client/rebag/watch/mobile_footer1.png" alt="" />
        <img src="/assets/client/rebag/watch/mobile_footer2.png" alt="" />
    </div>

    </div>
  )
}

export default WatchNew