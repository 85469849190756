import React from "react";
import styles from "./styles.module.scss";

function CreateJobMulti() {
  return (
    <div className={styles.container}>
      <div className={styles.iconSection}>X</div>
      <div className={styles.mainSection}>
        <div className={styles.left}>
          <div className={styles.titleSection}>
            <div className={styles.title}>Create new job</div>
            <div className={styles.subtitle}>Lorem ipsum dolor sit.</div>
          </div>
          <div className={styles.percentageSection}>
            <div className={`${styles.bar} ${styles.success}`}></div>
            <p>05 of 100% completed</p>
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.heading}>Product Type</div>

          <div className={styles.options}>
            <p>Single Product</p>
            <p className={styles.clicked}>Multi Product</p>
          </div>

          <div className={styles.line}></div>

          <div className={styles.heading} style={{ marginTop: "1rem" }}>
            Downloadables
          </div>

          <div className={styles.options}>
            <p>Sample CSV</p>
          </div>

          <div className={styles.rowSection}>
            <div className={styles.inputSection}>
              <div className={styles.uploadSection}>
                <label htmlFor="">File Upload ( CSV file only allowed )</label>
                <input className={styles.upload} type="file" />
              </div>
            </div>

            <div className={styles.inputSection}>
              <div>
                <label htmlFor="">
                  Reference Upload ( Zip file only allowed )
                </label>
                <input className={styles.upload} type="file" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.footerSection}>
        <div className={styles.buttonSection}>
          <button className={styles.secondary}>Save for later</button>
          <button className={styles.primary}>Create Job</button>
        </div>
      </div>
    </div>
  );
}

export default CreateJobMulti;
