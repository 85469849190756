import React from "react";
import styles from "./styles.module.scss";
import Month from "../../../atoms/dropdown/month/Month";
import Week from "../../../atoms/dropdown/week/Week";
import AppdrawerIcon from "../../../atoms/icons/AppdrawerIcon";
import common from "../../../../styles/styles.module.scss";
import { useNavigate } from "react-router-dom";

function WelcomeHeader() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/create-job");
  };
  return (
    <div className={styles.container}>
      <div className={common.column_gap_10}>
        <p>Welcome Imman</p>
        <h1>Job Board Overview</h1>
      </div>

      <div className={styles.notification}>
        <Week />
        <Month />
        <div className={styles.primaryButton} onClick={handleClick}>
          <img src="/assets/icons/add.png" alt="" />
          <button>Create New Job</button>
        </div>
        <AppdrawerIcon />
      </div>
    </div>
  );
}

export default WelcomeHeader;
