import React, { useEffect } from "react";
import { Player, Module, Effect, Dom, Webcam } from "@banuba/webar";
import data from "@banuba/webar/BanubaSDK.data";
import wasm from "@banuba/webar/BanubaSDK.wasm";
import simd from "@banuba/webar/BanubaSDK.simd.wasm";

function Banuba() {
  useEffect(() => {
    const run = async () => {
      try {
        const player = await Player.create({
          clientToken:
            "Qk5CIFF/QOUBi99K4rfpUQ9uN3Q5VcRE7DveCSMC3+fSXJs0BIE6KIM9EJWB7n2mEffRonMKh7IDrVoX5Bz6rWyWKfiAuzKnp0v2BUD/CJF7O5teyGaVjOIfu/JgoImsl4kGvItRdsufscaLFB7ziYMb0FqLtC5nvb4Ibj61R2lciUdEz4gBlPGuch1P/HStQxM+/sFWR7dKwcjEwOghAgGdKIaidpnhUjYMlkGS/2GsTTYGs8iHR/qy6ipgVsQqwlE8TyRyG2W1UkNXjtbRSc8jOoomAfygUBRutnsjwvVD48ZOyRWn/IZ7Wnk4gZOCH6K9PFEcnHJM/UTXjXjYRwRb3/Qk0wpSi9v1Y3cWvy5iI0RC6sFfJPVkTRqrCpORZp59RJvcWIPq/h0imNw1nzg+Oo9WfJMUBhbl27FJ+H3DqcNMw3LuhBXUXCVNuIfAkODHTnwhv0m8Vn4THEqG4kyzdQAiMlDRK3xX1Q2hwgZNd3UF/QaaL1MYOugv2hkRA3gQujwXUtvcVZTpRCOB/p7l/Q85EhaH1ClPYI5jMb/YWLeYbB4PQy+UIBO4Udj9hktExLhnRJeI+qyLMAGDKrP46farU0pm3ugEGj9qNeAeIO7RUvYkp++RFBSc98NSGNn4521jfLnTNb567vrD4Q==",
          locateFile: {
            "BanubaSDK.data": data,
            "BanubaSDK.wasm": wasm,
            "BanubaSDK.simd.wasm": simd,
          },
        });
        await player.addModule(new Module("face_tracker.zip"));
        player.use(new Webcam());
        player.applyEffect(new Effect("Octopus.zip"));
        player.play();
        Dom.render(player, "#webar-app");
      } catch (error) {
        console.error("Failed to initialize Banuba player:", error);
      }
    };

    run();

    return () => {
      // Add any cleanup logic here
    };
  }, []);

  return (
    <div>
      <h1>Title</h1>
      <div id="webar-app"></div>
    </div>
  );
}

export default Banuba;
