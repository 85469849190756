import React from "react";
import styles from "./styles.module.scss";
import ExportButton from "../../../atoms/buttons/ExportButton";
import SortFiles from "../../../atoms/dropdown/sort/SortFiles";
import TableSearch from "../../../atoms/inputs/TableSearch";
import FilterIcon from "../../../atoms/icons/FilterIcon";

function ListJobsHeader() {
  return (
    <div className={styles.largeSection}>
      <div className={styles.section}>
        <TableSearch />
        <FilterIcon />
      </div>
      <div className={styles.section}>
        <SortFiles />
        <ExportButton />
      </div>
    </div>
  );
}

export default ListJobsHeader;
