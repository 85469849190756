import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LoginPage from "./pages/common/LoginPage";
import NotFound from "./pages/common/NotFound";
import Jacob from "./pages/client/eyewear/Jacob";
import FreePeople from "./pages/client/freepeople/FreePeople";
import Shoes from "./pages/client/rebag/Shoes";
import Watch from "./pages/client/rebag/Watch";
import CameraTest from "./pages/client/camera/CameraTest";
import WatchNew from "./pages/client/rebag/WatchNew";
import ApproveGlb from "./pages/approve-glb/ApproveGlb";
import ViewGlb from "./hooks/ViewGlb";
import QcReport from "./pages/Internal-pod/QcReportPage/QcReport";
import QcReview from "./pages/Internal-pod/QcReportPage/QcReview";
import MobileView from "./pages/test/MobileView";
import Home from "./components/organisms/homepage/Home";
import SupportHome from "./components/organisms/supportpage/SupportHome";
import Raisedpage from "./components/organisms/supportpage/raised/Raisedpage";
import ListPage from "./components/organisms/listjobspage/ListPage";
import LibraryPage from "./components/organisms/librarypage/LibraryPage";
import CreateJob from "./components/organisms/createjobpage/CreateJob";
import ExternalFeedback from "./pages/Internal-pod/QcReportPage/ExternalFeedback";
import Glasses from "./pages/client/rebag/Glasses";
import LouisBag from "./pages/client/rebag/LouisBag";
import VirtualTryon from "./pages/vto/VirtualTryon";
import TurnAround from "./pages/turnaround/TurnAround";
import VrEnvironment from "./pages/client/sony/environments/VrEnvironment";
import HandheldVacuum from "./pages/client/sharperimage/HandheldVacuum";
import VirtualTryonFront from "./pages/vto/VirtualTryonFront";
import VirtualTryonFrontRagul from "./pages/vto/VirtualTryonFrontRagul";
import Stool from "./pages/client/anthropologie/Stool";
import Sofa from "./pages/client/anthropologie/Sofa";
import BaseballHat from "./pages/client/urbanoutfitters/BaseballHat";
import Can from "./pages/client/urbanoutfitters/Can";
import ReadingGlass from "./pages/client/urbanoutfitters/ReadingGlass";
import BlackGlass from "./pages/client/urbanoutfitters/BlackGlass";
import IqaTurnaround from "./pages/iqa-turnaround/IqaTurnaround";
import Banuba from "./pages/client/banuba/Banuba";
import JeansPant from "./pages/client/urbanoutfitters/JeansPant";
import ActionButton from "./components/atoms/buttons/actionbutton/ActionButton";
import Cup from "./pages/client/stojo/Cup";
import SkinnyJean from "./pages/client/perfect-jeans/SkinnyJean";
import SkinnyJeanNew from "./pages/client/perfect-jeans/SkinnyJeanNew";
import SkinnyJeanVto from "./pages/client/perfect-jeans/SkinnyJeanVto";
import TurnAroundConfigurator from "./pages/turnaround_configurator/TurnAroundConfigurator";
import Charger from "./pages/client/belkin/charger/Charger";
import CreateJobMulti from "./components/organisms/createjobpage/CreateJobMulti";
import Earbuds from "./pages/client/belkin/charger/Earbuds";
import ApprovedListPage from "./components/organisms/approvedjobspage/ApprovedListPage";
import Trolly from "./pages/client/stojo/Trolly";
import Wellington from "./pages/client/statebags/Wellington";
import PetitMoyen from "./pages/client/clarev/PetitMoyen";
import GrandeBateau from "./pages/client/clarev/GrandeBateau";
import Toranisyrup from "./pages/client/torani/Toranisyrup";
import TurnaroundAnimated from "./pages/turnaround_animated/TurnaroundAnimated";
import Caramelsauce from "./pages/client/torani/Caramelsauce";
import TurnaroundWall from "./pages/turnaround/TurnaroundWall";
import ClairesDaisy from "./pages/client/claires/ClairesDaisy";
import CellularShade from "./pages/client/shadestore/CellularShade";
import RollerShade from "./pages/client/shadestore/RollerShade";
import UrbanOverall from "./pages/client/urbanoutfitters/UrbanOverall";
import Radomen from "./pages/client/urbanoutfitters/products/Radomen";
import Radowomen from "./pages/client/urbanoutfitters/products/Radowomen";
import Jack6SeatCouch from "./pages/client/urbanoutfitters/products/Jack6SeatCouch";
import URBPetitMoyen from "./pages/client/urbanoutfitters/products/URBPetitMoyen";
import URBGrandeBateau from "./pages/client/urbanoutfitters/products/URBGrandeBateau";
import URBLouisVuiton from "./pages/client/urbanoutfitters/products/URBLouisVuiton";
import Ads from "./pages/client/urbanoutfitters/products/Ads";
import URBWellington from "./pages/client/statebags/URBWellington";
import Template from "./pages/client/urbanoutfitters/products/Template";
import KaneKidsMini from "./pages/client/statebags/KaneKidsMini";
import RalphOverall from "./pages/client/ralph-lauren/RalphOverall";
import OverallStool from "./pages/client/anthropologie/OverallStool";
import CanOverall from "./pages/client/urbanoutfitters/CanOverall";
import OverallImages from "./pages/client/urbanoutfitters/OverallImages";
import VideoContainer from "./hooks/VideoContainer";
import OverallProducts from "./pages/client/ross-simons/OverallProducts";
import TurnaroundJewellery from "./pages/turnaround/TurnaroundJewellery";
import TescoProducts from "./pages/client/Tesco/TescoProducts";
import ClarevProducts from "./pages/client/clarev/ClarevProducts";
import BestBuyProducts from "./pages/client/bestbuy/BestBuyProducts";
import Drone from "./pages/client/bestbuy/Drone";
import ImageCarousel from "./pages/common/ImageCarousel";
import Samedelman from "./pages/client/samedelman/Samedelman";
import BestbuyPage from "./pages/client/new-method/bestbuy/BestbuyPage";
import AdvertflairPage from "./pages/client/new-method/advertflair/AdvertflairPage";
import ShoeConfigurator from "./pages/configurator/ShoeConfigurator";
import AdvertflairPortfolio from "./pages/portfolio/advertflair/AdvertflairPortfolio";
import ClientPortfolio from "./pages/portfolio/client/ClientPortfolio";
import TypePortfolio from "./pages/portfolio/type/TypePortfolio";
import Walkthrough from "./pages/3d-walkthough/Walkthrough";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/button" element={<ActionButton />} />
        <Route path="/portfolio" element={<AdvertflairPortfolio />} />
        <Route path="/:client" element={<ClientPortfolio />} />
        <Route path="/samples/:type" element={<TypePortfolio />} />
        <Route path="/client/eyewear" element={<Jacob />} />
        <Route path="/client/freepeople" element={<FreePeople />} />
        <Route path="/client/rebag/shoes/nike-airmax/" element={<Shoes />} />
        <Route
          path="/client/rebag/bags/louis-vuitton/"
          element={<LouisBag />}
        />

        <Route
          path="/client/rebag/glasses/oakley-sutro/"
          element={<Glasses />}
        />

        <Route path="/client/rebag/watch" element={<Watch />} />
        <Route path="/client/urban/watch-men-rado" element={<Radomen />} />
        <Route path="/client/urban/couch-6-seat" element={<Jack6SeatCouch />} />

        <Route path="/client/urban/watch-women-rado" element={<Radowomen />} />

        <Route path="/client/perfect-jeans/skinny" element={<SkinnyJean />} />
        <Route path="/client/perfect-jeans/slim" element={<SkinnyJeanNew />} />
        <Route
          path="/client/perfect-jeans/slim-vto"
          element={<SkinnyJeanVto />}
        />
        <Route path="/client/belkin/charger" element={<Charger />} />
        <Route path="/client/belkin/earbuds" element={<Earbuds />} />

        <Route
          path="/client/anthropologie/dowel-counter-stool"
          element={<Stool />}
        />

        <Route path=":client/dowel-counter-stool" element={<OverallStool />} />
        <Route path=":client/can-overall" element={<CanOverall />} />
        {/* <Route path="/client/outputs" element={<OverallImages />} /> */}
        <Route path="/client/outputs" element={<AdvertflairPage />} />
        <Route path="/Tesco/outputs" element={<TescoProducts />} />
        <Route path="/Clarev/outputs" element={<ClarevProducts />} />
        <Route path="/ross-simon/outputs" element={<OverallProducts />} />
        <Route path="/BestbuyProducts/outputs" element={<BestBuyProducts />} />
        <Route path="/bestbuy/products" element={<BestbuyPage />} />
        <Route path="/advertflair/products" element={<AdvertflairPage />} />

        <Route path="/pdp-page/drone" element={<Drone />} />
        <Route path="/sam-edelman/outputs" element={<Samedelman />} />

        <Route path="/client/videos/" element={<VideoContainer />} />

        <Route path="/client/stojo/cup" element={<Cup />} />
        <Route path="/image-carousel" element={<ImageCarousel />} />

        <Route path="/client/statebags/trolly" element={<Trolly />} />
        <Route path="/client/statebags/wellington" element={<Wellington />} />
        <Route
          path="/client/statebags/kane-kids-mini"
          element={<KaneKidsMini />}
        />

        <Route
          path="/client/shadestore/cellularshade"
          element={<CellularShade />}
        />

        <Route
          path="/client/shadestore/rollershade"
          element={<RollerShade />}
        />

        <Route path="/client/clarev/petit-moyen" element={<PetitMoyen />} />
        <Route path="/client/clarev/grande-bateau" element={<GrandeBateau />} />

        <Route path="/client/anthropologie/modular-sofa" element={<Sofa />} />
        <Route path="/client/ads" element={<Ads />} />

        <Route path="/client/rebag/watch-new" element={<WatchNew />} />
        <Route path="/client/torani/almond-syrup" element={<Toranisyrup />} />
        <Route path="/client/torani/caramel-sauce" element={<Caramelsauce />} />

        <Route path="/client/claires/daisy-earing" element={<ClairesDaisy />} />
        <Route path="/client/urban-overall" element={<UrbanOverall />} />
        <Route path="/client/ralph-overall" element={<RalphOverall />} />

        <Route path="/client/urbanoutfitters/watercan" element={<Can />} />
        <Route
          path="/client/urbanoutfitters/petitmoyen"
          element={<URBPetitMoyen />}
        />

        <Route path="/client/template" element={<Template />} />

        <Route
          path="/client/urbanoutfitters/Wellington"
          element={<URBWellington />}
        />

        <Route
          path="/client/urbanoutfitters/grande-bateau"
          element={<URBGrandeBateau />}
        />

        <Route
          path="/client/urbanoutfitters/louisvuiton-bag"
          element={<URBLouisVuiton />}
        />

        <Route
          path="/client/urbanoutfitters/baseball-hat"
          element={<BaseballHat />}
        />

        <Route path="/client/urbanoutfitters/jeans" element={<JeansPant />} />

        <Route
          path="/client/urbanoutfitters/reading-glass"
          element={<ReadingGlass />}
        />

        <Route
          path="/client/urbanoutfitters/reading-glass"
          element={<BlackGlass />}
        />

        <Route path="/client/camera-test" element={<CameraTest />} />

        <Route path="/:clientId/:modelId" element={<ViewGlb />} />

        <Route path="/sony/environment" element={<VrEnvironment />} />
        <Route path="/walkthrough" element={<Walkthrough />} />

        <Route
          path="/client/sharperimage/handheld-vacuum"
          element={<HandheldVacuum />}
        />

        <Route
          path="/client/vto/:lensId/:lensGroup"
          element={<VirtualTryon />}
        />

        <Route path="/banuba/" element={<Banuba />} />

        <Route
          path="/client/vto/frontcamera/:lensId/:lensGroup"
          element={<VirtualTryonFront />}
        />

        <Route
          path="/client/vto/frontcamera/r/:lensId/:lensGroup"
          element={<VirtualTryonFrontRagul />}
        />

        <Route
          path="/approved/:clientId/:modelId/:lensId/:lensGroup/:vto_available"
          element={<ApproveGlb />}
        />

        <Route
          path="/approved/:clientId/:modelId/:vto_available"
          element={<ApproveGlb />}
        />

        <Route
          path="/turnaround_animated/:client/:product/"
          element={<TurnaroundAnimated />}
        />

        <Route path="/turnaround/:client/:product" element={<TurnAround />} />
        <Route
          path="/turnaround-jewellery/:client/:product"
          element={<TurnaroundJewellery />}
        />

        <Route
          path="/turnaround/:client/:product/wall"
          element={<TurnaroundWall />}
        />

        <Route
          path="/turnaround/configurator/:product/"
          element={<TurnAroundConfigurator />}
        />

        <Route path="/configurator/shoe" element={<ShoeConfigurator />} />

        <Route path="/iqa/turnaround/:poid/" element={<IqaTurnaround />} />
        <Route path="/qc-report" element={<QcReport />}></Route>
        <Route path="/qc-review/:partner_id" element={<QcReview />}></Route>

        <Route path="/mobileview" element={<MobileView />} />

        <Route path="/home" element={<Home />}></Route>
        <Route path="/support" element={<SupportHome />}></Route>
        <Route path="/support/raised" element={<Raisedpage />}></Route>
        <Route path="/listjobs/review" element={<ListPage />}></Route>
        <Route path="/listjobs/approved" element={<ApprovedListPage />}></Route>

        <Route path="/library" element={<LibraryPage />}></Route>

        <Route path="/create-job" element={<CreateJob />}></Route>
        <Route path="/create-job-multi" element={<CreateJobMulti />}></Route>

        <Route path="/eqa-feedbacks" element={<ExternalFeedback />}></Route>

        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
