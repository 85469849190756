import React, { useEffect, useState } from "react";
import "../../../../index.css";
import BestbuySidebar from "./BestbuySidebar";
import {
  configurator,
  interactive,
  renderImages,
  renderVideos,
  tryons,
  turnarounds,
} from "../../../../assets/bestbuy";
import useDisplayStore from "../../../../store/useDisplayStore";

function BestbuyContent() {
  const displaySection = useDisplayStore((state) => state.displaySection);
  const count = useDisplayStore((state) => state.count);
  const setCount = useDisplayStore((state) => state.setCount);

  const renderContainer = useDisplayStore((state) => state.renderContainer);
  const setRenderContainer = useDisplayStore(
    (state) => state.setRenderContainer
  );

  const [embedUrl, setEmbedUrl] = useState("");
  const [qrUrl, setQrUrl] = useState(false);

  const embedFunction = (url) => {
    setEmbedUrl(url);
    setRenderContainer(true);
  };

  const embedturnaround = (embedlink, qrlink) => {
    setEmbedUrl(embedlink);
    setQrUrl(qrlink);
    setRenderContainer(true);
  };

  useEffect(() => {
    if (displaySection === "images") {
      setCount(renderImages.length);
    } else if (displaySection === "videos") {
      setCount(renderVideos.length);
    } else if (displaySection === "turnaround") {
      setCount(turnarounds.length);
    } else if (displaySection === "interactive") {
      setCount(interactive.length);
    } else if (displaySection === "tryons") {
      setCount(tryons.length);
    } else if (displaySection === "configurator") {
      setCount(configurator.length);
    }
  }, [displaySection, setCount]);

  return (
    <div className="flex flex-col gap-4 lg:flex-row bg-white">
      <div className="w-[20%]">
        <BestbuySidebar />
      </div>
      <div className="flex flex-col lg:w-[80%]">
        <div className="flex flex-col items-center ">
          <p className="text-slate-700 text-lg lg:text-2xl font-bold lg:pt-4 pb-4 lg:pb-0">
            Advertflair / {displaySection === "images" && "3D Rendering"}
            {displaySection === "videos" && "Render Videos"}
            {displaySection === "turnaround" && "360/AR/VR"}
            {displaySection === "interactive" && "Interactive"}
            {displaySection === "tryons" && "Virtual Try-On"}
            {displaySection === "configurator" && "Configurator"}
          </p>
        </div>

        {renderContainer && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm">
            <div className="w-4/5   bg-gray-50 fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex items-center justify-center border border-black shadow-lg z-50 flex-col justify-between">
              {displaySection === "images" && (
                <img
                  className="object-contain lg:object-cover h-[80vh] lg:h-[700px]"
                  src={embedUrl}
                  alt=""
                />
              )}

              {(displaySection === "videos" ||
                displaySection === "interactive" ||
                displaySection === "configurator") && (
                <iframe
                  className="h-[80vh] lg:h-[660px] w-[100%] object-cover"
                  src={embedUrl}
                  frameborder="0"
                  allow="autoplay"
                ></iframe>
              )}

              {(displaySection === "turnaround" ||
                displaySection == "tryons") && (
                <>
                  <iframe
                    className="h-[80vh] lg:h-[660px] w-[100%] object-cover"
                    src={embedUrl}
                    frameborder="0"
                    allow="camera"
                  ></iframe>
                  <img
                    src={qrUrl}
                    alt=""
                    className="hidden lg:flex absolute top-1/2 right-8 w-[200px] h-[200px]"
                  />
                </>
              )}

              <button
                className="absolute top-2 right-2 text-white text-2xl font-bold px-[8px] py-[1px] rounded-full bg-red-700 items-center"
                onClick={() => setRenderContainer(false)}
              >
                &times;
              </button>
            </div>
          </div>
        )}

        <div>
          <p className=" hidden lg:flex lg:flex-row lg:items-center text-sm pl-12 lg:pl-0 lg:text-xl text-slate-700 py-2">
            {displaySection === "images" && "3D Rendering"}
            {displaySection === "videos" && "3D Product Videos"}
            {displaySection === "turnaround" && "360/AR/VR"}
            {displaySection === "interactive" && "Interactive"}
            {displaySection === "tryons" && "Virtual Try-On"}
            {displaySection === "configurator" && "Configurator"} {" - "}
            {count} Products
          </p>
        </div>
        <div className="grid grid-cols-2 lg:grid-cols-3 gap-2">
          {displaySection === "images" &&
            renderImages.map((image) => (
              <img
                key={image.id}
                src={image.thumbnail}
                alt={image.name}
                className="h-[168px] w-[224px] lg:h-[544px] lg:w-[364px] object-cover rounded-md cursor-pointer"
                onClick={() => {
                  embedFunction(image.thumbnail);
                }}
              />
            ))}

          {displaySection === "videos" &&
            renderVideos.map((video) => (
              <img
                key={video.id}
                src={video.thumbnail}
                alt={video.name}
                className="object-cover h-[168px] w-[224px] lg:h-[544px] lg:w-[364px] rounded-md cursor-pointer "
                onClick={() => embedFunction(video.outputlink)}
              />
            ))}

          {displaySection === "turnaround" &&
            turnarounds.map((model) => (
              <img
                key={model.id}
                src={model.thumbnail}
                alt={model.name}
                className="object-cover h-[168px] w-[224px] lg:h-[544px] lg:w-[364px] rounded-md cursor-pointer "
                onClick={() => embedturnaround(model.outputlink, model.qrlink)}
              />
            ))}

          {displaySection === "interactive" &&
            interactive.map((model) => (
              <img
                key={model.id}
                src={model.thumbnail}
                alt={model.name}
                className="object-cover h-[168px] w-[224px] lg:h-[544px] lg:w-[364px] rounded-md cursor-pointer"
                onClick={() => embedFunction(model.outputlink)}
              />
            ))}

          {displaySection === "tryons" &&
            tryons.map((model) => (
              <img
                key={model.id}
                src={model.thumbnail}
                alt={model.name}
                className="object-cover h-[168px] w-[224px] lg:h-[544px] lg:w-[364px] rounded-md cursor-pointer"
                onClick={() => embedturnaround(model.outputlink, model.qrlink)}
              />
            ))}

          {displaySection === "configurator" &&
            configurator.map((model) => (
              <img
                key={model.id}
                src={model.thumbnail}
                alt={model.name}
                className="object-cover h-[168px] w-[224px] lg:h-[544px] lg:w-[364px] rounded-md cursor-pointer"
                onClick={() => embedFunction(model.outputlink)}
              />
            ))}
        </div>
      </div>
    </div>
  );
}

export default BestbuyContent;
