import React from "react";
import styles from "./styles.module.scss";
import ExportIcon from "../icons/ExportIcon";

function ExportButton() {
  return (
    <div className={`${styles.buttonwithicon} ${styles.secondaryBtn}`}>
      <ExportIcon />
      Export
    </div>
  );
}

export default ExportButton;
