import React from "react";
import styles from "./styles.module.scss";

function FilterIcon() {
  return (
    <div className={`${styles.icon_squared} ${styles.bordered}`}>
      <img
        src="https://advertflair-public.s3.us-west-2.amazonaws.com/assets/icons/filter.svg"
        alt=""
      />
    </div>
  );
}

export default FilterIcon;
