import React from "react";
import DashboardLayout from "../../templates/dashboard_layout/DashboardLayout";
import ActionCard from "../../molecules/cards/actionCard/ActionCard";
import Graphs from "../../molecules/graphs/Graphs";
import styles from "./styles.module.scss";
import WelcomeHeader from "../../molecules/header/welcome/WelcomeHeader";
import JobsCount from "../../molecules/cards/jobsCount/JobsCount";

function Home() {
  return (
    <DashboardLayout>
      <WelcomeHeader />
      <ActionCard
        title="Action Required"
        subtitle="You have 15 completed jobs for review"
        buttonText="View Job"
        url="/listjobs/review"
      />

      <div className={styles.row}>
        <Graphs
          title="Approved Jobs"
          subtitle="35 of 120 Approved  - Week 45"
          buttonText="View"
          graph="Approval"
        />

        <Graphs
          title="Quality Check"
          subtitle="36 jobs Yet to review"
          buttonText="View"
          graph="ReviewPending"
        />
      </div>

      <div className={styles.row}>
        <JobsCount />

        <Graphs
          title="Revisions"
          subtitle="Total 12 jobs in revision"
          buttonText="View"
          graph="Rejection"
        />
      </div>
    </DashboardLayout>
  );
}

export default Home;
