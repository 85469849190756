import React, { useState } from "react";
import styles from "./sofa.module.scss";
function Sofa() {
  const [selectedImage, setSelectedImage] = useState(
    "https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/modular-sofa/render_1.webp"
  );

  const [glbView, setGlbView] = useState(false);
  const [mobilevideo, setMobilevideo] = useState(false);
  const [desktopvideo, setDesktopvideo] = useState(false);
  const [renderImage, setRenderImage] = useState(true);
  const toggleGlbView = () => {
    setGlbView(!glbView);
    setMobilevideo(false);
    setDesktopvideo(false);
    setRenderImage(false);
  };

  const handleImageClick = (imageSrc) => {
    setRenderImage(true);
    setGlbView(false);
    setMobilevideo(false);
    setDesktopvideo(false);
    setSelectedImage(imageSrc);
  };

  return (
    <div>
      <div className={styles.mobilecontainer}>
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/modular-sofa/mobile_header.webp"
          alt=""
        />

        <div className={styles.renderSection}>
          <div className={styles.render}>
            {renderImage ? <img src={selectedImage} alt="" /> : ""}

            {mobilevideo ? (
              <video
                autoPlay
                src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/dowel-counter-stool/video_preview.mp4"
              ></video>
            ) : (
              ""
            )}

            {glbView ? (
              <div>
                <iframe
                  title="mobileglb"
                  src="https://www.pod.advflr.com/turnaround/anthropologie/sofa"
                  width={320}
                  height={480}
                ></iframe>{" "}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className={styles.thumbnailSection}>
          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/modular-sofa/render_1.webp"
            alt=""
            onClick={() =>
              handleImageClick(
                "https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/modular-sofa/render_1.webp"
              )
            }
          />
          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/modular-sofa/render_2.webp"
            alt=""
            onClick={() =>
              handleImageClick(
                "https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/modular-sofa/render_2.webp"
              )
            }
          />
          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/modular-sofa/glb_thumbnail.webp"
            alt=""
            onClick={toggleGlbView}
          />

          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/modular-sofa/render_3.webp"
            alt=""
            onClick={() =>
              handleImageClick(
                "https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/modular-sofa/render_3.webp"
              )
            }
          />
        </div>

        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/modular-sofa/mobile_details.webp"
          alt=""
        />
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/modular-sofa/mobile_details_2.webp"
          alt=""
        />
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/modular-sofa/mobile_footer.webp"
          alt=""
        />
      </div>
      <div className={styles.desktopcontainer}>
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/modular-sofa/header.webp"
          alt=""
        />

        <div className={styles.maincontainer}>
          <div className={styles.renderSection}>
            <div className={styles.thumbnailSection}>
              <img
                src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/modular-sofa/render_1.webp"
                alt="thumb1"
                onClick={() =>
                  handleImageClick(
                    "https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/modular-sofa/render_1.webp"
                  )
                }
              />
              <img
                src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/modular-sofa/render_2.webp"
                alt="thumb2"
                onClick={() =>
                  handleImageClick(
                    "https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/modular-sofa/render_2.webp"
                  )
                }
              />

              <img
                src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/modular-sofa/glb_thumbnail.webp"
                alt="thumb1"
                onClick={toggleGlbView}
              />
              <img
                src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/modular-sofa/render_3.webp"
                alt="thumb2"
                onClick={() =>
                  handleImageClick(
                    "https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/modular-sofa/render_3.webp"
                  )
                }
              />
            </div>
            <div className={styles.render}>
              {renderImage ? <img src={selectedImage} alt="" /> : ""}

              {desktopvideo ? (
                <video
                  autoPlay
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/dowel-counter-stool/video_preview.mp4"
                ></video>
              ) : (
                ""
              )}

              {glbView ? (
                <div>
                  <iframe
                    title="desktopglb"
                    src="https://www.pod.advflr.com/turnaround/anthropologie/sofa"
                    width={658}
                    height={704}
                  ></iframe>{" "}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className={styles.detailSection}>
            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/modular-sofa/details.webp"
              alt=""
            />
          </div>
        </div>

        <div className={styles.secondContainer}>
          <div>
            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/modular-sofa/complete_look.webp"
              alt=""
            />
          </div>
          <div>
            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/modular-sofa/details_2.webp"
              alt=""
            />
          </div>
        </div>

        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/modular-sofa/trending.webp"
          alt=""
        />
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/modular-sofa/footer.webp"
          alt=""
        />
      </div>
    </div>
  );
}

export default Sofa;
