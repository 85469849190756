import React from "react";

function BackIcon() {
  return (
    <img
      src="https://advertflair-public.s3.us-west-2.amazonaws.com/assets/icons/back.png"
      style={{ width: "74px", height: "60px" }}
      alt=""
    />
  );
}

export default BackIcon;
