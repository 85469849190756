import React, { useEffect, useState } from "react";
import UsePortfolioStore from "./UsePortfolioStore";
import QRCode from "qrcode.react";

function Header() {
  const contactButton = () => {
    window.open("https://www.advertflair.com/#email-form", "_blank");
  };
  return (
    <>
      <div className="flex justify-between">
        <img className="h-12" src="/assets/icons/logoFull.png" alt="" />
        <div
          className="secondary h-10 w-28 flex items-center justify-center rounded-md cursor-pointer"
          onClick={contactButton}
        >
          Contact Us
        </div>
      </div>
      <div className="h-[1px] w-full bg-black/20 mt-4"></div>
    </>
  );
}

function Navbar() {
  const startProject = () => {
    window.open("https://www.advertflair.com/#email-form", "_blank");
  };
  return (
    <>
      <div className="flex justify-between items-center ">
        <div className="flex flex-col gap-0">
          <h1 className="font-[900] text-[28px] md:text-[40px] p-0 m-0">
            Portfolio
          </h1>
          <div className="font-[#777487] opacity-30">
            Designs That Inspire and Delight
          </div>
        </div>
        <div
          onClick={startProject}
          className="primary w-52 h-[48px] md:flex gap-4 items-center justify-center rounded-md hidden"
        >
          <p className="text-white font-bold cursor-pointer">Start Project</p>
          <img className="h-3" src="/assets/icons/rightArrowWhite.png" alt="" />
        </div>
      </div>
    </>
  );
}

function SideBar() {
  const {
    selectedMenu,
    setSelectedMenu,
    setFilteredData,
    setProductsCount,
    setCategoryCount,
    setSubCategorySelected,
    subCategorySelected,
    selectedSubCategory,
    selectedCategory,
    setSelectedCategory,
  } = UsePortfolioStore();
  useEffect(() => {
    filterMenu("turnaround");
  }, []);

  const filterMenu = async (value) => {
    try {
      const response = await fetch(
        "https://advertflair-public.s3.us-west-2.amazonaws.com/assets/json/client-products.json"
      );
      const data = await response.json(); // Corrected line
      // Ensure data is an array
      if (!Array.isArray(data)) {
        throw new Error("Data is not an array");
      }

      const filteredData = data.filter((item) => item.type === value);

      const categoryCounts = filteredData.reduce((counts, item) => {
        if (item.category) {
          counts[item.category] = (counts[item.category] || 0) + 1;
        }
        return counts;
      }, {});

      const sortedCategoryCounts = Object.entries(categoryCounts)
        .sort(([, a], [, b]) => b - a)
        .reduce((acc, [key, value]) => {
          acc[key] = value;
          return acc;
        }, {});

      // Set the filtered data
      console.log(filteredData);
      setFilteredData(filteredData);
      setProductsCount(filteredData.length);
      setCategoryCount(sortedCategoryCounts);
      setSubCategorySelected(false);
      setSelectedCategory("");
    } catch (error) {
      alert(error.message); // Show an alert with the error message
      console.error("Error filtering menu:", error); // Log the error for debugging
    }
  };

  const menuClickFunction = (value) => {
    setSelectedMenu(value);
    let jsonMenu = "";
    if (value === "360 / AR / VR") {
      jsonMenu = "turnaround";
    } else if (value === "") {
      jsonMenu = "turnaround";
    } else if (value === "3D Rendering") {
      jsonMenu = "images";
    } else if (value === "3D Product Videos") {
      jsonMenu = "videos";
    } else if (value === "Virtual Try-on") {
      jsonMenu = "tryons";
    } else if (value === "Configurator") {
      jsonMenu = "configurator";
    } else if (value === "Interactive") {
      jsonMenu = "interactive";
    } else if (value === "Virtual Showroom") {
      jsonMenu = "virtual-showroom";
    }
    filterMenu(jsonMenu);
  };
  return (
    <>
      <div className="flex flex-row md:flex-col gap-0 md:gap-1 md:w-[272px] w-full overflow-auto ">
        <div
          className={`flex justify-between items-center px-2 py-1 md:px-4 md:py-3 min-w-[188px]  rounded-md w-full ${
            selectedMenu === "360 / AR / VR" ? "bg-[#F1F0F8]" : ""
          } cursor-pointer hover:bg-[#F1F0F8]`}
          onClick={() => menuClickFunction("360 / AR / VR")}
        >
          <div className="flex gap-2 items-center">
            <img
              className="w-[32px] h-[32px]"
              src={`/assets/icons/portfolio/${
                selectedMenu === "360 / AR / VR" ? "360_Active" : "360"
              }.png`}
              alt=""
            />
            <h1
              className={`text-[14px] whitespace-nowrap ${
                selectedMenu === "360 / AR / VR"
                  ? "font-bold"
                  : "font-normal text-[#5B4A74]"
              } `}
            >
              360 / AR / VR
            </h1>
          </div>
          <img
            className={`h-[8px] ${
              selectedMenu === "360 / AR / VR" ? "flex" : "hidden"
            }`}
            src="/assets/icons/portfolio/rightArrowActive.png"
            alt=""
          />
        </div>

        <div
          className={`flex justify-between items-center min-w-[188px] px-2 py-1 md:px-4 md:py-3 rounded-md ${
            selectedMenu === "3D Rendering" ? "bg-[#F1F0F8]" : ""
          } cursor-pointer hover:bg-[#F1F0F8]`}
          onClick={() => menuClickFunction("3D Rendering")}
        >
          <div className="flex gap-2 items-center">
            <img
              className="w-[32px] h-[32px]"
              src={`/assets/icons/portfolio/${
                selectedMenu === "3D Rendering"
                  ? "3dRendering_Active"
                  : "3dRendering"
              }.png`}
              alt=""
            />
            <h1
              className={`text-[14px] whitespace-nowrap ${
                selectedMenu === "3D Rendering"
                  ? "font-bold"
                  : "font-normal text-[#5B4A74]"
              } `}
            >
              3D Rendering
            </h1>
          </div>
          <img
            className={`h-[8px] ${
              selectedMenu === "3D Rendering" ? "flex" : "hidden"
            }`}
            src="/assets/icons/portfolio/rightArrowActive.png"
            alt=""
          />
        </div>

        <div
          className={`flex justify-between items-center  px-2 py-1 md:px-4 md:py-3 min-w-[208px] rounded-md ${
            selectedMenu === "3D Product Videos" ? "bg-[#F1F0F8]" : ""
          } cursor-pointer hover:bg-[#F1F0F8]`}
          onClick={() => menuClickFunction("3D Product Videos")}
        >
          <div className="flex gap-2 items-center">
            <img
              className="w-[32px] h-[32px]"
              src={`/assets/icons/portfolio/${
                selectedMenu === "3D Product Videos" ? "video_Active" : "video"
              }.png`}
              alt=""
            />
            <h1
              className={`text-[14px] whitespace-nowrap ${
                selectedMenu === "3D Product Videos"
                  ? "font-bold"
                  : "font-normal text-[#5B4A74]"
              } `}
            >
              3D Product Videos
            </h1>
          </div>
          <img
            className={`h-[8px] ${
              selectedMenu === "3D Product Videos" ? "flex" : "hidden"
            }`}
            src="/assets/icons/portfolio/rightArrowActive.png"
            alt=""
          />
        </div>

        <div
          className={`flex justify-between items-center  px-2 py-1 md:px-4 md:py-3 min-w-[188px] rounded-md ${
            selectedMenu === "Virtual Try-on" ? "bg-[#F1F0F8]" : ""
          } cursor-pointer hover:bg-[#F1F0F8]`}
          onClick={() => menuClickFunction("Virtual Try-on")}
        >
          <div className="flex gap-2 items-center">
            <img
              className="w-[32px] h-[32px]"
              src={`/assets/icons/portfolio/${
                selectedMenu === "Virtual Try-on" ? "tryon_Active" : "tryon"
              }.png`}
              alt=""
            />
            <h1
              className={`text-[14px] whitespace-nowrap ${
                selectedMenu === "Virtual Try-on"
                  ? "font-bold"
                  : "font-normal text-[#5B4A74]"
              } `}
            >
              Virtual Try-on
            </h1>
          </div>
          <img
            className={`h-[8px] ${
              selectedMenu === "Virtual Try-on" ? "flex" : "hidden"
            }`}
            src="/assets/icons/portfolio/rightArrowActive.png"
            alt=""
          />
        </div>

        <div
          className={`flex justify-between items-center  px-2 py-1 md:px-4 md:py-3 min-w-[188px] rounded-md ${
            selectedMenu === "Configurator" ? "bg-[#F1F0F8]" : ""
          } cursor-pointer hover:bg-[#F1F0F8]`}
          onClick={() => menuClickFunction("Configurator")}
        >
          <div className="flex gap-2 items-center">
            <img
              className="w-[32px] h-[32px]"
              src={`/assets/icons/portfolio/${
                selectedMenu === "Configurator"
                  ? "configurator_Active"
                  : "configurator"
              }.png`}
              alt=""
            />
            <h1
              className={`text-[14px] whitespace-nowrap ${
                selectedMenu === "Configurator"
                  ? "font-bold"
                  : "font-normal text-[#5B4A74]"
              } `}
            >
              Configurator
            </h1>
          </div>
          <img
            className={`h-[8px] ${
              selectedMenu === "Configurator" ? "flex" : "hidden"
            }`}
            src="/assets/icons/portfolio/rightArrowActive.png"
            alt=""
          />
        </div>

        <div
          className={`flex justify-between items-center  px-2 py-1 md:px-4 md:py-3 min-w-[188px] rounded-md ${
            selectedMenu === "Interactive" ? "bg-[#F1F0F8]" : ""
          } cursor-pointer hover:bg-[#F1F0F8]`}
          onClick={() => menuClickFunction("Interactive")}
        >
          <div className="flex gap-2 items-center">
            <img
              className="w-[32px] h-[32px]"
              src={`/assets/icons/portfolio/${
                selectedMenu === "Interactive"
                  ? "Interactive_Active"
                  : "Interactive"
              }.png`}
              alt=""
            />
            <h1
              className={`text-[14px] whitespace-nowrap ${
                selectedMenu === "Interactive"
                  ? "font-bold"
                  : "font-normal text-[#5B4A74]"
              } `}
            >
              Interactive
            </h1>
          </div>
          <img
            className={`h-[8px] ${
              selectedMenu === "Interactive" ? "flex" : "hidden"
            }`}
            src="/assets/icons/portfolio/rightArrowActive.png"
            alt=""
          />
        </div>

        <div
          className={`flex justify-between items-center  px-2 py-1 md:px-4 md:py-3 min-w-[188px] rounded-md ${
            selectedMenu === "Virtual Showroom" ? "bg-[#F1F0F8]" : ""
          } cursor-pointer hover:bg-[#F1F0F8]`}
          onClick={() => menuClickFunction("Virtual Showroom")}
        >
          <div className="flex gap-2 items-center">
            <img
              className="w-[32px] h-[32px]"
              src={`/assets/icons/portfolio/${
                selectedMenu === "Virtual Showroom"
                  ? "showroom_Active"
                  : "showroom"
              }.png`}
              alt=""
            />
            <h1
              className={`text-[14px] whitespace-nowrap ${
                selectedMenu === "Virtual Showroom"
                  ? "font-bold"
                  : "font-normal text-[#5B4A74]"
              } `}
            >
              Virtual Showroom
            </h1>
          </div>
          <img
            className={`h-[8px] ${
              selectedMenu === "Virtual Showroom" ? "flex" : "hidden"
            }`}
            src="/assets/icons/portfolio/rightArrowActive.png"
            alt=""
          />
        </div>

        <div
          className={`flex justify-between items-center  px-2 py-1 md:px-4 md:py-3 min-w-[188px] rounded-md ${
            selectedMenu === "Performance Ads" ? "bg-[#F1F0F8]" : ""
          } cursor-pointer hover:bg-[#F1F0F8]`}
          onClick={() =>
            window.open("https://www.pod.advflr.com/client/ads", "_blank")
          }
        >
          <div className="flex gap-2 items-center">
            <img
              className="w-[20px] h-[20px] pl-1"
              src={`/assets/icons/portfolio/${
                selectedMenu === "Performance Ads"
                  ? "online-advertising"
                  : "online-advertising"
              }.png`}
              alt=""
            />
            <h1
              className={`text-[14px] whitespace-nowrap ${
                selectedMenu === "Performance Ads"
                  ? "font-bold"
                  : "font-normal text-[#5B4A74]"
              } `}
            >
              Performance Ads
            </h1>
          </div>
          <img
            className={`h-[8px] ${
              selectedMenu === "Performance Ads" ? "flex" : "hidden"
            }`}
            src="/assets/icons/portfolio/rightArrowActive.png"
            alt=""
          />
        </div>
      </div>
    </>
  );
}

function SubHeader() {
  const { selectedMenu, productsCount } = UsePortfolioStore();
  return (
    <>
      <div className="flex justify-between w-full items-end">
        <div className="flex flex-col gap-1">
          <h1 className="font-bold text-[18px] md:text-[24px]">
            {selectedMenu}
          </h1>
          <p className="text-[12px] md:text-[14px] text-[#777487] font-normal">
            Total {productsCount} Products
          </p>
        </div>
        <div className="md:flex gap-3 items-center border border-1 border-[#D4D2E4] rounded-md h-[48px] w-[288px] px-3 hidden">
          <img
            className="h-[18px]"
            src="/assets/icons/portfolio/search.png"
            alt=""
          />
          <input type="text" placeholder="Search" className="outline-none" />
        </div>
      </div>
    </>
  );
}

function Category() {
  const {
    selectedCategory,
    setSelectedCategory,
    categoryCount,
    selectedMenu,
    filteredData,
    setFilteredData,
  } = UsePortfolioStore();
  const [leftNavigationClicked, setLeftNavigationClicked] = useState(false);
  const [rightNavigationClicked, setRightNavigationClicked] = useState(false);

  const selectSubCategoryFunction = (value) => {
    filterCategory(value);
  };

  const filterCategory = async (value) => {
    try {
      const response = await fetch(
        "https://advertflair-public.s3.us-west-2.amazonaws.com/assets/json/client-products.json"
      );
      const data = await response.json(); // Corrected line
      // Ensure data is an array
      if (!Array.isArray(data)) {
        throw new Error("Data is not an array");
      }

      let menu = "";
      if (selectedMenu === "360 / AR / VR") {
        menu = "turnaround";
      } else if (selectedMenu === "3D Rendering") {
        menu = "images";
      } else if (selectedMenu === "3D Product Videos") {
        menu = "videos";
      } else if (selectedMenu === "Virtual Try-on") {
        menu = "tryons";
      } else if (selectedMenu === "Configurator") {
        menu = "configurator";
      } else if (selectedMenu === "Interactive") {
        menu = "interactive";
      } else if (selectedMenu === "Virtual Showroom") {
        menu = "virtual-showroom";
      }

      const filteredDatas = data
        .filter((item) => item.category === value)
        .filter((item) => item.type === menu);

      setFilteredData(filteredDatas);
      setSelectedCategory(value);
    } catch (error) {
      alert(error.message); // Show an alert with the error message
      console.error("Error filtering menu:", error); // Log the error for debugging
    }
  };

  return (
    <>
      <div className="h-[1px] bg-[#EDE8F0] mt-4"></div>
      <div className="w-full md:w-[820px] flex items-center overflow-hidden">
        <img
          className="h-5 cursor-pointer"
          src={`/assets/icons/portfolio/${
            leftNavigationClicked ? "leftNavigation_Active" : "leftNavigation"
          }.png`}
          alt=""
          onClick={() => setLeftNavigationClicked(true)}
        />

        <div className=" my-0 mx-auto overflow-scroll flex gap-2">
          {Object.entries(categoryCount).map(([category, count]) => (
            <div
              key={category}
              className={`h-[40px] px-4 whitespace-nowrap rounded-md flex items-center justify-center cursor-pointer hover:bg-[#2A2354] hover:text-white ${
                selectedCategory === category
                  ? "bg-[#2A2354] font-bold text-white"
                  : "bg-[#F1F0F8] font-normal text-[#2A2354]"
              }`}
              onClick={() => selectSubCategoryFunction(category)}
            >
              {`${
                category.charAt(0).toUpperCase() + category.slice(1)
              } - ${count}`}
            </div>
          ))}
        </div>

        <img
          className="ml-4 h-5 cursor-pointer"
          src={`/assets/icons/portfolio/${
            rightNavigationClicked
              ? "rightNavigation_Active"
              : "rightNavigation"
          }.png`}
          alt=""
          onClick={() => setRightNavigationClicked(true)}
        />
      </div>
      <div className="h-[1px] bg-[#EDE8F0] mt-1"></div>
    </>
  );
}

function ImageGallery() {
  const { filteredData, setModalWindowOpen, setEmbedProduct, modalWindowOpen } =
    UsePortfolioStore();

  const clickImageFunction = (id) => {
    fetchData(id);
  };

  const fetchData = async (id) => {
    try {
      const response = await fetch(
        "https://advertflair-public.s3.us-west-2.amazonaws.com/assets/json/client-products.json"
      );
      const data = await response.json(); // Corrected line
      if (!Array.isArray(data)) {
        throw new Error("Data is not an array");
      }
      const filteredData = data.filter((item) => item.id === id);
      setEmbedProduct(filteredData[0]);
      console.log(filteredData);
      setModalWindowOpen(true);
    } catch (error) {
      alert(error.message);
    }
  };
  return (
    <>
      {!modalWindowOpen && (
        <div className="grid grid-cols-2 md:grid-cols-3 gap-2 md:gap-8 w-full md:w-[812px] my-0 mx-auto mt-4 ">
          {filteredData.map((item) => (
            <img
              className="w-[252px] md:h-[326px] h-[302px] imageBackground rounded-md border border-1 border-transparent hover:border-black/10 object-cover cursor-pointer hover:scale-105 transition-all 2s hover:shadow-lg"
              src={item.thumbnail}
              alt={item.name}
              onClick={() => clickImageFunction(item.id)}
            />
          ))}
        </div>
      )}
    </>
  );
}

function ModalWindow() {
  const { modalWindowOpen, setModalWindowOpen, embedProduct, selectedMenu } =
    UsePortfolioStore();

  const startProject = () => {
    window.open("https://www.advertflair.com/#email-form", "_blank");
  };

  return (
    <>
      {modalWindowOpen && (
        <div className="w-full md:w-[1440px] overflow-y-hidden h-screen bg-white my-0 mx-auto  absolute top-0 flex flex-col gap-2 md:p-8 p-2">
          <div className="flex justify-end">
            <img
              className="h-6 cursor-pointer"
              src="/assets/icons/portfolio/closeIcon.png"
              alt=""
              onClick={() => setModalWindowOpen(false)}
            />
          </div>

          <div className="w-full md:w-[972px] my-0 mx-auto flex flex-col gap-5">
            <div className=" flex justify-between items-end">
              <div className="flex flex-col gap-1">
                <h1 className="text-[24px] md:text-[32px] font-[900] w-[200px] md:w-full truncate whitespace-nowrap">
                  {embedProduct.name}{" "}
                </h1>
                <p className="text-[#777487]"></p>
              </div>
              <div
                onClick={startProject}
                className="primary px-4 py-2 md:w-52 md:h-[48px] flex gap-4 items-center justify-center rounded-md"
              >
                <p className="text-white font-bold cursor-pointer">
                  Start Project
                </p>
                <img
                  className="h-3"
                  src="/assets/icons/rightArrowWhite.png"
                  alt=""
                />
              </div>
            </div>
            <div className="relative">
              {/* <iframe className='w-full h-[472px] border border-1 border-black/10 rounded-md outline-none' src={embedProduct.outputlink} frameborder="0"></iframe> */}

              {selectedMenu === "360 / AR / VR" ? (
                <>
                  <iframe
                    title="glb"
                    className="w-full h-[472px] border border-1 object-cover border-black/10 rounded-md outline-none"
                    src={embedProduct.outputlink}
                    frameborder="0"
                  ></iframe>
                  <QRCode
                    className="absolute bottom-[-20px] right-6 shadow-md  p-4 bg-white rounded-md hidden md:flex"
                    value={embedProduct.outputlink}
                    size={128}
                  />{" "}
                </>
              ) : (
                ""
              )}

              {selectedMenu === "Configurator" ? (
                <>
                  <iframe
                    title="configurator"
                    className="w-full h-[472px] border border-1 object-cover border-black/10 rounded-md outline-none"
                    src={embedProduct.outputlink}
                    frameborder="0"
                  ></iframe>
                  <QRCode
                    className="absolute bottom-[-20px] right-6 shadow-md  p-4 bg-white rounded-md hidden md:flex"
                    value={embedProduct.outputlink}
                    size={128}
                  />{" "}
                </>
              ) : (
                ""
              )}

              {selectedMenu === "Interactive" ? (
                <>
                  <iframe
                    title="interactive"
                    className="w-full h-[472px] border border-1 object-cover border-black/10 rounded-md outline-none"
                    src={embedProduct.outputlink}
                    frameborder="0"
                  ></iframe>
                  <QRCode
                    className="absolute bottom-[-20px] right-6 shadow-md  p-4 bg-white rounded-md hidden md:flex"
                    value={embedProduct.outputlink}
                    size={128}
                  />{" "}
                </>
              ) : (
                ""
              )}

              {selectedMenu === "Virtual Showroom" ? (
                <>
                  <iframe
                    title="showroom"
                    className="w-full h-[472px] border border-1 object-cover border-black/10 rounded-md outline-none"
                    src={embedProduct.outputlink}
                    frameborder="0"
                  ></iframe>
                  <QRCode
                    className="absolute bottom-[-20px] right-6 shadow-md  p-4 bg-white rounded-md hidden md:flex"
                    value={embedProduct.outputlink}
                    size={128}
                  />{" "}
                </>
              ) : (
                ""
              )}

              {selectedMenu === "3D Product Videos" ? (
                <iframe
                  title="videos"
                  className="w-full h-[472px] object-cover  rounded-md outline-none"
                  src={embedProduct.outputlink}
                  frameborder="0"
                ></iframe>
              ) : (
                ""
              )}

              {selectedMenu === "Virtual Try-on" ? (
                <>
                  <iframe
                    title="tryon"
                    allow="camera"
                    className="w-full h-[472px] border border-1 border-black/10 rounded-md outline-none"
                    src={embedProduct.outputlink}
                    frameborder="0"
                  ></iframe>
                  <QRCode
                    className="absolute bottom-[-20px] right-6 shadow-md  p-4 bg-white rounded-md hidden md:flex"
                    value={embedProduct.outputlink}
                    size={128}
                  />{" "}
                </>
              ) : (
                ""
              )}

              {selectedMenu === "3D Rendering" ? (
                <div className="flex flex-col gap-2">
                  <img
                    className="w-full h-[472px] object-cover rounded-md border-none"
                    src={embedProduct.outputlink}
                    alt=""
                  />
                  <div className="flex gap-2 overflow-auto">
                    {embedProduct.otherImages &&
                      Object.entries(embedProduct.otherImages).map(
                        ([key, src], index) => (
                          <img
                            key={index}
                            className="w-[172px] h-[172px] object-cover rounded-md border border-1 border-transparent hover:border-[#2A2354] cursor-pointer"
                            src={src}
                            alt={`Image ${index}`}
                          />
                        )
                      )}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

function AdvertflairPortfolio() {
  const { setRawData } = UsePortfolioStore();
  useEffect(() => {
    fetchRawdata();
  }, [setRawData]);

  const fetchRawdata = async () => {
    try {
      const response = await fetch(
        "https://advertflair-public.s3.us-west-2.amazonaws.com/assets/json/client-products.json"
      );
      const data = await response.json();
      setRawData(data);
    } catch (err) {
      alert(err.message);
    } finally {
    }
  };

  return (
    <div className="w-full md:w-[1440px] px-4 py-2 md:px-10 md:py-4 my-0 mx-auto">
      <Header />

      <div className="w-full md:w-[1112px] my-0 mx-auto md:mt-8 mt-2">
        <Navbar />
        <div className="flex flex-col md:flex-row md:gap-8 gap-2 md:mt-8 mt-2  w-full">
          <SideBar />
          <div className="flex flex-col gap-2 w-full">
            <SubHeader />
            <Category />
            <ImageGallery />
          </div>
        </div>
      </div>

      <ModalWindow />
    </div>
  );
}

export default AdvertflairPortfolio;
