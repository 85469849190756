import React, { useState, useEffect } from "react";
import layout from "./layout.module.scss";
import {
  bootstrapCameraKit,
  createMediaStreamSource,
  Transform2D,
} from "@snap/camera-kit";
import AWS from "aws-sdk";

function Watch() {
  const [selectedImage, setSelectedImage] = useState(
    "/assets/client/rebag/watch/render_1.png"
  );
  const [arViewActive, setArViewActive] = useState(false);
  const [mobileArViewActive, setMobileArViewActive] = useState(false);
  const [fileUrl, setFileUrl] = useState("");
  const [glbView, setGlbView] = useState(false);
  const [glbData, setGlbData] = useState("");
  const [cameraView, setCameraView] = useState(true);

  useEffect(() => {
    AWS.config.update({
      accessKeyId: "AKIAUMJ7HTB5PVVZDXX5",
      secretAccessKey: "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",
    });

    const s3 = new AWS.S3({
      region: "us-west-2",
    });

    const params = {
      Bucket: "advertflair-studio",
      Key: "client/rebag/output/RolexOyster.glb",
      Expires: 3600,
    };

    const fetchUrl = async () => {
      try {
        const url = await new Promise((resolve, reject) => {
          s3.getSignedUrl("getObject", params, (err, url) => {
            if (err) {
              reject(err);
            } else {
              resolve(url);
            }
          });
        });
        const response = await fetch(url);
        const data = await response.blob();
        const objectUrl = URL.createObjectURL(data);
        setGlbData(objectUrl);
        setFileUrl(objectUrl);
        console.log("Glb Data set: ", objectUrl);
      } catch (error) {
        console.error("Error fetching URL:", error);
      }
    };

    if (!fileUrl) {
      fetchUrl();
    }

    const main = async () => {
      try {
        const apiToken =
          "eyJhbGciOiJIUzI1NiIsImtpZCI6IkNhbnZhc1MyU0hNQUNQcm9kIiwidHlwIjoiSldUIn0.eyJhdWQiOiJjYW52YXMtY2FudmFzYXBpIiwiaXNzIjoiY2FudmFzLXMyc3Rva2VuIiwibmJmIjoxNzEwNTMxNjIxLCJzdWIiOiI0ZmM0NTg4Yi01OWMwLTRmNWUtYjYyMS05ZjZiNDM4MTFkZjZ-U1RBR0lOR345ZGI4NjliZS05MThjLTQ4ZWQtYmY2ZC02YjUzMjczMTk2NzQifQ.tYZpUHNq29VUhg0Jg910He63IsnKPGSyRrfeTTRi3Ow"; // Replace with your actual API token
        const cameraKit = await bootstrapCameraKit({ apiToken });

        const canvas = document.getElementById("arBox");
        const session = await cameraKit.createSession({
          liveRenderTarget: canvas,
        });
        session.events.addEventListener("error", (event) => {
          if (event.detail.error.name === "LensExecutionError") {
            console.log(
              "The current Lens encountered an error and was removed.",
              event.detail.error
            );
          }
        });

        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        const cameraType = cameraView ? "rear" : "front";
        const source = createMediaStreamSource(stream, {
          transform: Transform2D.MirrorX,
          cameraType: cameraType,
        });
        await session.setSource(source);

        const lens = await cameraKit.lensRepository.loadLens(
          "7daf88c7-687a-4b7b-81d2-8f379fa55fc2",
          "1ee15629-39c0-4eb9-849b-8dabd1a6729f"
        );
        await session.applyLens(lens);

        await session.play();
        console.log("Lens rendering has started!");
      } catch (error) {
        // Log the error if it's not the "Uncaught runtime error" you want to skip
        if (error.name !== "UncaughtRuntimeError") {
          console.error("Error accessing camera:", error);
        }
      }
    };

    if (arViewActive) {
      main();
    }

    return () => {
      // Cleanup code if needed
    };
  }, [arViewActive, mobileArViewActive, cameraView, fileUrl]);

  const handleImageClick = (imageSrc) => {
    setGlbView(false);
    setSelectedImage(imageSrc);
  };

  const toggleARView = () => {
    setArViewActive(!arViewActive);
  };

  const toggleMobileARView = () => {
    setMobileArViewActive(!mobileArViewActive);
  };

  const toggleCameraView = () => {
    setCameraView((prevCameraView) => !prevCameraView);
  };

  const toggleGlbView = () => {
    setGlbView(!glbView);
  };

  const switchCamera = () => {
    toggleCameraView(); // Toggle camera view between front and rear
  };

  return (
    <div>
      <div className={layout.Desktopcontainer}>
        <img src="/assets/client/rebag/watch/desktopheader.png" alt="" />
        <div className={layout.arLabelUpdated} onClick={toggleARView}>
          {toggleARView ? "Try Before Buy" : "Back to Gallery"}
          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/rebags/glasses/+oakley-sutro-sunglasses+/svg-edited.svg"
            alt=""
          />
        </div>

        <div className={layout.contentSection}>
          <div className={layout.thumbnailSection}>
            <img
              src="/assets/client/rebag/watch/render_1.png"
              onClick={() =>
                handleImageClick("/assets/client/rebag/watch/render_1.png")
              }
              alt="Render"
            />
            <img
              src="/assets/client/rebag/watch/render_2.png"
              onClick={() =>
                handleImageClick("/assets/client/rebag/watch/render_2.png")
              }
              alt="Render"
            />
            <img
              src="/assets/client/rebag/watch/glbImage.png"
              onClick={toggleGlbView}
              alt="Render"
            />
            <img
              src="/assets/client/rebag/watch/render_3.png"
              onClick={() =>
                handleImageClick("/assets/client/rebag/watch/render_3.png")
              }
              alt="Render"
            />
            <img
              src="/assets/client/rebag/watch/render_4.png"
              onClick={() =>
                handleImageClick("/assets/client/rebag/watch/render_4.png")
              }
              alt="Render"
            />
          </div>

          <div className={layout.renderSection}>
            {arViewActive ? <canvas id="arBox" className={layout.arBox} /> : ""}

            <div>
              {!arViewActive ? (
                <div className={layout.imageSection}>
                  {glbView ? "" : <img src={selectedImage} alt="" />}
                </div>
              ) : (
                ""
              )}

              {glbView ? (
                <div className={layout.glbSection}>
                  <model-viewer
                    alt="Rolex Model"
                    src={glbData}
                    ar
                    poster="/assets/client/rebag/watch/RolexOyster.webp"
                    shadow-intensity="1"
                    camera-controls
                    touch-action="pan-y"
                    style={{ width: "508px", height: "508px" }}
                  ></model-viewer>{" "}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          <img
            className={layout.detailsSection}
            src="/assets/client/rebag/watch/details_1.png"
            alt=""
          />
        </div>
        <img src="/assets/client/rebag/watch/similar.png" alt="" />
        <img src="/assets/client/rebag/watch/likable.png" alt="" />
        <img src="/assets/client/rebag/watch/sell.png" alt="" />
        <img src="/assets/client/rebag/watch/footer1.png" alt="" />
        <img src="/assets/client/rebag/watch/footer2.png" alt="" />
      </div>

      <div className={layout.mobilecontainer}>
        <img src="/assets/client/rebag/watch/mobileHeader.png" alt="" />
        <div className={layout.arLabelUpdated} onClick={toggleMobileARView}>
          {toggleMobileARView ? "Try Before Buy" : "Back to Gallery"}
          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/rebags/glasses/+oakley-sutro-sunglasses+/svg-edited.svg"
            alt=""
          />
        </div>
        <div className={layout.contentSection}>
          <div className={layout.thumbnailSection}>
            <img
              src="/assets/client/rebag/watch/render_1.png"
              onClick={() =>
                handleImageClick("/assets/client/rebag/watch/render_1.png")
              }
              alt="Render"
            />

            <img
              src="/assets/client/rebag/watch/render_2.png"
              onClick={() =>
                handleImageClick("/assets/client/rebag/watch/render_2.png")
              }
              alt="Render"
            />
            <img
              src="/assets/client/rebag/watch/glbImage.png"
              onClick={toggleGlbView}
              alt="Render"
            />
            <img
              src="/assets/client/rebag/watch/render_3.png"
              onClick={() =>
                handleImageClick("/assets/client/rebag/watch/render_3.png")
              }
              alt="Render"
            />
            <img
              src="/assets/client/rebag/watch/render_4.png"
              onClick={() =>
                handleImageClick("/assets/client/rebag/watch/render_4.png")
              }
              alt="Render"
            />
          </div>
          <div className={layout.renderSection}>
            {mobileArViewActive ? (
              <>
                <iframe
                  title="mobileAR"
                  src="https://www.pod.advflr.com/client/vto/f4c4f3e7-1730-4911-838f-984e44633381/1ee15629-39c0-4eb9-849b-8dabd1a6729f"
                  width={360}
                  height={340}
                ></iframe>
              </>
            ) : null}

            <div>
              {!mobileArViewActive ? (
                <div className={layout.imageSection}>
                  {glbView ? "" : <img src={selectedImage} alt="" />}
                </div>
              ) : null}

              {!mobileArViewActive && glbView ? (
                <div className={layout.glbSection}>
                  <model-viewer
                    alt="Rolex Model"
                    src={glbData}
                    ar
                    poster="/assets/client/rebag/watch/RolexOyster.webp"
                    shadow-intensity="1"
                    camera-controls
                    touch-action="pan-y"
                    style={{ width: "353px", height: "375px" }}
                  ></model-viewer>
                </div>
              ) : null}
            </div>
          </div>

          <img
            className={layout.detailsSection}
            src="/assets/client/rebag/watch/details_1.png"
            alt=""
          />
        </div>
        <img src="/assets/client/rebag/watch/mobile_similar.png" alt="" />
        <img src="/assets/client/rebag/watch/mobile_likable.png" alt="" />
        <img src="/assets/client/rebag/watch/mobile_sell.png" alt="" />
        <img src="/assets/client/rebag/watch/mobile_footer1.png" alt="" />
        <img src="/assets/client/rebag/watch/mobile_footer2.png" alt="" />
      </div>
    </div>
  );
}

export default Watch;
