import React from "react";
import styles from "./styles.module.scss";

function CreateJob() {
  return (
    <div className={styles.container}>
      <div className={styles.iconSection}>X</div>
      <div className={styles.mainSection}>
        <div className={styles.left}>
          <div className={styles.titleSection}>
            <div className={styles.title}>Create new job</div>
            <div className={styles.subtitle}>Lorem ipsum dolor sit.</div>
          </div>
          <div className={styles.percentageSection}>
            <div className={`${styles.bar} ${styles.success}`}></div>
            <p>05 of 100% completed</p>
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.heading}>Product Type</div>

          <div className={styles.options}>
            <p className={styles.clicked}>Single Product</p>
            <p>Multi Product</p>
          </div>

          <div className={styles.heading}>Product Specifications</div>
          <div className={styles.line}></div>
          <div className={styles.inputSection}>
            <div className={styles.input25}>
              <label htmlFor="">Product Name</label>
              <input type="text" placeholder="Enter product name" />
            </div>
            <div className={styles.input75}>
              <label htmlFor="">Units</label>
              <select name="" id="">
                <option value="Centimeter">CM</option>
                <option value="Meter">Meter</option>
              </select>
            </div>
          </div>

          <div className={styles.inputSection}>
            <div className={styles.inputequal}>
              <label htmlFor="">Length</label>
              <input type="text" placeholder="Length" />
            </div>
            <div className={styles.inputequal}>
              <label htmlFor="">Width</label>
              <input type="text" placeholder="Width" />
            </div>
            <div className={styles.inputequal}>
              <label htmlFor="">Height</label>
              <input type="text" placeholder="Height" />
            </div>
            <div className={styles.inputequal}>
              <label htmlFor="">Focal Length</label>
              <input type="text" placeholder="focallength" />
            </div>
          </div>

          <div className={styles.inputSection}>
            <div className={styles.input75}>
              <label htmlFor="">File Upload</label>
              <input className={styles.upload} type="file" />
            </div>
          </div>

          <div className={styles.inputSection}>
            <div className={styles.input25}>
              <label htmlFor="">External Reference Link</label>
              <input type="text" placeholder="www.example.com" />
            </div>
          </div>

          <div className={styles.heading} style={{ marginTop: "1rem" }}>
            Output Type
          </div>

          <div className={styles.options}>
            <p className>360 View</p>
            <p>Virtual Tryon</p>
          </div>

          <div className={styles.otherSection}>Other Details</div>

          <div className={styles.inputSection}>
            <div className={styles.inputequal}>
              <label htmlFor="">Start Date</label>
              <input type="date" placeholder="Length" />
            </div>
            <div className={styles.inputequal}>
              <label htmlFor="">End Date / Deadline</label>
              <input type="date" placeholder="Length" />
            </div>
          </div>

          <div className={styles.inputSection}>
            <div className={styles.inputequal}>
              <label htmlFor="">Lot Name</label>
              <input type="text" placeholder="WK-14-2024" disabled />
            </div>
            <div className={styles.inputequal}>
              <label htmlFor="">Select Priority</label>
              <select name="" id="">
                <option value="" selected disabled>
                  Select Priority
                </option>
                <option value="">Low</option>
                <option value="">Medium</option>
                <option value="">High</option>
              </select>
            </div>
          </div>
          <div className={styles.inputSection}>
            <h3>+ Add another specification</h3>
          </div>
        </div>
      </div>
      <div className={styles.footerSection}>
        <div className={styles.buttonSection}>
          <button className={styles.secondary}>Save for later</button>
          <button className={styles.primary}>Create Job</button>
        </div>
      </div>
    </div>
  );
}

export default CreateJob;
