import React from "react";
import styles from "./styles.module.scss";
import { HelpOutlineOutlined } from "@mui/icons-material";

function IframeLink({ position, onClose }) {
  return (
    <div
      style={{
        position: "absolute",
        top: `${position.top}px`,
        left: `${position.left}px`,
        marginLeft: "-16rem",
        zIndex: 1000,
      }}
    >
      <div className={styles.container}>
        <div className={styles.closeSection} onClick={onClose}>
          X
        </div>
        <div className={styles.header}>
          <button>Copy embed link</button>
          <button>Copy iframe code</button>
        </div>
        <div className={styles.line}></div>
        <div className={styles.footer}>
          <HelpOutlineOutlined />
          <p>Do you need any help on how to add an iframe to your site?</p>
        </div>
      </div>
    </div>
  );
}

export default IframeLink;
