import { useEffect,useState } from 'react';
import { bootstrapCameraKit, createMediaStreamSource, Transform2D } from '@snap/camera-kit';


const UseMobileLens = ({ lensGroupId, lensId, mobileArViewActive, rearCameraActive }) => {
    const [currentCameraType, setCurrentCameraType] = useState('front');
    const [session, setSession] = useState(null);
    const [error, setError] = useState(null);
    const toggleMobileCamera = () => {
        setCurrentCameraType(currentCameraType === 'rear' ? 'front' : 'rear');
    };


    useEffect(() => {
        const initLensSession = async () => {
            try {
                if (!mobileArViewActive) return;

                const apiToken = "eyJhbGciOiJIUzI1NiIsImtpZCI6IkNhbnZhc1MyU0hNQUNQcm9kIiwidHlwIjoiSldUIn0.eyJhdWQiOiJjYW52YXMtY2FudmFzYXBpIiwiaXNzIjoiY2FudmFzLXMyc3Rva2VuIiwibmJmIjoxNzEwNTMxNjIxLCJzdWIiOiI0ZmM0NTg4Yi01OWMwLTRmNWUtYjYyMS05ZjZiNDM4MTFkZjZ-U1RBR0lOR345ZGI4NjliZS05MThjLTQ4ZWQtYmY2ZC02YjUzMjczMTk2NzQifQ.tYZpUHNq29VUhg0Jg910He63IsnKPGSyRrfeTTRi3Ow"; // Fetch your API token here

                const cameraKit = await bootstrapCameraKit({ apiToken });
                const canvas = document.getElementById("arBox2");
                const newSession = await cameraKit.createSession({ liveRenderTarget: canvas });
                
                newSession.events.addEventListener('error', (event) => {
                    if (event.detail.error.name === 'LensExecutionError') {
                        console.log('The current Lens encountered an error and was removed.', event.detail.error);
                    }
                });

                try {
                    console.log(currentCameraType);
                    const sourceOptions = {
                        transform: currentCameraType === 'front' ? Transform2D.None : Transform2D.MirrorX,
                        cameraType: currentCameraType
                    };

                    const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: currentCameraType } });
                    const source = createMediaStreamSource(stream, sourceOptions);
                    await newSession.setSource(source);
                } catch (err) {
                    // Handle overconstrained error
                    console.error('Error accessing camera:', err);
                    setError(err);
                    return;
                }

                const lens = await cameraKit.lensRepository.loadLens( lensGroupId, lensId );
                await newSession.applyLens(lens);

                await newSession.play();
                console.log("Lens rendering has started!");

                setSession(newSession); // Set the session
            } catch (error) {
                console.error("Error initializing Lens session:", error);
            }
        };

        initLensSession();

        return () => {
            if (session) {
                session.close(); // Close the session when unmounting
            }
        };
    }, [lensGroupId, lensId, mobileArViewActive, currentCameraType]);

    

    useEffect(() => {
        if (rearCameraActive) {
            toggleMobileCamera();
        }
    }, [rearCameraActive]);

    const handleSwitchCameraClick = () => {
        console.log('camera switched');
        toggleMobileCamera(); // Toggle the camera type
    };

    if (error) {
        return <p>Error accessing camera: {error.message}</p>;
    }

    return mobileArViewActive ? (
        <p onClick={handleSwitchCameraClick}>Switch New Camera</p>
    ) : null;


};

export default UseMobileLens;
