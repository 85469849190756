import React, { useState } from "react";
import styles from "./RalphOverall.module.scss";

function RalphOverall() {
  const [vtocontainer, setVtocontainer] = useState(true);
  const [watchcontainer, setWatchcontainer] = useState(false);
  const [bagcontainer, setBagcontainer] = useState(false);
  const [apparelcontainer, setApparelcontainer] = useState(false);
  const [furniturecontainer, setFurniturecontainer] = useState(false);
  const [product, setProduct] = useState("All VTO's");
  const [popupcontainer, setPopupcontainer] = useState(false);
  const [iframeUrl, setIframeUrl] = useState("");
  const [pdpurl, setPdpurl] = useState("");
  const [qrcodeurl, setQrcodeurl] = useState("");
  const [qrcontainer, setQrcontainer] = useState(true);

  const toggleVto = () => {
    setProduct("All VTO's");
    setVtocontainer(true);
    setWatchcontainer(false);
    setBagcontainer(false);
    setApparelcontainer(false);
    setFurniturecontainer(false);
  };

  const toggleWatch = () => {
    setProduct("Watches");
    setVtocontainer(false);
    setBagcontainer(false);
    setWatchcontainer(true);
    setApparelcontainer(false);
    setFurniturecontainer(false);
  };

  const toggleBags = () => {
    setProduct("Bags");
    setVtocontainer(false);
    setBagcontainer(true);
    setWatchcontainer(false);
    setApparelcontainer(false);
    setFurniturecontainer(false);
  };

  const toggleApparel = () => {
    setProduct("Apparels");
    setVtocontainer(false);
    setBagcontainer(false);
    setWatchcontainer(false);
    setApparelcontainer(true);
    setFurniturecontainer(false);
  };

  const toggleFurniture = () => {
    setProduct("Furnitures");
    setVtocontainer(false);
    setBagcontainer(false);
    setWatchcontainer(false);
    setApparelcontainer(false);
    setFurniturecontainer(true);
  };

  const closePopup = () => {
    setPopupcontainer(false);
    setIframeUrl(false);
  };

  const openPopup = (url, url2, url3) => {
    console.log("working");
    setIframeUrl(url);
    setQrcontainer(true);
    setQrcodeurl(url3);

    setPdpurl(url2);
    setPopupcontainer(true);
  };

  return (
    <div>
      <div className={styles.container}>
        {popupcontainer && (
          <div className={styles.modalPopup}>
            <div className={styles.header}>
              <button
                className={styles.primary}
                onClick={() => window.open(pdpurl, "_blank")}
              >
                View Product Page
              </button>
              <button onClick={closePopup} className={styles.secondary}>
                Close
              </button>
            </div>

            <div className={styles.content}>
              {qrcontainer && <img src={qrcodeurl} alt="" />}

              <iframe
                title="ar"
                src={iframeUrl}
                seamless
                allow="camera"
              ></iframe>
            </div>
          </div>
        )}

        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/ralphlauren/overall/desktopHeader.webp"
          alt=""
        />
        <div className={styles.header}>
          <p>Ralph Lauren / {product}</p>
        </div>

        <div className={styles.mainSection}>
          <div className={styles.leftSection}>
            <div className={styles.title}>Browse by :</div>
            <div className={styles.horizontal_line}></div>
            <p onClick={toggleVto}>All Product's</p>
            <p onClick={toggleWatch}>Watches</p>
            <p onClick={toggleBags}>Bags </p>
            <p onClick={toggleApparel}>Apparels</p>
            <p onClick={toggleFurniture}>Furniture</p>
            <p>Sunglasses</p>
            <p
              onClick={() =>
                window.open("http://pod.advflr.com/client/ads", "_blank")
              }
            >
              Ads
            </p>
          </div>
          <div className={styles.rightSection}>
            <div className={styles.titleSection}>
              <div className={styles.title}>
                {vtocontainer && (
                  <>
                    All Products <span>12 Products</span>
                  </>
                )}

                {watchcontainer && (
                  <>
                    Watches <span>2 Products</span>
                  </>
                )}

                {bagcontainer && (
                  <>
                    Bags <span>4 Products</span>
                  </>
                )}
              </div>

              <div className={styles.searchSection}>
                <p>Sort:</p>
                <select>
                  <option value="" selected>
                    Featured
                  </option>
                  <option value="" onClick={toggleVto}>
                    Latest
                  </option>
                  <option value="">360 </option>
                  <option value="">Only VTO</option>
                  <option value="">Render Videos</option>
                </select>
              </div>
            </div>
            <div className={styles.gridSection}>
              {vtocontainer && (
                <>
                  <div className={styles.imageSection}>
                    <div className={styles.label}>
                      <p>360 Turnaround</p>
                    </div>

                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/dowel-counter-stool/render_1.webp"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://www.pod.advflr.com/turnaround/anthropologie/dowel-counter-stool",
                          "https://www.pod.advflr.com/client/anthropologie/dowel-counter-stool",
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/qr/stool-qr.png"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <div className={styles.label}>
                      <p>Interactive</p>
                    </div>

                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/jack-6seat-couch/thumbnail1.jpg"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://app.vectary.com/p/29nqAVVHR8Fe2ekgE6v6MK",
                          "http://pod.advflr.com/client/urban/couch-6-seat"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <div className={styles.label}>
                      <p>Interactive</p>
                    </div>

                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/owala_freesip_black/render_1.webp"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://app.vectary.com/p/7QWY3rWb7dip8vFGJiGk4S",
                          "https://www.pod.advflr.com/client/urbanoutfitters/watercan"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <div className={styles.label}>
                      <p>Tryon</p>
                    </div>

                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/reading-glass/render_1.webp"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://www.pod.advflr.com/client/vto/frontcamera/01ce031c-bbcc-40b2-a0a5-0478793ec0c7/1ee15629-39c0-4eb9-849b-8dabd1a6729f"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <div className={styles.label}>
                      <p>Tryon</p>
                    </div>

                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/clarev/grandetote-2/render1.webp"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://www.bagsdk.advflr.com/?glb=https://advertflair-public.s3.us-west-2.amazonaws.com/client/clarev/output/grandebateau_ar.glb",
                          "https://www.pod.advflr.com/client/urbanoutfitters/louisvuiton-bag",
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/qr/grandetoteQr.png"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <div className={styles.label}>
                      <p>Tryon</p>
                    </div>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/rebags/louis/render1-1.webp"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://www.bagsdk.advflr.com/?glb=https://advertflair-public.s3.us-west-2.amazonaws.com/client/rebags/output/louisbag_vto.glb",
                          "https://www.pod.advflr.com/client/urbanoutfitters/louisvuiton-bag",
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/qr/louisQr.png"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <div className={styles.label}>
                      <p>Tryon</p>
                    </div>

                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/clarev/petimoyen-2/render3.webp"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://www.bagsdk.advflr.com/?glb=https://advertflair-public.s3.us-west-2.amazonaws.com/client/clarev/output/petit-moyen-straight-ar.glb",
                          "https://www.pod.advflr.com/client/urbanoutfitters/petitmoyen",
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/qr/petitQr.png"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <div className={styles.label}>
                      <p>Tryon</p>
                    </div>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/marc-jacobs/marc-jacobs-1.jpg"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://www.bagsdk.advflr.com/?glb=https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/output/default_bag.glb",
                          "",
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/qr/macrosQr.png"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <div className={styles.label}>
                      <p>Tryon</p>
                    </div>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/temp/polo-bag-render.webp"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://www.bagsdk.advflr.com/?glb=https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/vto/polo-shoulder-bag.glb",
                          "www.google.com",
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/qr/poloQr.png"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <div className={styles.label}>
                      <p>Tryon</p>
                    </div>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/temp/posh-mini-crossbag.webp"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://www.bagsdk.advflr.com/?glb=https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/vto/posh-mini-crossbag.glb",
                          "www.google.com",
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/qr/mini-cross-bagQr.png"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <div className={styles.label}>
                      <p>Tryon</p>
                    </div>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/tshirt/thumbnail_1.webp"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://www.shirtsdk.advflr.com/?glb=https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/vto/butwiser_tshirt.glb",
                          "www.google.com",
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/qr/butt-wiserQr.png"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <div className={styles.label}>
                      <p>Tryon</p>
                    </div>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/tshirt/camflogue.jpeg"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://www.shirtsdk.advflr.com/?glb=https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/tshirt/jacket_ar.glb",
                          "www.google.com",
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/qr/overCoatQr.png"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <div className={styles.label}>
                      <p>Tryon</p>
                    </div>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/rebags/rolex/render_1.png"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://www.watchsdk.advflr.com/?glb=https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/vto/rolex.glb",
                          "www.google.com",
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/qr/rolexQr.png"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <div className={styles.label}>
                      <p>Tryon</p>
                    </div>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/patek-watch/patk_world.jpg"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://www.watchsdk.advflr.com/?glb=https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/vto/watch.glb",
                          "www.google.com",
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/qr/patekQr.png"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <div className={styles.label}>
                      <p>Tryon</p>
                    </div>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/rado/rado%2Bwomens%2Bwatch.webp"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://www.watchsdk.advflr.com/?glb=https://advertflair-public.s3.us-west-2.amazonaws.com/client/rado/output/rado-womens-watch-ar.glb",
                          "https://www.pod.advflr.com/client/urban/watch-women-rado",
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/qr/rado-womenQr.png"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <div className={styles.label}>
                      <p>Tryon</p>
                    </div>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/rado/rado%2Bmens%2Bwatch.jpg"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://www.watchsdk.advflr.com/?glb=https://advertflair-public.s3.us-west-2.amazonaws.com/client/rado/output/rado-mens-watch-ar.glb",
                          "https://www.pod.advflr.com/client/urban/watch-men-rado",
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/qr/rado-menQr.png"
                        )
                      }
                    />
                  </div>
                </>
              )}

              {watchcontainer && (
                <>
                  <div className={styles.imageSection}>
                    <div className={styles.label}>
                      <p>Tryon</p>
                    </div>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/rebags/rolex/render_1.png"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://www.watchsdk.advflr.com/?glb=https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/vto/rolex.glb",
                          "www.google.com",
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/qr/rolexQr.png"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <div className={styles.label}>
                      <p>Tryon</p>
                    </div>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/patek-watch/patk_world.jpg"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://www.watchsdk.advflr.com/?glb=https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/vto/watch.glb",
                          "www.google.com",
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/qr/patekQr.png"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <div className={styles.label}>
                      <p>Tryon</p>
                    </div>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/rado/rado%2Bwomens%2Bwatch.webp"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://www.watchsdk.advflr.com/?glb=https://advertflair-public.s3.us-west-2.amazonaws.com/client/rado/output/rado-womens-watch-ar.glb",
                          "https://www.pod.advflr.com/client/urban/watch-women-rado",
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/qr/rado-womenQr.png"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <div className={styles.label}>
                      <p>Tryon</p>
                    </div>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/rado/rado%2Bmens%2Bwatch.jpg"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://www.watchsdk.advflr.com/?glb=https://advertflair-public.s3.us-west-2.amazonaws.com/client/rado/output/rado-mens-watch-ar.glb",
                          "https://www.pod.advflr.com/client/urban/watch-men-rado",
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/qr/rado-menQr.png"
                        )
                      }
                    />
                  </div>
                </>
              )}

              {apparelcontainer && (
                <>
                  <div className={styles.imageSection}>
                    <div className={styles.label}>
                      <p>Tryon</p>
                    </div>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/tshirt/thumbnail_1.webp"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://www.shirtsdk.advflr.com/?glb=https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/vto/butwiser_tshirt.glb",
                          "www.google.com",
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/qr/butt-wiserQr.png"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <div className={styles.label}>
                      <p>Tryon</p>
                    </div>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/tshirt/camflogue.jpeg"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://www.shirtsdk.advflr.com/?glb=https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/tshirt/jacket_ar.glb",
                          "www.google.com",
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/qr/overCoatQr.png"
                        )
                      }
                    />
                  </div>
                </>
              )}

              {furniturecontainer && (
                <>
                  {" "}
                  <div className={styles.imageSection}>
                    <div className={styles.label}>
                      <p>360 Turnaround</p>
                    </div>

                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/dowel-counter-stool/render_1.webp"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://www.pod.advflr.com/turnaround/anthropologie/dowel-counter-stool",
                          "https://www.pod.advflr.com/client/anthropologie/dowel-counter-stool",
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/qr/stool-qr.png"
                        )
                      }
                    />
                  </div>
                  <div className={styles.imageSection}>
                    <div className={styles.label}>
                      <p>Interactive</p>
                    </div>

                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/jack-6seat-couch/thumbnail1.jpg"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://app.vectary.com/p/29nqAVVHR8Fe2ekgE6v6MK",
                          "http://pod.advflr.com/client/urban/couch-6-seat"
                        )
                      }
                    />
                  </div>
                </>
              )}

              {bagcontainer && (
                <>
                  <div className={styles.imageSection}>
                    <div className={styles.label}>
                      <p>Tryon</p>
                    </div>

                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/clarev/grandetote-2/render1.webp"
                      onClick={() =>
                        openPopup(
                          "https://www.bagsdk.advflr.com/?glb=https://advertflair-public.s3.us-west-2.amazonaws.com/client/clarev/output/grandebateau_ar.glb",
                          "https://www.pod.advflr.com/client/urbanoutfitters/louisvuiton-bag",
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/qr/grandetoteQr.png"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <div className={styles.label}>
                      <p>Tryon</p>
                    </div>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/rebags/louis/render1-1.webp"
                      onClick={() =>
                        openPopup(
                          "https://www.bagsdk.advflr.com/?glb=https://advertflair-public.s3.us-west-2.amazonaws.com/client/rebags/output/louisbag_vto.glb",
                          "https://www.pod.advflr.com/client/urbanoutfitters/louisvuiton-bag",
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/qr/louisQr.png"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <div className={styles.label}>
                      <p>Tryon</p>
                    </div>

                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/clarev/petimoyen-2/render3.webp"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://www.bagsdk.advflr.com/?glb=https://advertflair-public.s3.us-west-2.amazonaws.com/client/clarev/output/petit-moyen-straight-ar.glb",
                          "https://www.pod.advflr.com/client/urbanoutfitters/petitmoyen",
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/qr/petitQr.png"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <div className={styles.label}>
                      <p>Tryon</p>
                    </div>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/marc-jacobs/marc-jacobs-1.jpg"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://www.bagsdk.advflr.com/?glb=https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/output/default_bag.glb",
                          "",
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/qr/macrosQr.png"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <div className={styles.label}>
                      <p>Tryon</p>
                    </div>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/temp/polo-bag-render.webp"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://www.bagsdk.advflr.com/?glb=https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/vto/polo-shoulder-bag.glb",
                          "www.google.com",
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/qr/poloQr.png"
                        )
                      }
                    />
                  </div>

                  <div className={styles.imageSection}>
                    <div className={styles.label}>
                      <p>Tryon</p>
                    </div>
                    <img
                      src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/temp/posh-mini-crossbag.webp"
                      alt=""
                      onClick={() =>
                        openPopup(
                          "https://www.bagsdk.advflr.com/?glb=https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/vto/posh-mini-crossbag.glb",
                          "www.google.com",
                          "https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/qr/mini-cross-bagQr.png"
                        )
                      }
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/ralphlauren/overall/DesktopFooter.webp"
          alt=""
        />
      </div>

      <div className={styles.mobileContainer}>
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/ralphlauren/overall/mobileHeader.webp"
          alt=""
        />

        {popupcontainer && (
          <div className={styles.modalPopup}>
            <div className={styles.header}>
              <button className={styles.primary}>View Product Page</button>
              <button onClick={closePopup} className={styles.secondary}>
                Close
              </button>
            </div>

            <div className={styles.content}>
              <iframe
                title="ar"
                src={iframeUrl}
                seamless
                allow="camera"
              ></iframe>
            </div>
          </div>
        )}
        <div className={styles.title}>All VTO's</div>

        <select name="" id="">
          <option value="" selected>
            Choose VTO Category
          </option>
          <option>All VTO's</option>
          <option>Watches</option>
          <option>Bags</option>
          <option>Earings</option>
          <option>Tshirt's</option>
          <option>Caps</option>
          <option>Pants</option>
          <option>Sunglasses</option>
        </select>
        <div className={styles.subtitle}>{vtocontainer && <>All VTO's</>}</div>
        <div className={styles.gridSection}>
          {vtocontainer && (
            <>
              <img
                src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/owala_freesip_black/render_4.webp"
                alt=""
                onClick={() => openPopup("https://www.google.com")}
              />
              <img
                src="https://placehold.co/1024x1024?text=VTO-Thumbnail-2"
                alt=""
              />
              <img
                src="https://placehold.co/1024x1024?text=VTO-Thumbnail-3"
                alt=""
              />
              <img
                src="https://placehold.co/1024x1024?text=VTO-Thumbnail-4"
                alt=""
              />
              <img
                src="https://placehold.co/1024x1024?text=VTO-Thumbnail-5"
                alt=""
              />
              <img
                src="https://placehold.co/1024x1024?text=VTO-Thumbnail-6"
                alt=""
              />
            </>
          )}

          {watchcontainer && (
            <>
              <img
                src="https://placehold.co/1024x1024?text=Watch-Thumbnail-1"
                alt=""
                onClick={() =>
                  openPopup(
                    "https://www.watchsdk.advflr.com/?glb=https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/vto/rolex.glb"
                  )
                }
              />
              <img
                src="https://placehold.co/1024x1024?text=Watch-Thumbnail-2"
                alt=""
                onClick={() =>
                  openPopup(
                    "https://www.watchsdk.advflr.com/?glb=https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/vto/watch.glb"
                  )
                }
              />
              <img
                src="https://placehold.co/1024x1024?text=Watch-Thumbnail-3"
                alt=""
              />
              <img
                src="https://placehold.co/1024x1024?text=Watch-Thumbnail-4"
                alt=""
              />
              <img
                src="https://placehold.co/1024x1024?text=Watch-Thumbnail-5"
                alt=""
              />
            </>
          )}
        </div>
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/ralphlauren/overall/mobileFooter.webp"
          alt=""
        />
      </div>
    </div>
  );
}

export default RalphOverall;
