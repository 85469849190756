import React, { useEffect, useState } from "react";
import {
  bootstrapCameraKit,
  createMediaStreamSource,
  Transform2D,
} from "@snap/camera-kit";

const AiCameraMobile = () => {
  const [cameraType, setCameraType] = useState("front");

  const switchCamera = async () => {
    const newCameraType = cameraType === "front" ? "back" : "front";
    setCameraType(newCameraType);

    const stream = await navigator.mediaDevices.getUserMedia({
      video: { facingMode: newCameraType },
    });
    const source = createMediaStreamSource(stream, {
      transform: Transform2D.MirrorX,
      cameraType: newCameraType,
    });

    const apiToken =
      "eyJhbGciOiJIUzI1NiIsImtpZCI6IkNhbnZhc1MyU0hNQUNQcm9kIiwidHlwIjoiSldUIn0.eyJhdWQiOiJjYW52YXMtY2FudmFzYXBpIiwiaXNzIjoiY2FudmFzLXMyc3Rva2VuIiwibmJmIjoxNzEwNDYzNDgxLCJzdWIiOiJlYTUzMjc1NS1kYzVmLTQ0OGYtOGEwOS02MDQ4OTEwZmZlNTl-U1RBR0lOR34yZjIzMTlmNS0xMGQ0LTQyNzEtODc3Ny0yNDJkN2RlZDgwZWQifQ.W-opeQJsJOVsAvqDel4IF1vx70wQI3SMa9GVteTtofk"; // Replace with your actual API token
    const cameraKit = await bootstrapCameraKit({ apiToken });
    const canvas = document.getElementById("my-canvas");

    const session = await cameraKit.createSession({
      liveRenderTarget: canvas,
    });
    await session.setSource(source);
  };

  useEffect(() => {
    const main = async () => {
      try {
        const apiToken =
          "eyJhbGciOiJIUzI1NiIsImtpZCI6IkNhbnZhc1MyU0hNQUNQcm9kIiwidHlwIjoiSldUIn0.eyJhdWQiOiJjYW52YXMtY2FudmFzYXBpIiwiaXNzIjoiY2FudmFzLXMyc3Rva2VuIiwibmJmIjoxNzEwNDYzNDgxLCJzdWIiOiJlYTUzMjc1NS1kYzVmLTQ0OGYtOGEwOS02MDQ4OTEwZmZlNTl-U1RBR0lOR34yZjIzMTlmNS0xMGQ0LTQyNzEtODc3Ny0yNDJkN2RlZDgwZWQifQ.W-opeQJsJOVsAvqDel4IF1vx70wQI3SMa9GVteTtofk"; // Replace with your actual API token
        const cameraKit = await bootstrapCameraKit({ apiToken });

        const canvas = document.getElementById("my-canvas");
        const session = await cameraKit.createSession({
          liveRenderTarget: canvas,
        });
        session.events.addEventListener("error", (event) => {
          if (event.detail.error.name === "LensExecutionError") {
            console.log(
              "The current Lens encountered an error and was removed.",
              event.detail.error
            );
          }
        });

        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        const source = createMediaStreamSource(stream, {
          transform: Transform2D.MirrorX,
          cameraType: "front",
        });
        await session.setSource(source);
        const lens = await cameraKit.lensRepository.loadLens(
          "7daf88c7-687a-4b7b-81d2-8f379fa55fc2",
          "1ee15629-39c0-4eb9-849b-8dabd1a6729f"
        );

        await session.applyLens(lens);

        await session.play();
        console.log("Lens rendering has started!");
      } catch (error) {
        // Log the error if it's not the "Uncaught runtime error" you want to skip
        if (error.name !== "UncaughtRuntimeError") {
          console.error("Error accessing camera:", error);
        }
      }
    };

    main();

    return () => {
      // Cleanup code if needed
    };
  }, []);

  return (
    <div>
      <canvas id="my-canvas" style={{ width: "100%" }} />
      <button onClick={switchCamera}>Switch Camera</button>
    </div>
  );
};

export default AiCameraMobile;
