import React from "react";
import styles from "./styles.module.scss";
import HomeHeader from "../../molecules/header/HomeHeader";
import CommonNavbar from "../../molecules/sidenavbars/common/CommonNavbar";

function DashboardLayout({ children }) {
  return (
    <div className={styles.container}>
      <div className={styles.navbar}>
        <CommonNavbar />
      </div>

      <div className={styles.column}>
        <HomeHeader />
        {children}
      </div>
    </div>
  );
}

export default DashboardLayout;
