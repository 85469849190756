import React from "react";

function ArrowRight() {
  return (
    <img
      src="https://advertflair-public.s3.us-west-2.amazonaws.com/assets/icons/ArrowRight.svg"
      alt=""
    />
  );
}

export default ArrowRight;
