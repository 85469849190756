import React, { useEffect } from "react";
import styles from "./styles.module.scss";
import {
  bootstrapCameraKit,
  createMediaStreamSource,
  Transform2D,
} from "@snap/camera-kit";

const Jacob = () => {
  useEffect(() => {
    const main = async () => {
      try {
        const apiToken =
          "eyJhbGciOiJIUzI1NiIsImtpZCI6IkNhbnZhc1MyU0hNQUNQcm9kIiwidHlwIjoiSldUIn0.eyJhdWQiOiJjYW52YXMtY2FudmFzYXBpIiwiaXNzIjoiY2FudmFzLXMyc3Rva2VuIiwibmJmIjoxNzEwNDE2MTg5LCJzdWIiOiI0OGJmYzM4My1jZmQ1LTQxNTMtOWU5NS0wNjIyYzc1MDlhMDB-U1RBR0lOR35hNGRjOGQwOS01ZTkyLTQ1OWQtYTEzZi0yMDI2YzA4YmY3M2UifQ.r5ozTzw2qBu5AM3wwv4DivHN-VmsCTuEPMf3UsnACcA"; // Replace with your actual API token
        const cameraKit = await bootstrapCameraKit({ apiToken });

        const canvas = document.getElementById("my-canvas");
        const session = await cameraKit.createSession({
          liveRenderTarget: canvas,
        });
        session.events.addEventListener("error", (event) => {
          if (event.detail.error.name === "LensExecutionError") {
            console.log(
              "The current Lens encountered an error and was removed.",
              event.detail.error
            );
          }
        });

        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        const source = createMediaStreamSource(stream, {
          transform: Transform2D.MirrorX,
          cameraType: "front",
        });
        await session.setSource(source);

        const lens = await cameraKit.lensRepository.loadLens(
          "0ae8b391-a746-42db-ab8c-6d7c461a01da",
          "b1e590d7-1067-4c3e-893c-3dc4c2ca0627"
        );
        await session.applyLens(lens);

        await session.play();
        console.log("Lens rendering has started!");
      } catch (error) {
        // Log the error if it's not the "Uncaught runtime error" you want to skip
        if (error.name !== "UncaughtRuntimeError") {
          console.error("Error accessing camera:", error);
        }
      }
    };

    main();

    return () => {
      // Cleanup code if needed
    };
  }, []);

  return (
    <div className={styles.container}>
      <canvas id="my-canvas" width={1024} />
    </div>
  );
};

export default Jacob;
