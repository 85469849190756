import React, { useState } from "react";
import styles from "./styles.module.scss";

function PetitMoyen() {
  const [selectedImage, setSelectedImage] = useState(
    "https://advertflair-public.s3.us-west-2.amazonaws.com/client/clarev/petimoyen-2/render1.webp"
  );
  const [glbContainer, setGlbContainer] = useState(false);
  const [tryonContainer, setTryonContainer] = useState(false);
  const [mobileimageContainer, setMobileimageContainer] = useState(true);

  const handleImageClick = (imageSrc) => {
    setGlbContainer(false);
    setSelectedImage(imageSrc);
    setMobileimageContainer(true);
    setTryonContainer(false);
  };

  const toggleGlb = () => {
    setGlbContainer(true);
    setMobileimageContainer(false);
    setTryonContainer(false);
  };

  const tryonGlb = () => {
    setGlbContainer(false);
    setMobileimageContainer(false);
    setTryonContainer(true);
  };

  return (
    <div className={styles.megaContainer}>
      <div className={styles.container}>
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/clarev/petimoyen-2/mobileHeader.webp"
          alt=""
        />

        <div className={styles.renderSection}>
          <p onClick={tryonGlb}>Try Before Buy</p>

          {mobileimageContainer && <img src={selectedImage} alt="" />}

          {glbContainer && (
            <iframe
              title="desktopGlb"
              seamless
              src="https://www.pod.advflr.com/turnaround/clarev/petitmoyen"
            ></iframe>
          )}

          {tryonContainer && (
            <iframe
              title="desktopGlb"
              seamless
              allow="camera"
              src="https://www.bagsdk.advflr.com/?glb=https://advertflair-public.s3.us-west-2.amazonaws.com/client/clarev/output/petit-moyen-straight-ar.glb"
            ></iframe>
          )}
        </div>

        <div className={styles.thumbnailSection}>
          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/clarev/petimoyen-2/render1.webp"
            alt=""
            onClick={() =>
              handleImageClick(
                "https://advertflair-public.s3.us-west-2.amazonaws.com/client/clarev/petimoyen-2/render1.webp"
              )
            }
          />
          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/clarev/petimoyen-2/render2.webp"
            alt=""
            onClick={() =>
              handleImageClick(
                "https://advertflair-public.s3.us-west-2.amazonaws.com/client/clarev/petimoyen-2/render2.webp"
              )
            }
          />
          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/clarev/petimoyen-2/render3.webp"
            alt=""
            onClick={() =>
              handleImageClick(
                "https://advertflair-public.s3.us-west-2.amazonaws.com/client/clarev/petimoyen-2/render3.webp"
              )
            }
          />
          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/366-1.webp"
            alt=""
            onClick={toggleGlb}
          />
        </div>

        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/clarev/petimoyen-2/mobileBody-2.webp"
          alt=""
        />
      </div>
      <div className={styles.desktopContainer}>
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/clarev/grandetote-2/clarveHeader.png"
          alt=""
        />
        <div className={styles.mainSection}>
          <div className={styles.thumbnailSection}>
            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/clarev/petimoyen-2/render1.webp"
              alt=""
              onClick={() =>
                handleImageClick(
                  "https://advertflair-public.s3.us-west-2.amazonaws.com/client/clarev/petimoyen-2/render1.webp"
                )
              }
            />
            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/clarev/petimoyen-2/render2.webp"
              alt=""
              onClick={() =>
                handleImageClick(
                  "https://advertflair-public.s3.us-west-2.amazonaws.com/client/clarev/petimoyen-2/render2.webp"
                )
              }
            />
            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/clarev/petimoyen-2/render3.webp"
              alt=""
              onClick={() =>
                handleImageClick(
                  "https://advertflair-public.s3.us-west-2.amazonaws.com/client/clarev/petimoyen-2/render3.webp"
                )
              }
            />
            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/366-1.webp"
              alt=""
              onClick={toggleGlb}
            />
          </div>
          <div className={styles.renderSection}>
            <p onClick={tryonGlb}>Try Before Buy</p>
            {mobileimageContainer && <img src={selectedImage} alt="" />}

            {glbContainer && (
              <iframe
                title="desktopGlb"
                seamless
                src="https://www.pod.advflr.com/turnaround/clarev/petitmoyen"
              ></iframe>
            )}

            {tryonContainer && (
              <iframe
                title="desktopGlb"
                seamless
                allow="camera"
                src="https://www.bagsdk.advflr.com/?glb=https://advertflair-public.s3.us-west-2.amazonaws.com/client/clarev/output/petit-moyen-straight-ar.glb"
              ></iframe>
            )}
          </div>
          <div className={styles.cartSection}>
            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/clarev/petimoyen-2/cart1.png"
              alt=""
            />
            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/clarev/petimoyen-2/cart2.png"
              alt=""
            />
          </div>
        </div>

        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/clarev/grandetote-2/footer.png"
          alt=""
        />
      </div>
    </div>
  );
}

export default PetitMoyen;
