import React from "react";
import styles from "./styles.module.scss";
import AiCameraMobile from "../../hooks/AiCameraMobile";

function MobileView() {
  return (
    <div className={styles.container}>
      <div className={styles.renderSection}>
        <AiCameraMobile />
      </div>
    </div>
  );
}

export default MobileView;
