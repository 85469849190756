import React from "react";
import { useLocation } from "react-router-dom";
import styles from "./VideoContainer.module.scss";

function VideoContainer() {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const videoUrl = query.get("videoUrl");

  return (
    <div className={styles.container}>
      <iframe
        src={videoUrl}
        frameborder="0"
        allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
        title="wellingtonVideo"
        muted
      ></iframe>
    </div>
  );
}

export default VideoContainer;
