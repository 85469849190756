import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
function ApprovalChart() {
  const data = [
    { name: "Dec 8", Approved: 10 },
    { name: "Dec 9", Approved: 12 },
    { name: "Dec 10", Approved: 4 },
    { name: "Dec 11", Approved: 14 },
    { name: "Dec 12", Approved: 8 },
  ];

  return (
    <ResponsiveContainer width="100%">
      <BarChart data={data} barSize={24}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="Approved" fill="#87DEB9" />
      </BarChart>
    </ResponsiveContainer>
  );
}

export default ApprovalChart;
