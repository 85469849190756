import React from 'react'
import styles from './title.module.scss'

function Title({title,subtitle}) {
  return (
    <div className={styles.container}>
        <div className={styles.title}>{title}</div>
        <div className={styles.subtitle}>{subtitle}</div>
    </div>
  )
}

export default Title