import React from 'react'
import styles from './styles.module.scss'

function ProfileIcon() {
  return (
    <div>
        <img className={styles.profile} src="https://3dstudio.advertflair.com/common/assets/images/users/immangeek.webp" alt="" />
    </div>
  )
}

export default ProfileIcon