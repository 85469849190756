import React from "react";
import styles from "./styles.module.scss";

function CheckedIcon() {
  return (
    <div>
      <img
        className={styles.checkIcon}
        src="https://advertflair-public.s3.us-west-2.amazonaws.com/assets/icons/Checked.svg"
        alt=""
      />
    </div>
  );
}

export default CheckedIcon;
