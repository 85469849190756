import React, { useState } from "react";
import styles from "./Toranisyrup.module.scss";
function Toranisyrup() {
  const [selectedImage, setSelectedImage] = useState(
    "https://advertflair-public.s3.us-west-2.amazonaws.com/client/torani_syrup/thumbnail_2.webp"
  );
  const [imageContainer, setImageContainer] = useState(true);
  const [glbContainer, setGlbContainer] = useState(false);
  const [videoContainer, setVideoContainer] = useState(false);

  const handleImageClick = (imageSrc) => {
    setSelectedImage(imageSrc);
    setImageContainer(true);
    setGlbContainer(false);
    setVideoContainer(false);
  };

  const toggleGlb = () => {
    setGlbContainer(true);
    setImageContainer(false);
    setVideoContainer(false);
  };

  const toggleVideo = () => {
    setGlbContainer(false);
    setImageContainer(false);
    setVideoContainer(true);
  };

  return (
    <div>
      <div className={styles.container}>
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/torani_syrup/desktop_header.webp"
          alt=""
        />

        <div className={styles.mainSection}>
          <div className={styles.renderSection}>
            {imageContainer && <img src={selectedImage} alt="" />}

            {glbContainer && (
              <iframe
                title="desktopGlb"
                seamless
                src="https://www.pod.advflr.com/turnaround/torani_syrup/almond_syrup"
              ></iframe>
            )}

            {videoContainer && (
              <div>
                <video
                  id="my-video"
                  class="video-js"
                  controls
                  preload="auto"
                  autoPlay
                  poster=""
                  data-setup="{}"
                  muted
                >
                  <source
                    src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/torani_syrup/renderVideo.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            )}
          </div>
          <div className={styles.cartSection}>
            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/torani_syrup/cart.webp"
              alt=""
            />
          </div>
        </div>

        <div className={styles.thumbnailSection}>
          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/torani_syrup/thumbnail_2.webp"
            alt=""
            onClick={() =>
              handleImageClick(
                "https://advertflair-public.s3.us-west-2.amazonaws.com/client/torani_syrup/thumbnail_2.webp"
              )
            }
          />
          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/torani_syrup/thumbnail_1.webp"
            alt=""
            onClick={() =>
              handleImageClick(
                "https://advertflair-public.s3.us-west-2.amazonaws.com/client/torani_syrup/thumbnail_1.webp"
              )
            }
          />

          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/torani_syrup/renderImage.webp"
            alt=""
            onClick={() =>
              handleImageClick(
                "https://advertflair-public.s3.us-west-2.amazonaws.com/client/torani_syrup/renderImage.webp"
              )
            }
          />

          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/366-1.webp"
            alt="360Glb"
            onClick={toggleGlb}
          />

          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/video_thumbnail.webp"
            alt=""
            onClick={toggleVideo}
          />
        </div>
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/torani_syrup/desktop_footer.webp"
          alt=""
        />
      </div>

      <div className={styles.mobilecontainer}>
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/torani_syrup/mobile_header.webp"
          alt=""
        />

        <div className={styles.mainSection}>
          <div className={styles.renderSection}>
            {imageContainer && <img src={selectedImage} alt="" />}

            {glbContainer && (
              <iframe
                title="desktopGlb"
                seamless
                src="https://www.pod.advflr.com/turnaround/torani_syrup/almond_syrup"
              ></iframe>
            )}

            {videoContainer && (
              <div>
                <video
                  id="my-video"
                  class="video-js"
                  controls
                  preload="auto"
                  autoPlay
                  poster=""
                  data-setup="{}"
                  muted
                >
                  <source
                    src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/torani_syrup/renderVideo.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            )}
          </div>
        </div>

        <div className={styles.thumbnailSection}>
          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/torani_syrup/thumbnail_2.webp"
            alt=""
            onClick={() =>
              handleImageClick(
                "https://advertflair-public.s3.us-west-2.amazonaws.com/client/torani_syrup/thumbnail_2.webp"
              )
            }
          />
          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/torani_syrup/thumbnail_1.webp"
            alt=""
            onClick={() =>
              handleImageClick(
                "https://advertflair-public.s3.us-west-2.amazonaws.com/client/torani_syrup/thumbnail_1.webp"
              )
            }
          />

          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/torani_syrup/renderImage.webp"
            alt=""
            onClick={() =>
              handleImageClick(
                "https://advertflair-public.s3.us-west-2.amazonaws.com/client/torani_syrup/renderImage.webp"
              )
            }
          />

          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/366-1.webp"
            alt="360Glb"
            onClick={toggleGlb}
          />

          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/video_thumbnail.webp"
            alt=""
            onClick={toggleVideo}
          />
        </div>
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/torani_syrup/cart_1.webp"
          alt=""
        />
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/torani_syrup/cart_2.webp"
          alt=""
        />
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/torani_syrup/cart_3.webp"
          alt=""
        />
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/torani_syrup/mobile_footer.webp"
          alt=""
        />
      </div>
    </div>
  );
}
export default Toranisyrup;
