import React from "react";
import styles from "./style.module.scss";

function Ads() {
  return (
    <div className={styles.container}>
      <iframe
        src="https://projects.geenee.ar/advertflair-nytimes/"
        frameborder="0"
        allow="camera"
      ></iframe>
    </div>
  );
}

export default Ads;
