import React from "react";
import styles from "./styles.module.scss";
import ArrowRight from "../../atoms/icons/ArrowRight";
import ApprovalChart from "./ApprovalChart";
import ReviewpendingChart from "./ReviewpendingChart";
import RejectionChart from "./RejectionChart";

function Graphs({ title, subtitle, buttonText, graph }) {
  return (
    <div className={styles.container}>
      <div className={styles.contentSection}>
        <div className={styles.titleSection}>
          <div className={styles.title}>{title}</div>
          <div className={styles.subtitle}>{subtitle}</div>
        </div>

        <div className={styles.actionSection}>
          <div className={styles.actionBtn}>
            {buttonText}
            <ArrowRight />
          </div>
        </div>
      </div>

      <div className={styles.graphSection}>
        {graph === "Approval" && <ApprovalChart />}
        {graph === "ReviewPending" && <ReviewpendingChart />}
        {graph === "Rejection" && <RejectionChart />}
      </div>
    </div>
  );
}

export default Graphs;
