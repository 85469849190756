import React from 'react'
import styles from './styles.module.scss'
import OverallSearch from '../../../atoms/inputs/OverallSearch'

function LibrayHeader() {
  return (
    <div className={styles.container}>
        <OverallSearch/>
    </div>
  )
}

export default LibrayHeader