import React from 'react'
import DashboardLayout from '../../../templates/dashboard_layout/DashboardLayout'
import Raise from '../../../molecules/header/raiseIssue/Raise'
import RaisedTickets from '../../../molecules/cards/raisedtickets/RaisedTickets'
import styles from './styles.module.scss'


function Raisedpage() {
  return (
    
    <DashboardLayout>
        <Raise />
        <div className={styles.tickets}>

        <RaisedTickets 
            tagline = "Job on Hold - Need reason"
            poid="PTAKJDKGAG"
            type="Moderate"
            status="Closed"
            created="2024-12-23"
            week="Week 49"
            description="Lorumiposum dolarsitamir amat
            dolarsit amitlorumiosum..."

        />

        <RaisedTickets 
            tagline = "Revision got delayed"
            poid="PTAKJDKGAG"
            type="Urgent"
            status="Yet to Check"
            created="2024-12-23"
            week="Week 49"
            description="Lorumiposum dolarsitamir amat
            dolarsit amitlorumiosum..."

        />


        <RaisedTickets 
            tagline = "Job on Hold - Need reason"
            poid="PTAKJDKGAG"
            type="Moderate"
            status="Closed"
            created="2024-12-23"
            week="Week 49"
            description="Lorumiposum dolarsitamir amat
            dolarsit amitlorumiosum..."

        />


        <RaisedTickets 
            tagline = "Job on Hold - Need reason"
            poid="PTAKJDKGAG"
            type="Moderate"
            status="Closed"
            created="2024-12-23"
            week="Week 49"
            description="Lorumiposum dolarsitamir amat
            dolarsit amitlorumiosum..."

        />

        <RaisedTickets 
            tagline = "Revision got delayed"
            poid="PTAKJDKGAG"
            type="Urgent"
            status="Yet to Check"
            created="2024-12-23"
            week="Week 49"
            description="Lorumiposum dolarsitamir amat
            dolarsit amitlorumiosum..."

        />


        <RaisedTickets 
            tagline = "Job on Hold - Need reason"
            poid="PTAKJDKGAG"
            type="Moderate"
            status="Closed"
            created="2024-12-23"
            week="Week 49"
            description="Lorumiposum dolarsitamir amat
            dolarsit amitlorumiosum..."

        />
        </div>
        

        
    </DashboardLayout>
  )
}

export default Raisedpage