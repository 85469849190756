import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

function RejectionChart() {
  const data = [
    { date: "Dec 8", Rejections: 18 },
    { date: "Dec 9", Rejections: 20 },
    { date: "Dec 10", Rejections: 3 },
    { date: "Dec 11", Rejections: 12 },
    { date: "Dec 12", Rejections: 8 },
  ];

  return (
    <div>
      <ResponsiveContainer width="100%" height={200}>
        <AreaChart data={data}>
          <defs>
            <linearGradient id="colorGradient" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#FF9CA4" stopOpacity={1} />
              <stop offset="100%" stopColor="#FF9CA4" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis
            dataKey="date"
            tick={{ fontFamily: "Inter", fontSize: 12, fill: "#7C6E90" }}
          />
          <YAxis
            tick={{ fontFamily: "Inter", fontSize: 12, fill: "#7C6E90" }}
          />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip
            tick={{ fontFamily: "Inter", fontSize: 14, fill: "#7C6E90" }}
          />
          <Area
            type="monotone"
            dataKey="Rejections"
            stroke="#FF9CA4"
            fill="url(#colorGradient)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}

export default RejectionChart;
