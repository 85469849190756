import React from "react";
import styles from "./styles.module.scss";
import { Link } from "react-router-dom";

function CommonNavbar() {
  return (
    <div className={styles.container}>
      <img
        className={styles.logo}
        src="https://advertflair-public.s3.us-west-2.amazonaws.com/logos/advertflair_navheader.webp"
        alt=""
      />

      <img
        className={styles.mdlogo}
        src="https://advertflair-public.s3.us-west-2.amazonaws.com/logos/mdlogo.webp"
        alt=""
      />
      <ul>
        <Link to="/home">
          <li>
            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/assets/icons/app.svg"
              alt=""
            />
            <p>Dashboard</p>
          </li>
        </Link>

        <Link to="/library">
          <li>
            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/assets/icons/library.svg"
              alt=""
            />
            <p>Gallery</p>
          </li>
        </Link>

        <Link to="/support">
          <li>
            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/assets/icons/support.svg"
              alt=""
            />
            <p>Support</p>
          </li>
        </Link>

        <Link to="/settings">
          <li>
            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/assets/icons/settings.svg"
              alt=""
            />
            <p>Settings</p>
          </li>
        </Link>
      </ul>
    </div>
  );
}

export default CommonNavbar;
