import React from 'react'
import styles from './styles.module.scss'

function RaisedTickets({
    tagline,poid,type,status,created,week,description
}) {
  return (
    <div className={styles.container}>
        <div className={styles.header}>
            <div className={styles.titleSection}>
                <div className={styles.title}>{tagline}</div>
                <div className={styles.subtitle}>#{poid}</div>
            </div>
            
            <img src="/assets/icons/options.svg" style={{"width":4}} alt="" />
            
        </div>

        <div className={styles.color}>
        </div>

        <div className={styles.row}>
            <div className={styles.column}>
                <div className={styles.title}>
                    Issue Type
                </div>
                
                <div className={styles.value}>
                    {type}
                </div>
            </div>

            <div className={styles.column}>
                <div className={styles.title}>
                    Issue Status
                </div>
                
                <div className={styles.value}>
                    {status}
                </div>
            </div>

        </div>


        <div className={styles.row}>
            <div className={styles.column}>
                <div className={styles.title}>
                    Created On
                </div>
                
                <div className={styles.value}>
                    {created}
                </div>
            </div>

            <div className={styles.column}>
                <div className={styles.title}>
                    Week Name
                </div>
                
                <div className={styles.value}>
                    {week}
                </div>
            </div>

        </div>

        <div className={styles.column}>
                <div className={styles.title}>
                    Description
                </div>
                
                <div className={styles.value}>
                    {description}
                </div>
            </div>
    </div>
  )
}

export default RaisedTickets