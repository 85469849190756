import React from "react";

function ExportIcon() {
  return (
    <div>
      <img
        src="https://advertflair-public.s3.us-west-2.amazonaws.com/assets/icons/exporticon.svg"
        alt=""
      />
    </div>
  );
}

export default ExportIcon;
