import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { useNavigate } from "react-router-dom";

function QcReport() {
  const [reportData, setReportData] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    fetch("https://3dstudio.advertflair.com/report/qcReport.php?qc_type=IQA")
      .then((response) => response.json())
      .then((data) => setReportData(data.data))
      .catch((error) => console.error("Error fetching data: ", error));
  }, []);
  return (
    <div className={styles.container}>
      <div className={styles.headerSection}>
        <div className={styles.header}>
          <h1>IQA-2 Review Jobs</h1>
          <p>{reportData.length} Jobs in review</p>
        </div>
      </div>

      <table>
        <thead>
          <tr>
            <td>POID</td>
            <td>Artist</td>
            <td>IQA Artist</td>
            <td>IQA Rounds</td>
            <td>Action</td>
          </tr>
        </thead>
        <tbody>
          {reportData.map((item, index) => (
            <tr key={index}>
              <td>{item.partner_id}</td>
              <td>{item.artist}</td>
              <td>{item.qc_by}</td>
              <td>{item.qc_rounds}</td>
              <td>
                <button
                  onClick={() => {
                    navigate(`/qc-review/${item.partner_id}`);
                  }}
                >
                  Review
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default QcReport;
