import React from 'react'
import styles from './styles.module.scss'
import DashboardLayout from '../../templates/dashboard_layout/DashboardLayout'
import Title from '../../molecules/title/Title'
import FolderIcon from '../../atoms/icons/FolderIcon'
import LibrayHeader from '../../molecules/header/LibraryHeader/LibrayHeader'

function LibraryPage() {
  return (
    <DashboardLayout>
        <Title 
        title="Files Library"
        subtitle="Storage Made Smart: Your Secure Files Library"
        />

        <div className={styles.container}>
            <LibrayHeader />

            <div className={styles.contentSection}>
                <div className={styles.files}>
                    <FolderIcon 
                        poid="PTIJKDJGAKJSG"
                    />

                    <FolderIcon 
                        poid="PTIJKDJGAKJS4"
                    />

                    <FolderIcon 
                        poid="PTIJKDJGAKJ23"
                    />

                    <FolderIcon 
                        poid="PTIJKDJGAKJS9"
                    />

                    <FolderIcon 
                        poid="PTIJKDJGAKJS4"
                    />

                    <FolderIcon 
                        poid="PTIJKDJGAKJ23"
                    />

                    <FolderIcon 
                        poid="PTIJKDJGAKJS9"
                    />
                </div>
                <div className={styles.loadbutton}>
                    <button>Load More</button>
                </div>
                

            </div>

        </div>
    </DashboardLayout>
  )
}

export default LibraryPage