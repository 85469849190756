import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { useParams } from "react-router-dom";

function CanOverall() {
  const [desktopHeader, setDesktopHeader] = useState("");
  const [desktopFooter, setDesktopFooter] = useState("");
  const [mobileHeader, setMobileHeader] = useState("");
  const [mobileFooter, setMobileFooter] = useState("");
  const { client } = useParams();

  const handleClientChange = () => {
    if (client === "ralph-lauren") {
      setDesktopFooter(
        "https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/dowel-counter-stool/footer.webp"
      );
      setDesktopHeader(
        "https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/dowel-counter-stool/header.webp"
      );
      setMobileHeader(
        "https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/dowel-counter-stool/mobile_header.webp"
      );
      setMobileFooter(
        "https://advertflair-public.s3.us-west-2.amazonaws.com/client/anthropologie/dowel-counter-stool/mobile_footer.webp"
      );
    } else if (client === "urbn") {
      setDesktopFooter(
        "https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/overall/footer.webp"
      );
      setDesktopHeader(
        "https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/overall/header.webp"
      );
      setMobileHeader(
        "https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/overall/mobileheader.webp"
      );
      setMobileFooter(
        "https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/overall/mobilefooter.webp"
      );
    }
  };

  useEffect(() => {
    handleClientChange();
  }, [client]);

  const [mobileGlb, setMobileGlb] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [imageView, setImageView] = useState(true);
  const [touchableGlbContainer, setTouchableGlbContainer] = useState(false);

  const toggleVideo = () => {
    setShowVideo(true);
    setMobileGlb(false);
    setImageView(false);
    setTouchableGlbContainer(false);
  };

  const [selectedImage, setSelectedImage] = useState(
    "https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/owala_freesip_black/render_1.webp"
  );

  const toggleMobileglb = () => {
    setMobileGlb(true);
    setImageView(false);
    setShowVideo(false);
    setTouchableGlbContainer(false);
  };

  const handleImageClick = (imageSrc) => {
    setShowVideo(false);
    setMobileGlb(false);
    setSelectedImage(imageSrc);
    setImageView(true);
    setTouchableGlbContainer(false);
  };

  const toggletouchableGlb = () => {
    setShowVideo(false);
    setMobileGlb(false);
    setImageView(false);
    setTouchableGlbContainer(true);
    console.log("working");
  };

  return (
    <div>
      <div className={styles.container}>
        <img src={mobileHeader} alt="" />

        <div className={styles.renderSection}>
          {mobileGlb && (
            <iframe
              title="mobileGlb"
              src="https://www.pod.advflr.com/turnaround/urbanoutfitters/owala_freesip_black"
            ></iframe>
          )}

          {touchableGlbContainer && (
            <iframe
              title="animatedglb"
              src="https://app.vectary.com/p/7QWY3rWb7dip8vFGJiGk4S"
              seamless
            ></iframe>
          )}

          {showVideo && (
            <video
              id="my-video"
              class="video-js"
              controls
              preload="auto"
              autoPlay
              poster=""
              data-setup="{}"
              muted
            >
              <source
                src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/owala_freesip_black/video.mp4"
                type="video/mp4"
              />
            </video>
          )}

          {imageView && <img src={selectedImage} alt="" />}
        </div>

        <div className={styles.thumbnailSection}>
          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/owala_freesip_black/render_1.webp"
            alt=""
            onClick={() =>
              handleImageClick(
                "https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/owala_freesip_black/render_1.webp"
              )
            }
          />

          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/owala_freesip_black/render_2.webp"
            alt=""
            onClick={() =>
              handleImageClick(
                "https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/owala_freesip_black/render_2.webp"
              )
            }
          />

          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/owala_freesip_black/360_thumbnail-1.webp"
            onClick={toggleMobileglb}
            alt=""
          />
          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/owala_freesip_black/video_thumbnail-3.webp"
            onClick={toggleVideo}
            alt=""
          />

          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/belkin/earbuds/touch_view.webp"
            alt=""
            onClick={toggletouchableGlb}
          />

          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/owala_freesip_black/render_3.webp"
            alt=""
            onClick={() =>
              handleImageClick(
                "https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/owala_freesip_black/render_3.webp"
              )
            }
          />

          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/owala_freesip_black/render_4.webp"
            alt=""
            onClick={() =>
              handleImageClick(
                "https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/owala_freesip_black/render_4.webp"
              )
            }
          />
        </div>

        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/owala_freesip_black/mobile_details.webp"
          alt=""
        />
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/owala_freesip_black/mobile_likable.webp"
          alt=""
        />
        <img src={mobileFooter} alt="" />
      </div>

      <div className={styles.desktopContainer}>
        <img src={desktopHeader} alt="" />

        <div className={styles.mainContainer}>
          <div className={styles.renderSection}>
            {mobileGlb && (
              <iframe
                title="mobileGlb"
                src="https://www.pod.advflr.com/turnaround/urbanoutfitters/owala_freesip_black"
              ></iframe>
            )}
            {touchableGlbContainer && (
              <iframe
                title="animatedglb"
                src="https://app.vectary.com/p/7QWY3rWb7dip8vFGJiGk4S"
                seamless
              ></iframe>
            )}

            {showVideo && (
              <video
                id="my-video"
                class="video-js"
                controls
                preload="auto"
                width="737"
                height="502"
                autoPlay
                poster=""
                data-setup="{}"
                muted
              >
                <source
                  src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/owala_freesip_black/video.mp4"
                  type="video/mp4"
                />
              </video>
            )}

            {imageView && <img src={selectedImage} alt="" />}
          </div>

          <div className={styles.cartSection}>
            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/owala_freesip_black/details.webp"
              alt=""
            />
          </div>
        </div>

        <div className={styles.thumbnailSection}>
          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/owala_freesip_black/render_1.webp"
            alt=""
            onClick={() =>
              handleImageClick(
                "https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/owala_freesip_black/render_1.webp"
              )
            }
          />
          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/owala_freesip_black/render_2.webp"
            alt=""
            onClick={() =>
              handleImageClick(
                "https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/owala_freesip_black/render_2.webp"
              )
            }
          />
          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/owala_freesip_black/360_thumbnail-1.webp"
            onClick={toggleMobileglb}
            alt=""
          />

          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/belkin/earbuds/touch_view.webp"
            alt=""
            onClick={toggletouchableGlb}
          />
          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/owala_freesip_black/video_thumbnail-3.webp"
            onClick={toggleVideo}
            alt=""
          />

          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/owala_freesip_black/render_3.webp"
            alt=""
            onClick={() =>
              handleImageClick(
                "https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/owala_freesip_black/render_3.webp"
              )
            }
          />

          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/owala_freesip_black/render_4.webp"
            alt=""
            onClick={() =>
              handleImageClick(
                "https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/owala_freesip_black/render_4.webp"
              )
            }
          />
        </div>

        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/owala_freesip_black/recently_viewed.webp"
          alt=""
        />

        <img src={desktopFooter} alt="" />
      </div>
    </div>
  );
}

export default CanOverall;
