import React, { useState } from "react";
import styles from "./styles.module.scss";

function URBGrandeBateau() {
  const [selectedImage, setSelectedImage] = useState(
    "https://advertflair-public.s3.us-west-2.amazonaws.com/client/clarev/grandetote-2/render1.webp"
  );
  const [imageContainer, setImageContainer] = useState(true);
  const [glbContainer, setGlbContainer] = useState(false);
  const [tryonContainer, setTryonContainer] = useState(false);

  const handleImageClick = (imageSrc) => {
    setGlbContainer(false);
    setSelectedImage(imageSrc);
    setImageContainer(true);
    setTryonContainer(false);
  };

  const toggleGlb = () => {
    setGlbContainer(true);
    setImageContainer(false);
    setTryonContainer(false);
  };

  const tryonGlb = () => {
    setGlbContainer(false);
    setImageContainer(false);
    setTryonContainer(true);
  };

  return (
    <div>
      <div className={styles.container}>
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/overall/header.webp"
          alt=""
        />

        <div className={styles.mainSection}>
          <div className={styles.leftSection}>
            <div className={styles.renderSection}>
              <div className={styles.textSection}>
                <p onClick={tryonGlb}>Try Before Buy</p>
              </div>
              {imageContainer && <img src={selectedImage} alt="" />}
              {glbContainer && (
                <iframe
                  title="desktopGlb"
                  seamless
                  src="https://www.pod.advflr.com/turnaround/clarev/grandetote"
                ></iframe>
              )}

              {tryonContainer && (
                <iframe
                  title="desktopGlb"
                  seamless
                  allow="camera"
                  src="https://www.bagsdk.advflr.com/?glb=https://advertflair-public.s3.us-west-2.amazonaws.com/client/clarev/output/grandebateau_ar.glb"
                ></iframe>
              )}
            </div>

            <div className={styles.thumbnailSection}>
              <img
                src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/clarev/grandetote-2/render1.webp"
                onClick={() =>
                  handleImageClick(
                    "https://advertflair-public.s3.us-west-2.amazonaws.com/client/clarev/grandetote-2/render1.webp"
                  )
                }
              />
              <img
                src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/clarev/grandetote-2/render2.webp"
                onClick={() =>
                  handleImageClick(
                    "https://advertflair-public.s3.us-west-2.amazonaws.com/client/clarev/grandetote-2/render2.webp"
                  )
                }
              />
              <img
                src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/clarev/grandetote-2/render3.webp"
                onClick={() =>
                  handleImageClick(
                    "https://advertflair-public.s3.us-west-2.amazonaws.com/client/clarev/grandetote-2/render3.webp"
                  )
                }
              />
              <img
                src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/366-1.webp"
                onClick={toggleGlb}
              />
            </div>
          </div>
          <div className={styles.rightSection}>
            <h1>PETIT MOYEN : Camel New Look</h1>
            <p>See all Petit Moyen</p>
            <h1>$1,800.00</h1>
            <div className={styles.color}>Color:</div>

            <button>Add to Bag</button>

            <p> Or 4 interest-free installments of $25.00 with or </p>
          </div>
        </div>

        <div className={styles.footerSection}>
          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/overall/footer.webp"
            alt=""
          />
        </div>
      </div>
      <div className={styles.mobileContainer}>
        <img
          src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/overall/mobileheader.webp"
          alt=""
        />

        <div className={styles.mainSection}>
          <div className={styles.renderSection}>
            <div className={styles.textSection}>
              <p onClick={tryonGlb}>Try Before Buy</p>
            </div>
            {imageContainer && <img src={selectedImage} alt="" />}
            {glbContainer && (
              <iframe
                title="desktopGlb"
                seamless
                src="https://www.pod.advflr.com/turnaround/clarev/grandetote"
              ></iframe>
            )}

            {tryonContainer && (
              <iframe
                title="desktopGlb"
                seamless
                allow="camera"
                src="https://www.bagsdk.advflr.com/?glb=https://advertflair-public.s3.us-west-2.amazonaws.com/client/clarev/output/grandebateau_ar.glb"
              ></iframe>
            )}
          </div>

          <div className={styles.thumbnailSection}>
            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/clarev/grandetote-2/render1.webp"
              onClick={() =>
                handleImageClick(
                  "https://advertflair-public.s3.us-west-2.amazonaws.com/client/clarev/grandetote-2/render1.webp"
                )
              }
            />
            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/clarev/grandetote-2/render2.webp"
              onClick={() =>
                handleImageClick(
                  "https://advertflair-public.s3.us-west-2.amazonaws.com/client/clarev/grandetote-2/render2.webp"
                )
              }
            />
            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/clarev/grandetote-2/render3.webp"
              onClick={() =>
                handleImageClick(
                  "https://advertflair-public.s3.us-west-2.amazonaws.com/client/clarev/grandetote-2/render3.webp"
                )
              }
            />
            <img
              src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/stojo/cup/366-1.webp"
              onClick={toggleGlb}
            />
          </div>
        </div>

        <div className={styles.contentSection}>
          <h1>PETIT MOYEN : Camel New Look</h1>
          <p>See all Petit Moyen</p>
          <h1>$1,800.00</h1>
          <div className={styles.color}>Color:</div>

          <button>Add to Bag</button>

          <p> Or 4 interest-free installments of $25.00 with or </p>
        </div>

        <div className={styles.footerSection}>
          <img
            src="https://advertflair-public.s3.us-west-2.amazonaws.com/client/urbanoutfitters/overall/mobilefooter.webp"
            alt=""
          />
        </div>
      </div>
    </div>
  );
}

export default URBGrandeBateau;
