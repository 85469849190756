import React, { useEffect, useState } from "react";
import "../../../../index.css";
import useDisplayStore from "../../../../store/useDisplayStore";
import AdvertflairSidebar from "./AdvertflairSidebar";
import QRCode from "qrcode.react";

function AdvertflairContent() {
  //DisplaySection - Main Category , //subCategory - Industry
  const displaySection = useDisplayStore((state) => state.displaySection);
  const count = useDisplayStore((state) => state.count);
  const setCount = useDisplayStore((state) => state.setCount);
  const renderContainer = useDisplayStore((state) => state.renderContainer);
  const setRenderContainer = useDisplayStore(
    (state) => state.setRenderContainer
  );
  const [products, setProducts] = useState([]);
  const [embedUrl, setEmbedUrl] = useState("");
  const [categories, setCategories] = useState([]);

  const { setThumbnailsLoaded, thumbnailsLoaded, subCategory, setSubCategory } =
    useDisplayStore();

  const embedFunction = (url) => {
    setEmbedUrl(url);
    setRenderContainer(true);
  };

  const handleSubcategoryclick = (data) => {
    setThumbnailsLoaded(false);
    setSubCategory(data);
    fetchData();
  };

  const embedturnaround = (embedlink) => {
    setEmbedUrl(embedlink);
    setRenderContainer(true);
  };

  const fetchData = async () => {
    try {
      const response = await fetch(
        "https://advertflair-public.s3.us-west-2.amazonaws.com/assets/json/client-products.json"
      );
      const data = await response.json();

      let filteredData;
      let categoryData = data.filter(
        (product) => product.type === displaySection
      );

      if (subCategory !== "") {
        filteredData = categoryData.filter(
          (product) => product.category === subCategory
        );
      } else {
        filteredData = categoryData;
      }

      setProducts(filteredData);
      setCount(filteredData.length);

      const categoryCounts = categoryData.reduce((acc, item) => {
        acc[item.category] = (acc[item.category] || 0) + 1;
        return acc;
      }, {});

      const sortedCategories = Object.keys(categoryCounts)
        .map((category) => ({
          category,
          count: categoryCounts[category],
        }))
        .sort((a, b) => b.count - a.count);

      setCategories(sortedCategories);
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setThumbnailsLoaded(true);
    }
  };

  useEffect(() => {
    fetchData();
  }, [displaySection, subCategory]);

  return (
    <div className="flex flex-col gap-4 lg:flex-row bg-white">
      <div className="w-[20%]">
        <AdvertflairSidebar />
      </div>
      <div className="flex flex-col lg:w-[80%]">
        <div className="flex flex-col items-center ">
          <p className="text-slate-700 text-lg lg:text-2xl font-bold lg:pt-4 pb-4 lg:pb-0">
            Advertflair / {displaySection === "images" && "3D Rendering"}
            {displaySection === "videos" && "Render Videos"}
            {displaySection === "turnaround" && "360/AR/VR"}
            {displaySection === "interactive" && "Interactive"}
            {displaySection === "tryons" && "Virtual Try-On"}
            {displaySection === "configurator" && "Configurator"}
            {displaySection === "virtual-showroom" && "Virtual Showroom"}
          </p>
        </div>

        {renderContainer && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm">
            <div className="w-4/5   bg-gray-50 fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex items-center justify-center border border-black shadow-lg z-50 flex-col">
              {displaySection === "images" && (
                <img
                  className="object-contain lg:object-cover h-[80vh] lg:h-[700px]"
                  src={embedUrl}
                  alt=""
                />
              )}

              {(displaySection === "videos" ||
                displaySection === "interactive" ||
                displaySection === "virtual-showroom" ||
                displaySection === "configurator") && (
                <iframe
                  className="h-[80vh] lg:h-[660px] w-[100%] object-cover"
                  src={embedUrl}
                  frameborder="0"
                  title="product1"
                  allow="autoplay"
                ></iframe>
              )}

              {displaySection === "turnaround" && (
                <>
                  <iframe
                    className="h-[80vh] lg:h-[660px] w-[100%] object-cover"
                    src={embedUrl}
                    frameborder="0"
                    allow="camera"
                    title="product1"
                  ></iframe>

                  <QRCode
                    className="hidden lg:flex absolute top-1/2 right-8 w-[200px] h-[200px]"
                    value={embedUrl}
                    size={256}
                  />
                </>
              )}

              {displaySection === "tryons" && (
                <>
                  <iframe
                    className="h-[80vh] lg:h-[660px] w-[100%] object-cover"
                    src={embedUrl}
                    frameborder="0"
                    allow="camera"
                    title="product1"
                  ></iframe>

                  <QRCode
                    className="hidden rounded-md p-3 bg-white/40 lg:flex absolute top-1/2 right-8 w-[200px] h-[200px]"
                    value={embedUrl}
                    size={256}
                  />
                </>
              )}

              <button
                className="absolute top-2 right-2 text-white text-2xl font-bold px-[8px] py-[1px] rounded-full bg-red-700 items-center"
                onClick={() => setRenderContainer(false)}
              >
                &times;
              </button>
            </div>
          </div>
        )}

        <div>
          <p className=" hidden lg:flex lg:flex-row lg:items-center text-sm pl-12 lg:pl-0 lg:text-xl text-slate-700 py-2">
            {displaySection === "images" && "3D Rendering"}
            {displaySection === "videos" && "3D Product Videos"}
            {displaySection === "turnaround" && "360/AR/VR"}
            {displaySection === "interactive" && "Interactive"}
            {displaySection === "tryons" && "Virtual Try-On"}
            {displaySection === "virtual-showroom" && "Virtual Showroom"}
            {displaySection === "configurator" && "Configurator"} {" - "}
            {count} Products
          </p>
        </div>
        <div className="flex gap-2 items-center pb-2 w-full overflow-scroll">
          <p
            onClick={() => handleSubcategoryclick("")}
            className={
              subCategory === ""
                ? "px-3 py-1 whitespace-nowrap font-bold rounded-2xl border-[1px] bg-slate-800 text-white "
                : "px-3 py-1 whitespace-nowrap font-bold rounded-2xl border-[1px] border-slate-800 hover:bg-slate-800 hover:text-white cursor-pointer"
            }
          >
            Overall
          </p>

          {categories.map((cat) => (
            <p
              key={cat.category}
              onClick={() => handleSubcategoryclick(cat.category)}
              className={
                subCategory === cat.category
                  ? "px-3 py-1 whitespace-nowrap font-bold rounded-full border-[1px] bg-slate-800 text-white "
                  : "px-3 py-1 whitespace-nowrap font-bold rounded-full border-[1px] border-slate-800 hover:bg-slate-800 hover:text-white cursor-pointer"
              }
            >
              {cat.category.charAt(0).toUpperCase() + cat.category.slice(1)} -{" "}
              {cat.count}
            </p>
          ))}
        </div>
        {thumbnailsLoaded ? (
          <>
            {/* All Thumbnail Loaded */}
            <div className="grid grid-cols-2 lg:grid-cols-3 gap-2">
              {displaySection === "virtual-showroom" &&
                products.map((image) => (
                  <img
                    key={image.id}
                    src={image.thumbnail}
                    alt={image.name}
                    className="h-[168px] w-[224px] lg:h-[544px] lg:w-[364px] object-cover rounded-md cursor-pointer"
                    onClick={() => {
                      embedFunction(image.outputlink);
                    }}
                  />
                ))}

              {displaySection === "images" &&
                products.map((image) => (
                  <img
                    key={image.id}
                    src={image.thumbnail}
                    alt={image.name}
                    className="h-[168px] w-[224px] lg:h-[544px] lg:w-[364px] object-cover rounded-md cursor-pointer"
                    onClick={() => {
                      embedFunction(image.thumbnail);
                    }}
                  />
                ))}
              {displaySection === "videos" &&
                products.map((video) => (
                  <img
                    key={video.id}
                    src={video.thumbnail}
                    alt={video.name}
                    className="object-cover h-[168px] w-[224px] lg:h-[544px] lg:w-[364px] rounded-md cursor-pointer "
                    onClick={() => embedFunction(video.outputlink)}
                  />
                ))}
              {displaySection === "turnaround" &&
                products.map((model) => (
                  <img
                    key={model.id}
                    src={model.thumbnail}
                    alt={model.name}
                    className="object-cover h-[168px] w-[224px] lg:h-[544px] lg:w-[364px] rounded-md cursor-pointer "
                    onClick={() => embedturnaround(model.outputlink)}
                  />
                ))}
              {displaySection === "interactive" &&
                products.map((model) => (
                  <img
                    key={model.id}
                    src={model.thumbnail}
                    alt={model.name}
                    className="object-cover h-[168px] w-[224px] lg:h-[544px] lg:w-[364px] rounded-md cursor-pointer"
                    onClick={() => embedFunction(model.outputlink)}
                  />
                ))}
              {displaySection === "tryons" &&
                products.map((model) => (
                  <img
                    key={model.id}
                    src={model.thumbnail}
                    alt={model.name}
                    className="object-cover h-[168px] w-[224px] lg:h-[544px] lg:w-[364px] rounded-md cursor-pointer"
                    onClick={() => embedturnaround(model.outputlink)}
                  />
                ))}
              {displaySection === "configurator" &&
                products.map((model) => (
                  <img
                    key={model.id}
                    src={model.thumbnail}
                    alt={model.name}
                    className="object-cover h-[168px] w-[224px] lg:h-[544px] lg:w-[364px] rounded-md cursor-pointer"
                    onClick={() => embedFunction(model.outputlink)}
                  />
                ))}
            </div>
          </>
        ) : (
          <>
            <div className="grid grid-cols-2 lg:grid-cols-3 gap-2">
              {/* Skeleton Loader */}
              {Array.from({ length: 6 }).map((_, index) => (
                <div
                  key={index}
                  className="h-[168px] w-[224px] lg:h-[544px] lg:w-[364px] bg-gray-300 animate-pulse rounded-md"
                />
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default AdvertflairContent;
