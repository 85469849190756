import React from "react";
import styles from "./styles.module.scss";
import { useParams } from "react-router-dom";

function TurnAround() {
  const { client, product } = useParams();

  return (
    <div className={styles.container}>
      <model-viewer
        src={`https://advertflair-public.s3.us-west-2.amazonaws.com/client/${client}/output/${product}.glb`}
        ar
        poster={`https://advertflair-public.s3.us-west-2.amazonaws.com/client/${client}/output/${product}.webp`}
        shadow-intensity="1"
        camera-controls
        style={{ width: "100%", height: "90vh" }}
        touch-action="pan-y"
        autoplay
      >
        <div class="progress-bar hide" slot="progress-bar">
          <div class="update-bar"></div>
        </div>
        <button
          slot="ar-button"
          id="ar-button"
          className="absolute bottom-0 right-1 px-2 py-1 bg-gradient-to-r from-orange-400 to-red-400 text-white font-bold rounded-md"
        >
          View in AR
        </button>
      </model-viewer>
    </div>
  );
}

export default TurnAround;
