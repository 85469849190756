import React from "react";
import { pagelayout } from "../../../../assets/bestbuy";

function BestbuyHeader() {
  const desktopHeader = pagelayout.find(
    (item) => item.name === "desktopHeader"
  ).link;

  const mobileHeader = pagelayout.find(
    (item) => item.name === "mobileHeader"
  ).link;
  return (
    <div className="w-screen">
      <img className="hidden md:flex" src={desktopHeader} alt="" />
      <img className="md:hidden" src={mobileHeader} alt="" />
    </div>
  );
}

export default BestbuyHeader;
