import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { useNavigate, useParams } from "react-router-dom";
function QcReview() {
  const { partner_id } = useParams();
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate(-1);
  };
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        `https://3dstudio.advertflair.com/report/qcReport.php?qc_type=IQA&partner_id=${partner_id}`
      );
      const result = await response.json();
      setData(result.data);
    };

    fetchData();
  }, [partner_id]);
  return (
    <div className={styles.container}>
      <div className={styles.headerSection}>
        <img src="/assets/icons/back.png" onClick={handleBackClick} alt="" />
        <div className={styles.header}>
          <h1>Back to home</h1>
          <p>{data.length > 0 ? data[0].partner_id : "Loading..."} - Report</p>
        </div>
      </div>

      {data.map((item, index) => (
        <div key={index} className={styles.rejectionContainer}>
          <div className={styles.rejectionHeader}>
            <ul>
              <li>IQA - {item.qc_rounds}</li>
              <li>Artist - {item.artist}</li>
              <li>QC - {item.qc_by}</li>
              <li>{item.date}</li>
            </ul>
          </div>

          <div className={styles.rejections}>
            {JSON.parse(item.rejections).map((rejection, rejectionIndex) => {
              return (
                <div key={rejectionIndex} className={styles.rejection}>
                  <img
                    src={`https://3dstudio.advertflair.com${rejection.image_location}`}
                    alt={rejection.category}
                    className={styles.rejection}
                  />
                  <p>
                    {rejection.category} - {rejection.comment}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      ))}
    </div>
  );
}

export default QcReview;
